import React, { useState, useEffect } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import SelectExchangeSearchEngineFilter from "../../molecule/Select_ExchangeSearchEngineFilter/Select_ExchangeSearchEngineFilter";
import { Link } from "react-router-dom";
import EquipoExchangeCard from "../../../components/organism/EquipoExchangeCard/EquipoExchangeCard";
import { Button, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import { EquiposExchangeAPI } from "../../../services/API";
import { filterEquipos } from "../../../helpers/Filter";
import AuthService from "../../../services/AuthService";

export default function TabExchangePlanta(props) {
  const {
    plantaId,
    history,
    goBack,
    IsPartnerPlanta
  } = props;
  const [loadingEquipos, setLoadingEquipos] = useState(false);
  const [equipmetSearch, setEquipmetSearch] = useState('');
  const [activeFilter, setActiveFilter] = useState(0);
  const [equiposExchange, setEquiposExchange] = useState([]);
  const isExchange = AuthService.isExchange();

  useEffect(() => {
    setLoadingEquipos(true);
    EquiposExchangeAPI.getEquiposPlanta(plantaId)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquiposExchange(data);
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  }, [plantaId]);

  return (
    <RelativeBackdrop hidden={!loadingEquipos}>
      <Grid container spacing={2}>
        <Grid item container xs={12} sm={8} md={6} xl={4}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: equipmetSearch,
              onChange: (event) => {
                setEquipmetSearch(event.target.value);
              },
            }}
            goBack={goBack}
            endButton={
              (plantaId && isExchange) && (
                <Button
                  style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
                  onClick={() => history.push(`./${plantaId}/equipoexchange/`, { IsPartnerPlanta: IsPartnerPlanta })}
                >
                  <Add />
                  {i18n.t("common.new.masculine")}
                </Button>
              )
            }
          />
        </Grid>
        <Grid item container xs={12} sm={4} md={3} xl={2}>
          <SelectExchangeSearchEngineFilter
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
          />
        </Grid>
        <Grid item container spacing={2}>
            {filterEquipos(equiposExchange, equipmetSearch)
              .filter(
                (e) =>
                  activeFilter === 0 ||
                  (e.estadoExchange === 0 && activeFilter === 1) ||
                  (e.estadoExchange === 1 && activeFilter === 2) ||
                  (e.estadoExchange === 2 && activeFilter === 3)
              )
              .map((equipoExchange) => {
                return (
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    md={3}
                    xl={2}
                    style={{ padding: 8 }}
                    key={equipoExchange.id}
                  >
                    <Link
                      to={{
                        pathname: `./${plantaId}/equipoexchange/${equipoExchange.id}`, 
                        state: { IsPartnerPlanta: IsPartnerPlanta }
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <EquipoExchangeCard
                        equipoExchange={equipoExchange}
                        plantaFrom={plantaId}
                      />
                    </Link>
                  </Grid>
                );
              })}
        </Grid>
      </Grid>
    </RelativeBackdrop>
  );
}
