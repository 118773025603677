import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import PropTypes from "prop-types";
import { Divider } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import RelativeBackdrop from "./RelativeBackdrop";

export default class AlertDialog extends Component {
  state = {
    open: false,
    item: null,
  };

  getItem = () => {
    return this.state.item;
  };

  open = (item) => {
    this.setState({ open: true, item: item });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      isWarning,
      title,
      text,
      confirmText,
      confirmAction,
      cancelText,
      style,
      maxWidth,
      fullWidth,
      contentStyle,
      loading,
      leftButton,
      confirmDisabled,
      cancelAction,
    } = this.props;

    return (
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        style={style}
        open={this.state.open}
        onClose={cancelAction || this.close}
      >
        <DialogTitle>
          {isWarning && (
            <Warning
              style={{ verticalAlign: "text-bottom" }}
              className="mr-2"
            />
          )}
          {title}
        </DialogTitle>
        <Divider />
        <RelativeBackdrop hidden={!loading}>
          <DialogContent className="mt-2" style={contentStyle}>
            {typeof text === "string" ? (
              <DialogContentText>{text}</DialogContentText>
            ) : (
              text
            )}
          </DialogContent>
          <DialogActions>
            {leftButton}
            {cancelText && (
              <Button onClick={cancelAction || this.close} color="primary">
                {cancelText}
              </Button>
            )}
            <Button
              disabled={confirmDisabled}
              onClick={confirmAction}
              color="primary"
              autoFocus
            >
              {confirmText}
            </Button>
          </DialogActions>
        </RelativeBackdrop>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  isWarning: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmDisabled: PropTypes.bool,
  confirmAction: PropTypes.func,
  cancelText: PropTypes.string,
  loading: PropTypes.bool,
  leftButton: PropTypes.node,
};
