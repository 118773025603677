import { Card, CardContent } from "@material-ui/core";
import React from "react";
import Gbar from "../../../assets/images/g-bar.png";
import EquipoRepuestosEnAlmacen from "../../molecule/equipoRepuestosEnAlmacen/EquipoRepuestosEnAlmacen";

export default function CardStockIdentico(props) {
  const {
    equipo,
    stock_identico
  } = props;
  return (
    <>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 25,
          marginTop: 25,
          backgroundImage: `url("${Gbar}")`,
          backgroundSize: "cover",
          height: 3,
          width: "100%",
        }}
      />
      <Card>
        {/* <CardHeader title={i18n.t("equipo.sustitutos.equal")} /> */}
        <CardContent>
          <EquipoRepuestosEnAlmacen
            equipo={equipo}
            sustituciones={stock_identico}
          />
        </CardContent>
      </Card>
    </>
  );
}
