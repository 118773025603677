import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import React from "react";
import i18n from "../../../locales/i18n";
import withHover from "../../withHover";

const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

/**
 * Card que aparece en la lista de Plantas y de Clientes
 * @Component
 * @param {Object} props
 * @param {string} props.title //Titulo Principal de la Card
 * @param {string} props.subtitle // Informacin secundaria de la cad
 * @param {Array} props.contents // Lineas de datos
 * @param {Object} props.onClick //evento click
 * @param {string} props.type // Tipo de calrd [Cliente, Planta]
 * @param {Object} props.style // Fondo y estilo de la card
 *
 */
function CardPlantClient(props) {
  const { title, subtitle, contents, onClick, type, style } = props;
  const abbreviatonLetters = title.split(" ");
  const abbreviaton =
    abbreviatonLetters.length > 1
      ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
      : title.substr(0, 2).toUpperCase();
  return (
    <CardWithHover
      onClick={onClick}
      style={style}
    >
      <CardHeader
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{ noWrap: true, variant: "body2" }}
        subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
        title={title}
        subheader={subtitle}
        avatar={
          <Avatar
            aria-label="avatar"
            style={{ backgroundColor: "#552877", fontWeight: 300 }}
          >
            {abbreviaton}
          </Avatar>
        }
      />
      <CardContent>
        {contents.map((value, index) => (
          <Typography key={index} variant="body2" display="block" noWrap>
            {i18n.t(type + "." + value.key)} : {value.value}
          </Typography>
        ))}
      </CardContent>
    </CardWithHover>
  );
}

export default CardPlantClient;
