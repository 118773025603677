export const devVersion = "ID238";

/**
 *  Funcion que cmprueva si un variador es Vacon
 * True - Si
 * False - No
 */
export function isVacon(brand) {
    if (brand === "Vacon") {
      return true;
    } else {
      return false;
    }
}

export const delay = (function () {
  let timer = 0;
  return function (callback, ms) {
    clearTimeout(timer);
    timer = parseInt(setTimeout(callback, ms).toString());
  };
})(); 

/**
 *  Funcion que devuelve la cadena de entrada 
 *  con la primera letra en mayuscula
 */
export const capitalizeFirstLetter = (string) => {
  if (string.length <= 3) {
    return string.toUpperCase();
  } else {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
};
