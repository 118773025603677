import React, { useState, useEffect } from "react";

import i18n from "../../../locales/i18n";
import {
  ClientesAPI,
  PlantasAPI,
  ZonasAPI,
  UsersAPI,
  PartnersAPI,
} from "../../../services/API";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import {
  TextField,
  Card,
  Grid,
  CardHeader,
  CardContent,
  IconButton,
  CardActions,
  Avatar,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  Button,
} from "@material-ui/core";
import {
  Save,
  Cancel,
  ArrowBack,
  AssignmentInd,
  Visibility,
  VisibilityOff,
  Send,
} from "@material-ui/icons";
import AuthService from "../../../services/AuthService";
import Swal from "sweetalert2";

export default function UserAdd(props) {
  const { setNavBarTitle } = props;
  const [loading, setLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [cargo, setCargo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [partner, setPartner] = useState(0);
  const [showPwd, setShowPwd] = useState(false);
  const [repeatPwd, setRepeatPwd] = useState("");
  const [showRepeatPwd, setShowRepeatPwd] = useState(false);
  const [level, setLevel] = useState("");
  const [elementsToAccess, setElementsToAccess] = useState([]);
  const [elementAccess, setElementAccess] = useState("");
  const [rolesToAccess, setRolesToAccess] = useState([]);
  const [roleAccess, setRoleAccess] = useState("");
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [unblockForm, setUnblockForm] = useState(false);
  const [editable, setEditable] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  //   let gridWidth = fullWidth ? 12 : 10;
  //   let colWith = fullWidth ? 2 : 4;
  //   let spacing = fullWidth ? 0 : 3;

  useEffect(() => {
    setNavBarTitle(i18n.t("gestorUsuarios.titulo"));
    document.getElementById("main-content").scrollTop = 0;
  }, [setNavBarTitle]);

  useEffect(() => {
    getPartnerOptions();
  }, []);

  const getPartnerOptions = async () => {
    return await PartnersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        var partners = [];
        data.forEach((element) => {
          partners.push({ id: element.id, name: element.razonSocial });
        });
        setPartnersOptions(partners);
      })
      .catch(() => {});
  };

  const handleChangePartnerValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setPartner(value);
  };

  const handleEmailChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setEmail(value);
    setNombre("");
    setApellidos("");
    setCargo("");
    setTelefono("");
    setRepeatPwd("");
    setPassword("");

    setUnblockForm(false);
  };

  const verificarEmail = async () => {
    let newFormErrors = {};
    if (email === "") {
      newFormErrors.email = i18n.t("textField.required");
    }

    if (Object.entries(newFormErrors).length === 0) {
      setFormErrors({});
      setLoading(true);
      await UsersAPI.getIfUserExist(email)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data.exist) {
            Swal.fire({
              icon: "info",
              title: i18n.t("gestorUsuarios.nuevoUsuario.existe.titulo"),
              text: i18n.t("gestorUsuarios.nuevoUsuario.existe.texto"),
            });
            var usuario = data.user;
            setNombre(usuario.nombre);
            setApellidos(usuario.apellidos);
            setCargo(usuario.cargo);
            setTelefono(usuario.telefono);
            setEditable(false);
            setUnblockForm(true);
          } else {
            setUnblockForm(true);
            setEditable(true);
          }
        });
      setLoading(false);
    } else {
      setFormErrors(newFormErrors);
    }
  };

  async function CreateNewUser() {
    console.log(
      "Create new user: " +
        "email: " +
        email +
        ", password: " +
        password +
        ", repeat: " +
        repeatPwd +
        ", level: " +
        level +
        ", element: " +
        elementAccess +
        ", rol: " +
        roleAccess
    );

    if (!props.keyuser && partner === 0) {
      setPartner(AuthService.getPartnerId());
    }

    let newFormErrors = {};
    if (nombre === "") {
      newFormErrors.nombre = i18n.t("textField.required");
    }
    if (apellidos === "") {
      newFormErrors.apellidos = i18n.t("textField.required");
    }
    if (email === "") {
      newFormErrors.email = i18n.t("textField.required");
    }
    if (password === "" && editable) {
      newFormErrors.password = i18n.t("textField.required");
    }
    if (password !== repeatPwd && editable) {
      newFormErrors.repeatPwd = i18n.t(
        "gestorUsuarios.nuevoUsuario.passwordNoCoincide"
      );
    }
    if (level === "") {
      newFormErrors.level = i18n.t("textField.required");
    }
    if (elementAccess === "") {
      newFormErrors.elementAccess = i18n.t("textField.required");
    }
    if (roleAccess === "") {
      newFormErrors.roleAccess = i18n.t("textField.required");
    }

    if (partner === 0) {
      newFormErrors.partner = i18n.t("textField.required");
    }

    if (Object.entries(newFormErrors).length === 0) {
      setFormErrors({});
      setLoading(true);
      let requestUser = {
        nombre: nombre,
        apellidos: apellidos,
        cargo: cargo,
        telefono: telefono,
        email: email,
        password: password,
        repeatPwd: repeatPwd,
        level: level,
        elementAccess: elementAccess,
        roleAccess: roleAccess,
        partner: partner,
      };
      UsersAPI.add(requestUser)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data.status === "error") {
            alert(data.message);
            console.log(data.message);
          } else {
            alert(data.message);
            props.history.push(`./users`);
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setFormErrors(newFormErrors);
    }
  }

  async function CancelNewUser() {
    setNombre("");
    setApellidos("");
    setCargo("");
    setTelefono("");
    setEmail("");
    setPassword("");
    setRepeatPwd("");
    setLevel("");
    setElementAccess("");
    setRoleAccess("");
    setShowPwd(false);
    setShowRepeatPwd(false);
    console.log("Cancel new user");
  }

  const handleInputChangeLevel = (event) => {
    setLevel(event.target.value);
    setLoading(true);
    if (event.target.value === "cliente") {
      ClientesAPI.get()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          let elements = [];
          data.forEach(function (item, index) {
            elements.push({ name: item.razonSocial, id: item.id });
          });
          setElementsToAccess(elements);
        })
        .catch((error) => {});

      setRolesToAccess(["gestor-cliente", "usuario-cliente"]);
    }
    if (event.target.value === "planta") {
      PlantasAPI.get()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          let elements = [];
          data.forEach(function (item, index) {
            elements.push({ name: item.denominacion, id: item.id });
          });
          setElementsToAccess(elements);
        })
        .catch((error) => {});

      setRolesToAccess(["gestor-planta", "usuario-planta"]);
    }
    if (event.target.value === "zona") {
      ZonasAPI.get()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          let elements = [];
          data.forEach(function (item, index) {
            elements.push({ name: item.descripcion, id: item.id });
          });
          setElementsToAccess(elements);
        })
        .catch((error) => {});

      setRolesToAccess(["gestor-zona", "usuario-zona"]);
    }
    if (event.target.value === "partner") {
      PartnersAPI.get()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          let elements = [];
          data.forEach(function (item, index) {
            elements.push({ name: item.razonSocial, id: item.id });
          });
          setElementsToAccess(elements);
        })
        .catch((error) => {});

      setRolesToAccess(["gestor-partner", "usuario-partner"]);
    }
    setLoading(false);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <RelativeBackdrop hidden={!loading}>
          <Card>
            <CardHeader
              title={i18n.t("gestorUsuarios.nuevoUsuario.tituloCard")}
              //subheader={planta.denominacion}
              avatar={
                <Avatar style={{ backgroundColor: "#f50057" }}>
                  <AssignmentInd />
                </Avatar>
              }
            ></CardHeader>
            <CardContent>
              <form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item container>
                    <Grid item xs={12} md={8} lg={6}>
                      <TextField
                        required
                        fullWidth
                        value={email}
                        autoComplete="new-email"
                        label={i18n.t("user.email")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="email"
                        onChange={(event) => handleEmailChange(event)}
                        error={formErrors["email"] !== undefined}
                        helperText={
                          formErrors["email"] !== undefined
                            ? formErrors["email"]
                            : null
                        }
                      />
                    </Grid>
                  </Grid>
                  {unblockForm && (
                    <>
                      <Grid item container>
                        <Grid item xs={6} md={4} lg={3} className="pr-3">
                          <TextField
                            required
                            inputProps={{
                              readOnly: !editable,
                            }}
                            fullWidth
                            value={nombre}
                            label={i18n.t("user.nombre")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="nombre"
                            onChange={(event) => {
                              setNombre(event.target.value);
                            }}
                            error={formErrors["nombre"] !== undefined}
                            helperText={
                              formErrors["nombre"] !== undefined
                                ? formErrors["nombre"]
                                : null
                            }
                          />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} className="pl-3">
                          <TextField
                            required
                            fullWidth
                            inputProps={{
                              readOnly: !editable,
                            }}
                            value={apellidos}
                            label={i18n.t("user.apellidos")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="apellidos"
                            onChange={(event) =>
                              setApellidos(event.target.value)
                            }
                            error={formErrors["apellidos"] !== undefined}
                            helperText={
                              formErrors["apellidos"] !== undefined
                                ? formErrors["apellidos"]
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Grid item xs={6} md={4} lg={3} className="pr-3">
                          <TextField
                            fullWidth
                            inputProps={{
                              readOnly: !editable,
                            }}
                            value={cargo}
                            label={i18n.t("user.cargo")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="cargo"
                            onChange={(event) => setCargo(event.target.value)}
                            error={formErrors["cargo"] !== undefined}
                            helperText={
                              formErrors["cargo"] !== undefined
                                ? formErrors["cargo"]
                                : null
                            }
                          />
                        </Grid>
                        <Grid item xs={6} md={4} lg={3} className="pl-3">
                          <TextField
                            fullWidth
                            inputProps={{
                              readOnly: !editable,
                            }}
                            value={telefono}
                            label={i18n.t("user.telefono")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="telefono"
                            onChange={(event) =>
                              setTelefono(event.target.value)
                            }
                            error={formErrors["telefono"] !== undefined}
                            helperText={
                              formErrors["telefono"] !== undefined
                                ? formErrors["telefono"]
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Grid item xs={12} md={8} lg={6}>
                          <FormControl
                            fullWidth
                            required
                            error={formErrors["level"] !== undefined}
                          >
                            <InputLabel shrink id="level">
                              {i18n.t(
                                "gestorUsuarios.nuevoUsuario.nivelAcceso"
                              )}
                            </InputLabel>
                            <Select
                              labelId="nivelAcceso"
                              value={level}
                              name="level"
                              onChange={handleInputChangeLevel}
                            >
                              {props.keyuser && (
                                <MenuItem value={"partner"}>
                                  {i18n.t("gestorUsuarios.nivelAcceso.partner")}
                                </MenuItem>
                              )}
                              <MenuItem value={"cliente"}>
                                {i18n.t("gestorUsuarios.nivelAcceso.cliente")}
                              </MenuItem>
                              <MenuItem value={"planta"}>
                                {i18n.t("gestorUsuarios.nivelAcceso.planta")}
                              </MenuItem>
                              <MenuItem value={"zona"}>
                                {i18n.t("gestorUsuarios.nivelAcceso.zona")}
                              </MenuItem>
                            </Select>
                            {formErrors["level"] !== undefined && (
                              <FormHelperText>
                                {formErrors["level"]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Grid item xs={12} md={8} lg={6}>
                          <FormControl
                            fullWidth
                            required
                            error={formErrors["elementAccess"] !== undefined}
                          >
                            <InputLabel shrink id="elementoAcceso">
                              {i18n.t(
                                "gestorUsuarios.nuevoUsuario.elementoAcceso"
                              )}
                            </InputLabel>
                            <Select
                              labelId="elementoAcceso"
                              value={elementAccess}
                              name="elementoAcceso"
                              onChange={(event) =>
                                setElementAccess(event.target.value.toString())
                              }
                            >
                              {elementsToAccess.map((element) => (
                                <MenuItem key={element.name} value={element.id}>
                                  {element.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {formErrors["elementAccess"] !== undefined && (
                              <FormHelperText>
                                {formErrors["elementAccess"]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Grid item xs={12} md={8} lg={6}>
                          <FormControl
                            fullWidth
                            required
                            error={formErrors["roleAccess"] !== undefined}
                          >
                            <InputLabel shrink id="roleAccess">
                              {i18n.t("gestorUsuarios.nuevoUsuario.rol")}
                            </InputLabel>
                            <Select
                              labelId="roleAccess"
                              value={roleAccess}
                              name="roleAccess"
                              onChange={(event) =>
                                setRoleAccess(event.target.value.toString())
                              }
                            >
                              {rolesToAccess.map((role) => (
                                <MenuItem key={role} value={role}>
                                  {i18n.t(`roles.${role}`)}
                                </MenuItem>
                              ))}
                            </Select>
                            {formErrors["roleAccess"] !== undefined && (
                              <FormHelperText>
                                {formErrors["roleAccess"]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      {props.keyuser && (
                        <Grid item container>
                          <Grid item xs={12} md={8} lg={6}>
                            <FormControl
                              fullWidth
                              required
                              error={formErrors["partner"] !== undefined}
                            >
                              <InputLabel shrink id="partner">
                                {i18n.t("gestorUsuarios.nuevoUsuario.partner")}
                              </InputLabel>
                              <Select
                                labelId="partner"
                                value={partner}
                                name="partner"
                                onChange={(event) =>
                                  handleChangePartnerValue(event)
                                }
                              >
                                {partnersOptions.map((element) => (
                                  <MenuItem key={element.id} value={element.id}>
                                    {element.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              {formErrors["partner"] !== undefined && (
                                <FormHelperText>
                                  {formErrors["partner"]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {editable && (
                        <>
                          <Grid item container>
                            <Grid item xs={12} md={8} lg={6}>
                              <FormControl
                                error={formErrors["password"] !== undefined}
                                required
                                fullWidth
                              >
                                <InputLabel htmlFor="password" shrink>
                                  {i18n.t(
                                    "gestorUsuarios.nuevoUsuario.password"
                                  )}
                                </InputLabel>
                                <Input
                                  id="password"
                                  type={showPwd ? "text" : "password"}
                                  name="password"
                                  autoComplete="new-password"
                                  value={password}
                                  onChange={(event) =>
                                    setPassword(event.target.value)
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setShowPwd(!showPwd)}
                                        onMouseDown={(event) => {
                                          event.preventDefault();
                                        }}
                                      >
                                        {showPwd ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                {formErrors["password"] !== undefined && (
                                  <FormHelperText id="password-error-text">
                                    {formErrors["password"] !== undefined
                                      ? formErrors["password"]
                                      : null}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item container>
                            <Grid item xs={12} md={8} lg={6}>
                              <FormControl
                                error={formErrors["repeatPwd"] !== undefined}
                                required
                                fullWidth
                              >
                                <InputLabel htmlFor="password" shrink>
                                  {i18n.t(
                                    "gestorUsuarios.nuevoUsuario.repeatPassword"
                                  )}
                                </InputLabel>
                                <Input
                                  id="repeatPassword"
                                  type={showRepeatPwd ? "text" : "password"}
                                  name="repeatPassword"
                                  value={repeatPwd}
                                  onChange={(event) =>
                                    setRepeatPwd(event.target.value)
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() =>
                                          setShowRepeatPwd(!showRepeatPwd)
                                        }
                                        onMouseDown={(event) => {
                                          event.preventDefault();
                                        }}
                                      >
                                        {showRepeatPwd ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                {formErrors["repeatPwd"] !== undefined && (
                                  <FormHelperText id="repeatPassword-error-text">
                                    {formErrors["repeatPwd"] !== undefined
                                      ? formErrors["repeatPwd"]
                                      : null}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </form>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton onClick={() => props.history.goBack()}>
                <ArrowBack />
              </IconButton>
              {!unblockForm && (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Send />}
                  onClick={() => verificarEmail()}
                >
                  {i18n.t("gestorUsuarios.nuevoUsuario.verificarEmail")}
                </Button>
              )}
              {unblockForm && (
                <IconButton onClick={() => CreateNewUser()}>
                  <Save />
                </IconButton>
              )}
              <IconButton
                style={{ marginLeft: "auto" }}
                onClick={() => CancelNewUser()}
              >
                <Cancel />
              </IconButton>
            </CardActions>
          </Card>
        </RelativeBackdrop>
      </Grid>
    </Grid>
  );
}
