import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  ArrowBackIos,
  ArrowForwardIos,
  Cancel,
  Memory,
  Save,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { isVacon } from "../../../helpers/Common";
import i18n from "../../../locales/i18n";
import { EquiposAPI } from "../../../services/API";
import AlertDialog from "../../AlertDialog";
import CardImagenesVariador from "../../molecule/cardImgVariador/cardImgVariador";
import RelativeBackdrop from "../../RelativeBackdrop";

/**
 * Dialog para Revision de variadores
 * @Component
 * @param {Object} props
 * @param {Boolean} props.open
 * @param {Boolean} props.readOnly
 * @param {() => void} props.closeFunction
 * @param {Object} props.equipo
 * @param {() => void} props.siguienteEquipo
 * @param {() => void} props.anteriorEquipo
 * @param {() => void} props.handleInputChange
 * @param {(inactivo, provisional) => void} props.guardarEquipo
 *
 */
export default function DialogRevisionEquipos(props) {
  const {
    open,
    closeFunction,
    equipo,
    siguienteEquipo,
    anteriorEquipo,
    handleInputChange,
    guardarEquipo,
    readOnly,
  } = props;
  const colWith = 6;
  const errors = [];

  const [equipoId, setEquipoId] = useState(0);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const provisionalDialog = useRef(null);

  useEffect(() => {
    if (equipoId) {
      setImages([]);
      setLoading(true);
      EquiposAPI.getEquipoImages(equipoId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setImages(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [equipoId]);

  useEffect(() => {
    if (equipo && equipo.id !== equipoId) {
      setEquipoId(equipo.id);
    }
  }, [equipo, equipoId]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Dialog
        open={open}
        onClose={closeFunction}
        fullWidth={true}
        maxWidth={"md"}
        style={{ zIndex: 10 }}
      >
        {equipo?.zona && equipo?.zona.isWarehouse && (
          <div className="ribbon ribbon-top-right-revision purpleAndWhite">
            <Tooltip title={i18n.t("equipo.almacenHelp")} placement="left">
              <span>{i18n.t("zona.warehouse")}</span>
            </Tooltip>
          </div>
        )}
        {equipo?.provisional && (
          <div className="ribbon ribbon-top-right-revision yellowAndBlack">
            <Tooltip title={i18n.t("equipo.provisionalHelp")} placement="left">
              <span>{i18n.t("equipo.provisional")}</span>
            </Tooltip>
          </div>
        )}
        {equipo?.inactivo && (
          <div className="ribbon ribbon-top-right-revision greyAndWhite">
            <Tooltip title={i18n.t("equipo.inactiveHelp")} placement="left">
              <span>{i18n.t("equipo.inactive")}</span>
            </Tooltip>
          </div>
        )}
        <DialogTitle>
          <Grid container spacing={3}>
            <Grid item>
              <Avatar
                aria-label="avatar"
                style={{ backgroundColor: "#f50057" }}
              >
                <Memory />
              </Avatar>
            </Grid>
            <Grid item>{equipo?.nombre}</Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ height: "700px" }}>
          <Grid container>
            <Grid item lg={6}>
              <form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={colWith} lg={11}>
                    <TextField
                      required
                      fullWidth
                      value={equipo?.sn || ""}
                      label={i18n.t("equipo.sn")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      name="sn"
                      error={
                        errors["SN"] !== undefined ||
                        errors["SN_Unique"] !== undefined
                      }
                      helperText={
                        errors["SN"]
                          ? i18n.t("textField.required")
                          : errors["SN_Unique"]
                          ? i18n.t("textField.unique", { name: "SN" })
                          : null
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith} lg={11}>
                    <TextField
                      required
                      fullWidth
                      value={equipo?.pn || ""}
                      label={i18n.t("equipo.pn")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly || isVacon(equipo?.marca),
                      }}
                      name="pn"
                      id="pn"
                      error={errors["PN"] !== undefined}
                      helperText={
                        errors["PN"] ? i18n.t("textField.required") : null
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith} lg={11}>
                    <TextField
                      required
                      fullWidth
                      value={equipo?.tc || ""}
                      label={
                        isVacon(equipo?.marca)
                          ? i18n.t("equipo.vType")
                          : i18n.t("equipo.tc")
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      name="tc"
                      id="tc"
                      error={errors["TC"] !== undefined}
                      helperText={
                        errors["TC"] ? i18n.t("textField.required") : null
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith} lg={11}>
                    <TextField
                      fullWidth
                      value={equipo?.idCliente || ""}
                      label={i18n.t("equipo.idCliente")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      name="idCliente"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith} lg={11}>
                    <TextField
                      fullWidth
                      value={equipo?.nombre || ""}
                      label={i18n.t("equipo.nombre")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      name="nombre"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith} lg={11}>
                    <FormControl fullWidth>
                      <InputLabel>
                        {i18n.t("maintenance.fields.brand")}
                      </InputLabel>
                      <Select
                        required
                        value={equipo?.marca || ""}
                        name="marca"
                        displayEmpty
                        onChange={handleInputChange}
                        inputProps={{
                          readOnly: readOnly,
                        }}
                      >
                        <MenuItem value="VLT">Danfoss VLT</MenuItem>
                        <MenuItem value="Vacon">Danfoss Vacon</MenuItem>
                        <MenuItem value="Otro">
                          {i18n.t("equipo.other")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={colWith} lg={11}>
                    <TextField
                      fullWidth
                      value={equipo?.descripcion || ""}
                      label={i18n.t("equipo.descripcion")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      name="descripcion"
                      error={errors["Descripcion"] !== undefined}
                      helperText={
                        errors["Descripcion"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
            {/* </DialogContent>
        <DialogContent style={{ maxHeight: "550px" }}> */}
            <Grid item lg={6} style={{ overflowX: "auto", maxHeight: "650px" }}>
              <RelativeBackdrop hidden={!loading}>
                {images.length !== 0 ? (
                  <CardImagenesVariador
                    images={images}
                    isExchange={true}
                    hideHeader={true}
                  />
                ) : (
                  <div style={{ alignSelf: "center" }}>
                    <Typography variant="button">
                      {i18n.t("planta.revisionEquipos.noImage")}
                    </Typography>
                  </div>
                )}
              </RelativeBackdrop>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button
                startIcon={<ArrowBackIos />}
                onClick={() => {
                  anteriorEquipo();
                }}
                variant="contained"
              >
                {i18n.t("planta.revisionEquipos.prev")}
              </Button>
            </Grid>
            <Grid item>
              {!readOnly && (
                <Button
                  onClick={() =>
                    equipo.provisional
                      ? provisionalDialog.current.open()
                      : guardarEquipo()
                  }
                  style={{ margin: "5px" }}
                  variant="contained"
                  color="primary"
                  endIcon={<Save />}
                >
                  {i18n.t("planta.revisionEquipos.save")}
                </Button>
              )}
              <Button
                onClick={() => closeFunction()}
                style={{ margin: "5px" }}
                variant="contained"
                endIcon={<Cancel />}
                color={"secondary"}
              >
                {i18n.t("planta.revisionEquipos.close")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  siguienteEquipo();
                }}
                variant="contained"
                endIcon={<ArrowForwardIos />}
              >
                {i18n.t("planta.revisionEquipos.next")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <AlertDialog
        ref={provisionalDialog}
        loading={loading}
        title={i18n.t("equipo.provisionalTitle")}
        text={i18n.t("equipo.provisionalText")}
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.confirmar")}
        confirmAction={() => {
          guardarEquipo(undefined, false);
          provisionalDialog.current.close();
        }}
      />
    </RelativeBackdrop>
  );
}
