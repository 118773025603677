import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function getEquipo(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getEquiposPlanta(plantaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/planta/${plantaId}`, {
    headers: { Authorization: `Bearer ${access_token}` }
  })
}

export function getEquiposExchange() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange`, {
    headers: { Authorization: `Bearer ${access_token}` }
  })
}

export function takeEquiposExchange(fromId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/from/${fromId}`, {
    headers: { Authorization: `Bearer ${access_token}` }
  })
}

export function getEquipoImage(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${id}/imagen`, {
    headers: { Authorization: `Bearer ${access_token}` }
  })
}

export function getEquipoImages(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${id}/imagenes`, {
    headers: { Authorization: `Bearer ${access_token}` }
  })
}

export function getEquiposSearch(query) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/search/${query}`, {
    headers: { Authorization: `Bearer ${access_token}` }
  })
}

export function updateEquipoExchange(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoexchange/${equipo.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function addEquipoExchange(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function sendSolicitud(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${id}/solicitar`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function sendNecesito(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/necesito`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function deleteEquipoExchange(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function deleteImage(id, imageIndex) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipoExchange/${id}/images/${imageIndex}/remove`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}