import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@material-ui/core";
import { ArrowBack, Delete, Memory, Save } from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import FormularioVariador from "../../molecule/formVariador/formVariador";
import FormularioRiesgoVariador from "../../molecule/formRiesgoVariador/formRiesgoVariador";
import { isVacon } from "../../../helpers/Common";
import { EquiposAPI, EquipoStockEmergenciaAPI } from "../../../services/API";
import EquipoRetrofitTable from "../../molecule/equipoRetrofitTable/EquipoRetrofitTable";
import Tooltip from "@material-ui/core/Tooltip";

function CardVariador({
  id,
  keyuser,
  plantaId,
  zonas,
  readOnly,
  equipo,
  setEquipo,
  saveEquipo,
  errors,
  colWith,
  goBack,
  handleInputChange,
  provisionalDialog,
  deleteDialog,
}) {

  // const [retrofit, setRetrofit] = useState([]);

  // useEffect(() => {
  //   if (equipo && equipo.retrofit) {
  //     setRetrofit(equipo.retrofit.split(";").map((x) => ({ pn: x })));
  //   }
  // }, [equipo, equipo.retrofit]);

  return (
    <Card style={{ position: "relative" }}>
      {equipo.zona && equipo.zona.isWarehouse && (
        <div className="ribbon ribbon-top-right purpleAndWhite">
          <Tooltip title={i18n.t("equipo.almacenHelp")} placement="left">
            <span>{i18n.t("zona.warehouse")}</span>
          </Tooltip>
        </div>
      )}
      {equipo.provisional && (
        <div className="ribbon ribbon-top-right yellowAndBlack">
          <Tooltip title={i18n.t("equipo.provisionalHelp")} placement="left">
            <span>{i18n.t("equipo.provisional")}</span>
          </Tooltip>
        </div>
      )}
      {equipo.inactivo && (
        <div className="ribbon ribbon-top-right greyAndWhite">
          <Tooltip title={i18n.t("equipo.inactiveHelp")} placement="left">
            <span>{i18n.t("equipo.inactive")}</span>
          </Tooltip>
        </div>
      )}
      {/* {equipo.cicloVida === 2 && (
          <div
            style={{
              background: "black",
              display: "flex",
              justifyContent: "center",
              border: '1px solid black',
              color: "white",
            }}
          >
            <Typography variant="caption">
              <b>{i18n.t("equipo.obsoleto")}</b>
            </Typography>
          </div>
        )} */}
      <CardHeader
        title={equipo.nombre || i18n.t("common.noName")}
        subheader={
          id
            ? equipo.descripcion || i18n.t("common.noDescription")
            : i18n.t("equipo.nuevo")
        }
        avatar={
          <Avatar
            aria-label="avatar"
            style={
              equipo.inactivo
                ? { backgroundColor: "white", color: "#2f2f2f" }
                : equipo.provisional
                ? { backgroundColor: "#2f2f2f" }
                : equipo.zona && equipo.zona.isWarehouse
                ? { backgroundColor: "#552877" }
                : { backgroundColor: "#f50057" }
            }
          >
            <Memory />
          </Avatar>
        }
      />

      <CardContent>
        <FormularioVariador
          keyuser={keyuser}
          zonas={zonas}
          equipo={equipo}
          setEquipo={setEquipo}
          colWith={colWith}
          readOnly={readOnly}
          errors={errors}
          handleInputChange={handleInputChange}
        />
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="back" onClick={() => goBack()}>
          <ArrowBack />
        </IconButton>
        {!readOnly && (
          <>
            <IconButton
              aria-label="save"
              onClick={() =>
                equipo.provisional
                  ? provisionalDialog.current.open()
                  : saveEquipo()
              }
            >
              <Save />
            </IconButton>
            {id && (
              <Button
                aria-label="delete"
                className="text-danger"
                style={{ marginLeft: "auto" }}
                onClick={() => deleteDialog.current.open()}
              >
                {i18n.t("equipo.borrar")}
                <Delete className="ml-2" />
              </Button>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
}

export default CardVariador;
