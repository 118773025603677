import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  ArrowBack,
  Delete,
  Memory,
  Room,
  Save,
  InfoOutlined,
  HomeWorkOutlined,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AlertDialog from "../../../components/AlertDialog";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import TabPanel from "../../../components/TabPanel";
import ZonaSearchAndAdd from "../../organism/ZonaSearchAndAdd/ZonaSearchAndAdd";
import i18n from "../../../locales/i18n";
import { ZonasAPI, PlantasAPI } from "../../../services/API";
import FrequencyConvertersSearchEngine from "../../organism/FrequencyConverterSearchEngine/FrequencyConverterSearchEngine";
import { capitalizeFirstLetter } from "../../../helpers/Common";
import AuthService from "../../../services/AuthService";

export default function ZonasDetails(props) {
  const { match, keyuser, setNavBarTitle, location } = props;
  const id = match.params.id;
  const plantaId = match.params.plantaId;
  const readOnly = props.readOnly && !keyuser;
  const colWith = props.fullWidth ? 2 : 6;
  const isGestorFromPlant = AuthService.isGestorFromPlant(plantaId);

  const [zona, setZona] = useState({
    descripcion: "",
    plantaZonaId: "",
    id: "",
    plantaId: "",
    isWarehouse: false,
    warehouseID: null,
  });
  const [initialIsWarehouse, setInitialWarehouse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [allZones, setAllZones] = useState([]);
  const [errors, setErrors] = useState({});

  const deleteDialog = useRef(null);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_ZonasDetails: newValue,
    };
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setZona((prevState) => ({ ...prevState, [name]: value }));
    const errorName = name ? capitalizeFirstLetter(name) : null;
    if (errors[errorName]) {
      setErrors((e) => ({ ...errors, [errorName]: undefined }));
    }
  };

  const deleteZona = async () => {
    setLoading(true);

    ZonasAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        props.history.goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getZona = useCallback(async () => {
    ZonasAPI.get(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZona({ ...data, plantaZonaId: data.plantaZonaId || "-1" });
        setInitialWarehouse(data.isWarehouse);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const updateZona = async () => {
    setLoading(true);
    const zonaToUpdate = {
      ...zona,
      plantaZonaId: zona.plantaZonaId === "-1" ? null : zona.plantaZonaId,
    };
    ZonasAPI.update(zonaToUpdate)
      .then(async (response) => {
        if (response.ok) {
          if (zona.isWarehouse !== initialIsWarehouse) {
            goBack();
          }
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getZonesOfThePlant = useCallback(async () => {
    PlantasAPI.getZonas(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setAllZones(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId]);

  const goBack = () => {
    if (props.history.length > 1) {
      handleChangeTab({}, 0);
      props.history.goBack();
    } else {
      props.history.replace(`/plantas/${plantaId}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_ZonasDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_ZonasDetails);
    }
  }, []);

  useEffect(() => {
    if (location.state && location.state.tabZonaValue) {
      setTabValue(location.state.tabZonaValue);
    }
  }, [location.state]);

  useEffect(() => {
    if (zona.descripcion) {
      setNavBarTitle(i18n.t("zona.title") + " " + zona.descripcion);
    } else {
      setNavBarTitle(i18n.t("zona.title"));
    }
  }, [setNavBarTitle, zona.descripcion]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      isGestorFromPlant ? getZonesOfThePlant() : null,
      getZona(),
    ]).then(() => setLoading(false));
  }, [getZonesOfThePlant, getZona, isGestorFromPlant]);

  return (
    <>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          variant="standard"
          indicatorColor="secondary"
          textColor="secondary"
          TabIndicatorProps={{
            style: {
              height: 3,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            },
          }}
        >
          <Tab
            label={i18n.t("zona.itemTitle")}
            icon={<Room />}
            id="simple-tab-0"
            value={0}
          />
          <Tab
            label={i18n.t("equipo.tituloCard")}
            icon={<Memory />}
            id="simple-tab-2"
            value={2}
          />
        </Tabs>
      </Paper>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={0}>
        <RelativeBackdrop hidden={!loading}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card style={{ position: "relative" }}>
                {zona.isWarehouse && (
                  <div className="ribbon ribbon-top-right purpleAndWhite">
                    <span>{i18n.t("zona.warehouse")}</span>
                  </div>
                )}
                <CardHeader
                  title={zona.descripcion}
                  subheader={
                    zona.isWarehouse
                      ? i18n.t("zona.warehouse")
                      : i18n.t("zona.itemTitle")
                  }
                  avatar={
                    <Avatar
                      aria-label="avatar"
                      style={
                        zona.isWarehouse
                          ? { backgroundColor: "#552877" }
                          : { backgroundColor: "#f50057" }
                      }
                    >
                      {zona.isWarehouse ? <HomeWorkOutlined /> : <Room />}
                    </Avatar>
                  }
                />
                <CardContent>
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={6}>
                      <Grid item xs={colWith}>
                        <TextField
                          required
                          fullWidth
                          value={zona.descripcion || ""}
                          label={i18n.t("zona.descripcion")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          error={errors["Descripcion"] !== undefined}
                          helperText={
                            errors["Descripcion"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          name="descripcion"
                          onChange={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={zona.isWarehouse}
                              onChange={handleInputChange}
                              name="isWarehouse"
                              color="secondary"
                              disabled={
                                zona.warehouseID && zona.warehouseID !== zona.id
                              }
                            />
                          }
                          label={i18n.t("zona.isWarehouse")}
                        />
                        <Grid>
                          <InfoOutlined />
                          <span style={{ marginLeft: "5px" }}>
                            {i18n.t("zona.infoWarehouse")}
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                    {isGestorFromPlant && allZones.length && (
                      <Grid container spacing={6}>
                        <Grid item xs={colWith}>
                          <FormControl fullWidth>
                            <InputLabel id="select-location">
                              {i18n.t("zona.location")}
                            </InputLabel>
                            <Select
                              labelId="select-location"
                              value={zona.plantaZonaId || "-1"}
                              name="plantaZonaId"
                              onChange={handleInputChange}
                              disabled={
                                zona.warehouseID && zona.warehouseID !== zona.id
                              }
                            >
                              <MenuItem value="-1">
                                {i18n.t("zona.locationFirtLevel")}
                              </MenuItem>
                              {allZones.map((item) =>
                                item.id !== zona.id ? (
                                  <MenuItem
                                    key={item.id.toString()}
                                    value={item.id}
                                  >
                                    {item.descripcion}
                                  </MenuItem>
                                ) : (
                                  ""
                                )
                              )}
                            </Select>
                            {/* <FormHelperText>
                              {i18n.t("zona.locationHelpText")}
                            </FormHelperText> */}
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                  </form>
                </CardContent>
                <CardActions disableSpacing>
                  <IconButton aria-label="back" onClick={() => goBack()}>
                    <ArrowBack />
                  </IconButton>
                  {!readOnly && (
                    <>
                      <IconButton
                        aria-label="save"
                        onClick={() => updateZona()}
                      >
                        <Save />
                      </IconButton>
                      <Button
                        aria-label="delete"
                        className="text-danger"
                        style={{ marginLeft: "auto" }}
                        onClick={() => deleteDialog.current.open()}
                      >
                        {i18n.t("planta.borrar")}
                        <Delete className="ml-2" />
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            </Grid>
            {id && (
              <ZonaSearchAndAdd
                plantaId={plantaId}
                zonaId={id}
                readOnly={readOnly}
                history={props.history}
                goBack={goBack}
              />
            )}
          </Grid>
        </RelativeBackdrop>
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={2}>
        <FrequencyConvertersSearchEngine
          readOnly={readOnly}
          PlantaId={plantaId}
          ZonaId={id}
          history={props.history}
          goBack={goBack}
          setErrors={setErrors}
        />
      </TabPanel>
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("planta.alertDialog.title")}
        text={i18n.t("planta.alertDialog.text")}
        cancelText={i18n.t("planta.alertDialog.cancelText")}
        confirmText={i18n.t("planta.alertDialog.confirmText")}
        confirmAction={() => deleteZona()}
      ></AlertDialog>
    </>
  );
}
