import React from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "../assets/jss/material-dashboard-react/sidebarStyle";

function AutoNavLink(props) {
  const isActive = () => {
    if (props.fragment) {
      return props.location.pathname.startsWith(props.fragment);
    } else {
      return props.location.pathname === props.to;
    }
  };

  const { classes, onClick } = props;

  let linkClassNames = classes.itemLink;
  if (isActive()) linkClassNames += ` ${classes[props.color]}`;

  return (
    <NavLink
      onClick={onClick}
      style={{ transition: "all 90ms linear" }}
      to={props.to}
      className={linkClassNames}
      activeClassName="active"
    >
      {props.children}
    </NavLink>
  );
}

export default withStyles(sidebarStyle)(withRouter(AutoNavLink));
