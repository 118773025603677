import React, { useEffect, useState, useCallback } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import InfiniteScroll from "react-infinite-scroll-component";
import Swal from 'sweetalert2'
//import EquipoExchangeCard from "../../../components/organism/EquipoExchangeCard/EquipoExchangeCard";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  makeStyles,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";
import { EquiposExchangeAPI } from "../../../services/API";
import { delay } from "../../../helpers/Common";
import AuthService from "../../../services/AuthService";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function ExchangeSearchEngine(props) {
  const { goBack } = props;
  const classes = useStyles();

  const [loadingEquipos, setLoadingEquipos] = useState(false);
  const [search, setSearch] = useState('');
  const [equiposExchange, setEquiposExchange] = useState([]);
  const [hasMoreEquiposExchange, setHasMoreEquiposExchange] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogMarca, setDialogMarca] = useState('');
  const [dialogPN_Type, setDialogPN_Type] = useState('');
  const isExchange = AuthService.isExchange();

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setDialogMarca('');
    setDialogPN_Type('');
  };

  const handleSendRequestDialog = () => {
    setOpen(false);
    if (!dialogMarca || !dialogPN_Type) {
      Swal.fire({
        icon: 'error',
        title: i18n.t("exchange.dialogoError.titulo"),
        text: i18n.t("exchange.dialogoError.info")
      })
    } else {
      setLoadingEquipos(true);
      const equipoNecesito = { "marca": dialogMarca, "pn_type": dialogPN_Type };
      EquiposExchangeAPI.sendNecesito(equipoNecesito)
        .then(async (response) => {
          if (response.ok) {
            Swal.fire(
              i18n.t("exchange.dialogoOkLoNecesito.titulo"),
              i18n.t("exchange.dialogoOkLoNecesito.info"),
              'success'
            );
          } else {
            Swal.fire(
              i18n.t("exchange.dialogoError.titulo"),
              i18n.t("exchange.dialogoError.info2"),
              'error'
            );
          }
          setLoadingEquipos(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingEquipos(false);
        });
    }
    setDialogMarca('');
    setDialogPN_Type('');
  };

  const solicitarUnExchange = (equipoId) => {
    console.log('solicitar un exchange con id ' + equipoId);

    Swal.fire({
      title: i18n.t("exchange.dialogoSolicitar.titulo"),
      text: i18n.t("exchange.dialogoSolicitar.info"),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: i18n.t("common.cancel"),
      confirmButtonText: i18n.t("common.confirmar")
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingEquipos(true);
        EquiposExchangeAPI.sendSolicitud(equipoId)
          .then(async (response) => {
            if (response.ok) {
              Swal.fire(
                i18n.t("exchange.dialogoOkSolicitar.titulo"),
                i18n.t("exchange.dialogoOkSolicitar.info"),
                'success'
              ).then((result) => {
                if (result.isConfirmed) {
                  equipmetSearch(search);
                }
                setLoadingEquipos(false);
              });
            } else {
              Swal.fire(
                i18n.t("exchange.dialogoError.titulo"),
                i18n.t("exchange.dialogoError.info2"),
                'error'
              );
              setLoadingEquipos(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingEquipos(false);
          });
      }
    });
  };

  const equipmetSearch = (query) => {
    delay(() => {
      if (query) {
        searchEquiposExchange(query);
      } else {
        getEquiposExchange(-1);
      }
    }, 500);
  };

  const searchEquiposExchange = (query) => {
    setLoadingEquipos(true);

    return EquiposExchangeAPI.getEquiposSearch(query)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquiposExchange(data);
        setHasMoreEquiposExchange(false);
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  };

  const getEquiposExchange = useCallback((fromId) => {
    setLoadingEquipos(true);

    return EquiposExchangeAPI.takeEquiposExchange(fromId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {

        if (fromId > -1) {
          setEquiposExchange(e => e.concat(data.equipos))
        } else {
          setEquiposExchange(data.equipos);
        }

        setHasMoreEquiposExchange(data.hasMore);
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  }, []
  );

  useEffect(() => {
    getEquiposExchange(-1);
  }, [getEquiposExchange]);

  return (
    <>
      <RelativeBackdrop hidden={!loadingEquipos}>
        <Grid container spacing={2}>
          <Grid item container xs={12} sm={8} md={6} xl={4}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: search,
                onChange: (event) => {
                  setSearch(event.target.value);
                  equipmetSearch(event.target.value);
                },
              }}
              goBack={goBack}
              endButton={null}
            />
          </Grid>
          <Grid item container spacing={2} className="mt-2" >
            <InfiniteScroll
              style={{ display: "flex", flexWrap: "wrap" }}
              scrollableTarget="main-content"
              dataLength={equiposExchange.length}
              next={() =>
                getEquiposExchange(
                  equiposExchange.length === 0
                    ? -1
                    : Math.max.apply(
                      Math,
                      equiposExchange.map((e) => e.id)
                    )
                )
              }
              hasMore={hasMoreEquiposExchange}
              loader
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow
                      style={{
                        background:
                          "linear-gradient(45deg, rgb(232, 55, 105, 0.9) 15%, rgb(110, 68, 142, 0.9) 70%)",
                      }}
                    >
                      <StyledTableCell>Id</StyledTableCell>
                      <StyledTableCell>{i18n.t("exchange.marca")}</StyledTableCell>
                      <StyledTableCell>{i18n.t("exchange.sn")}</StyledTableCell>
                      <StyledTableCell>{i18n.t("exchange.pn")}</StyledTableCell>
                      <StyledTableCell>{i18n.t("exchange.tctype")}</StyledTableCell>
                      <StyledTableCell>{i18n.t("exchange.provincia")}</StyledTableCell>
                      {isExchange &&
                        <StyledTableCell>{i18n.t("exchange.accion")}</StyledTableCell>
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {equiposExchange
                      .map((equipo) => {
                        return (
                          //<Grid
                          //  item
                          //  xs={6}
                          //  sm={4}
                          //  md={3}
                          //  xl={2}
                          //  style={{ padding: 8 }}
                          //  key={equipo.id}
                          //>
                          //  <Link
                          //    to={`./plantas/${equipo.plantaId}/equipoexchange/${equipo.id}`}
                          //    style={{ textDecoration: "none" }}
                          //  >
                          //    <EquipoExchangeCard
                          //      equipoExchange={equipo}
                          //      plantaFrom={null}
                          //    />
                          //  </Link>
                          //</Grid>
                          <StyledTableRow key={equipo.id.toString()}>
                            <StyledTableCell component="th" scope="row">{equipo.id}</StyledTableCell>
                            <StyledTableCell>{equipo.marca}</StyledTableCell>
                            <StyledTableCell>{equipo.sn}</StyledTableCell>
                            <StyledTableCell>{equipo.pn}</StyledTableCell>
                            <StyledTableCell>{equipo.tc}</StyledTableCell>
                            <StyledTableCell>{equipo.planta.provincia}</StyledTableCell>
                            {isExchange &&
                              <StyledTableCell align="center">
                                <Button variant="outlined" onClick={() => solicitarUnExchange(equipo.id)}>{i18n.t("exchange.solicitar")}</Button>
                              </StyledTableCell>
                            }
                          </StyledTableRow>
                        );
                      })}
                    {(equiposExchange.length === 0 && isExchange) &&
                      <StyledTableRow key="-1">
                        <StyledTableCell component="th" scope="row" colSpan={7} align="center">
                        <Button variant="outlined" color="secondary" onClick={handleClickOpenDialog}>{i18n.t("exchange.necesito")}</Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </Grid>
        </Grid>
      </RelativeBackdrop>
      <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{i18n.t("exchange.dialogo.titulo")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("exchange.dialogo.texto")}
          </DialogContentText>
          <FormControl fullWidth required>
            <InputLabel>{i18n.t("exchange.marca")}</InputLabel>
            <Select
              required
              value={dialogMarca}
              name="marca"
              displayEmpty
              onChange={(event) => (setDialogMarca(event.target.value.toString()))}
            >
              <MenuItem value="VLT">Danfoss VLT</MenuItem>
              <MenuItem value="Vacon">Danfoss Vacon</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            margin="dense"
            id="pn_type"
            label={dialogMarca === 'Vacon' ? i18n.t("exchange.type") : i18n.t("exchange.pn")}
            type="text"
            value={dialogPN_Type}
            onChange={(event) => (setDialogPN_Type(event.target.value))}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            {i18n.t("exchange.dialogo.btnCancelar")}
          </Button>
          <Button onClick={handleSendRequestDialog} color="primary">
            {i18n.t("exchange.dialogo.btnOk")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);