export default class PlantaBase {
    /** @type {number} */ id = undefined;
    /** @type {number} */ clienteId = undefined;
    /** @type {string} */ denominacion = null;
    /** @type {string} */ direccion = null;
    /** @type {string} */ cp = null;
    /** @type {string} */ poblacion = null;
    /** @type {string} */ provincia = null;
    /** @type {string} */ pais = null;
    /** @type {Number} */ partnerId = null;
    /** @type {string} */ appUserId = null;
    /** @type {boolean} */ isExchange = false;
  
    /**@param {Object} IntiObj  */
    constructor(IntiObj) {
      if(IntiObj.id) { this.id = IntiObj.id; }
      if(IntiObj.clienteId) { this.clienteId = IntiObj.clienteId; }
      if(IntiObj.denominacion) { this.denominacion = IntiObj.denominacion; }
      if(IntiObj.direccion) { this.direccion = IntiObj.direccion; }
      if(IntiObj.cp) { this.cp = IntiObj.cp; }
      if(IntiObj.poblacion) { this.poblacion = IntiObj.poblacion; }
      if(IntiObj.provincia) { this.provincia = IntiObj.provincia; }
      if(IntiObj.pais) { this.pais = IntiObj.pais; }
      if(IntiObj.partnerId) { this.partnerId = IntiObj.partnerId; }
      if (IntiObj.appUserId) { this.appUserId = IntiObj.appUserId; }
      if (IntiObj.isExchange) { this.isExchange = IntiObj.isExchange; }
    }
  }