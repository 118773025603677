/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import {
  ExitToApp,
  Group,
  Dns,
  Business,
  Dashboard,
  GroupAdd,
  FiberNew,
  PeopleOutline,
  CompareArrows,
  Print,
  ShowChart,
} from "@material-ui/icons";

import AutoNavLink from "../AutoNavLink";
import sidebarStyle from "../../assets/jss/material-dashboard-react/sidebarStyle";

import AuthService from "../../services/AuthService";
import { Avatar, SwipeableDrawer } from "@material-ui/core";
import i18n from "../../locales/i18n";

// @ts-ignore
import DanfossLogo from "../../assets/images/danfossLogo.png";
import { UsersAPI } from "../../services/API";

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
var ps;

class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {AuthService.isAuthenticated() && user}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      partner: "",
    };
  }
  mainPanel = React.createRef();

  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  componentDidMount() {
    if (AuthService.isAuthenticated()) {
      UsersAPI.getUserPartner()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          this.setState({partner: data.nombreAbreviado});
        })
        .catch((error) => {
          console.error(error);
        });
    }else{
      this.setState({partner: "ingesis"});
    }
  }

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      bgColor,
      color,
      rtlActive,
      setMobileOpen,
    } = this.props;

    const itemText =
      classes.itemText +
      " " +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        [classes.itemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.itemTextRTL]: rtlActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      " " +
      cx({
        [classes.collapseItemTextMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.collapseItemTextRTL]: rtlActive,
      });
    const userWrapperClass =
      classes.user +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    const caret =
      classes.caret +
      " " +
      cx({
        [classes.caretRTL]: rtlActive,
      });
    const collapseItemMini =
      classes.collapseItemMini +
      " " +
      cx({
        [classes.collapseItemMiniRTL]: rtlActive,
      });
    const photo =
      classes.photo +
      " " +
      cx({
        [classes.photoRTL]: rtlActive,
      });

    var user = (
      <div className={userWrapperClass} style={{ userSelect: "none" }}>
        <div className={photo}>
          <Avatar
            aria-label="avatar"
            style={{
              backgroundColor: "#552877",
              height: 34,
              width: 34,
              fontWeight: 300,
              fontSize: "1em",
            }}
          >
            {AuthService.isAuthenticated() &&
              AuthService.getUserInfo().username.substr(0, 1).toUpperCase()}
          </Avatar>
        </div>
        <List className={classes.list}>
          <ListItem className={classes.item + " " + classes.userItem}>
            <div
              style={{ cursor: "pointer", marginBottom: 20 }}
              className={classes.itemLink + " " + classes.userCollapseButton}
              onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={
                  AuthService.isAuthenticated() &&
                  `${AuthService.getUserInfo().nombre} ${
                    AuthService.getUserInfo().apellidos
                  }`
                }
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      classes.userCaret +
                      " " +
                      (this.state.openAvatar ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={itemText + " " + classes.userItemText}
              />
            </div>
            <Collapse in={this.state.openAvatar}>
              <List className={classes.list + " " + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <AutoNavLink
                    color={color}
                    to={
                      AuthService.isAuthenticated() &&
                      "/user/" + AuthService.getUserInfo().userid
                    }
                    className={
                      classes.itemLink + " " + classes.userCollapseLinks
                    }
                  >
                    <span className={collapseItemMini}>MP</span>
                    <ListItemText
                      primary={i18n.t("sidebar.miPerfil")}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </AutoNavLink>
                </ListItem>
                {(AuthService.isGestorFromSomeClient() ||
                  AuthService.isGestorFromSomePlant()) && (
                  <ListItem
                    className={classes.collapseItem}
                    style={{ marginBottom: 10 }}
                  >
                    <AutoNavLink
                      color={color}
                      to="/users"
                      className={
                        classes.itemLink + " " + classes.userCollapseLinks
                      }
                    >
                      <span className={collapseItemMini}>U</span>
                      <ListItemText
                        primary={i18n.t("sidebar.usuarios")}
                        disableTypography={true}
                        className={collapseItemText}
                      />
                    </AutoNavLink>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });

    const logoClsassesPartner =
      classes.logoPartner +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });

    var brand = (
      <div className={logoClasses}>
        <a target="_blank" className={logoMini}>
          <img src={logo} alt="logo" className={classes.img} />
        </a>
        <a target="_blank" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );

    var brandDanfoss = (
      <div className={logoClsassesPartner}>
        <a
          target="_blank"
          className={logoNormal}
          style={{ marginLeft: "22px" }}
        >
          <img src={DanfossLogo} alt="logo" />
        </a>
      </div>
    );

    var brandPartner = (
      <div className={logoClsassesPartner}>
        <a
          target="_blank"
          className={logoNormal}
          style={{ marginLeft: "22px" }}
        >
          <img src={`images/logosPartnerMenu/${this.state.partner}.png`} alt="logo" />
        </a>
      </div>
    );

    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });

    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    var links = (
      <List className={classes.list} style={{ marginTop: 10 }}>
        {AuthService.isAuthenticated() && (
          <AutoNavLink
            to="/"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <Dashboard className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.dashboard")}
            </ListItemText>
          </AutoNavLink>
        )}

        {(AuthService.isInRole("keyuser") ||
          AuthService.isGestorFromSomePartner()) && (
          <AutoNavLink
            to="/listaregistros"
            fragment="/listaregistros"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <GroupAdd className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.registros")}
            </ListItemText>
          </AutoNavLink>
        )}

        {(AuthService.isInRole("keyuser") ||
          AuthService.isGestorFromSomePartner()) && (
          <AutoNavLink
            to="/partners"
            fragment="/partners"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <PeopleOutline className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.partners")}
            </ListItemText>
          </AutoNavLink>
        )}

        {(AuthService.isInRole("keyuser") ||
          AuthService.isGestorFromSomePartner()) && (
          <AutoNavLink
            to="/clientes"
            fragment="/clientes"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <Group className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.clientes")}
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isAuthenticated() && (
          <AutoNavLink
            to="/plantas"
            fragment="/plantas"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <Business className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.plantas")}
            </ListItemText>
          </AutoNavLink>
        )}

        {(AuthService.isAuthenticated()
          && (AuthService.isGestorFromSomePartner()
          || AuthService.isExchange()
          )) && (
          <AutoNavLink
            to="/exchange"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <CompareArrows className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.exchange")}
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isInRole("keyuser") && (
          <AutoNavLink
            to="/pns"
            fragment="/pns"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <Dns className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.pns")}
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isInRole("keyuser") && (
          <AutoNavLink
            to="/kpi"
            fragment="/kpi"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <ShowChart className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              KPI
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isInRole("keyuser") && (
          <AutoNavLink
            to="/barcode"
            fragment="/barcode"
            color={color}
            onClick={() => setMobileOpen(false)}
          >
            <Print className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
            {i18n.t("sidebar.barcode")}
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isAuthenticated() && (
          <AutoNavLink to="/news" color={color}>
            <FiberNew className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.news")}
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isAuthenticated() && (
          <AutoNavLink to={`/login/${this.state.partner}`} color={color}>
            <ExitToApp className={classes.itemIcon} />
            <ListItemText className={itemText} disableTypography>
              {i18n.t("sidebar.cerrarSesion")}
            </ListItemText>
          </AutoNavLink>
        )}

        {AuthService.isAuthenticated() && (
          <ListItemText className={itemText + "ml-3"} disableTypography>
            v{process.env.REACT_APP_VERSION}
          </ListItemText>
        )}
        {/* {!process.env.NODE_ENV ||
          (process.env.NODE_ENV === "development" && (
            <ListItemText className={itemText + "ml-3"} disableTypography>
              {devVersion}
            </ListItemText>
          ))} */}
      </List>
    );

    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            variant="temporary"
            anchor="left"
            disableSwipeToOpen={iOS}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onOpen={() => setMobileOpen(true)}
            onClose={() => setMobileOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor="left"
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {brandPartner}
            {brandDanfoss}
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "blue",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
    "ingesis",
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

export default withStyles(sidebarStyle)(Sidebar);
