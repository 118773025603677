import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Clear,
  Schedule,
  Event,
  Done,
  Edit,
  AssignmentOutlined,
} from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import moment from "moment";
import Swal from "sweetalert2";
import { EquipoScheduledPreventiveMaintenancesAPI } from "../../../services/API";

function ActualScheduledMaintenance({
  scheduledPreventiveMaintinance,
  setLoading,
  getScheduledPreventiveMaintinance,
  equipo,
  setErrors,
  readOnly,
}) {
  const [scheduleDialog_open, setScheduleDialog_open] = useState(false);
  const [scheduleDialog_id, setScheduleDialog_id] = useState(null);
  const [scheduleDialog_showErrors, setScheduleDialog_showErrors] =
    useState(false);
  const [scheduleDialog_Date, setScheduleDialog_Date] = useState(null);
  const [scheduleDialog_Provisional, setScheduleDialog_Provisional] =
    useState(false);
  const [scheduleDialog_type, setScheduleDialog_type] = useState("");
  const [scheduleDialog_state, setScheduleDialog_state] = useState("");

  const scheduleDialogDelete = (id) => {
    Swal.fire({
      title: i18n.t("maintenance.altualScheduled.DialogDeleteTitle"),
      text: i18n.t("maintenance.altualScheduled.DialogDeleteText"),
      icon: "warning",
      confirmButtonText: i18n.t(
        "maintenance.altualScheduled.DialogDeleteConfirmButtonText"
      ),
      cancelButtonText: i18n.t(
        "maintenance.altualScheduled.DialogDeleteCancelButtonText"
      ),
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        deleteScheduledPreventiveMaintenance(id);
      } else {
        setScheduleDialog_id(null);
      }
    });
  };

  const deleteScheduledPreventiveMaintenance = (id) => {
    setLoading(true);
    EquipoScheduledPreventiveMaintenancesAPI.remove(id)
      .then(async (response) => {
        if (response.ok) {
          setLoading(false);
          getScheduledPreventiveMaintinance();
          setLoading(false);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addNewScheduledPreventiveMaintenance = () => {
    setLoading(true);
    const newSchedule = {
      equipoId: equipo.id,
      scheduledDate: scheduleDialog_Date,
      isProvisionalDate: scheduleDialog_Provisional,
      type: scheduleDialog_type,
      state: scheduleDialog_state,
    };

    EquipoScheduledPreventiveMaintenancesAPI.add(newSchedule)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((prevMaintenance) => {
        setLoading(false);
        getScheduledPreventiveMaintinance();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const editScheduledPreventiveMaintenance = () => {
    setLoading(true);
    const schedule = {
      id: scheduleDialog_id,
      equipoId: equipo.id,
      scheduledDate: scheduleDialog_Date,
      isProvisionalDate: scheduleDialog_Provisional,
      type: scheduleDialog_type,
      state: scheduleDialog_state,
    };

    EquipoScheduledPreventiveMaintenancesAPI.update(schedule)
      .then(async (response) => {
        if (response.ok) {
          setLoading(false);
          getScheduledPreventiveMaintinance();
          setLoading(false);
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const scheduleDialogSave = () => {
    if (
      !scheduleDialog_Date ||
      (!scheduleDialog_type && scheduleDialog_type !== "0") ||
      (!scheduleDialog_state && scheduleDialog_state !== "0")
    ) {
      setScheduleDialog_showErrors(true);
    } else {
      if (scheduleDialog_id) {
        editScheduledPreventiveMaintenance();
      } else {
        addNewScheduledPreventiveMaintenance();
      }
      scheduleDialogClose();
    }
  };

  const scheduleDialogClose = () => {
    setScheduleDialog_open(false);
    setScheduleDialog_id(null);
    setScheduleDialog_showErrors(false);
    setScheduleDialog_Date(null);
    setScheduleDialog_Provisional(false);
    setScheduleDialog_type("");
    setScheduleDialog_state("");
  };

  const handleDelete = (id) => {
    scheduleDialogDelete(id);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow style={{ backgroundColor: "#424242", height: 65 }}>
              <TableCell align="right" colSpan={4}>
                <Button
                  style={{ backgroundColor: "white" }}
                  startIcon={<Add />}
                  onClick={() => setScheduleDialog_open(true)}
                  hidden={readOnly}
                >
                  {i18n.t("maintenance.altualScheduled.addSchedule")}
                </Button>
              </TableCell>
            </TableRow>
            <TableRow style={{ height: 45 }}>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  {i18n.t("maintenance.altualScheduled.date")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  {i18n.t("maintenance.altualScheduled.type")}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="subtitle1">
                  {i18n.t("maintenance.altualScheduled.state")}
                </Typography>
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ fontSize: 10 }}>
            {scheduledPreventiveMaintinance.length < 1 && (
              <TableRow key="empty">
                <TableCell align="center" colSpan={4} height={100}>
                  {i18n.t("maintenance.altualScheduled.emptyDataSourceMessage")}
                </TableCell>
              </TableRow>
            )}
            {scheduledPreventiveMaintinance.length > 0 &&
              scheduledPreventiveMaintinance.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {moment(row.scheduledDate).local().format("DD/MM/yyyy")}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        width: "100px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        color:
                          row.type === 0
                            ? "#43a047"
                            : row.type === 1
                            ? "#0288d1"
                            : row.type === 2
                            ? "#ffb300"
                            : "#000000",
                        borderRadius: "10px",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderColor:
                          row.type === 0
                            ? "#43a047"
                            : row.type === 1
                            ? "#0288d1"
                            : row.type === 2
                            ? "#ffb300"
                            : "#000000",
                      }}
                    >
                      {row.type === 0
                        ? i18n.t("maintenance.altualScheduled.module1")
                        : row.type === 1
                        ? i18n.t("maintenance.altualScheduled.module2")
                        : row.type === 2
                        ? i18n.t("maintenance.altualScheduled.module3")
                        : i18n.t("maintenance.altualScheduled.moduleBase")}
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {row.state === 3 ? (
                      <div>
                        <AssignmentOutlined style={{ color: "#8E44AD" }} />
                        <Typography
                          variant="body2"
                          style={{ color: "#8E44AD" }}
                        >
                          {i18n.t("maintenance.altualScheduled.proposed")}
                        </Typography>
                      </div>
                    ) : row.state === 2 ? (
                      <div>
                        <Clear style={{ color: "#a04743" }} />
                        <Typography
                          variant="body2"
                          style={{ color: "#a04743" }}
                        >
                          {i18n.t("maintenance.altualScheduled.cancelled")}
                        </Typography>
                      </div>
                    ) : row.state === 1 ? (
                      <div>
                        <Done style={{ color: "#43a047" }} />
                        <Typography
                          variant="body2"
                          style={{ color: "#43a047" }}
                        >
                          {i18n.t("maintenance.altualScheduled.done")}
                        </Typography>
                      </div>
                    ) : row.isProvisionalDate ? (
                      <div>
                        <Schedule />
                        <Typography variant="body2">
                          {i18n.t("maintenance.altualScheduled.pending")}
                        </Typography>
                      </div>
                    ) : (
                      <div>
                        <Event />
                        <Typography variant="body2">
                          {i18n.t("maintenance.altualScheduled.scheduled")}
                        </Typography>
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={i18n.t("maintenance.altualScheduled.edit")}>
                      <Button
                        style={{ backgroundColor: "white" }}
                        startIcon={<Edit />}
                        onClick={() => {
                          setScheduleDialog_id(row.id);
                          setScheduleDialog_Date(row.scheduledDate);
                          setScheduleDialog_Provisional(row.isProvisionalDate);
                          setScheduleDialog_type(row.type.toString());
                          setScheduleDialog_state(row.state.toString());
                          setScheduleDialog_open(true);
                        }}
                        hidden={readOnly}
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      title={i18n.t("maintenance.altualScheduled.delete")}
                    >
                      <Button
                        style={{ backgroundColor: "white" }}
                        startIcon={<Clear />}
                        onClick={() => {
                          console.log("pressed: " + row.id);
                          handleDelete(row.id);
                        }}
                        hidden={readOnly}
                      ></Button>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={scheduleDialog_open} onClose={() => scheduleDialogClose()}>
        <DialogTitle id="form-dialog-title">
          {i18n.t("maintenance.altualScheduled.dialogTitle")}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            margin="dense"
            id="scheduleDate"
            label={i18n.t("maintenance.altualScheduled.date")}
            type="date"
            defaultValue={
              scheduleDialog_Date &&
              moment(scheduleDialog_Date).local().format("yyyy-MM-DD")
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
            error={scheduleDialog_showErrors && !scheduleDialog_Date}
            helperText={
              scheduleDialog_showErrors &&
              !scheduleDialog_Date &&
              i18n.t("textField.required")
            }
            onChange={(event) => setScheduleDialog_Date(event.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={scheduleDialog_Provisional}
                onChange={(event) => {
                  setScheduleDialog_Provisional(event.target.checked);
                  setScheduleDialog_state("0");
                }}
                name="provisionalDate"
              />
            }
            label={i18n.t("maintenance.altualScheduled.provisionalDate")}
          />
          <TextField
            id="type"
            select
            label={i18n.t("maintenance.altualScheduled.type")}
            value={scheduleDialog_type}
            onChange={(event) => setScheduleDialog_type(event.target.value)}
            SelectProps={{
              native: true,
            }}
            fullWidth
            required
            error={scheduleDialog_showErrors && !scheduleDialog_type}
            helperText={
              scheduleDialog_showErrors &&
              !scheduleDialog_type &&
              i18n.t("textField.required")
            }
          >
            <option key={-1} value=""></option>
            <option key={3} value="3">
              {i18n.t("maintenance.altualScheduled.moduleBase")}
            </option>
            <option key={0} value="0">
              {i18n.t("maintenance.altualScheduled.module1")}
            </option>
            <option key={1} value="1">
              {i18n.t("maintenance.altualScheduled.module2")}
            </option>
            <option key={2} value="2">
              {i18n.t("maintenance.altualScheduled.module3")}
            </option>
          </TextField>
          <TextField
            id="state"
            select
            label={i18n.t("maintenance.altualScheduled.state")}
            value={scheduleDialog_state}
            disabled={scheduleDialog_Provisional}
            onChange={(event) => setScheduleDialog_state(event.target.value)}
            SelectProps={{
              native: true,
            }}
            fullWidth
            required
            error={scheduleDialog_showErrors && !scheduleDialog_state}
            helperText={
              scheduleDialog_showErrors &&
              !scheduleDialog_state &&
              i18n.t("textField.required")
            }
            style={{ marginTop: 20 }}
          >
            <option key={-1} value=""></option>
            <option key={0} value="0">
              {i18n.t("maintenance.altualScheduled.pending")}
            </option>
            <option key={1} value="1">
              {i18n.t("maintenance.altualScheduled.done")}
            </option>
            <option key={2} value="2">
              {i18n.t("maintenance.altualScheduled.cancelled")}
            </option>
            <option key={3} value="3">
              {i18n.t("maintenance.altualScheduled.proposed")}
            </option>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => scheduleDialogSave()} color="primary">
            {scheduleDialog_id
              ? i18n.t("maintenance.altualScheduled.save")
              : i18n.t("maintenance.altualScheduled.add")}
          </Button>
          <Button
            onClick={() => scheduleDialogClose()}
            //color="primary"
          >
            {i18n.t("maintenance.altualScheduled.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ActualScheduledMaintenance;
