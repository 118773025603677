import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Room, Memory, HomeWorkOutlined } from "@material-ui/icons";
import React from "react";
import withHover from "../../withHover";
import i18n from "../../../locales/i18n";
const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function ZonaCard(props) {
  const { zona } = props;

  return (
    <CardWithHover {...props} style={{ position: "relative" }}>
      {zona.isWarehouse && (
        <div className="ribbon ribbon-top-right purpleAndWhite">
          <span>{i18n.t("zona.warehouse")}</span>
        </div>
      )}
      <CardHeader
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{ noWrap: true, variant: "body2" }}
        title={zona.descripcion}
        subheader={
          zona.isWarehouse ? i18n.t("zona.warehouse") : i18n.t("zona.itemTitle")
        }
        avatar={
          <Avatar
            aria-label="avatar"
            style={
              zona.isWarehouse
                ? { backgroundColor: "#552877" }
                : { backgroundColor: "#f50057" }
            }
          >
            {zona.isWarehouse ? <HomeWorkOutlined /> : <Room />}
          </Avatar>
        }
      />
      <CardContent>
        <Typography variant="body2" noWrap>
          <Room
            className="mr-1"
            color="action"
            style={{ fontSize: 21, verticalAlign: "bottom" }}
          />
          {zona.numSubZonas +
            " " +
            (zona.numSubZonas === 1
              ? i18n.t("zona.ContSingular")
              : i18n.t("zona.ContPlural"))}
        </Typography>
        <Typography variant="body2" className="mt-2" noWrap>
          <Memory
            className="mr-1"
            color="action"
            style={{ fontSize: 21, verticalAlign: "bottom" }}
          />
          {zona.numEquipos + " " + i18n.t("equipo.Abbreviated")}
        </Typography>
      </CardContent>
    </CardWithHover>
  );
}
