import React, { useEffect, useRef, useState } from "react";

import { Button, Grid, IconButton } from "@material-ui/core";
import MaterialTable, { MTableGroupRow } from "material-table";
import i18n from "../../../locales/i18n";
import RelativeBackdrop from "../../RelativeBackdrop";
import { PNTCsAPI } from "../../../services/API";
import { CloudDownload, PostAdd, Sync, AssignmentTurnedIn} from "@material-ui/icons";

export default function PartNumbers({ setNavBarTitle }) {
  const [loading, setLoading] = useState(false);
  const [loadingMissing, setLoadingMissing] = useState(false);
  const [loadingVacon, setLoadingVacon] = useState(false);

  const [tcpns, setTCPNs] = useState([]);
  const [typesPN, setTypesPN] = useState([]);
  const [missingPNs, setMissingPNs] = useState([]);

  const fileInputRef = useRef(null);

  const getPNS = async () => {
    setLoading(true);

    await PNTCsAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setTCPNs(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getMissingPNS = async () => {
    setLoadingMissing(true);

    await PNTCsAPI.getMissing()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setMissingPNs(data);
        setLoadingMissing(false);
      })
      .catch((error) => {
        setLoadingMissing(false);
      });
  };

  const getMisingTypes = async () => {
    setLoadingVacon(true);
    await PNTCsAPI.getMissingVacon()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setTypesPN(data);
        setLoadingVacon(false);
      })
      .catch((error) => {
        setLoadingVacon(false);
      });
  };

  const addPN = async (familia) => {
    setLoading(true);

    await PNTCsAPI.add(familia)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        getPNS();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePN = async (newData, oldData) => {
    setLoading(true);

    console.log(newData);
    await PNTCsAPI.update(newData)
      .then((response) => {
        if (response.ok) {
          const index = tcpns.indexOf(oldData);
          setTCPNs((prevState) => {
            prevState[index].pn = newData.pn;
            prevState[index].tc = newData.tc;
            return prevState;
          });
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  };

  const deletePN = async (familia) => {
    setLoading(true);

    await PNTCsAPI.remove(familia.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        getPNS();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const uploadExcel = async () => {
    setLoading(true);

    const file = fileInputRef.current.files[0];
    let formData = new FormData();
    formData.append("file", file);

    await PNTCsAPI.uploadExcel(formData)
      .then(async (response) => {
        if (response.ok) {
          getPNS();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });

    setLoading(false);
  };

  const syncPNTCS = async() => {
    setLoading(true);

    await PNTCsAPI.syncVLT()
      .then((response) => {
        if (response.ok) {
          getMissingPNS();
          setLoading(false);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const syncTCPNS = async () => {
    setLoading(true);

    await PNTCsAPI.syncVacon()
      .then((response) =>{
        if(response.ok){
          setLoading(false);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      })
  };

  const syncTYPES = async (id) => {
    setLoading(true)

    await PNTCsAPI.syncType(id)
      .then((response) =>{
        if(response.ok){
          setLoading(false);
          getPNS()
          getMisingTypes()
        }else{
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      })
  };

  const syncStore = () => {
    setLoading(true);

    PNTCsAPI.syncStore()
      .then((response) => {
        if (response.ok) {
          getPNS();
          getMissingPNS();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const downloadMissingExcel = async () => {
    setLoadingMissing(true);
    try {
      const response = await PNTCsAPI.downloadMissingExcel();
      if (response.ok) {
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "missing-pns.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      } else {
        var error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingMissing(false);
  };

  useEffect(() => {
    setNavBarTitle("Part Numbers");
  }, [setNavBarTitle]);

  useEffect(() => {
    getPNS();
    getMissingPNS();
    getMisingTypes();
  }, []);

  return (
    <>
      <RelativeBackdrop hidden={!loading && !loadingMissing && !loadingVacon}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <h3>{i18n.t("partNumber.master")}</h3>
          </Grid>
          <Grid item>
            <input
              type="file"
              ref={fileInputRef}
              accept=".xlsx"
              onChange={uploadExcel}
              style={{ display: "none" }}
            />
            <Button
              onClick={() => {
                fileInputRef.current.value = "";
                fileInputRef.current.click();
              }}
              variant="contained"
              endIcon={<PostAdd />}
              style={{ backgroundColor: "#01723a", color: "white" }}
            >
              {i18n.t("partNumber.excelImport")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => syncPNTCS()}
              variant="contained"
              color="secondary"
              id="acualizaTC"
              endIcon={<Sync />}
            >
              {i18n.t("partNumber.uploadVlt")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => syncTCPNS()}
              variant="contained"
              color="secondary"
              id="acualizaPN"
              endIcon={<Sync />}
            >
              {i18n.t("partNumber.uploadVacon")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              // @ts-ignore
              localization={i18n.t("dataTableProps")}
              data={tcpns}
              columns={[
                {
                  title: "PN",
                  field: "pn",
                },
                {
                  title: "TC / TYPE",
                  field: "tc",
                },
              ]}
              editable={{
                onRowAdd: (newData) => addPN(newData),
                onRowUpdate: (newData, oldData) => updatePN(newData, oldData),
                onRowDelete: (oldData) => deletePN(oldData),
              }}
              options={{
                addRowPosition: "first",
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false,
                searchFieldAlignment: "left",
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} className="mt-4">
          <Grid item xs={12}>
            <h3>{i18n.t("partNumber.missing")}</h3>
          </Grid>
          <Grid item>
            <Button
              onClick={() => downloadMissingExcel()}
              variant="contained"
              endIcon={<CloudDownload />}
              style={{ backgroundColor: "#01723a", color: "white" }}
            >
              {i18n.t("partNumber.export")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => syncStore()}
              variant="contained"
              endIcon={<Sync />}
              color="secondary"
            >
              {i18n.t("partNumber.danfossSync")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              // @ts-ignore
              localization={i18n.t("dataTableProps")}
              data={missingPNs}
              columns={[
                {
                  title: i18n.t("partNumber.id"),
                  field: "id",
                  render: rowData => <a href={"./plantas/"+rowData.zona.plantaId+"/zonas/"+rowData.zona.id+"/equipos/"+rowData.id} target="_blamk">{rowData.id}</a>
                },
                {
                  title: i18n.t("partNumber.pn"),
                  field: "pn",
                  defaultGroupOrder: 1
                },
                {
                  title: i18n.t("partNumber.link"),
                  render: (item) => (
                    <a
                      href={`https://store.danfoss.com/es/p/${item.pn}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{`https://store.danfoss.com/es/p/${item.pn}`}</a>
                  ),
                },
              ]}
              options={{
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false,
                searchFieldAlignment: "left",
                grouping: true,
              }}
              components={{
                GroupRow: (props) => {
                  const { groupData } = props;
                  var value = (
                    <span>
                      {groupData.value === 0
                        ? "0"
                        : !groupData.value
                        ? "N/A"
                        : groupData.value}{" "}
                      -{" "}
                      <b>
                        {groupData.data.length === 0
                          ? groupData.groups.length
                          : groupData.data.length}{" "}
                        {i18n.t("common.unidades")}
                      </b>
                    </span>
                  );
                  var newGroupData = { ...groupData, value: value };
                  return <MTableGroupRow {...props} groupData={newGroupData} />;
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-4">
          <Grid item xs={12}>
            <h3>{i18n.t("partNumber.missingV")}</h3>
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              // @ts-ignore
              localization={i18n.t("dataTableProps")}
              data={typesPN}
              columns={[
                {
                  title: i18n.t("partNumber.id"),
                  field: "id",
                  render: rowData => <a href={"./plantas/"+rowData.zona.plantaId+"/zonas/"+rowData.zona.id+"/equipos/"+rowData.id} target="_blamk">{rowData.id}</a>
                },
                {
                  title: i18n.t("partNumber.tc"),
                  field: "tc",
                  defaultGroupOrder: 1
                },
              ]}
              actions={[
                {
                  icon: '',
                  tooltip: 'Sync TYPE',
                  onClick: (event, data) => syncTYPES(data.id),
                }
              ]}
              components={{
                Action: props => (
                  <IconButton 
                    onClick={(event) => props.action.onClick(event, props.data)}
                    aria-label="SyncType"
                  >
                    <AssignmentTurnedIn />
                  </IconButton >
                ),
                GroupRow: (props) => {
                  const { groupData } = props;
                  var value = (
                    <span>
                      {groupData.value === 0
                        ? "0"
                        : !groupData.value
                        ? "N/A"
                        : groupData.value}{" "}
                      -{" "}
                      <b>
                        {groupData.data.length === 0
                          ? groupData.groups.length
                          : groupData.data.length}{" "}
                        {i18n.t("common.unidades")}
                      </b>
                    </span>
                  );
                  var newGroupData = { ...groupData, value: value };
                  return <MTableGroupRow {...props} groupData={newGroupData} />;
                },
              }}
              options={{
                addRowPosition: "first",
                actionsColumnIndex: -1,
                sorting: true,
                showTitle: false,
                grouping: true,
                searchFieldAlignment: "left",
              }}
            />
          </Grid>
        </Grid>
      </RelativeBackdrop>
    </>
  );
}
