import React from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@material-ui/core";
import {
  AddShoppingCart,
  Assistant,
  Description,
  Sync,
  SyncDisabled,
} from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import Gbar from "../../../assets/images/g-bar.png";

function CardUtilidadVariador({
  locale,
  equipo,
  updateEquipo,
  getPNFromStore,
  goBack,
  ofertaDialog,
  restoreDialog,
  readOnly,
}) {
  return (
    <>
      <Card style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <CardHeader
          avatar={
            <Avatar style={{ backgroundColor: "#552877" }}>
              <Assistant />
            </Avatar>
          }
          title={i18n.t("equipo.utilidades")}
          subheader={i18n.t("equipo.utilidadesDesc")}
        />
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: 5,
            backgroundImage: `url("${Gbar}")`,
            backgroundSize: "cover",
            height: 3,
            width: "90%",
          }}
        />
        <CardContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            paddingBottom: 15,
          }}
        >
          {equipo.id && equipo.sn && equipo.tc && equipo.pn ? (
            <React.Fragment>
              <Button
                fullWidth
                onClick={() => ofertaDialog.current.open()}
                variant="contained"
                color="primary"
                className="btn-special"
                startIcon={<AddShoppingCart />}
              >
                {i18n.t("equipo.pedirOferta")}
              </Button>
              <Link
                target="_blank"
                style={{ textDecoration: "none" }}
                href={`https://store.danfoss.com/${locale}/p/${equipo.pn}`}
              >
                <Button
                  fullWidth
                  className="noWrap mt-2"
                  variant="contained"
                  color="primary"
                  startIcon={<Description />}
                  onClick={(e) => {
                    if (
                      (equipo.textoOCR && equipo.textoOCR.includes("KRONES")) ||
                      /^S00[0-9]$/i.test(equipo.tc.substr(23, 4))
                    ) {
                      e.preventDefault();
                      if (equipo.tc.startsWith("FC-302")) {
                        getPNFromStore();
                      } else {
                        alert(i18n.t("equipo.noDocAvailable"));
                      }
                    }
                  }}
                >
                  {i18n.t("equipo.documentacion")}
                </Button>
              </Link>
              {!equipo.inactivo && !readOnly && (
                <Button
                  onClick={() => {
                    updateEquipo(true).then(() => goBack());
                  }}
                  fullWidth
                  className="noWrap mt-auto"
                  variant="contained"
                  color="secondary"
                  startIcon={<SyncDisabled />}
                >
                  {i18n.t("equipo.desactivarButton")}
                </Button>
              )}
              {equipo.inactivo && !readOnly && (
                <Button
                  onClick={() => {
                    restoreDialog.current.open();
                  }}
                  fullWidth
                  className="noWrap mt-auto"
                  variant="contained"
                  color="primary"
                  startIcon={<Sync />}
                >
                  {i18n.t("equipo.recuperarButton")}
                </Button>
              )}
            </React.Fragment>
          ) : (
            <Typography>{i18n.t("equipo.utilidadesWarning")}</Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default CardUtilidadVariador;
