import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { getEquipoFromVisionString } from "../../../helpers/OCR";
import i18n from "../../../locales/i18n";
import moment from "moment";
import { Sync } from "@material-ui/icons";
import { isVacon } from "../../../helpers/Common";

/**
 * @Conponent
 * @param {Object} props
 * @param {Boolean} props.keyuser
 * @param {Object} props.zonas
 * @param {Object} props.equipo
 * @param {Function} props.setEquipo
 * @param {import("@material-ui/core/Grid").GridSize} props.colWith
 * @param {Boolean} props.readOnly
 * @param {Object} props.errors
 * @param {*} props.handleInputChange
 */

function FormularioVariador({
  keyuser,
  zonas,
  equipo,
  setEquipo,
  colWith,
  readOnly,
  errors,
  handleInputChange,
}) {
  /**
   * Evita que el formularia haga OnSubmit
   * @param {*} event
   */
  const onSubmit = (event) => {
    event.preventDefault();
  };

  /**
   * Manda el equipo al OCR con el boton Reintentar
   */
  const getOCREquipo = async () => {
    var ocrEquipo = await getEquipoFromVisionString(equipo.textoOCR, equipo);

    var info = "TC -> " + ocrEquipo.tc;
    info += `\nPN -> ` + ocrEquipo.pn;
    info += `\nSN -> ` + ocrEquipo.sn;
    info += `\nIN -> ` + ocrEquipo.in;
    info += `\nOUT -> ` + ocrEquipo.out;
    info += `\nTemp -> ` + ocrEquipo.tempAmbiental;
    info += `\nChassis -> ` + ocrEquipo.chassis;
    info += `\nMarca -> ` + ocrEquipo.marca;
    info += `\n\n¿Quieres aplicar los datos detectados?`;

    if (window.confirm(info)) {
      setEquipo({
        ...equipo,
        tc: ocrEquipo.tc,
        pn: ocrEquipo.pn,
        sn: ocrEquipo.sn,
        in: ocrEquipo.in,
        out: ocrEquipo.out,
        tempAmbiental: ocrEquipo.tempAmbiental,
        chassis: ocrEquipo.chassis,
        semanaFabricacion: ocrEquipo.semanaFabricacion,
        añoFabricacion: ocrEquipo.añoFabricacion,
        marca: ocrEquipo.marca,
      });
    }
  };

  return (
    <form autoComplete="off" onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={equipo.idCliente || ""}
            label={i18n.t("equipo.idCliente")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="idCliente"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={equipo.nombre || ""}
            label={i18n.t("equipo.nombre")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="nombre"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={equipo.descripcion || ""}
            label={i18n.t("equipo.descripcion")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="descripcion"
            error={errors["Descripcion"] !== undefined}
            helperText={
              errors["Descripcion"] ? i18n.t("textField.required") : null
            }
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <FormControl fullWidth>
            <InputLabel>{i18n.t("maintenance.fields.brand")}</InputLabel>
            <Select
              required
              value={equipo.marca || ""}
              name="marca"
              displayEmpty
              onChange={handleInputChange}
              inputProps={{
                readOnly: readOnly,
              }}
            >
              <MenuItem value="VLT">Danfoss VLT</MenuItem>
              <MenuItem value="Vacon">Danfoss Vacon</MenuItem>
              <MenuItem value="Otro">{i18n.t("equipo.other")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            required
            fullWidth
            value={equipo.sn || ""}
            label={i18n.t("equipo.sn")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="sn"
            error={
              errors["SN"] !== undefined || errors["SN_Unique"] !== undefined
            }
            helperText={
              errors["SN"]
                ? i18n.t("textField.required")
                : errors["SN_Unique"]
                ? i18n.t("textField.unique", { name: "SN" })
                : null
            }
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            required
            fullWidth
            value={equipo.tc || ""}
            label={
              isVacon(equipo.marca)
                ? i18n.t("equipo.vType")
                : i18n.t("equipo.tc")
            }
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="tc"
            id="tc"
            error={errors["TC"] !== undefined}
            helperText={errors["TC"] ? i18n.t("textField.required") : null}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            required
            fullWidth
            value={equipo.pn || ""}
            label={i18n.t("equipo.pn")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly || isVacon(equipo.marca),
            }}
            name="pn"
            id="pn"
            error={errors["PN"] !== undefined}
            helperText={errors["PN"] ? i18n.t("textField.required") : null}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={equipo.in || ""}
            label={i18n.t("equipo.in")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="in"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={equipo.out || ""}
            label={i18n.t("equipo.out")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="out"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={equipo.chassis || ""}
            label={i18n.t("equipo.chassis")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="chassis"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            type="number"
            value={equipo.tempAmbiental || ""}
            label={i18n.t("equipo.tempAmbMax")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="tempAmbiental"
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            type="number"
            value={equipo.añoFabricacion || ""}
            label={i18n.t("equipo.añoFabricacion")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="añoFabricacion"
            error={errors["AñoFabricacion"] !== undefined}
            helperText={
              errors["AñoFabricacion"] ? i18n.t("textField.required") : null
            }
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            type="number"
            value={equipo.semanaFabricacion || ""}
            label={i18n.t("equipo.semanaFabricacion")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="semanaFabricacion"
            error={errors["SemanaFabricacion"] !== undefined}
            helperText={
              errors["SemanaFabricacion"] ? i18n.t("textField.required") : null
            }
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            select
            required
            fullWidth
            value={equipo.zonaId || ""}
            label={i18n.t("equipo.zona")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="zonaId"
            error={errors["ZonaId"] !== undefined}
            helperText={errors["ZonaId"] ? i18n.t("textField.required") : null}
            onChange={handleInputChange}
          >
            {zonas.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.descripcion}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={
              equipo.appUser
                ? `${equipo.appUser.nombre} ${equipo.appUser.apellidos}`
                : ""
            }
            label={i18n.t("equipo.appuser")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={colWith} lg={4}>
          <TextField
            fullWidth
            value={
              equipo.fechaCreacion
                ? moment(equipo.fechaCreacion)
                    .local()
                    .format("DD/MM/yyyy HH:mm")
                : ""
            }
            label={i18n.t("equipo.fecha")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={colWith} lg={12}>
          <TextField
            fullWidth
            multiline
            value={equipo.observaciones || ""}
            label={i18n.t("equipo.observacion")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="observaciones"
            onChange={handleInputChange}
          />
        </Grid>
        {keyuser && (
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                value={equipo.textoOCR || ""}
                label="Texto OCR"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            {equipo.textoOCR && (
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  endIcon={<Sync />}
                  style={{ marginRight: "auto" }}
                  onClick={() => getOCREquipo()}
                >
                  Reinterpretar
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default FormularioVariador;
