import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import {
  TextField,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Paper,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import AuthService from "../services/AuthService";
import Auth from "../components/layout/Auth";

import {
  EmailOutlined,
  ExpandMore,
  Translate,
} from "@material-ui/icons";
import i18n from "../locales/i18n";
import { Link } from "react-router-dom";
import { UsersAPI, PartnersAPI } from "../services/API";

export default function RecoveryLand(props) {
  const [partner, setPartner] = useState(props.match.params.partner);
  const [partnerEmail, setPartnerEmail] = useState(null);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [locale, setLocale] = useState(i18n.locale || i18n.defaultLocale);

  const handleLangClick = (event) => {
    setLanguageMenuAnchor(event.currentTarget);
  };

  const handleLangClose = (locale) => {
    setLanguageMenuAnchor(null);
    let currentLocale = i18n.locale || i18n.defaultLocale;
    if (locale !== currentLocale) {
      switch (locale) {
        case "es":
        case "en":
          localStorage.setItem("locale", locale);
          i18n.locale = locale;
          setLocale(locale);
          break;
        default:
          break;
      }
    }
  };



  useEffect(() => {
    AuthService.logOut();
  }, []);

  useEffect(() => {
    PartnersAPI.getPartnerList()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        let bEncontrado = false;
        for (let index = 0; index < data.length; index++) {
          if (data[index].nombreAbreviado === partner) {
            bEncontrado = true;
            setPartnerEmail(data[index].emailContacto);
            break;
          }
        }
        if (!bEncontrado) {
          setPartner("");
          console.log("Not includes");
        } else {
          console.log("Includes");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [partner]);

  return (
    <Auth locale={locale} partner={partner} email={partnerEmail}>
      <Row
        className="justify-content-center align-items-center h-100 ml-0 mr-0"
        style={{ paddingBottom: "15%" }}
      >
        <Col sm="12" md="10" lg="8">
          <Paper elevation={3} style={{ padding: 40 }}>
              <>
                <Row className="d-flex justify-content-start mb-4">
                  <Col>
                    <>
                      <h5 className="mt-2">{i18n.t("login.recovery1")}</h5>
                    </>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: 0.3,
                    }}
                  >
                    <Button
                      aria-haspopup="true"
                      onClick={handleLangClick}
                      startIcon={<Translate />}
                      endIcon={<ExpandMore />}
                    >
                      {i18n.t("settings.languageName")}
                    </Button>
                    <Menu
                      keepMounted
                      anchorEl={languageMenuAnchor}
                      open={Boolean(languageMenuAnchor)}
                      onClose={handleLangClose}
                    >
                      <MenuItem onClick={() => handleLangClose("es")}>
                        {i18n.t("settings.languageName", { locale: "es" })}
                      </MenuItem>
                      <MenuItem onClick={() => handleLangClose("en")}>
                        {i18n.t("settings.languageName", { locale: "en" })}
                      </MenuItem>
                    </Menu>
                  </Col>
                </Row>
                
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}
                    >
                      <Typography variant="button" style={{
                        marginBottom:10,
                      }}>
                        {i18n.t("login.recovery2")}
                      </Typography>
                      <Typography variant="button">{i18n.t("login.recovery3")}</Typography>
                      
                      {/* <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        onClick={() => UsersAPI.emailReminder()}
                      >
                        TEST EMAIL
                      </Button> */}
                    </div>

                    {/* <h3 className="mt-2 mb-3">{i18n.t("login.login")}</h3> */}
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    // flex: 0.3,
                  }}
                >
                  <EmailOutlined fontSize="large" />
                </Row>
              </>
           
          </Paper>
        </Col>
      </Row>
    </Auth>
  );
}
