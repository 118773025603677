import { Card, CardContent } from "@material-ui/core";
import React from "react";
import Gbar from "../../../assets/images/g-bar.png";
import EquipoRetrofitTable from "../../molecule/equipoRetrofitTable/EquipoRetrofitTable";

export default function CardRetrofit(props) {
  const {
    equipo,
    keyuser,
    loadingRetrofit,
    setLoadingRetrofit,
    setEquipo,
    stock_retrofit,
  } = props;
  return (
    <>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 25,
          marginTop: 25,
          backgroundImage: `url("${Gbar}")`,
          backgroundSize: "cover",
          height: 3,
          width: "100%",
        }}
      />
      <Card>
        {/* <CardHeader title={i18n.t("equipo.retrofitTable.title")} /> */}
        <CardContent>
          <EquipoRetrofitTable
            equipo={equipo}
            keyuser={keyuser}
            loading={loadingRetrofit}
            setLoading={setLoadingRetrofit}
            setEquipo={setEquipo}
            sustituciones={stock_retrofit}
          />
        </CardContent>
      </Card>
    </>
  );
}
