import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import {
  TextField,
  Button,
  FormGroup,
  InputAdornment,
  Paper,
  Grid,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import RelativeBackdrop from "../components/RelativeBackdrop";
import AuthService from "../services/AuthService";
import Auth from "../components/layout/Auth";

import {
  Business,
  BusinessCenterOutlined,
  EmailOutlined,
  ExpandMore,
  Phone,
  Translate,
  PeopleOutline,
} from "@material-ui/icons";
import swal from "sweetalert";
import queryString from "query-string";
import Alert from "@material-ui/lab/Alert";
import i18n from "../locales/i18n";
import { PartnersAPI } from "../services/API";

export default function Registro(props) {
  /** @type {object} */ const query = queryString.parse(props.location.search);
  const [partner, setPartner] = useState(props.match.params.partner);
  const [partnerEmail, setPartnerEmail] = useState(null);
  const [form, setForm] = useState({
    nombre: "",
    apellidos: "",
    empresa: "",
    cargo: "",
    email: "",
    telefono: "",
    partner: "",
    privacyPolicy: false,
  });
  const [message, setMessage] = useState(undefined);
  const [token, setToken] = useState(query.token);
  const [verifying, setVerifying] = useState(false);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [locale, setLocale] = useState(i18n.locale || i18n.defaultLocale);
  const [partnersList, setPartnersList] = useState([]);
  const [partnerDisabled, setPartnerDisabled] = useState(false);

  const handleLangClick = (event) => {
    setLanguageMenuAnchor(event.currentTarget);
  };

  const handleLangClose = (locale) => {
    setLanguageMenuAnchor(null);
    let currentLocale = i18n.locale || i18n.defaultLocale;
    if (locale !== currentLocale) {
      switch (locale) {
        case "es":
        case "en":
          localStorage.setItem("locale", locale);
          i18n.locale = locale;
          setLocale(locale);
          break;
        default:
          break;
      }
    }
  };

  const registro = (event) => {
    event.preventDefault();

    AuthService.register(
      form.nombre,
      form.apellidos,
      form.empresa,
      form.cargo,
      form.telefono,
      form.email,
      form.partner
    )
      .then((res) =>
        swal(
          "Registro solicitado",
          "Recibirás un email para confirmar tu correo electrónico",
          "success"
        )
      )
      .catch((error) => {
        if (error.response) {
          setMessage(error.response.data);
        } else if (error.request) {
          setMessage("There was an error");
        } else {
          setMessage(error.message);
        }
      });
  };

  const onChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setForm((preState) => ({ ...preState, [name]: value }));
  };

  useEffect(() => {
    PartnersAPI.getPartnerList()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPartnersList(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [partner]);

  useEffect(() => {
    if (partnersList.length > 0) {
      let bEncontrado = false;
      partnersList.forEach((element) => {
        if (element.nombreAbreviado === partner) {
          bEncontrado = true;
          setPartnerEmail(element.emailContacto);
        }
      });
      if (bEncontrado) {
        console.log("Includes");
        setForm((prev) => ({ ...prev, partner: partner }));
        setPartnerDisabled(true);
      } else {
        console.log("Not includes");
        setForm((prev) => ({ ...prev, partner: "" }));
        setPartnerDisabled(false);
        setPartner("");
      }
    }
  }, [partner, partnersList]);

  useEffect(() => {
    if (token) {
      setVerifying(true);

      AuthService.verify(token)
        .then((res) =>
          setMessage(
            <Alert severity="success">{i18n.t("login.verifySuccess")}</Alert>
          )
        )
        .catch((error) => {
          setMessage(
            <Alert severity="error">{i18n.t("login.verifyFailed")}</Alert>
          );
        });

      setVerifying(false);
    }
    AuthService.logOut();
  }, [token]);

  return (
    <Auth locale={locale} partner={partner} email={partnerEmail}>
      <Row className="justify-content-center align-items-center h-100 ml-0 mr-0">
        <Col sm="12" md="10" lg="8">
          <Paper elevation={3} style={{ padding: 40 }}>
            <Row className="d-flex justify-content-start mb-4">
              <Col>
                <h3 className="mt-2 mb-3">
                  {token
                    ? i18n.t("login.verify")
                    : partner
                    ? i18n.t("login.registro") + " " + partner
                    : i18n.t("login.registro")}
                </h3>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0.3,
                }}
              >
                <Button
                  aria-haspopup="true"
                  onClick={handleLangClick}
                  startIcon={<Translate />}
                  endIcon={<ExpandMore />}
                >
                  {i18n.t("settings.languageName")}
                </Button>
                <Menu
                  keepMounted
                  anchorEl={languageMenuAnchor}
                  open={Boolean(languageMenuAnchor)}
                  onClose={handleLangClose}
                >
                  <MenuItem onClick={() => handleLangClose("es")}>
                    {i18n.t("settings.languageName", { locale: "es" })}
                  </MenuItem>
                  <MenuItem onClick={() => handleLangClose("en")}>
                    {i18n.t("settings.languageName", { locale: "en" })}
                  </MenuItem>
                </Menu>
              </Col>
            </Row>

            {token ? (
              <RelativeBackdrop hidden={!verifying}>
                {message}
                <Grid container className="mt-4">
                  <Button
                    onClick={() =>
                      partner
                        ? props.history.push("/login/" + partner)
                        : props.history.push("/login")
                    }
                    variant="text"
                    color="secondary"
                  >
                    {i18n.t("login.login")}
                  </Button>
                  <Button
                    onClick={() => {
                      setToken("");
                      setMessage("");
                    }}
                    variant="text"
                    color="secondary"
                    className="ml-auto"
                  >
                    {i18n.t("login.registro")}
                  </Button>
                </Grid>
              </RelativeBackdrop>
            ) : (
              <form onSubmit={registro}>
                <Grid container spacing={3} className="mb-5">
                  <Grid item xs={12} lg={6}>
                    <FormGroup row>
                      <TextField
                        required
                        type="text"
                        name="nombre"
                        label={i18n.t("user.nombre")}
                        size="medium"
                        fullWidth
                        value={form.nombre}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormGroup row>
                      <TextField
                        required
                        type="text"
                        name="apellidos"
                        label={i18n.t("user.apellidos")}
                        size="medium"
                        fullWidth
                        value={form.apellidos}
                        onChange={onChange}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <TextField
                        select
                        required
                        name="partner"
                        label={i18n.t("user.partner")}
                        size="medium"
                        fullWidth
                        value={partnersList.length > 0 ? form.partner : ""}
                        onChange={onChange}
                        disabled={partnerDisabled}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PeopleOutline />
                            </InputAdornment>
                          ),
                        }}
                      >
                        {partnersList.map((option) => (
                          <MenuItem
                            key={option.nombreAbreviado}
                            value={option.nombreAbreviado}
                          >
                            {option.razonSocial}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <TextField
                        required
                        type="text"
                        name="empresa"
                        label={i18n.t("user.empresa")}
                        size="medium"
                        fullWidth
                        value={form.empresa}
                        onChange={onChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Business />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <TextField
                        type="text"
                        name="cargo"
                        label={i18n.t("user.cargo")}
                        size="medium"
                        fullWidth
                        value={form.cargo}
                        onChange={onChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <BusinessCenterOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <TextField
                        type="text"
                        name="telefono"
                        label={i18n.t("user.telefono")}
                        size="medium"
                        fullWidth
                        value={form.telefono}
                        onChange={onChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Phone />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <TextField
                        type="text"
                        name="email"
                        label={i18n.t("user.email")}
                        size="medium"
                        fullWidth
                        error={message !== undefined}
                        helperText={message}
                        value={form.email}
                        onChange={onChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EmailOutlined />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.privacyPolicy}
                            onChange={onChange}
                            name="privacyPolicy"
                            color="secondary"
                          />
                        }
                        label={i18n.t("user.privacyPolicy")}
                        className={"check-long-text"}
                      />
                    </FormGroup>
                  </Grid>
                </Grid>

                <Grid container>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={!form.privacyPolicy}
                  >
                    {i18n.t("login.continuar")}
                  </Button>

                  <Button
                    onClick={() =>
                      partner
                        ? props.history.push("/login/" + partner)
                        : props.history.push("/login")
                    }
                    variant="text"
                    color="secondary"
                    className="ml-auto"
                  >
                    {i18n.t("login.login")}
                  </Button>
                </Grid>
              </form>
            )}
          </Paper>
        </Col>
      </Row>
    </Auth>
  );
}
