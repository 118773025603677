import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function recoverAccount(email) {
  return fetch(`${baseUrl}/api/userManager/recovery?email=${email}`, {
    method: "POST",
  });
}

export function validateAccountRecovery(token, email) {
  return fetch(
    `${baseUrl}/api/userManager/recovery-validation?token=${token}&email=${email}`,
    {
      method: "POST",
    }
  );
}

export function resetPassword(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}/resetPassword`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function update(id, requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function removePartnerAcces(id, partnerId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/partnerAcces/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(partnerId),
  });
}

export function getAccess(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/access/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function removeAccess(id, requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/access/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function addAccess(id, requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/access/${id}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function updateAccess(id, requestUser) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/access/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestUser),
  });
}

export function deshabilitarUsuario(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}/disable`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function habilitarUsuario(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/${id}/enable`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getIfUserExist(email) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/getByEmail`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  });
}

export function getUserPartner() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/userManager/getUserPartner`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function emailReminder() {

  return fetch(`${baseUrl}/api/userManager/remindertest`, {
  });
}

export function turnOffReminder(id) {

  return fetch(`${baseUrl}/api/userManager/reminderoff`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(id),
  });
}
