import { Button, Snackbar } from "@material-ui/core";
import React, { useState } from "react";

export default function CookieSnackbar() {
  const [cookiePolicy, setCookiePolicy] = useState(
    localStorage.getItem("cookie_policy")
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={cookiePolicy !== "ok"}
      message="Este sitio web utiliza cookies, tanto propias como de terceros, para recopilar información estadística sobre su navegación."
      action={
        <React.Fragment>
          <Button
            onClick={() => {
              localStorage.setItem("cookie_policy", "ok");
              setCookiePolicy("ok");
            }}
            className="mr-2"
            color="secondary"
            variant="contained"
            size="small"
          >
            Estoy de acuerdo
          </Button>
          <Button
            href="/cookies"
            target="_blank"
            color="primary"
            variant="contained"
            size="small"
          >
            Más información
          </Button>
        </React.Fragment>
      }
    />
  );
}
