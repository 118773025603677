import React, { useEffect, useState, useCallback } from "react";
import RiskCalculation from "../helpers/riskCalculation.json";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { EquiposAPI } from "../services/API";
import RelativeBackdrop from "./RelativeBackdrop";
import i18n from "../locales/i18n";

export default function AssessmentTable(props) {
  const { plantaId } = props;
  const [families, setFamilies] = useState(null);
  const [loading, setLoading] = useState(false);

  const getFamilies = useCallback(async () => {
    setLoading(true);
    await EquiposAPI.getAssessmentByPlanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        Object.keys(data.fcGroups).forEach((family) => {
          data.fcGroups[family] = data.fcGroups[family].map((equipo) =>
            getRisk(equipo)
          );
        });
        Object.keys(data.vltGroups).forEach((family) => {
          data.vltGroups[family] = data.vltGroups[family].map((equipo) =>
            getRisk(equipo)
          );
        });

        data.fcd302 = data.fcd302.map((equipo) => getRisk(equipo));
        data.fcd300 = data.fcd300.map((equipo) => getRisk(equipo));
        data.mcd200 = data.mcd200.map((equipo) => getRisk(equipo));
        data.fcm = data.fcm.map((equipo) => getRisk(equipo));
        data.fcp = data.fcp.map((equipo) => getRisk(equipo));
        setFamilies(data);
      })
      .catch((error) => {
        setLoading(false);
      });

    setLoading(false);
  }, [plantaId]);

  const getRisk = (equipo) => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );
    if (!risk) return equipo;
    equipo.riesgo = risk.result;
    return equipo;
  };

  useEffect(() => {
    getFamilies();
  }, [getFamilies]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RelativeBackdrop hidden={!loading} absolute>
          <TableContainer component={Paper}>
            <Table size="small" style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={15}
                    align="center"
                    style={{
                      color: "white",
                      background:
                        "linear-gradient(45deg, rgb(232, 55, 105, 0.9) 30%, rgb(110, 68, 142, 0.9) 90%)",
                    }}
                  >
                    {i18n.t("assessment.title")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    rowSpan={3}
                    align="center"
                    style={{
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("assessment.productFamily")}
                  </TableCell>
                  <TableCell
                    rowSpan={3}
                    align="center"
                    style={{
                      borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("assessment.numAssets")}
                  </TableCell>
                  <TableCell
                    rowSpan={3}
                    align="center"
                    style={{
                      borderLeft: "1px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("maintenance.fields.brand")}
                  </TableCell>
                  <TableCell
                    colSpan={12}
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                      backgroundColor: "#eceff1",
                    }}
                  >
                    {i18n.t("assessment.riskFactors")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                      backgroundColor: "#eceff1",
                    }}
                  >
                    {i18n.t("assessment.LCS")}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                      backgroundColor: "#eceff1",
                    }}
                  >
                    {i18n.t("assessment.AC")}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                      backgroundColor: "#eceff1",
                    }}
                  >
                    {i18n.t("assessment.ACo")}
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      padding: "6px 0",
                      backgroundColor: "#eceff1",
                    }}
                  >
                    {i18n.t("assessment.AR")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      backgroundColor: "#a5d6a7",
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("common.activo.singularM")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#ffab91", padding: "6px 0" }}
                  >
                    {i18n.t("common.limitado")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#f48fb1", padding: "6px 0" }}
                  >
                    {i18n.t("common.inactivo")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      backgroundColor: "#f48fb1",
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("common.alto")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#ffab91", padding: "6px 0" }}
                  >
                    {i18n.t("common.medio")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#a5d6a7", padding: "6px 0" }}
                  >
                    {i18n.t("common.bajo")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      backgroundColor: "#a5d6a7",
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("common.bueno")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#ffab91", padding: "6px 0" }}
                  >
                    {i18n.t("common.medio")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#f48fb1", padding: "6px 0" }}
                  >
                    {i18n.t("common.pobre")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      borderLeft: "3px solid rgba(224, 224, 224, 1)",
                      backgroundColor: "#a5d6a7",
                      padding: "6px 0",
                    }}
                  >
                    {i18n.t("common.bajo")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#ffab91", padding: "6px 0" }}
                  >
                    {i18n.t("common.medio")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ backgroundColor: "#f48fb1", padding: "6px 0" }}
                  >
                    {i18n.t("common.alto")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {families !== null && (
                  <React.Fragment>
                    {Object.keys(families.vltGroups).map((family) => (
                      <TableRow key={family}>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {family}
                        </TableCell>
                        <TableCell align="center">
                          {families.vltGroups[family].length}
                        </TableCell>
                        <TableCell align="center">VLT</TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.vltGroups[family].filter(
                              (e) => e.cicloVida === 0
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.cicloVida === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.cicloVida === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.vltGroups[family].filter(
                              (e) => e.criticidad === 0
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.criticidad === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.criticidad === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.vltGroups[family].filter(
                              (e) => e.estado === 0
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.estado === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.estado === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.vltGroups[family].filter(
                              (e) => e.riesgo === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.riesgo === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.vltGroups[family].filter(
                              (e) => e.riesgo === 0
                            ).length
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                    {Object.keys(families.fcGroups).map((family) => (
                      <TableRow key={family}>
                        <TableCell
                          align="center"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {family}
                        </TableCell>
                        <TableCell align="center">
                          {families.fcGroups[family].length}
                        </TableCell>
                        <TableCell align="center">VLT</TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.fcGroups[family].filter(
                              (e) => e.cicloVida === 0
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.cicloVida === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.cicloVida === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.fcGroups[family].filter(
                              (e) => e.criticidad === 0
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.criticidad === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.criticidad === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.fcGroups[family].filter(
                              (e) => e.estado === 0
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.estado === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.estado === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            borderLeft: "3px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {
                            families.fcGroups[family].filter(
                              (e) => e.riesgo === 2
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.riesgo === 1
                            ).length
                          }
                        </TableCell>
                        <TableCell align="center">
                          {
                            families.fcGroups[family].filter(
                              (e) => e.riesgo === 0
                            ).length
                          }
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell align="center">FCD300</TableCell>
                      <TableCell align="center">
                        {families.fcd300.length}
                      </TableCell>
                      <TableCell align="center">VLT</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcd300.filter((e) => e.cicloVida === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd300.filter((e) => e.cicloVida === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd300.filter((e) => e.cicloVida === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcd300.filter((e) => e.criticidad === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd300.filter((e) => e.criticidad === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd300.filter((e) => e.criticidad === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcd300.filter((e) => e.estado === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd300.filter((e) => e.estado === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd300.filter((e) => e.estado === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcd300.filter((e) => e.riesgo === 2).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd300.filter((e) => e.riesgo === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd300.filter((e) => e.riesgo === 0).length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">FCD302</TableCell>
                      <TableCell align="center">
                        {families.fcd302.length}
                      </TableCell>
                      <TableCell align="center">VLT</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcd302.filter((e) => e.cicloVida === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd302.filter((e) => e.cicloVida === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd302.filter((e) => e.cicloVida === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcd302.filter((e) => e.criticidad === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd302.filter((e) => e.criticidad === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcd302.filter((e) => e.criticidad === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcd302.filter((e) => e.estado === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd302.filter((e) => e.estado === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd302.filter((e) => e.estado === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcd302.filter((e) => e.riesgo === 2).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd302.filter((e) => e.riesgo === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcd302.filter((e) => e.riesgo === 0).length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">MCD200</TableCell>
                      <TableCell align="center">
                        {families.mcd200.length}
                      </TableCell>
                      <TableCell align="center">VLT</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.mcd200.filter((e) => e.cicloVida === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.mcd200.filter((e) => e.cicloVida === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.mcd200.filter((e) => e.cicloVida === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.mcd200.filter((e) => e.criticidad === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.mcd200.filter((e) => e.criticidad === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.mcd200.filter((e) => e.criticidad === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.mcd200.filter((e) => e.estado === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.mcd200.filter((e) => e.estado === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.mcd200.filter((e) => e.estado === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.mcd200.filter((e) => e.riesgo === 2).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.mcd200.filter((e) => e.riesgo === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.mcd200.filter((e) => e.riesgo === 0).length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">FCM</TableCell>
                      <TableCell align="center">
                        {families.fcm.length}
                      </TableCell>
                      <TableCell align="center">VLT</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcm.filter((e) => e.cicloVida === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcm.filter((e) => e.cicloVida === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcm.filter((e) => e.cicloVida === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcm.filter((e) => e.criticidad === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcm.filter((e) => e.criticidad === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcm.filter((e) => e.criticidad === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcm.filter((e) => e.estado === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcm.filter((e) => e.estado === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcm.filter((e) => e.estado === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcm.filter((e) => e.riesgo === 2).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcm.filter((e) => e.riesgo === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcm.filter((e) => e.riesgo === 0).length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">FCP</TableCell>
                      <TableCell align="center">
                        {families.fcp.length}
                      </TableCell>
                      <TableCell align="center">VLT</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcp.filter((e) => e.cicloVida === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcp.filter((e) => e.cicloVida === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcp.filter((e) => e.cicloVida === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.fcp.filter((e) => e.criticidad === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcp.filter((e) => e.criticidad === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.fcp.filter((e) => e.criticidad === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcp.filter((e) => e.estado === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcp.filter((e) => e.estado === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcp.filter((e) => e.estado === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.fcp.filter((e) => e.riesgo === 2).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcp.filter((e) => e.riesgo === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.fcp.filter((e) => e.riesgo === 0).length}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="center">VACON</TableCell>
                      <TableCell align="center">
                        {families.vacon.length}
                      </TableCell>
                      <TableCell align="center">VACON</TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.vacon.filter((e) => e.cicloVida === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.vacon.filter((e) => e.cicloVida === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.vacon.filter((e) => e.cicloVida === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {
                          families.vacon.filter((e) => e.criticidad === 0)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.vacon.filter((e) => e.criticidad === 1)
                            .length
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          families.vacon.filter((e) => e.criticidad === 2)
                            .length
                        }
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.vacon.filter((e) => e.estado === 0).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.vacon.filter((e) => e.estado === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.vacon.filter((e) => e.estado === 2).length}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderLeft: "3px solid rgba(224, 224, 224, 1)",
                        }}
                      >
                        {families.vacon.filter((e) => e.riesgo === 2).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.vacon.filter((e) => e.riesgo === 1).length}
                      </TableCell>
                      <TableCell align="center">
                        {families.vacon.filter((e) => e.riesgo === 0).length}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </RelativeBackdrop>
      </Grid>
    </Grid>
  );
}
