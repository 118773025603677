import React from "react";
import { withRouter } from "react-router-dom";

import {
  IconButton,
  makeStyles,
  Paper,
  Divider,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  title: {
    padding: 10,
  },
  startIcon: {
    margin: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

function SmallTitle(props) {
  const { title, goBack } =props;
  const classes = useStyles();

  // const goBack = () => {
  //   props.history.goBack();
  // };

  return (
    <Paper component="form" className={classes.root}>
      <IconButton
        className={classes.iconButton}
        onClick={goBack}
        aria-label="back"
      >
        <ArrowBack />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <Typography variant="subtitle1" className={classes.title}>{title}</Typography>
    </Paper>
  );
}

export default withRouter(SmallTitle);
