import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import FormularioRiesgoVariador from "../../molecule/formRiesgoVariador/formRiesgoVariador";
import Gbar from "../../../assets/images/g-bar.png";
import i18n from "../../../locales/i18n";

export default function CardRiesgoVariador(props) {
  const { equipo, colWith, handleInputChange, readOnly } = props;
  return (
    <>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 25,
          marginTop: 25,
          backgroundImage: `url("${Gbar}")`,
          backgroundSize: "cover",
          height: 3,
          width: "100%",
        }}
      />
      <Card>
        <CardHeader title={i18n.t("equipo.riesgo")} />
        <CardContent>
          <FormularioRiesgoVariador
            equipo={equipo}
            colWith={colWith}
            handleInputChange={handleInputChange}
            readOnly={readOnly}
          />
        </CardContent>
      </Card>
    </>
  );
}
