import React, { useEffect } from "react";
import i18n from "../locales/i18n";

export default function Cookies({ setNavBarTitle }) {
  useEffect(() => {
    setNavBarTitle(i18n.t("cookies.title"));
  }, [setNavBarTitle]);

  return (
    <div
      style={{
        maxWidth: "1000px",
        textAlign: "justify",
      }}
    >
      <h3>POLÍTICA DE COOKIES</h3>
      <br />
      <h5>1.Introducción.</h5>
      <p>
        Conforme a lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de
        julio, de Servicios de la Sociedad de la Información y de Comercio
        Electrónico (LSSI-CE) vigente, INGESIS AUTOMATIZACIÓN, S.L viene a
        cumplir sus obligaciones de información sobre las cookies que utiliza y
        sus finalidades.
      </p>
      <h5>2. Definición y función genérica de cookies.</h5>
      <p>
        Las cookies son archivos creados por un sitio web y/o aplicación que
        contiene pequeñas cantidades de datos y que se envían entre un emisor y
        un receptor permitiendo a una página web, entre otras cosas, almacenar y
        recuperar información sobre los hábitos de navegación de un usuario o de
        su equipo y, dependiendo de la información que contenga y de la forma en
        que utilice su equipo, pueden utilizarse para reconocer al usuario.
      </p>
      <p>
        Las cookies son esenciales para el funcionamiento de internet, aportando
        innumerables ventajas en la prestación de servicios interactivos,
        facilitándole la navegación y usabilidad de nuestra web.
      </p>
      <p>
        <b>
          <ins>
            Estos son los tipos principales de cookies que puede encontrarse un
            usuario en una página web:
          </ins>
        </b>
      </p>
      <p>
        <b>
          <ins>SEGÚN LA ENTIDAD QUE LAS GESTIONE:</ins>
        </b>
      </p>
      <p>
        <b>COOKIES PROPIAS:</b> Son aquellas que se envían al equipo terminal
        del usuario desde un equipo o dominio gestionado por el propio editor y
        desde el que se presta el servicio solicitado por el usuario.
      </p>
      <p>
        <b>COOKIES DE TERCERO</b>: Son aquellas que se envían al equipo terminal
        del usuario desde un equipo o dominio que no es gestionado por el
        editor, sino por otra entidad que trata los datos obtenidos a través de
        las cookies.
      </p>
      <p>
        <b>
          <ins>SEGÚN EL PLAZO DE TIEMPO QUE PERMANEZCAN ACTIVADAS</ins>
        </b>
      </p>
      <p>
        <b>COOKIES DE SESIÓN:</b> Son aquellas diseñadas para recabar y
        almacenar datos mientras el usuario accede a una página web. Se suelen
        emplear para almacenar información que solo interesa conservar para la
        prestación del servicio solicitado por el usuario en una sola ocasión
        (por ejemplo, una lista de productos adquiridos) y desaparecen al
        terminar la sesión.
      </p>
      <p>
        <b>COOKIES PERSISTENTES:</b> Son aquellas en las que los datos siguen
        almacenados en el terminal y pueden ser accedidos y tratados durante un
        periodo definido por el responsable de la cookie, y que puede ir de unos
        minutos a varios años.
      </p>
      <p>
        <b>
          <ins>SEGÚN SU FINALIDAD:</ins>
        </b>
      </p>
      <p>
        <b>COOKIES TÉCNICAS:</b> Son aquellas que permiten al usuario la
        navegación a través de una página web, plataforma o aplicación y la
        utilización de las diferentes opciones o servicios que en ella existan.
      </p>
      <p>
        <b>COOKIES DE PERSONALIZACIÓN:</b> Permiten aplicar características
        propias para la navegación del usuario por el website (Ej. idioma).
      </p>
      <p>
        <b>COOKIES DE ANÁLISIS:</b> Son aquellas que permiten al responsable de
        las mismas el seguimiento y análisis del comportamiento de los usuarios
        de los sitios web a los que están vinculadas, incluida la cuantificación
        de los impactos de los anuncios. La información recogida mediante este
        tipo de cookies se utiliza en la medición de la actividad de los sitios
        web, aplicación o plataforma, con el fin de introducir mejoras en
        función del análisis de los datos de uso que hacen los usuarios del
        servicio.
      </p>
      <p>
        <b>COOKIES PUBLICITARIAS:</b> Permiten al editor incluir en la página
        web espacios publicitarios, según el contenido de la propia web.
      </p>
      <p>
        <b>COOKIES DE PUBLICIDAD COMPORTAMENTAL:</b> Son aquellas que almacenan
        información del comportamiento de los usuarios obtenida a través de la
        observación continuada de sus hábitos de navegación, lo que permite
        desarrollar un perfil específico para mostrar publicidad en función del
        mismo.
      </p>
      <br />
      <h5>3.Tipos de cookies utilizadas y finalidades.</h5>
      <p>
        El presente sitio web y la app ALTID INGESIS no utiliza cookies para
        recoger información de las personas usuarias.
      </p>
      <p>
        No obstante, esta web contiene enlaces a sitios web de terceros, cuyas
        políticas de privacidad son ajenas a INGESIS AUTOMATIZACIÓN, S.L. Al
        acceder a tales sitios web usted puede decidir si acepta sus políticas
        de privacidad y de cookies a través de sus paneles de configuración. Con
        carácter general, si navega por internet usted puede aceptar o rechazar
        las cookies de terceros desde las opciones de configuración de su
        navegador.
      </p>
      <br />
      <h5>
        4. Voluntariedad en la aceptación de cookies y revocación de
        consentimiento otorgado.
      </h5>
      <p>
        <b>
          Para utilizar este Sitio web y/o aplicación no resulta necesaria la
          instalación de cookies analíticas ni publicitarias.
        </b>{" "}
        Usted puede configurar su navegador para que acepte, rechace o le
        notifique cuando una cookie se va a alojar en su ordenador, cada
        navegador es diferente.
      </p>
      <p>
        A continuación, le proporcionamos los enlaces de diversos navegadores, a
        través de los cuales podrá modificar la configuración de su navegador
        sobre el uso de cookies:
      </p>
      <ul>
        <li>
          Firefox desde aquí:{" "}
          <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
            http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we
          </a>
        </li>
        <li>
          Chrome desde aquí:{" "}
          <a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">
            http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
          </a>
        </li>
        <li>
          Explorer desde aquí:{" "}
          <a href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10">
            http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10
          </a>
        </li>
        <li>
          Safari desde aquí:{" "}
          <a href="https://support.apple.com/kb/ph17191?locale=es_ES">
            https://support.apple.com/kb/ph17191?locale=es_ES
          </a>
        </li>
        <li>
          Opera desde aquí:{" "}
          <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
            https://help.opera.com/en/latest/web-preferences/#cookies
          </a>
        </li>
      </ul>
      <br />
      <h5>
        5. Información ampliada sobre las cookies utilizadas en este website.
      </h5>
      <p>
        Realizamos un gran esfuerzo para mantener la política de cookies
        actualizada. Si es conocedor de que nosotros o algún tercero estamos
        habilitando alguna cookie sin su consentimiento o considera que no hemos
        informado con toda la claridad sobre alguna cookie específica, le
        rogamos que nos lo haga saber en la siguiente dirección de e-mail:
        INGESIS@INGESIS.ES. Le recordamos que puede ejercer sus derechos de
        acceso, rectificación, supresión, limitación, portabilidad y cualquier
        otro derecho siguiendo el procedimiento indicado en nuestra política de
        privacidad.
      </p>
      <br />
      <h6>ALBERIC, a 03 de NOVIEMBRE de 2021</h6>
    </div>
  );
}
