import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getDocuments(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/documents`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function downloadDocument(id, filePath) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/documents/${filePath}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function uploadFile(id, formData) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/documents`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
}

export function uploadInventario(plantaId, formData) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/inventario/${plantaId}/import`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
}

export function add(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function update(equipo) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${equipo.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(equipo),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function removeDocument(id, filePath) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/documents/${filePath}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function removeImage(id, imageIndex) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/images/${imageIndex}/remove`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getEquipoImages(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/imagenes`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getEquiposByZona(id = "", filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/zona/${id}/${filter}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getEquiposByplanta(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/planta/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getAssessment() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/assessment`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getAssessmentByPlanta(plantaId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/planta/${plantaId}/assessment`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function takeEquipoByPlanta(plantaId, fromId, filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/planta/${plantaId}/${fromId}/${filter}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function searchEquipoByPlanta(plantaId, query, filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/planta/${plantaId}/search/${query}/${filter}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function searchEquipoByZona(zonaId, query, filter) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(
    `${baseUrl}/api/equipos/zona/${zonaId}/search/${query}/${filter}`,
    {
      headers: { Authorization: `Bearer ${access_token}` },
    }
  );
}

export function getReplacementEquipment(equipmentId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${equipmentId}/replacement`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getExportInventario(equipos, locale) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/inventario/export`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ equipos: equipos, locale: locale }),
  });
}

export function getExportInventarioDanfoss(equipos, locale) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/inventario/exportDanfoss`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ equipos: equipos, locale: locale }),
  });
}

export function sendOferta(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/oferta`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
  });
}

export function postAssessment(zonas) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/assessment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(zonas),
  });
}

export async function addImages(id, base64Images) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/equipos/${id}/addImage`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(base64Images),
  });
}
