import React, { useEffect } from "react";
import ExchangeSearchEngine from "../../../components/organism/ExchangeSearchEngine/ExchangeSearchEngine"

export default function EquiposExchangeSearch(props) {
  const { setNavBarTitle, history } = props;

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(``);
    }
  };

  useEffect(() => {
    const title = "Altid Exchange Club";
    setNavBarTitle(title);
  }, [setNavBarTitle]);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);

  return (
    <ExchangeSearchEngine
      history={history}
      goBack={goBack}
    />
  );
}