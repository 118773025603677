import React, { useState, useEffect } from "react";
import i18n from "../../../locales/i18n";

export default (props) => {
  const { setNavBarTitle } = props;

  const [versions, setVersions] = useState([]);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);
  useEffect(() => {
    setNavBarTitle(i18n.t("common.news"));
  }, [setNavBarTitle]);

  useEffect(() => {
    const objVersiones = [
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.3.2",
        webFeatures: [
          i18n.t("newsPage.stability"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.2.1",
        appFeatures: [
          i18n.t("newsPage.stability"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.3.0",
        webFeatures: [
          i18n.t("newsPage.web.v130.f1"),
          i18n.t("newsPage.web.v130.f2"),
          i18n.t("newsPage.web.v130.f3"),
          i18n.t("newsPage.stability"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.2.0",
        appFeatures: [
          i18n.t("newsPage.app.v120.f1"),
          i18n.t("newsPage.app.v120.f2"),
          i18n.t("newsPage.stability"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.2.0",
        webFeatures: [
          i18n.t("newsPage.web.v120.f1"),
          i18n.t("newsPage.web.v120.f2"),
          i18n.t("newsPage.web.v120.f3"),
          i18n.t("newsPage.stability"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.1.0",
        appFeatures: [
          i18n.t("newsPage.app.v110.f1"),
          i18n.t("newsPage.app.v110.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.12",
        webFeatures: [i18n.t("newsPage.stability"), i18n.t("newsPage.errors")],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.39",
        appFeatures: [
          i18n.t("newsPage.app.v1039.f1"),
          i18n.t("newsPage.app.v1039.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.11",
        webFeatures: [
          i18n.t("newsPage.web.v1111.f1"),
          i18n.t("newsPage.web.v1111.f2"),
          i18n.t("newsPage.web.v1111.f3"),
          i18n.t("newsPage.web.v1111.f4"),
          i18n.t("newsPage.web.v1111.f5"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.39",
        appFeatures: [
          i18n.t("newsPage.app.v1039.f1"),
          i18n.t("newsPage.app.v1039.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.10",
        webFeatures: [
          i18n.t("newsPage.web.v1110.f1"),
          i18n.t("newsPage.web.v1110.f2"),
          i18n.t("newsPage.web.v1110.f3"),
          i18n.t("newsPage.web.v1110.f4"),
          i18n.t("newsPage.web.v1110.f5"),
          i18n.t("newsPage.web.v1110.f6"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.39",
        appFeatures: [
          i18n.t("newsPage.app.v1039.f1"),
          i18n.t("newsPage.app.v1039.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.9",
        webFeatures: [
          i18n.t("newsPage.web.v119.f1"),
          i18n.t("newsPage.web.v119.f2"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.38",
        appFeatures: [
          i18n.t("newsPage.app.v1038.f1"),
          i18n.t("newsPage.app.v1038.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.8",
        webFeatures: [i18n.t("newsPage.stability")],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.37",
        appFeatures: [
          i18n.t("newsPage.app.v1037.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.7",
        webFeatures: [
          i18n.t("newsPage.web.v117.f1"),
          i18n.t("newsPage.web.v117.f2"),
          i18n.t("newsPage.web.v117.f3"),
          i18n.t("newsPage.web.v117.f4"),
          i18n.t("newsPage.web.v117.f5"),
          i18n.t("newsPage.web.v117.f6"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.36",
        appFeatures: [
          i18n.t("newsPage.app.v1036.f1"),
          i18n.t("newsPage.app.v1036.f2"),
          i18n.t("newsPage.app.v1036.f3"),
          i18n.t("newsPage.app.v1036.f4"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.6",
        webFeatures: [
          i18n.t("newsPage.web.v116.f1"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.35",
        appFeatures: [i18n.t("newsPage.errors")],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.5",
        webFeatures: [
          i18n.t("newsPage.web.v115.f1"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.32",
        appFeatures: [
          i18n.t("newsPage.app.v1032.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.3",
        webFeatures: [
          i18n.t("newsPage.web.v113.f1"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.31",
        appFeatures: [
          i18n.t("newsPage.app.v1031.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.2",
        webFeatures: [
          i18n.t("newsPage.web.v112.f1"),
          i18n.t("newsPage.web.v112.f2"),
          i18n.t("newsPage.web.v112.f3"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.30",
        appFeatures: [
          i18n.t("newsPage.app.v1030.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.1",
        webFeatures: [i18n.t("newsPage.stability")],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.29",
        appFeatures: [i18n.t("newsPage.errors")],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.1.0",
        webFeatures: [
          i18n.t("newsPage.web.v110.f1"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.28",
        appFeatures: [
          i18n.t("newsPage.app.v1028.f1"),
          i18n.t("newsPage.app.v1028.f2"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.10",
        webFeatures: [i18n.t("newsPage.web.v1010.f1")],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.27",
        appFeatures: [i18n.t("newsPage.stability"), i18n.t("newsPage.errors")],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.9",
        webFeatures: [
          i18n.t("newsPage.web.v109.f1"),
          i18n.t("newsPage.stability"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.26",
        appFeatures: [
          i18n.t("newsPage.app.v1026.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.8",
        webFeatures: [
          i18n.t("newsPage.web.v108.f1"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.22",
        appFeatures: [
          i18n.t("newsPage.app.v1022.f1"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.7",
        webFeatures: [
          i18n.t("newsPage.web.v107.f1"),
          i18n.t("newsPage.web.v107.f2"),
          i18n.t("newsPage.web.v107.f3"),
          i18n.t("newsPage.web.v107.f4"),
          i18n.t("newsPage.web.v107.f5"),
          i18n.t("newsPage.errors"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.21",
        appFeatures: [
          i18n.t("newsPage.app.v1021.f1"),
          i18n.t("newsPage.app.v1021.f2"),
          i18n.t("newsPage.app.v1021.f3"),
          i18n.t("newsPage.app.v1021.f4"),
          i18n.t("newsPage.app.v1021.f5"),
          i18n.t("newsPage.errors"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.5",
        webFeatures: [
          i18n.t("newsPage.web.v105.f1"),
          i18n.t("newsPage.web.v105.f2"),
          i18n.t("newsPage.web.v105.f3"),
          i18n.t("newsPage.web.v105.f4"),
          i18n.t("newsPage.web.v105.f5"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.18",
        appFeatures: [
          i18n.t("newsPage.app.v1018.f1"),
          i18n.t("newsPage.app.v1018.f2"),
          i18n.t("newsPage.app.v1018.f3"),
          i18n.t("newsPage.app.v1018.f4"),
          i18n.t("newsPage.app.v1018.f5"),
          i18n.t("newsPage.app.v1018.f6"),
          i18n.t("newsPage.app.v1018.f7"),
        ],
      },
      {
        webTitle: i18n.t("newsPage.web.title") + "v1.0.4",
        webFeatures: [
          i18n.t("newsPage.web.v104.f1"),
          i18n.t("newsPage.web.v104.f2"),
          i18n.t("newsPage.web.v104.f3"),
          i18n.t("newsPage.web.v104.f4"),
        ],
        appTitle: i18n.t("newsPage.app.title") + "v1.0.16",
        appFeatures: [
          i18n.t("newsPage.app.v1016.f1"),
          i18n.t("newsPage.app.v1016.f2"),
          i18n.t("newsPage.app.v1016.f3"),
          i18n.t("newsPage.app.v1016.f4"),
          i18n.t("newsPage.app.v1016.f5"),
          i18n.t("newsPage.app.v1016.f6"),
          i18n.t("newsPage.app.v1016.f7"),
          i18n.t("newsPage.app.v1016.f8"),
          i18n.t("newsPage.app.v1016.f9"),
        ],
      },
    ];
    setVersions(objVersiones);
  }, []);

  if (versions.length === 0) {
    return <h2 style={styles.pageTitle}>En construcción</h2>;
  } else {
    return (
      <>
        <h2 style={styles.pageTitle}>{i18n.t("newsPage.title")}</h2>
        <h3 style={styles.sectionTitle}>{i18n.t("newsPage.actualVerson")}</h3>
        <hr />
        <div>
          <h4 style={styles.listTitle}>{versions[0].webTitle}</h4>
          <ul>
            {versions[0].webFeatures.map((feature) => (
              <li>{feature}</li>
            ))}
          </ul>
          <h4 style={styles.listTitle}>{versions[0].appTitle}</h4>
          <ul>
            {versions[0].appFeatures.map((feature) => (
              <li>{feature}</li>
            ))}
          </ul>
        </div>
        <br />
        <h3 style={styles.sectionTitle}>{i18n.t("newsPage.prevVersion")}</h3>
        <hr />
        <div>
          {versions.slice(1).map((version) => (
            <>
              <h4 style={styles.listTitle}>{version.webTitle}</h4>
              <ul>
                {version.webFeatures.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
              <h4 style={styles.listTitle}>{version.appTitle}</h4>
              <ul>
                {version.appFeatures.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
              <hr />
            </>
          ))}
        </div>
      </>
    );
  }
};

const styles = {
  pageTitle: {
    color: "purple",
    fontWeight: 350,
  },
  sectionTitle: {
    color: "purple",
    fontWeight: 300,
  },
  listTitle: {
    color: "#e01a76",
    fontWeight: 400,
  },
  listItem: {},
};
