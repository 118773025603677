import React, { useState, useEffect } from "react";

import i18n from "../locales/i18n";
import AuthService from "../services/AuthService";
import RelativeBackdrop from "../components/RelativeBackdrop";
import {
  Card,
  Grid,
  CardContent,
  Typography,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import {
  Group,
  Business,
  Room,
} from "@material-ui/icons";
import { DashboardAPI } from "../services/API";
import ScheduledPreventiveMaintenance from "../components/ScheduledPreventiveMaintenance";
import AssessmentChart from "../components/AssessmentChart";



export default function Home({ history, setNavBarTitle }) {
  const [loading, setLoading] = useState(false);
  const [numClients, setNumClients] = useState(0);
  const [numPlants, setNumPlants] = useState(0);
  const [numZones, setNumZones] = useState(0);
  /** @type {boolean} */
  const IsGestorFromSomeClient = AuthService.isGestorFromSomeClient();

  const getDashboardData = async () => {
    await DashboardAPI.getAccess()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setNumClients(data.clienteAccesos.length);
        setNumPlants(data.plantaAccesos.length);
        setNumZones(data.zonaAccesos.length);
      })
      .catch((error) => {});
  };


  useEffect(() => {
    setNavBarTitle(i18n.t("dashboard.titulo"));
  }, [setNavBarTitle]);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;

    const getAllData = async () => {
      setLoading(true);
      return Promise.all([
        getDashboardData(),
      ]).then(() => setLoading(false));
    };

    getAllData();
  }, [setNavBarTitle]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Grid container spacing={3}>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={i18n.t("dashboard.card5_titulo")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    style={{ backgroundColor: "#552877" }}
                  >
                    <Group />
                  </Avatar>
                }
              />
              <CardContent
                style={IsGestorFromSomeClient ? { cursor: "pointer" } : null}
                onClick={() => IsGestorFromSomeClient ? history.push(`./clientes`) : null}
              >
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numClients}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={i18n.t("dashboard.card6_titulo")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    style={{ backgroundColor: "#552877" }}
                  >
                    <Business />
                  </Avatar>
                }
              />
              <CardContent
                style={{ cursor: "pointer" }}
                onClick={() => history.push(`./plantas`)}
              >
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numPlants}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardHeader
                title={i18n.t("dashboard.card7_titulo")}
                className="pb-0"
                avatar={
                  <Avatar
                    aria-label="avatar"
                    style={{ backgroundColor: "#552877" }}
                  >
                    <Room />
                  </Avatar>
                }
              />
              <CardContent>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {numZones}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item container spacing={3} xs={12}>
          <Grid item xs={12} >
            <AssessmentChart />
          </Grid>
          <Grid item xs={12}>
            <ScheduledPreventiveMaintenance history={history} />
          </Grid>
        </Grid>
      </Grid>
    </RelativeBackdrop>
  );
}
