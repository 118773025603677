import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TableBody,
} from "@material-ui/core";
import {
  AddShoppingCart,
  CheckCircle,
  RemoveShoppingCart,
} from "@material-ui/icons";
import moment from "moment";
import i18n from "../../../locales/i18n";

/**
 * Componente de tabla del previsto del mantenimiento
 * @Component
 * @param {Object} props
 * @param {Object} props.mantenimiento //Estado del componente Padre
 * @param {Function} props.click //Evento click del boton de la tabla
 * @param {Function} props.getModule2Years
 * @param {Function} props.getModule3Years
 * @param {boolean} props.shouldRenderMaintenance
 * @param {boolean} props.readOnly
 */
function MaintenanceTimeline({
  mantenimiento,
  getModule2Years,
  getModule3Years,
  click,
  shouldRenderMaintenance,
  readOnly,
}) {
  const { startup } = mantenimiento;

  // this limits the timeline to -6 months ... +6 months
  const minTime = mantenimiento.personalizado
    ? moment(mantenimiento.fechaModulo1)
    : moment(startup);
  const maxTime = mantenimiento.personalizado
    ? moment(mantenimiento.fechaModulo1).add(19, "year")
    : moment(startup).add(19, "year");

  var rows = [];
  var years = maxTime.diff(minTime, "years");

  for (let i = 0; i <= years; i++) {
    rows.push({
      years: i + 1,
      module1: false,
      module2: false,
      module3: false,
      dueDate: minTime.add(1, "year").format("YYYY"),
    });
  }

  const moudle1Interval = mantenimiento.personalizado
    ? parseInt(mantenimiento.customIntervaloModulo1)
    : 1;

  console.log(moudle1Interval);

  const yearsDifmodulo1 = mantenimiento.personalizado
    ? moment(mantenimiento.fechaModulo1).diff(
        moment(mantenimiento.fechaModulo1),
        "years"
      )
    : 0;

  if (moudle1Interval > 0) {
    for (
      let i = 0 + yearsDifmodulo1 - 1;
      i <= rows.length;
      i += moudle1Interval
    ) {
      if (rows[i] !== undefined) {
        rows[i].module1 = true;
      }
    }
  }

  const moudle2Interval = mantenimiento.personalizado
    ? parseInt(mantenimiento.customIntervaloModulo2)
    : getModule2Years();

  const yearsDifmodulo2 = mantenimiento.personalizado
    ? moment(mantenimiento.fechaModulo2).diff(
        moment(mantenimiento.fechaModulo1),
        "years"
      )
    : 0;

  if (moudle2Interval > 0) {
    for (
      let i = 0 + yearsDifmodulo2 - 1;
      i <= rows.length;
      i += moudle2Interval
    ) {
      if (rows[i] !== undefined) {
        rows[i].module2 = true;
      }
    }
  }

  const moudle3Interval = mantenimiento.personalizado
    ? parseInt(mantenimiento.customIntervaloModulo3)
    : getModule3Years();

  const yearsDifmodulo3 = mantenimiento.personalizado
    ? moment(mantenimiento.fechaModulo3).diff(
        moment(mantenimiento.fechaModulo1),
        "years"
      )
    : 0;

  if (moudle3Interval > 0) {
    for (
      let i = 0 + yearsDifmodulo3 - 1;
      i <= rows.length;
      i += moudle3Interval
    ) {
      if (rows[i] !== undefined) {
        rows[i].module3 = true;
      }
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small" style={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow style={{ backgroundColor: "#424242", height: 65 }}>
            <TableCell>
              <Button
                className="btn-special"
                startIcon={
                  mantenimiento.activo ? (
                    <RemoveShoppingCart />
                  ) : (
                    <AddShoppingCart />
                  )
                }
                onClick={() => click()}
                hidden={!shouldRenderMaintenance || readOnly}
              >
                {mantenimiento.activo
                  ? i18n.t("maintenance.deactivate")
                  : i18n.t("maintenance.contract")}
              </Button>
            </TableCell>
            <TableCell align="center" colSpan={3} style={{ color: "white" }}>
              {i18n.t("maintenance.drivePro")}
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}></TableCell>
          </TableRow>
          <TableRow style={{ height: 45 }}>
            <TableCell align="center">
              {i18n.t("maintenance.yearsFromStartup")}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.performModule", { module: 1 })}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.performModule", { module: 2 })}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.performModule", { module: 3 })}
            </TableCell>
            <TableCell align="center">
              {i18n.t("maintenance.dueDate")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ fontSize: 10 }}>
          {!shouldRenderMaintenance ? (
            <TableRow key="empty">
              <TableCell align="center" colSpan={5} height={100}>
                {i18n.t("maintenance.formNotFilledOut")}
              </TableCell>
            </TableRow>
          ) : (
            rows.map((row) => (
              <TableRow key={row.years}>
                <TableCell align="center">{row.years}</TableCell>
                <TableCell align="center">
                  {row.module1 && <CheckCircle style={{ color: "#43a047" }} />}
                </TableCell>
                <TableCell align="center">
                  {row.module2 && <CheckCircle style={{ color: "#0288d1" }} />}
                </TableCell>
                <TableCell align="center">
                  {row.module3 && <CheckCircle style={{ color: "#ffb300" }} />}
                </TableCell>
                <TableCell align="center">{row.dueDate}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MaintenanceTimeline;
