import React, { useState, useCallback, useEffect } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import SelectFrequencyConvertersSearchEngineFilter from "../../molecule/Select_FrequencyConvertersSearchEngineFilter/Select_FrequencyConvertersSearchEngineFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import EquipoCard from "../../../components/organism/EquipoCard/EquipoCard";
import { Button, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import i18n from "../../../locales/i18n";
import Equipo from "../../../models/equipo";
import DialogRevisionEquipos from "../DialogRevisionEquipos/DialogRevisionEquipos";
import { EquiposAPI } from "../../../services/API";
import { delay } from "../../../helpers/Common";
import Swal from "sweetalert2";

export default function FrequencyConvertersSearchEngine(props) {
  const { readOnly, PlantaId, ZonaId, history, goBack, setErrors } = props;

  const [value, setValue] = useState("");
  const [loadingEquipos, setLoadingEquipos] = useState(false);
  const [equipos, setEquipos] = useState([]);
  const [equipo, setEquipo] = useState(new Equipo());
  const [hasMoreEquipos, setHasMoreEquipos] = useState(false);
  const [changed, setChanged] = useState(false);
  const [activeFilter, setActiveFilter] = useState(0);
  const [index, setIndex] = useState(0);
  const [openRevision, setOpenRevision] = useState(false);

  const getEquiposByplanta = useCallback(
    (fromId, filter) => {
      setLoadingEquipos(true);

      return EquiposAPI.takeEquipoByPlanta(PlantaId, fromId, filter)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (fromId > -1) {
            setEquipos((e) => e.concat(data.equipos));
          } else {
            setEquipos(data.equipos);
            setEquipo(data.equipos[0]);
          }

          setHasMoreEquipos(data.hasMore);
          setLoadingEquipos(false);
        })
        .catch((error) => {
          setLoadingEquipos(false);
        });
    },
    [PlantaId]
  );

  const getEquiposByZona = useCallback(
    async (filter) => {
      EquiposAPI.getEquiposByZona(ZonaId, filter)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setEquipos(data);
          setEquipo(data[0]);
          setHasMoreEquipos(false);
        })
        .catch((error) => {
          setLoadingEquipos(false);
        });
    },
    [ZonaId]
  );

  const searchEquipoByZona = async (query, filter) => {
    setLoadingEquipos(true);

    return EquiposAPI.searchEquipoByZona(ZonaId, query, filter)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipos(data);
        setHasMoreEquipos(false);
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  };

  const searchEquiposByplanta = (query, filter) => {
    setLoadingEquipos(true);

    return EquiposAPI.searchEquipoByPlanta(PlantaId, query, filter)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipos(data);
        setHasMoreEquipos(false);
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  };
  const equipmetSearch = (query, filter) => {
    delay(() => {
      if (ZonaId) {
        if (query) {
          searchEquipoByZona(query, filter);
        } else {
          getEquiposByZona(filter);
        }
      } else {
        if (query) {
          searchEquiposByplanta(query, filter);
        } else {
          getEquiposByplanta(-1, filter);
        }
      }
    }, 600);
  };
  const updateEquipo = async (inactivo, provisional) => {
    setLoadingEquipos(true);
    setChanged(false);
    return EquiposAPI.update({
      ...equipo,
      inactivo: inactivo === undefined ? equipo.inactivo : inactivo,
      provisional: provisional === undefined ? equipo.provisional : provisional,
    })
      .then(async (response) => {
        console.log(response.ok);
        console.log(response);
        if (response.ok) {
          setErrors([]);
          var equiposN = equipos;
          equipo.provisional =
            provisional === undefined ? equipo.provisional : provisional;
          equiposN[index] = equipo;
          setEquipos(equiposN);
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
        setLoadingEquipos(false);
      })
      .catch((error) => {
        setLoadingEquipos(false);
      });
  };
  const siguienteEquipo = () => {
    const ind = index + 1;
    if (ind < equipos.length) {
      if (changed) {
        Swal.fire({
          title: i18n.t("planta.revisionEquipos.alert.title"),
          text: i18n.t("planta.revisionEquipos.alert.text"),
          showCancelButton: true,
          confirmButtonText: i18n.t("planta.revisionEquipos.alert.accept"),
          cancelButtonText: i18n.t("planta.revisionEquipos.alert.cancel"),
          icon: "warning",
        }).then((result) => {
          if (result.isConfirmed) {
            setEquipo(equipos[ind]);
            setIndex(ind);
            setChanged(false);
            if (ind > equipos.length - 5 && hasMoreEquipos) {
              getEquiposByplanta(
                Math.max.apply(
                  Math,
                  equipos.map((e) => e.id)
                ),
                activeFilter
              );
            }
          }
        });
      } else {
        setEquipo(equipos[ind]);
        setIndex(ind);
        if (ind > equipos.length - 5 && hasMoreEquipos) {
          getEquiposByplanta(
            Math.max.apply(
              Math,
              equipos.map((e) => e.id)
            ),
            activeFilter
          );
        }
      }
    }
  };
  const anteriorEquipo = () => {
    const ind = index - 1;
    if (ind >= 0) {
      if (changed) {
        Swal.fire({
          title: i18n.t("planta.revisionEquipos.alert.title"),
          text: i18n.t("planta.revisionEquipos.alert.text"),
          showCancelButton: true,
          confirmButtonText: i18n.t("planta.revisionEquipos.alert.accept"),
          cancelButtonText: i18n.t("planta.revisionEquipos.alert.cancel"),
          icon: "warning",
        }).then((result) => {
          if (result.isConfirmed) {
            setEquipo(equipos[ind]);
            setIndex(ind);
          }
        });
      } else {
        setEquipo(equipos[ind]);
        setIndex(ind);
      }
    }
  };
  const openRevisionFunction = () => {
    setOpenRevision(true);
  };

  const closeRevisionFunction = () => {
    setOpenRevision(false);
    setEquipo(equipos[0]);
    setIndex(0);
  };
  const handleChangeEquipo = (event) => {
    setChanged(true);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const equipoN = { ...equipo, [name]: value };
    setEquipo(equipoN);
  };
  useEffect(() => {
    if (PlantaId) {
      if (ZonaId) {
        Promise.all([getEquiposByZona(0)]).then(() => setLoadingEquipos(false));
      } else {
        Promise.all([getEquiposByplanta(-1, 0)]).then(() =>
          setLoadingEquipos(false)
        );
      }
    }
  }, [PlantaId, ZonaId, getEquiposByZona, getEquiposByplanta]);
  return (
    <RelativeBackdrop hidden={!loadingEquipos}>
      <Grid container spacing={2}>
        <Grid item container xs={12} sm={8} md={6} xl={4}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: value,
              onChange: (event) => {
                equipmetSearch(event.target.value, activeFilter);
                setValue(event.target.value);
              },
            }}
            goBack={goBack}
            endButton={
              !readOnly && (
                <Button
                  style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
                  onClick={() => {
                    if (ZonaId) {
                      history.push(`./${ZonaId}/equipos/`);
                    } else {
                      history.push(`./${PlantaId}/equipos/`);
                    }
                  }}
                >
                  <Add />
                  {i18n.t("common.new.masculine")}
                </Button>
              )
            }
          />
        </Grid>
        <Grid item container xs={12} sm={4} md={3} xl={2}>
          <SelectFrequencyConvertersSearchEngineFilter
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            equipmetSearch={equipmetSearch}
            setValue={setValue}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => {
              openRevisionFunction();
            }}
            style={{ height: "50px", backgroundColor: "white" }}
            hidden={readOnly || equipos.length === 0}
          >
            {i18n.t("planta.revisionEquipos.revision")}
          </Button>
        </Grid>
        <Grid item container spacing={2}>
          <InfiniteScroll
            style={{ display: "flex", flexWrap: "wrap" }}
            scrollableTarget="main-content"
            dataLength={equipos.length}
            next={() =>
              getEquiposByplanta(
                equipos.length === 0
                  ? -1
                  : Math.max.apply(
                      Math,
                      equipos.map((e) => e.id)
                    ),
                activeFilter
              )
            }
            hasMore={hasMoreEquipos}
            loader
          >
            {equipos.map((equipo) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  xl={2}
                  style={{ padding: 8 }}
                  key={equipo.id}
                >
                  <Link
                    to={
                      ZonaId
                        ? `./${ZonaId}/equipos/${equipo.id}`
                        : `./${PlantaId}/equipos/${equipo.id}`
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <EquipoCard equipo={equipo} />
                  </Link>
                </Grid>
              );
            })}
          </InfiniteScroll>
        </Grid>
      </Grid>
      <DialogRevisionEquipos
        open={openRevision}
        closeFunction={closeRevisionFunction}
        equipo={equipo}
        siguienteEquipo={siguienteEquipo}
        anteriorEquipo={anteriorEquipo}
        handleInputChange={handleChangeEquipo}
        guardarEquipo={updateEquipo}
        readOnly={readOnly}
      />
    </RelativeBackdrop>
  );
}
