import React, { useEffect, useState, useCallback } from "react";
import MaterialTable from "material-table";
import AuthService from "../../../services/AuthService";
import i18n from "../../../locales/i18n";
import { PNTCsAPI, PlantaStockEmergenciaAPI } from "../../../services/API";
import PlantaStockEmergencia from "../../../models/PlantaStockEmergencia";

export default function PlantaStockEmergenciaTable(props) {
  const { plantaId } = props;
  const [stock, setStock] = useState([]);
  const [pntcs, setPntcs] = useState([]);
  const [loading, setLoading] = useState(false);
  const isPartner = AuthService.isGestorFromSomePartner();

  const getPNS = async () => {
    setLoading(true);

    await PNTCsAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPntcs(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStock = useCallback(async () => {
    setLoading(true);
    await PlantaStockEmergenciaAPI.getByPlanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setStock(data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });

    setLoading(false);
  }, [plantaId]);

  const addStock = async (stock) => {
    if (
      !stock.pntc ||
      !stock.pntc.pn ||
      !pntcs.some((p) => p.pn === stock.pntc.pn)
    )
      return;
    setLoading(true);

    stock.pntcId = pntcs.find((p) => p.pn === stock.pntc.pn).id;
    stock.pntc = null;

    await PlantaStockEmergenciaAPI.add({ ...stock, plantaId: plantaId })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        getStock();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateStock = async (newData, oldData) => {
    if (
      !newData.pntc ||
      !newData.pntc.pn ||
      !pntcs.some((p) => p.pn === newData.pntc.pn)
    )
      return;
    setLoading(true);

    newData.pntc = pntcs.find((p) => p.pn === newData.pntc.pn);
    newData.pntcId = newData.pntc.id;

    await PlantaStockEmergenciaAPI.update(new PlantaStockEmergencia(newData))
      .then((response) => {
        if (response.ok) {
          setStock((prevState) =>
            prevState.map((plantaStock) =>
              plantaStock.id === oldData.id
                ? {
                    ...plantaStock,
                    pntc: newData.pntc,
                    pntcId: newData.pntc.id,
                  }
                : plantaStock
            )
          );
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });

    setLoading(false);
  };

  const deleteStock = async (stock) => {
    setLoading(true);

    await PlantaStockEmergenciaAPI.remove(stock.id)
      .then((response) => {
        if (response.ok) {
          getStock();
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStock();
    getPNS();
  }, [getStock]);

  return (
    <MaterialTable
      style={{ width: "100%" }}
      title={null}
      // @ts-ignore
      localization={i18n.t("dataTableProps")}
      isLoading={loading}
      data={stock}
      options={{
        addRowPosition: "first",
        actionsColumnIndex: -1,
        sorting: true,
        showTitle: false,
        searchFieldAlignment: "left",
        pageSize: 10,
        exportButton: { csv: true },
        exportCsv: (columns, renderData) => {
          PlantaStockEmergenciaAPI.getExport(plantaId)
            .then((response) => response.blob())
            .then((blob) => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = url;
              a.download = "emergency-stock.xlsx";
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
            });
        },
      }}
      columns={[
        {
          field: "pntc.pn",
          title: "PN",
          validate: (rowData) => {
            var isEmpty = !rowData.pntc;
            var isValid =
              !isEmpty && pntcs && pntcs.some((p) => p.pn === rowData.pntc.pn);
            return {
              isValid: isValid,
              helperText: isEmpty
                ? "Requerido"
                : isValid
                ? ""
                : "Referencia no encontrada en Altid",
            };
          },
        },
        {
          field: "pntc.tc",
          title: "TC / TYPE",
          editable: "never",
        },
        {
          field: "countEquipos",
          title: i18n.t("planta.stockEmergencia.numberOfEquipments"),
          editable: "never",
        },
        {
          field: "countAlmacen",
          title: i18n.t("planta.stockEmergencia.numberInWarehouse"),
          editable: "never",
        },
      ]}
      editable={
        isPartner
          ? {
              onRowAdd: (newData) => addStock(newData),
              onRowUpdate: (newData, oldData) => updateStock(newData, oldData),
              onRowDelete: (oldData) => deleteStock(oldData),
            }
          : {}
      }
      actions={[
        {
          icon: "refresh",
          tooltip: i18n.t("alarma.actualizar"),
          isFreeAction: true,
          onClick: (event) => {
            getStock();
          },
        },
      ]}
    />
  );
}
