import React, { useState, useEffect } from "react";

import i18n from "../../../locales/i18n";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import {
  Card,
  Grid,
  Button,
  CardHeader,
  Avatar,
  Typography,
  CardContent,
} from "@material-ui/core";
import { PersonAdd, Group, Person } from "@material-ui/icons";
import { UsersAPI } from "../../../services/API";
import withHover from "../../../components/withHover";
import { filter } from "../../../helpers/Filter";
import SearchField from "../../molecule/SearchField/SearchField";
import AuthService from "../../../services/AuthService";
const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function UsersList(props) {
  const { setNavBarTitle, keyuser } = props;
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const isGestorFromSomePartner = AuthService.isGestorFromSomePartner();

  const GetUsers = () => {
    setLoading(true);
    UsersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(({users, inactivos}) => {
        inactivos.forEach(user => {
          users.forEach(usuario => {
            if(usuario.id === user.id){
              let userId = users.indexOf(usuario);
              users[userId] = {...usuario, "inactivo":true}
            }
          });      
        });
        console.log(users);
        setUsers(users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setNavBarTitle(i18n.t("gestorUsuarios.titulo"));
    document.getElementById("main-content").scrollTop = 0;
    GetUsers();
  }, [setNavBarTitle]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={i18n.t("gestorUsuarios.listaUsuarios")}
              subheader={`${users.length} ${i18n.t(
                "gestorUsuarios.listaUsuariosSubheader"
              )}`}
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#552877" }}
                >
                  <Group />
                </Avatar>
              }
            />
          </Card>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={12} lg={6} xl={4}>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: searchQuery,
                onChange: (event) => setSearchQuery(event.target.value),
              }}
              endButton={
              (isGestorFromSomePartner || keyuser) &&
                
                  <Button
                    startIcon={<PersonAdd />}
                    style={{
                      padding: 10,
                      paddingLeft: 15,
                      color: "rgba(0, 0, 0, 0.70)",
                    }}
                    onClick={(event) => props.history.push(`./newuser`)}
                  >
                    {i18n.t("gestorUsuarios.nuevoUsuario.btnNuevoUsuario")}
                  </Button>
                
              }
            />
          </Grid>
          <Grid container item spacing={2}>
            {filter(users, searchQuery).map((user) => (
              <UserCard {...user} {...props} key={user.id} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </RelativeBackdrop>
  );

  function UserCard(props) {
    const { nombre, apellidos, cargo, telefono, email, id, inactivo } = props;
    return (
      <Grid item xs={6} lg={3} xl={2}>
        <CardWithHover 
          onClick={() => props.history.push(`./user/${id}`)} 
          style = {inactivo ? {background:"#999999"} : {}}
        >
          <CardHeader
            title={`${nombre} ${apellidos}`}
            classes={{content: "overflow-hidden"}}
            titleTypographyProps={{ noWrap: true, variant: "body2" }}
            subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
            subheader={email}
            avatar={
              <Avatar
                aria-label="avatar"
                style={{ backgroundColor: "#552877", fontWeight: 300 }}
              >
                <Person />
              </Avatar>
            }
          />
          <CardContent>
            <Typography variant="body2">{cargo || i18n.t("common.noCargo")}</Typography>
            <Typography variant="body2">{telefono || i18n.t("common.noTelefono")}</Typography>
          </CardContent>
        </CardWithHover>
      </Grid>
    );
  }
}
