import React from "react";
import AuthService from "../services/AuthService";
import { Route, Redirect } from "react-router";

/**
* Component que genera las rutas privadas y limita su acceso.
* @Component
*/

const PrivateRoute = ({ component: Component, acceso: Acceso, ...rest }) => (
  
  <Route
    {...rest}
    render={(props) => {
      /** @type {boolean} */ let AccessRequired = Acceso !== undefined;
      /** @type {boolean} */ let authorized = false;
      /** @type {boolean} */ let isAuthenticated = AuthService.isAuthenticated();
      /** @type {boolean} */ let readOnly = true;
      /** @type {boolean} */ let keyuser = false;

      if (isAuthenticated && !AccessRequired) {
        authorized = true;
      } else if (isAuthenticated && AccessRequired) {
        let userInfo = AuthService.getUserInfo();
        /* let hasRequiredRole = userInfo.roles.some(r =>
            Roles.includes(r)
          );
          authorized = hasRequiredRole; */
        if (userInfo.roles.some((r) => r === "keyuser")) {
          authorized = true;
          keyuser = true;
          readOnly = false;
        } else {
          // Control de seguridad inicial
          if (
            userInfo.partnerAccesos.length === 0 &&
            userInfo.clienteAccesos.length === 0 &&
            userInfo.plantaAccesos.length === 0 &&
            userInfo.zonaAccesos.length === 0
          ) {
            authorized = false;
            AuthService.logOut();
            window.location.replace("/unauthorized");
          } else {
            // Compruebo los acceso en función del tipo de acceso que requiera esta ruta.
            switch (Acceso.nivel + "") {
              case "partner":
                if (Acceso.varId) {
                  let elPartner = userInfo.partnerAccesos.find(
                    (c) => String(c.partnerId) === props.match.params[Acceso.varId]
                  );
                  if (elPartner !== undefined) {
                    authorized = true;
                    if (elPartner.appRoleName === "gestor-partner") {
                      readOnly = false;
                    }
                  }
                } else {
                  if (AuthService.isGestorFromSomePartner()) {
                    authorized = true;
                    readOnly = false;
                  }
                }
                break;
              case "cliente":
                let elCliente = userInfo.clienteAccesos.find(
                  (c) => String(c.clienteId) === props.match.params[Acceso.varId]
                );
                if (elCliente !== undefined) {
                  authorized = true;
                  if (elCliente.appRoleName === "gestor-cliente") {
                    readOnly = false;
                  }
                }
                break;
              case "planta":
                let laPlanta = userInfo.plantaAccesos.find(
                  (p) => String(p.plantaId) === props.match.params[Acceso.varId]
                );
                if (laPlanta !== undefined) {
                  authorized = true;
                  if (laPlanta.appRoleName === "gestor-planta") {
                    readOnly = false;
                  }
                }
                break;
              case "zona":
                let laZona = userInfo.zonaAccesos.find(
                  (z) => String(z.plantaZonaId) === props.match.params[Acceso.varId]
                );
                if (laZona !== undefined) {
                  authorized = true;
                  if (laZona.appRoleName === "gestor-zona") {
                    readOnly = false;
                  }
                }
                break;
              case "free":
                authorized = true;
                break;
              default:
                break;
            }
          }
        }
      }

      if (authorized) {
        return <Component {...props} readOnly={readOnly} keyuser={keyuser} />;
      } else {
        return (
          <Redirect
            to={{
              pathname:
                isAuthenticated && AccessRequired ? "/unauthorized" : "/login",
              state: { referrer: props.location.pathname }
            }}
          />
        );
      }
    }}
  />
);

export default PrivateRoute;
