import React, { useState, useEffect, useCallback } from "react";
import CardImagenesVariador from "../../molecule/cardImgVariador/cardImgVariador";
import RelativeBackdrop from "../../RelativeBackdrop";
import { EquiposExchangeAPI } from "../../../services/API";

export default function CardImgVariadorExchange(props) {
  const { id, loading, setLoading, isExchange } = props;
  const [images, setImages] = useState([]);

  const getImages = useCallback(async () => {
    EquiposExchangeAPI.getEquipoImages(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setImages(data);
      })
      .catch((error) => {});
  }, [id]);

  const removeImage = async (imageIndex) => {
    setLoading(true);
    await EquiposExchangeAPI.deleteImage(id, imageIndex)
      .then((response) => {
        if (response.ok) {
          setImages(images.filter((value, index) => index !== imageIndex));
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getImages();
  }, [getImages]);

  return (
    <>
      {images.length > 0 && (
        <RelativeBackdrop hidden={!loading}>
          <CardImagenesVariador images={images} removeImage={removeImage} isExchange={isExchange} />
        </RelativeBackdrop>
      )}
    </>
  );
}
