import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Grid,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import {
  Business,
  Memory,
  CalendarToday,
  LocalOffer,
  Build,
  CompareArrows,
  Attachment,
  GetApp,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AlertDialog from "../../../components/AlertDialog";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import TabPanel from "../../../components/TabPanel";
import EventDialog from "../../../components/EventDialog";
import i18n from "../../../locales/i18n";
import {
  PlantasAPI,
  EquipoScheduledPreventiveMaintenancesAPI,
  EquipoActividadesAPI,
} from "../../../services/API";
import SmallTitle from "../../../components/SmallTitle";
import moment from "moment";
import Calendar from "rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.es";
import AuthService from "../../../services/AuthService";
import InventarioTable from "../../molecule/inventarioTable/InventarioTable";
import PlantaStockEmergenciaTable from "../../molecule/plantaStockEmergencia/PlantaStockEmergenciaTable";
import AssessmentTable from "../../../components/AssessmentTable";
import ZonaSearchAndAdd from "../../organism/ZonaSearchAndAdd/ZonaSearchAndAdd";
import CardPlanta from "../../organism/cardPlanta/cardPlanta";
import FrequencyConvertersSearchEngine from "../../organism/FrequencyConverterSearchEngine/FrequencyConverterSearchEngine";
import { capitalizeFirstLetter } from "../../../helpers/Common";
import PlantaBase from "../../../models/plantaBase";
import TabExchangePlanta from "../../organism/tabExchangePlanta/tabExchangePlanta";
import TabDocumentosPlanta from "../../organism/tabDocumentosPlanta/tabDocumentosPlanta";

export default function PlantasDetails(props) {
  const { setNavBarTitle, keyuser, fullWidth, match, location } = props;
  const id = match.params.id;
  const clienteId = match.params.clienteId;
  const readOnly = props.readOnly && !keyuser;
  const colWith = fullWidth ? 2 : 4;
  const isGestor = AuthService.isGestorFromPlant(id);
  const plantaB = new PlantaBase({});
  plantaB.clienteId = clienteId;

  const [planta, setPlanta] = useState(plantaB);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [eventDialogEvents, setEventDialogEvents] = useState([]);
  const [errors, setErrors] = useState({});
  const [inventoryTabValue, setInventoryTabValue] = useState(0);
  const [events, setEvents] = useState([]);
  const [scheduledPreventiveMaintenances, setScheduledPreventiveMaintenances] =
    useState([]);
  const [equipoActividades, setEquipoActividades] = useState([]);
  const [isGestorThisPartner, setIsGestorThisPartner] = useState(false);

  const IsPartnerPlanta = AuthService.getPartnerId() === planta.partnerId;
  const userIsResposableExchange =
    AuthService.getUserInfo().userid === planta.appUserId;

  const deleteDialog = useRef(null);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_PlantasDetails: newValue,
    };
  };

  const handleInventoryChangeTab = (event, newValue) => {
    setInventoryTabValue(newValue);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPlanta((prevState) => ({ ...prevState, [name]: value }));
    if (name === "isExchange" && !value) {
      setPlanta((prevState) => ({ ...prevState, appUserId: null }));
    }
    const errorName = name ? capitalizeFirstLetter(name) : null;
    if (errors[errorName] || errors["Denominacion_unica"]) {
      if (errors["Denominacion_unica"]) {
        setErrors((e) => ({ ...errors, Denominacion_unica: undefined }));
      } else {
        setErrors((e) => ({ ...errors, [errorName]: undefined }));
      }
    } else if (errors[name]) {
      setErrors((e) => ({ ...errors, [name]: undefined }));
    }
  };

  const onEventClick = (e) => {
    setEventDialogEvents(e.events);
    setEventDialogOpen(e.events.length ? true : false);
  };

  const deletePlanta = async () => {
    setLoading(true);

    PlantasAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        props.history.goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getPlanta = useCallback(async () => {
    setLoading(true);

    return PlantasAPI.get(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPlanta(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const updatePlanta = async () => {
    setLoading(true);
    const updatePlanta = new PlantaBase(planta);
    PlantasAPI.update(updatePlanta)
      .then(async (response) => {
        if (response.ok) {
          if (!planta.denominacion) {
            props.setNavBarTitle(
              i18n.t("planta.titulo") + " " + planta.denominacion
            );
          }
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addPlanta = async () => {
    setLoading(true);
    if (!planta.partnerId && !keyuser) {
      planta.partnerId = AuthService.getPartnerId();
    }
    PlantasAPI.add(planta)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((dataPlanta) => {
        AuthService.addTempAccessToPlant(dataPlanta.id);
        props.history.replace(`/plantas/${dataPlanta.id}`);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getScheduledPreventiveMaintenanceByPlant = useCallback(async () => {
    return EquipoScheduledPreventiveMaintenancesAPI.getScheduledByPlant(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setScheduledPreventiveMaintenances(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const getEquipoActividadesByPlant = useCallback(async () => {
    return EquipoActividadesAPI.getActivityByPlant(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipoActividades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  const downloadMantenimientosPrevistos = () => {
    setLoading(true);
    PlantasAPI.downloadMantenimientosPrevistos(planta.id, i18n.locale)
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download =
          i18n.locale === "en"
            ? "Planned Maintenance.xlsx"
            : "Mantenimientos Previstos.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        setLoading(false);
      });
  };

  const goBack = () => {
    if (props.history.length > 1) {
      props.history.goBack();
    } else {
      props.history.replace(`/plantas`);
    }
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);

  useEffect(() => {
    if (location.state && location.state.tabPlantaValue) {
      setTabValue(location.state.tabPlantaValue);
    }
  }, [location.state]);

  useEffect(() => {
    if (planta.denominacion) {
      setNavBarTitle(i18n.t("planta.titulo") + " " + planta.denominacion);
    } else {
      setNavBarTitle(i18n.t("planta.titulo"));
    }
  }, [setNavBarTitle, planta.denominacion]);

  useEffect(() => {
    if (
      id &&
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PlantasDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_PlantasDetails);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      Promise.all([
        getPlanta(),
        getScheduledPreventiveMaintenanceByPlant(),
        getEquipoActividadesByPlant(),
      ]).then(() => setLoading(false));
    }
  }, [
    id,
    getPlanta,
    getScheduledPreventiveMaintenanceByPlant,
    getEquipoActividadesByPlant,
  ]);

  useEffect(() => {
    const events = [];

    if (equipoActividades) {
      equipoActividades.forEach((actividad) => {
        events.push({
          isTask: true,
          equipoId: actividad.equipo.id,
          estado: actividad.estado,
          name: `${actividad.equipo.nombre} - ${actividad.descripcion}`,
          description: `${moment(actividad.fecha).format("DD/MM/YYYY")} - ${
            actividad.appUser.nombre
          } ${actividad.appUser.apellidos}`,
          startDate: new Date(actividad.fecha),
          endDate: new Date(actividad.fecha),
          color: actividad.estado === 0 ? "gray" : "purple",
        });
      });
    }

    if (scheduledPreventiveMaintenances) {
      scheduledPreventiveMaintenances.forEach((scheduledPM) => {
        events.push({
          equipoId: scheduledPM.equipo.id,
          name:
            scheduledPM.type === 3
              ? i18n.t("maintenance.altualScheduled.moduleBase") +
                ` - ${scheduledPM.equipo.nombre || scheduledPM.equipo.sn}`
              : `${i18n.t("common.modulo")} ${scheduledPM.type + 1} - ${
                  scheduledPM.equipo.nombre || scheduledPM.equipo.sn
                }`,
          startDate: new Date(scheduledPM.scheduledDate),
          endDate: new Date(scheduledPM.scheduledDate),
          type: scheduledPM.type,
          color:
            scheduledPM.type === 0
              ? "YellowGreen"
              : scheduledPM.type === 1
              ? "SteelBlue"
              : scheduledPM.type === 2
              ? "Coral"
              : "Black",
          state: scheduledPM.state,
        });
      });
    }

    setEvents(events);
  }, [scheduledPreventiveMaintenances, equipoActividades]);

  useEffect(() => {
    if (planta.partnerId) {
      setIsGestorThisPartner(AuthService.isGestorFromPartner(planta.partnerId));
    }
  }, [planta.partnerId]);

  return (
    <>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          scrollButtons="auto"
          variant="scrollable"
          // centered
          indicatorColor="secondary"
          textColor="secondary"
          TabIndicatorProps={{
            style: {
              height: 3,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            },
          }}
        >
          <Tab
            value={0}
            label={i18n.t("planta.tituloCard")}
            icon={<Business />}
            id="simple-tab-0"
          />
          {id && (
            <Tab
              value={2}
              label={i18n.t("equipo.tituloCard")}
              icon={<Memory />}
              id="simple-tab-2"
            />
          )}
          {id && (
            <Tab
              value={3}
              label={i18n.t("maintenance.title")}
              icon={<CalendarToday />}
              id="simple-tab-3"
            />
          )}
          {id && (
            <Tab
              value={8}
              label={i18n.t("planta.documents")}
              icon={<Attachment />}
              id="simple-tab-6"
            />
          )}
          {id && (
            <Tab
              value={5}
              label={i18n.t("planta.inventario")}
              icon={<LocalOffer />}
              id="simple-tab-5"
            />
          )}
          {id && (
            <Tab
              value={6}
              label={i18n.t("planta.emergencyStock")}
              icon={<Build />}
              id="simple-tab-6"
            />
          )}
          {id && (props.keyuser||IsPartnerPlanta || userIsResposableExchange) && (
            <Tab
              value={7}
              label={i18n.t("planta.exchange")}
              icon={<CompareArrows />}
              id="simple-tab-7"
            />
          )}
        </Tabs>
      </Paper>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={0}>
        <RelativeBackdrop hidden={!loading}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CardPlanta
                planta={planta}
                colWith={colWith}
                errors={errors}
                handleInputChange={handleInputChange}
                id={id}
                deleteDialog={deleteDialog}
                updatePlanta={updatePlanta}
                addPlanta={addPlanta}
                IsPartnerPlanta={IsPartnerPlanta}
                goBack={goBack}
                {...props}
              />
            </Grid>
            {id && (
              <ZonaSearchAndAdd
                plantaId={id}
                readOnly={readOnly}
                history={props.history}
                goBack={goBack}
              />
            )}
          </Grid>
        </RelativeBackdrop>
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={2}>
        <FrequencyConvertersSearchEngine
          readOnly={readOnly}
          PlantaId={id}
          ZonaId={null}
          history={props.history}
          goBack={goBack}
          setErrors={setErrors}
        />
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={3}>
        <RelativeBackdrop hidden={!loading}>
          <Grid container spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item container xs={12} sm={8} md={6} xl={4}>
                <SmallTitle
                  title={i18n.t("maintenance.nextItems", { months: 12 })}
                  goBack={goBack}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => downloadMantenimientosPrevistos()}
                  variant="contained"
                  startIcon={<GetApp />}
                  style={{ backgroundColor: "#01723a", color: "white" }}
                >
                  {i18n.t("maintenance.downloadMaintenance")}
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="avatar"
                        style={{
                          background:
                            "linear-gradient(45deg, rgb(232, 55, 105) 30%, rgb(110, 68, 142) 90%)",
                        }}
                      >
                        {
                          events.filter(
                            (e) =>
                              !e.isTask &&
                              e.startDate <
                                new Date().setMonth(new Date().getMonth() + 12)
                          ).length
                        }
                      </Avatar>
                    }
                    title={i18n.t("maintenance.plantTab.maintenance")}
                    subheader={i18n.t("maintenance.plantTab.pending")}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="avatar"
                        style={{ backgroundColor: "#552877" }}
                      >
                        {events.filter((e) => e.isTask && e.estado < 2).length}
                      </Avatar>
                    }
                    title={i18n.t("maintenance.plantTab.tasks")}
                    subheader={i18n.t("maintenance.plantTab.tasksStatus")}
                  />
                </Card>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Paper style={{ padding: 20 }}>
                  <Calendar
                    language={i18n.locale}
                    enableContextMenu={false}
                    dataSource={events}
                    onDayClick={onEventClick}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </RelativeBackdrop>
      </TabPanel>
      <TabPanel
        className="TabPanelOnlyHalfTopPadding"
        value={tabValue}
        index={5}
      >
        <Grid container>
          <Grid item xs={12}>
            <Paper square>
              <Tabs
                value={inventoryTabValue}
                onChange={handleInventoryChangeTab}
                centered
                variant="standard"
                indicatorColor="secondary"
                textColor="secondary"
                TabIndicatorProps={{
                  style: {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  },
                }}
              >
                <Tab
                  value={0}
                  label={i18n.t("planta.inventario")}
                  id="inventory-tab-0"
                />
                <Tab value={1} label="Assessment" id="inventory-tab-1" />
              </Tabs>
            </Paper>
          </Grid>

          <TabPanel className="SubTabPanel" value={inventoryTabValue} index={0}>
            <Grid container item xs={12}>
              <InventarioTable
                plantaId={id}
                keyuser={keyuser}
                nombre={planta.denominacion}
                isGestorThisPartner={isGestorThisPartner}
                isGestorThisPlant={isGestor}
              />
            </Grid>
          </TabPanel>

          <TabPanel className="SubTabPanel" value={inventoryTabValue} index={1}>
            <Grid container item xs={12}>
              <AssessmentTable plantaId={id} keyuser={keyuser} />
            </Grid>
          </TabPanel>
        </Grid>
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={6}>
        <PlantaStockEmergenciaTable plantaId={id} keyuser={keyuser} />
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={7}>
        <TabExchangePlanta
          readOnly={readOnly}
          plantaId={id}
          history={props.history}
          goBack={goBack}
          IsPartnerPlanta={IsPartnerPlanta}
        />
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={8}>
        <TabDocumentosPlanta goBack={goBack} planta={planta} />
      </TabPanel>

      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("planta.alertDialog.title")}
        text={i18n.t("planta.alertDialog.text")}
        cancelText={i18n.t("planta.alertDialog.cancelText")}
        confirmText={i18n.t("planta.alertDialog.confirmText")}
        confirmAction={() => deletePlanta()}
      />
      <EventDialog
        {...props}
        open={eventDialogOpen}
        events={eventDialogEvents}
        onClose={() => setEventDialogOpen(false)}
        onItemClick={(event) => {
          const { equipoId, fisicalElementId } = event;
          if (equipoId) {
            window.open(`./plantas/${id}/equipos/${equipoId}`, "_blank");
          } else {
            window.open(
              `./plantas/${id}/fisicalElements/${fisicalElementId}`,
              "_blank"
            );
          }
        }}
      />
    </>
  );
}
