import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import {
  Memory,
  Room,
} from "@material-ui/icons";
import React from "react";
import i18n from "../../../locales/i18n";
// @ts-ignore
import danfossImage from "../../../assets/images/danfoss.png";
// @ts-ignore
import vaconImage from "../../../assets/images/vacon3.jpg";
import { isVacon } from "../../../helpers/Common";

export default function EquipoExchangeCard(props) {
  const { equipoExchange, plantaFrom } = props;

  const textColor = equipoExchange.estadoExchange === 0 ? "inherit" : "white";
  const headerbgdColor = (equipoExchange.estadoExchange === 1
    ? "rgba(102,64,130,1)"
    : (equipoExchange.estadoExchange === 2
      ? "rgba(212,52,97,1)"
      : "white"));
  const backgroundGradient =
    "rgba(255,255,255,1), rgba(255,255,255,0.8), rgba(255,255,255,1)";

  return (
    <Card>
      <CardHeader
        style={{ backgroundColor: headerbgdColor }}
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{
          noWrap: true,
          variant: "body2",
          style: { color: textColor },
        }}
        title={equipoExchange.sn || i18n.t("common.noName")}
        subheaderTypographyProps={{
          style: { color: textColor },
        }}
        subheader={
          equipoExchange.estadoExchange === 0
            ? i18n.t("equipo.estadoExchange.disponible")
            : equipoExchange.estadoExchange === 1
              ? i18n.t("equipo.estadoExchange.prestado")
              : i18n.t("equipo.estadoExchange.solicitado")
        }
        avatar={
          <Avatar aria-label="avatar" style={{ backgroundColor: "#f50057" }}>
            <Memory />
          </Avatar>
        }
      />
      <CardContent
        style={{
          background: `linear-gradient(${backgroundGradient}), url('${isVacon(equipoExchange.marca) ? vaconImage : danfossImage}')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {!plantaFrom &&
          <Typography variant="body2" className="mt-2" noWrap>
            <Room
              className="mr-1"
              style={{ fontSize: 21, verticalAlign: "bottom" }}
            />
            {equipoExchange.planta.provincia || "Sin provincia asignada"}
          </Typography>
        }
        <Typography variant="body2" className="mt-2" noWrap>
          <span style={{ fontWeight: "bold" }}>ID:</span> {equipoExchange.id}
        </Typography>
        <Typography variant="body2" className="mt-1" noWrap>
          <span style={{ fontWeight: "bold" }}>{i18n.t("exchange.sn")}:</span> {equipoExchange.sn}
        </Typography>
        <Typography variant="body2" className="mt-1" noWrap>
          <span style={{ fontWeight: "bold" }}>{i18n.t("exchange.pn")}:</span> {equipoExchange.pn}
        </Typography>
        <Typography variant="body2" className="mt-1" noWrap>
          <span style={{ fontWeight: "bold" }}>{isVacon(equipoExchange.marca) ? "TYPE:" : "TC:"} </span> {equipoExchange.tc}
        </Typography>
        <Typography variant="body2" className="mt-1" noWrap>
          <span style={{ fontWeight: "bold" }}>{i18n.t("exchange.marca")}:</span> {equipoExchange.marca}
        </Typography>
      </CardContent>
    </Card>
  );
}

