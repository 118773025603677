import { Button } from "@material-ui/core";
import React from "react";
import { getEquipoFromVisionString } from "../../../helpers/OCR";
import { EquiposExchangeAPI, PlantasAPI } from "../../../services/API";
//import { Post } from "../../../services/TestService";

export default function TestOffline() {
  // const crearVariador = async () => {
  //   var content = {
  //     zona: {
  //       id: 852,
  //       plantaId: 6,
  //       plantaZonaId: null,
  //       descripcion: "zona test",
  //       equipos: [
  //         {
  //           modeloId: "",
  //           zonaId: 852,
  //           idCliente: "Test",
  //           semanaFabricacion: "",
  //           añoFabricacion: "",
  //           chassis: "",
  //           tempAmbiental: "",
  //           in: "",
  //           out: "",
  //           equipoVariables: [],
  //           fotoUrl: "",
  //           base64Image: "",
  //           textoOCR: "",
  //           nombre: "Test var",
  //           tc: "PROVISIONAL",
  //           pn: "PROVISIONAL",
  //           sn: "PRO-1621929298383",
  //           retrofit: "PROVISIONAL",
  //           provisional: true,
  //           offline: true,
  //         },
  //       ],
  //     },
  //     equipo: {
  //       modeloId: "",
  //       zonaId: 852,
  //       idCliente: "Test",
  //       semanaFabricacion: "",
  //       añoFabricacion: "",
  //       chassis: "",
  //       tempAmbiental: "",
  //       in: "",
  //       out: "",
  //       equipoVariables: [],
  //       fotoUrl: "",
  //       base64Image: "",
  //       textoOCR: "",
  //       nombre: "Test var",
  //       tc: "PROVISIONAL",
  //       pn: "PROVISIONAL",
  //       sn: "PRO-1621929298383",
  //       retrofit: "PROVISIONAL",
  //       provisional: true,
  //       offline: true,
  //     },
  //   };
  //   return Post("api/plantaZonas/offline", content)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const crearVariadorZonaNueva = async () => {
  //   var content = {
  //     zona: {
  //       id: 853,
  //       plantaId: 6,
  //       plantaZonaId: null,
  //       descripcion: "zona test offline",
  //       equipos: [
  //         {
  //           modeloId: "",
  //           zonaId: 853,
  //           idCliente: "Test",
  //           semanaFabricacion: "",
  //           añoFabricacion: "",
  //           chassis: "",
  //           tempAmbiental: "",
  //           in: "",
  //           out: "",
  //           equipoVariables: [],
  //           fotoUrl: "",
  //           base64Image: "",
  //           textoOCR: "",
  //           nombre: "Test var",
  //           tc: "PROVISIONAL",
  //           pn: "PROVISIONAL",
  //           sn: "PRO-1621929298383",
  //           retrofit: "PROVISIONAL",
  //           provisional: true,
  //           offline: true,
  //         },
  //       ],
  //     },
  //     equipo: {
  //       modeloId: "",
  //       zonaId: 853,
  //       idCliente: "Test",
  //       semanaFabricacion: "",
  //       añoFabricacion: "",
  //       chassis: "",
  //       tempAmbiental: "",
  //       in: "",
  //       out: "",
  //       equipoVariables: [],
  //       fotoUrl: "",
  //       base64Image: "",
  //       textoOCR: "",
  //       nombre: "Test var",
  //       tc: "PROVISIONAL",
  //       pn: "PROVISIONAL",
  //       sn: "PRO-1621929298383",
  //       retrofit: "PROVISIONAL",
  //       provisional: true,
  //       offline: true,
  //     },
  //   };
  //   return Post("api/plantaZonas/offline", content)
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const listarTodo = async () => {
    EquiposExchangeAPI.getEquiposExchange()
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const listarEquiposPlanta = async () => {
    EquiposExchangeAPI.getEquiposPlanta(7)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEquipo = async () => {
    EquiposExchangeAPI.getEquipo(4)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEquipoQuery = async () => {
    EquiposExchangeAPI.getEquiposSearch("VLT")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateEquipo = async () => {
    var equipo = {
      id: 4,
      PN: "1038",
      TC: "VLT1038",
      SN: "1038",
      Marca: "VLT",
      plantaId: 6,
    };
    EquiposExchangeAPI.updateEquipoExchange(equipo)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const insertEquipo = async () => {
    var equipo = {
      PN: "1020",
      TC: "VLT1020",
      SN: "1020",
      Marca: "VLT",
      plantaId: 6,
    };
    EquiposExchangeAPI.addEquipoExchange(equipo)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendSolicitud = async () => {
    EquiposExchangeAPI.sendSolicitud(4)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendNecesito = async () => {
    var equipo = {
      TC: "VLT1020",
      PN: "1020",
    };
    EquiposExchangeAPI.sendNecesito(equipo)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteEquipo = async () => {
    EquiposExchangeAPI.deleteEquipoExchange(4)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const usuariosPlanta = async () => {
    PlantasAPI.getUsuariosPlanta(6)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ocrTestFC100 = async () => {
    const equipo = {};
    const visionString =
      "WWW.DANFOSS.COM \n" +
      "TIC: FC-102P2K2T4E20H2XOXXXXSXXXXAXD\n" +
      "P/N: 13183602 S/N: 0486260388\n" +
      "2.2KW(400V)/3.0HP(460V)\n" +
      "IN: 3X380-480V 50/60HZ 5.0/4.3A\n" +
      "OUT: 3X0-VIN 0-590HZ 5.6/4.8A\n" +
      "CHASSIS/IP20 TAMB. 50°C/122°F\n";

    const e = await getEquipoFromVisionString(visionString, equipo);
    if (
      e.tc === "FC-102P3K7T2E20H2XGXXXXSXXXXAXBKCXXXXD0" &&
      e.pn === "131B3602" &&
      e.sn === "048626G388" &&
      e.in === "3X380-480V 50/60HZ 5.0/4.3A" &&
      e.out === "3X0-VIN 0-590HZ 5.6/4.8A"
    ) {
      alert("Test Succes");
    } else {
      alert("Test Fail");
    }
  };

  const ocrTestFC200 = async () => {
    const equipo = {};
    const visionString =
      "VLT® AQUA DRIVE\n" +
      "WWW.DANFOSS.COM\n" +
      "TIC: FC-202P15KT4E20H2XGXXXXSXXXXAXBXCXXXXDX\n" +
      "P/N: 131F6641 S/N: 0254246409\n" +
      "15KW(400V) / 20HP(460V)\n" +
      "IN: 3X380-480V 50/60HZ 29/25A\n" +
      "OUT: 3X0-VIN 0-590HZ 32/27A\n" +
      "CHASSISI IP20 TAMB. 45°C/113°F\n" +
      "MADE IN\n" +
      "DENMARK\n" +
      "131F66410254246409\n";

    const e = await getEquipoFromVisionString(visionString, equipo);
    if (
      e.tc === "FC-202P15KT4E20H2XGXXXXSXXXXAXBXCXXXXDX" &&
      e.pn === "131F6641" &&
      e.sn === "025424G409" &&
      e.in === "3X380-480V 50/60HZ 29/25A" &&
      e.out === "3X0-VIN 0-590HZ 32/27A"
    ) {
      alert("Test Succes");
    } else {
      alert("Test Fail");
    }
  };

  const ocrTestVacon = async () => {
    const equipo = {};
    const visionString =
      "TYPE:\n" +
      "VACONO100-3L-0205-5+SDE5+SEBJ+IPOO+FLO3+DLNL\n" +
      "MADE IN FINLAND\n" +
      "家\n" +
      "EAC\n" +
      "INPUT: UIN:3~AC,380-500V, 50/60HZ\n" +
      "200A 40°C / 166.5A 50°C\n" +
      "OUTPUT: 3-AC,O-UIN, 0-320HZ 205A 40°C / 170A 50°C\n" +
      "POWER: 110KW:400V / 150HP:480V\n" +
      "S/N: V00002289717\n" +
      "ENCL: IPOO/OPEN TYPE\n" +
      "DANFOSS A/S, 6430 NORDBORG, DENMARK\n" +
      "VAQON\n" +
      "B.ID:\n" +
      "CODE:\n";

    const e = await getEquipoFromVisionString(visionString, equipo);
    if (
      e.tc === "0-3L-0205-5+SDE5+SEBJ+IP00+FL03+DLNL" &&
      e.pn === "0-3L-0205-5+SDE5+SEBJ+IP00+FL03+DLNL" &&
      e.sn === "V00002289717ENCL:" &&
      e.in === "3~AC,380-500V, 50/60HZ" &&
      e.out === "3-AC,O-UIN, 0-320HZ 205A 40°C / 170A 50°C"
    ) {
      alert("Test Succes");
    } else {
      alert("Test Fail");
    }
  };

  const ocrTestFCM300 = async () => {
    const equipo = {};
    const visionString =
      "24 %\n" +
      "PANTALLA CO\n" +
      "88 @ @ @\n" +
      "A\n" +
      "BARLAD REQUENCY CONVERTER MOTOR\n" +
      "284831- 0014\n" +
      "5CM322 MOTOR REFERENCE 495043\n" +
      "S/NO 177H0191\n" +
      "CINO FCM322PTACSESTR DOF10X400BO521500495043 CE\n" +
      "TH.CL. F. AMB.TEMP. 40 °C\n" +
      "THERMALLY PROTECTED\n" +
      "IP55 TYPE 12 WT 31KG MB5\n" +
      "3~ 380-480 V 4.7 A 2.20 KW\n" +
      "A\n" +
      "50/60 HZ COS0.9 DUTY S1\n" +
      "UL\n" +
      "LATES\n" +
      "76X1 E14261\n" +
      "NDCONTEC\n" +
      "1500 1FNIN\n" +
      "SEE MANUAL FOR PREFUSE TYPE IN UL-APPLICATIONS\n";

    const e = await getEquipoFromVisionString(visionString, equipo);
    if (
      e.tc === "FCM322PT4C55STR1D0F10X400B052151D0" &&
      e.pn === "177H0191" &&
      e.sn === "284831-0014"
    ) {
      alert("Test Succes");
    } else {
      alert("Test Fail");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        onClick={() => {
          insertEquipo();
        }}
      >
        InsertEquipo
      </Button>
      <Button
        onClick={() => {
          updateEquipo();
        }}
      >
        update Equipo
      </Button>
      <Button
        onClick={() => {
          getEquipo();
        }}
      >
        get equipo
      </Button>
      <Button
        onClick={() => {
          listarTodo();
        }}
      >
        lista Todo
      </Button>
      <Button
        onClick={() => {
          listarEquiposPlanta();
        }}
      >
        Lista Planta
      </Button>
      <Button
        onClick={() => {
          getEquipoQuery();
        }}
      >
        get query
      </Button>
      <Button
        onClick={() => {
          sendSolicitud();
        }}
      >
        Oferta
      </Button>
      <Button
        onClick={() => {
          sendNecesito();
        }}
      >
        necesito
      </Button>
      <Button
        onClick={() => {
          deleteEquipo();
        }}
      >
        delete
      </Button>
      <Button
        onClick={() => {
          usuariosPlanta();
        }}
      >
        usuarios planta
      </Button>
      <Button
        onClick={() => {
          ocrTestFC100();
        }}
      >
        OCR Test FC100
      </Button>
      <Button
        onClick={() => {
          ocrTestFC200();
        }}
      >
        OCR Test FC200
      </Button>
      <Button
        onClick={() => {
          ocrTestVacon();
        }}
      >
        OCR Test VACON
      </Button>
      <Button
        onClick={() => {
          ocrTestFCM300();
        }}
      >
        OCR Tes FCM300
      </Button>
    </div>
  );
}
