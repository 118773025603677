import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core";
import {
  InfoOutlined,
  Memory,
  Room,
  Build,
} from "@material-ui/icons";
import React from "react";
import withHover from "../../withHover";
import i18n from "../../../locales/i18n";
// @ts-ignore
import danfossImage from "../../../assets/images/danfoss.png";
// @ts-ignore
import vaconImage from "../../../assets/images/vacon3.jpg";
import { isVacon } from "../../../helpers/Common";

const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function EquipoCard(props) {
  const { equipo, onClick } = props;
  const textColor = equipo.inactivo ? "white" : "inherit";
  const backgroundGradient =
    "rgba(255,255,255,1), rgba(255,255,255,0.8), rgba(255,255,255,1)";

  return (
    <CardWithHover onClick={onClick} style={{ position: "relative" }}>
      {equipo.zona.isWarehouse && (
        <div className="ribbon ribbon-top-right purpleAndWhite">
          <span>{i18n.t("zona.warehouse")}</span>
        </div>
      )}
      <CardHeader
        style={
          equipo.inactivo
            ? { backgroundColor: "#2f2f2f" }
            : equipo.provisional
            ? { backgroundColor: "#f9a825" }
            : { backgroundColor: "white" }
        }
        classes={{ content: "overflow-hidden" }}
        titleTypographyProps={{
          noWrap: true,
          variant: "body2",
          style: { color: textColor },
        }}
        title={equipo.nombre || i18n.t("common.noName")}
        subheaderTypographyProps={{
          style: { color: textColor },
        }}
        subheader={
          equipo.inactivo
            ? i18n.t("common.inactivo")
            : equipo.provisional
            ? "Provisional"
            : i18n.t("common.activo.singularM")
        }
        avatar={
          <Avatar
            aria-label="avatar"
            style={
              equipo.inactivo
                ? { backgroundColor: "white", color: "#2f2f2f" }
                : equipo.provisional
                ? { backgroundColor: "#2f2f2f" }
                : equipo.zona && equipo.zona.isWarehouse
                ? { backgroundColor: "#552877" }
                : { backgroundColor: "#f50057" }
            }
          >
            <Memory />
          </Avatar>
        }
        action={
          equipo.equipoMantenimientos &&
          equipo.equipoMantenimientos.length !== 0 ? (
            <Build fontSize="small" color="disabled" />
          ) : (
            <></>
          )
        }
      />
      <CardContent
        style={{
          background: `linear-gradient(${backgroundGradient}), url('${
            equipo.tipo === "VACON" ? vaconImage : danfossImage
          }')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* {equipo.cicloVida === 2 && (
          <div
            style={{
              background: "black",
              position:'absolute',
              marginTop:'-20px',
              paddingLeft:'25px',
              paddingRight:'25px',
              paddingTop:'5px',
              paddingBottom:'3px',
              borderRadius: "15px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              height: "15px",
            }}
          >
            <Typography variant="caption">
              <b>{i18n.t("equipo.obsoleto")}</b>
            </Typography>
          </div>
        //   <div
        //   style={{
        //     background: "white",
        //     position:'absolute',
        //     marginTop:'-20px',
        //     paddingLeft:'25px',
        //     paddingRight:'25px',
        //     borderRadius: "15px",
        //     display: "flex",
        //     justifyContent: "center",
        //     alignItems: "center",
        //     border: '1px solid black',
        //     height: "17px",
        //   }}
        // >
        //   <Typography variant="caption">
        //     <b>{i18n.t("equipo.obsoleto")}</b>
        //   </Typography>
        // </div>
        )} */}
        <Typography variant="body2" noWrap>
          <InfoOutlined
            className="mr-1"
            style={{ fontSize: 21, verticalAlign: "bottom" }}
          />
          {equipo.descripcion || i18n.t("common.noDescription")}
        </Typography>
        <Typography variant="body2" className="mt-2" noWrap>
          <Room
            className="mr-1"
            style={{ fontSize: 21, verticalAlign: "bottom" }}
          />
          {equipo.zona.descripcion || "Sin zona asignada"}
        </Typography>
        <Typography variant="body2" className="mt-4" noWrap>
          <span style={{ fontWeight: "bold" }}>ID:</span>{" "}
          {equipo.idCliente || i18n.t("common.noId")}
        </Typography>
        <Typography variant="body2" className="mt-1" noWrap>
          <span style={{ fontWeight: "bold" }}>SN:</span> {equipo.sn}
        </Typography>
        <Typography variant="body2" className="mt-1" noWrap>
          <span style={{ fontWeight: "bold" }}>
            {isVacon(equipo.marca) ? "TYPE:" : "TC:"}
          </span>{" "}
          {equipo.tc}
        </Typography>
      </CardContent>
    </CardWithHover>
  );
}
