import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { PlantasAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import { filter } from "../../../helpers/Filter";
import AuthService from "../../../services/AuthService";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
// @ts-ignore
import PlantaCard from "../../../assets/images/planta-card.jpg";

export default function PlantasList(props) {
  const { setNavBarTitle } = props;
  const [plantas, setPlantas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plantasSearch, setPlantasSearch] = useState("");

  const getPlantas = async () => {
    setLoading(true);
    PlantasAPI.getWithUserAccessPermisionsLight()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPlantas(data.plantas);
        setLoading(false);
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const goBack = () => {
    if(props.history.length > 1) {
      props.history.goBack();
    } else {
      props.history.push(``);
    }
  };

  useEffect(() => {
    const title = i18n.t("planta.tituloPaginaLista");
    setNavBarTitle(title);
  }, [setNavBarTitle]);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PlantasDetails !== undefined
    ) {
      window["AltidVars"].Tabs_PlantasDetails = undefined;
    }
    getPlantas();
  }, []);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: plantasSearch,
              onChange: (event) => setPlantasSearch(event.target.value),
            }}
            goBack={goBack}
          />
        </Grid>
        <Grid container item spacing={2}>
          {filter(plantas, plantasSearch).map((planta) => (
            <Grid item xs={6} md={4} xl={3} key={planta.id}>
              <CardPlantClient
                title={planta.denominacion}
                subtitle={planta.cliente.razonSocial}
                contents={[
                  { key: "direccion", value: planta.direccion },
                  { key: "cp", value: planta.cp },
                  { key: "poblacion", value: planta.poblacion },
                  { key: "provincia", value: planta.provincia },
                  { key: "pais", value: planta.pais },
                ]}
                type="planta"
                style={{
                  background: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('${PlantaCard}')`,
                }}
                onClick={() => {
                  props.history.push(`./plantas/${planta.id}`);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </RelativeBackdrop>
  );
}
