import { Button, Grid } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { ClientesAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import { filter } from "../../../helpers/Filter";
import AuthService from "../../../services/AuthService";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
// @ts-ignore
import ClienteCard from "../../../assets/images/cliente-card.jpg";

export default function Clientes(props) {
  const { keyuser, setNavBarTitle, history } = props;
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  /** @type {boolean} */ const isGestorPartner = AuthService.isGestorFromSomePartner();

  const getClientes = async () => {
    setLoading(true);

    ClientesAPI.getWithUserAccessPermisions()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setClientes(data.clientes);
        setLoading(false);
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const goBack = () => {
    if(props.history.length > 1) {
      props.history.goBack();
    } else {
      props.history.push(``);
    }
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    getClientes();
  }, []);

  useEffect(() => {
    setNavBarTitle(i18n.t("cliente.tituloPaginaLista"));
  }, [setNavBarTitle]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: searchQuery,
              onChange: (event) => setSearchQuery(event.target.value),
            }}
            goBack={goBack}
            endButton={
              (keyuser || isGestorPartner) && (
                <Button
                  style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
                  onClick={() => history.push(`/nuevo-cliente`)}
                >
                  <Add />
                  {i18n.t("common.new.masculine")}
                </Button>
              )
            }
          />
        </Grid>
        <Grid container item spacing={2}>
          {filter(clientes, searchQuery).map((client) => (
            <Grid item xs={6} md={4} xl={3} key={client.id.toString()}>
              <CardPlantClient
                title={client.razonSocial}
                subtitle={client.direccion}
                contents={[
                  { key: "cif", value: client.cif },
                  { key: "cp", value: client.cp },
                  { key: "poblacion", value: client.poblacion },
                  { key: "provincia", value: client.provincia },
                  { key: "pais", value: client.pais },
                ]}
                type="cliente"
                style={{
                  background: `linear-gradient(rgba(255,255,255,1),rgba(255,255,255,.9), rgba(255,255,255, 1)), url('${ClienteCard}')`,
                  backgroundSize: "115%",
                }}
                onClick={() => {
                  history.push(`./clientes/${client.id}`);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </RelativeBackdrop>
  );
}
