import React, { useState, useEffect, useCallback } from "react";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  FormHelperText,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { ArrowBack, CalendarToday, Save, AddShoppingCart, RemoveShoppingCart } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Alert from "@material-ui/lab/Alert";
import TabPanel from "../../TabPanel";
import MaintenanceTimeline from "../../molecule/maintenanceTimeline/maintenanceTimeline";
import ActualScheduledMaintenance from "../../molecule/actualScheduledMaintenace/actualScheduledMaintenace";
import VaconMaintenance from "../../../helpers/vacon-maintenance-calculation.json";
import VLTMaintenance from "../../../helpers/vlt-maintenance-calculation.json";
import {
  EquipoMantenimientosAPI,
  EquipoScheduledPreventiveMaintenancesAPI,
} from "../../../services/API";
// @ts-ignore
import Gbar from "../../../assets/images/g-bar.png";

function TabMantenimiento({
  id,
  equipo,
  readOnly,
  brandStored,
  colWith,
  goBack,
  mantFlag,
  setMantFlag,
  loading,
  setLoading,
  errors,
  setErrors,
}) {
  const [mantenimiento, setMantenimiento] = useState({
    id: null,
    frame: null,
    temperatura: null,
    usoDiario: null,
    startup: null,
    ambiente: null,
    carga: null,
    activo: null,
    personalizado: false,
    customIntervaloModulo1: 0,
    customIntervaloModulo2: 0,
    customIntervaloModulo3: 0,
    fechaModulo1: null,
    fechaModulo2: null,
    fechaModulo3: null,
  });
  const [personalizado, setPersonalizado] = useState(
    mantenimiento.personalizado
  );
  const [maintenance, setMaintenance] = useState(VaconMaintenance);
  const [maintenanceTabValue, setMaintenanceTabValue] = useState(0);
  const [maintDialogOpen, setMaintDialogOpen] = useState(false);
  const [scheduledPreventiveMaintinance, setScheduledPreventiveMaintinance] =
    useState([]);
  
  const handleMaintenanceTypeChange = (event) => {
    const value = event.target.checked;
    setPersonalizado(value);
    setMantenimiento((prevMantenimiento) => ({
      ...prevMantenimiento,
      personalizado: value,
    }));
  };

  const handleMaintenanceInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setMantenimiento((prevMantenimiento) => ({
      ...prevMantenimiento,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  const handleAutocompleteChange = (
    target_Name,
    target_Value,
    detailField_Name,
    detailField_Value
  ) => {
    setMantenimiento((prevMantenimiento) => ({
      ...prevMantenimiento,
      [target_Name]: target_Value,
      [detailField_Name]: detailField_Value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [target_Name]: undefined,
    }));
  };

  const shouldRenderMaintenance = () => {
    const { marca } = equipo;
    const {
      startup,
      frame,
      temperatura,
      ambiente,
      carga,
      usoDiario,
      fechaModulo1,
      fechaModulo2,
      fechaModulo3,
    } = mantenimiento;

    return (
      (marca &&
        startup &&
        frame &&
        temperatura &&
        ambiente &&
        carga &&
        usoDiario) ||
      (fechaModulo1 && fechaModulo2 && fechaModulo3)
    );
  };

  if (mantenimiento.frame) {
    var frame = maintenance.frames.find((f) =>
      f.sizes.includes(mantenimiento.frame)
    );

    if (frame) {
      var temperaturas = frame.fanMapping.map((mapping) => {
        var text =
          (mapping.isLessOperator ? "<= " : ">= ") + mapping.temperature;

        return (
          <MenuItem value={text} key={mapping.temperature}>
            {text}
          </MenuItem>
        );
      });
    }
  }
  const getModule2Years = () => {
    const { temperatura, ambiente, usoDiario } = mantenimiento;

    const frame = maintenance.frames.find((f) =>
      f.sizes.includes(mantenimiento.frame)
    );

    const usage = maintenance.dailyUsage.find(
      (usage) => usage.hours === usoDiario
    );

    if (frame && temperatura && ambiente && usoDiario) {
      var fanMapping = frame.fanMapping.find((m) => {
        var temperatureText =
          (m.isLessOperator ? "<= " : ">= ") + m.temperature;
        return temperatureText === temperatura;
      });

      if (fanMapping) {
        const ambient = fanMapping.ambients.find((a) => a.ambient === ambiente);

        return ambient.value + usage.value;
      } else {
        return 0;
      }
    }
  };

  const getModule3Years = () => {
    const { temperatura, carga } = mantenimiento;

    const frame = maintenance.frames.find((f) =>
      f.sizes.includes(mantenimiento.frame)
    );

    if (frame && temperatura && carga) {
      var capacitorMapping = frame.capacitorMapping.find((m) => {
        var temperatureText =
          (m.isLessOperator ? "<= " : ">= ") + m.temperature;
        return temperatureText === temperatura;
      });

      if (capacitorMapping) {
        const loadValue = capacitorMapping.loadProfiles.find(
          (a) => a.load === carga
        ).value;

        return loadValue;
      } else {
        return 0;
      }
    }
  };
  const getScheduledPreventiveMaintinance = useCallback(async () => {
    setLoading(true);

    EquipoScheduledPreventiveMaintenancesAPI.getFromEquipo(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setScheduledPreventiveMaintinance(data);
        if (data.length === 0) {
          setMaintenanceTabValue(0);
        } else {
          setMaintenanceTabValue(1);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);
  const saveMaintenance = (isContract) => {
    const {
      id,
      startup,
      frame,
      temperatura,
      ambiente,
      carga,
      usoDiario,
      activo,
      personalizado,
      customIntervaloModulo1,
      customIntervaloModulo2,
      customIntervaloModulo3,
      fechaModulo1,
      fechaModulo2,
      fechaModulo3,
    } = mantenimiento;

    var years2 = getModule2Years() || null;
    var years3 = getModule3Years() || null;

    var equipoMantenimiento = {
      equipoId: equipo.id,
      intervaloModulo1: 1,
      intervaloModulo2: years2,
      intervaloModulo3: years3,
      fecha: new Date(),
      startup: startup ? new Date(startup) : null,
      frame: frame,
      temperatura: temperatura,
      ambiente: ambiente,
      carga: carga,
      usoDiario: usoDiario,
      activo: isContract ? !activo : activo,
      customIntervaloModulo1: customIntervaloModulo1,
      customIntervaloModulo2: customIntervaloModulo2,
      customIntervaloModulo3: customIntervaloModulo3,
      fechaModulo1: fechaModulo1,
      fechaModulo2: fechaModulo2,
      fechaModulo3: fechaModulo3,
      personalizado: personalizado,
    };

    if (id) {
      equipoMantenimiento.id = id;
    }

    setLoading(true);
    setMaintDialogOpen(false);
    EquipoMantenimientosAPI.addOrUpdate(equipo.id, equipoMantenimiento)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((mantenimiento) => {
        if (mantenimiento) {
          setMantenimiento(mantenimiento);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  useEffect(()=>{
    if (equipo.mantenimientoActivo) {
      setMantenimiento(equipo.mantenimientoActivo);
      setPersonalizado(equipo.mantenimientoActivo.personalizado)
    } else {
      setMantenimiento((prevMantenimiento) => ({
        ...prevMantenimiento,
        activo: false,
      }));
    }
  },[equipo])
  useEffect(() => {
    equipo.marca === "Vacon"
      ? setMaintenance(VaconMaintenance)
      : (setMaintenance(VLTMaintenance));
      // setMantenimiento((prevMantenimiento) => {
      //     prevMantenimiento.frame = null;
      //     prevMantenimiento.temperatura = null;
      //     prevMantenimiento.usoDiario = null;
      //     return prevMantenimiento;
      //   });
  }, [equipo.marca]);
  useEffect(() => {
    getScheduledPreventiveMaintinance();
  }, [getScheduledPreventiveMaintinance]);
  useEffect(()=>{
    if(mantFlag){
      setMantenimiento((prevMantenimiento) => {
          prevMantenimiento.frame = null;
          prevMantenimiento.temperatura = null;
          prevMantenimiento.usoDiario = null;
          setMantFlag(false);
          return prevMantenimiento;
        });
    }
  },[mantFlag])
  return (
    <>
      <RelativeBackdrop hidden={!loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={i18n.t("maintenance.title")}
                subheader={equipo.sn}
                avatar={
                  <Avatar
                    aria-label="avatar"
                    style={{ backgroundColor: "#552877" }}
                  >
                    <CalendarToday />
                  </Avatar>
                }
              />
              <CardContent>
                {brandStored && equipo.marca !== "Otro" ? (
                  <>
                    <Grid
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>{i18n.t("maintenance.danfoss")}</Grid>
                      <Grid item>
                        <Switch
                          checked={personalizado}
                          onChange={handleMaintenanceTypeChange}
                          color="default"
                          disabled={readOnly}
                          inputProps={{
                            "aria-label": "checkbox with default color",
                          }}
                        />
                      </Grid>
                      <Grid item>{i18n.t("maintenance.personalized")}</Grid>
                    </Grid>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 25,
                        marginTop: 25,
                        backgroundImage: `url("${Gbar}")`,
                        backgroundSize: "cover",
                        height: 3,
                        width: "100%",
                      }}
                    />
                    <React.Fragment>
                      <Grid container spacing={3}>
                        <Grid item xs={colWith} lg={12}>
                          <Typography>
                            {i18n.t("maintenance.danfoss")}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={colWith} lg={4}>
                          <TextField
                            required
                            fullWidth
                            type="date"
                            value={
                              mantenimiento.startup
                                ? mantenimiento.startup.substring(0, 10)
                                : ""
                            }
                            label={i18n.t("maintenance.fields.startup")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                            name="startup"
                            error={errors["startup"] !== undefined}
                            helperText={
                              errors["startup"]
                                ? i18n.t("textField.required")
                                : null
                            }
                            disabled={personalizado}
                            onChange={handleMaintenanceInputChange}
                          />
                        </Grid>
                        <Grid item xs={colWith} lg={4}>
                          <Autocomplete
                            options={maintenance.frames
                              .map((f) => f.sizes)
                              .flat()}
                            noOptionsText={i18n.t(
                              "autocompleteProps.noOptionsText"
                            )}
                            getOptionLabel={(option) => option}
                            getOptionSelected={(option, value) =>
                              option === value
                            }
                            renderOption={(option) => (
                              <Typography>{option}</Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required
                                fullWidth
                                label={i18n.t("maintenance.fields.frameSize")}
                                error={errors["frame"] !== undefined}
                                helperText={
                                  errors["frame"]
                                    ? i18n.t("textField.required")
                                    : null
                                }
                              />
                            )}
                            disabled={personalizado || readOnly}
                            value={mantenimiento.frame || ""}
                            onChange={(event, value) =>
                              handleAutocompleteChange(
                                "frame",
                                value,
                                "temperatura",
                                undefined
                              )
                            }
                          />
                        </Grid>
                        <Grid item xs={colWith} lg={4}>
                          <FormControl
                            required
                            fullWidth
                            disabled={personalizado}
                            error={errors["temperatura"] ? true : false}
                          >
                            <InputLabel>
                              {i18n.t("maintenance.fields.temp")}
                            </InputLabel>
                            <Select
                              required
                              fullWidth
                              value={mantenimiento.temperatura || ""}
                              name="temperatura"
                              onChange={handleMaintenanceInputChange}
                              inputProps={{
                                readOnly: readOnly,
                              }}
                            >
                              {temperaturas || (
                                <MenuItem value={null}>
                                  {i18n.t("maintenance.selectFrame")}
                                </MenuItem>
                              )}
                            </Select>
                            <FormHelperText>
                              {errors["temperatura"]
                                ? i18n.t("textField.required")
                                : null}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={colWith} lg={4}>
                          <FormControl
                            required
                            fullWidth
                            disabled={personalizado}
                            error={errors["ambiente"] ? true : false}
                          >
                            <InputLabel>
                              {i18n.t("maintenance.fields.ambient")}
                            </InputLabel>
                            <Select
                              required
                              fullWidth
                              value={mantenimiento.ambiente || ""}
                              name="ambiente"
                              displayEmpty
                              onChange={handleMaintenanceInputChange}
                              inputProps={{
                                readOnly: readOnly,
                              }}
                            >
                              <MenuItem value="Standard">
                                {i18n.t("maintenance.ambients.standard")}
                              </MenuItem>
                              <MenuItem value="Dust">
                                {i18n.t("maintenance.ambients.dust")}
                              </MenuItem>
                              <MenuItem value="Extreme">
                                {i18n.t("maintenance.ambients.extreme")}
                              </MenuItem>
                            </Select>
                            <FormHelperText>
                              {errors["ambiente"]
                                ? i18n.t("textField.required")
                                : null}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={colWith} lg={4}>
                          <FormControl
                            required
                            fullWidth
                            disabled={personalizado}
                            error={errors["carga"] ? true : false}
                          >
                            <InputLabel>
                              {i18n.t("maintenance.fields.load")}
                            </InputLabel>
                            <Select
                              required
                              fullWidth
                              value={mantenimiento.carga || ""}
                              name="carga"
                              displayEmpty
                              onChange={handleMaintenanceInputChange}
                              inputProps={{
                                readOnly: readOnly,
                              }}
                            >
                              <MenuItem value={"< 100%"}>{"< 100%"}</MenuItem>
                              <MenuItem value={"< 90%"}>{"< 90%"}</MenuItem>
                              <MenuItem value={"< 75%"}>{"< 75%"}</MenuItem>
                            </Select>
                            <FormHelperText>
                              {errors["carga"]
                                ? i18n.t("textField.required")
                                : null}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={colWith} lg={4}>
                          <FormControl
                            required
                            fullWidth
                            disabled={personalizado}
                            error={errors["usoDiario"] ? true : false}
                          >
                            <InputLabel>
                              {i18n.t("maintenance.fields.usage")}
                            </InputLabel>
                            <Select
                              required
                              fullWidth
                              value={mantenimiento.usoDiario || ""}
                              name="usoDiario"
                              onChange={handleMaintenanceInputChange}
                              inputProps={{
                                readOnly: readOnly,
                              }}
                            >
                              {maintenance.dailyUsage.map((usage) => {
                                return (
                                  <MenuItem
                                    value={usage.hours}
                                    key={usage.hours}
                                  >
                                    {usage.hours}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText>
                              {errors["usoDiario"]
                                ? i18n.t("textField.required")
                                : null}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 25,
                        marginTop: 25,
                        backgroundImage: `url("${Gbar}")`,
                        backgroundSize: "cover",
                        height: 3,
                        width: "100%",
                      }}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={colWith} lg={12}>
                        <Typography>
                          {i18n.t("maintenance.personalized")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid item xs={colWith} lg={4}>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          disabled={!personalizado}
                          value={
                            mantenimiento.fechaModulo1
                              ? mantenimiento.fechaModulo1.substring(0, 10)
                              : ""
                          }
                          label={i18n.t("maintenance.fields.inicioM1")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="fechaModulo1"
                          error={errors["fechaModulo1"] !== undefined}
                          helperText={
                            errors["fechaModulo1"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleMaintenanceInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={4}>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          disabled={!personalizado}
                          value={
                            mantenimiento.fechaModulo2
                              ? mantenimiento.fechaModulo2.substring(0, 10)
                              : ""
                          }
                          label={i18n.t("maintenance.fields.inicioM2")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="fechaModulo2"
                          error={errors["fechaModulo2"] !== undefined}
                          helperText={
                            errors["fechaModulo2"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleMaintenanceInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={4}>
                        <TextField
                          required
                          fullWidth
                          type="date"
                          disabled={!personalizado}
                          value={
                            mantenimiento.fechaModulo3
                              ? mantenimiento.fechaModulo3.substring(0, 10)
                              : ""
                          }
                          label={i18n.t("maintenance.fields.inicioM3")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="fechaModulo3"
                          error={errors["fechaModulo3"] !== undefined}
                          helperText={
                            errors["fechaModulo3"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleMaintenanceInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={4}>
                        <TextField
                          fullWidth
                          type="number"
                          disabled={!personalizado}
                          value={mantenimiento.customIntervaloModulo1 || ""}
                          label={i18n.t("maintenance.fields.intervaloM1")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="customIntervaloModulo1"
                          error={errors["customIntervaloModulo1"] !== undefined}
                          helperText={
                            errors["customIntervaloModulo1"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleMaintenanceInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={4}>
                        <TextField
                          fullWidth
                          type="number"
                          disabled={!personalizado}
                          value={mantenimiento.customIntervaloModulo2 || ""}
                          label={i18n.t("maintenance.fields.intervaloM2")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="customIntervaloModulo2"
                          error={errors["customIntervaloModulo2"] !== undefined}
                          helperText={
                            errors["customIntervaloModulo2"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleMaintenanceInputChange}
                        />
                      </Grid>
                      <Grid item xs={colWith} lg={4}>
                        <TextField
                          fullWidth
                          type="number"
                          disabled={!personalizado}
                          value={mantenimiento.customIntervaloModulo3 || ""}
                          label={i18n.t("maintenance.fields.intervaloM3")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="customIntervaloModulo3"
                          error={errors["customIntervaloModulo3"] !== undefined}
                          helperText={
                            errors["customIntervaloModulo3"]
                              ? i18n.t("textField.required")
                              : null
                          }
                          onChange={handleMaintenanceInputChange}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <Alert severity="info">
                    {equipo.marca
                      ? i18n.t("equipo.noMarcaGuardada")
                      : i18n.t("equipo.noMarca")}
                  </Alert>
                )}
              </CardContent>
              <CardActions disableSpacing>
                <IconButton aria-label="back" onClick={() => goBack()}>
                  <ArrowBack />
                </IconButton>
                {(brandStored || personalizado) && !readOnly && (
                  <IconButton
                    aria-label="save"
                    onClick={() => saveMaintenance(false)}
                  >
                    <Save />
                  </IconButton>
                )}
              </CardActions>
            </Card>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Paper square>
                <Tabs
                  value={maintenanceTabValue}
                  onChange={(event, value) => setMaintenanceTabValue(value)}
                  centered
                  variant="standard"
                  indicatorColor="secondary"
                  textColor="secondary"
                  TabIndicatorProps={{
                    style: {
                      height: 3,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3,
                    },
                  }}
                >
                  <Tab
                    value={0}
                    label={i18n.t("maintenance.subtabs.forecast")}
                    id="maintenance-tab-0"
                  />
                  <Tab
                    value={1}
                    label={i18n.t("maintenance.subtabs.scheduled")}
                    id="maintenance-tab-1"
                  />
                </Tabs>
              </Paper>
            </Grid>
          </Grid>
          <TabPanel
            className="SubTabPanel"
            value={maintenanceTabValue}
            index={0}
          >
            <Grid item container xs={12} alignItems="stretch">
              <MaintenanceTimeline
                mantenimiento={mantenimiento}
                getModule2Years={getModule2Years}
                getModule3Years={getModule3Years}
                click={() => setMaintDialogOpen(true)}
                shouldRenderMaintenance={shouldRenderMaintenance()}
                readOnly={readOnly}
              />
            </Grid>
          </TabPanel>
          <TabPanel
            className="SubTabPanel"
            value={maintenanceTabValue}
            index={1}
          >
            <Grid item container xs={12} alignItems="stretch">
              <ActualScheduledMaintenance
                equipo={equipo}
                getScheduledPreventiveMaintinance={
                  getScheduledPreventiveMaintinance
                }
                scheduledPreventiveMaintinance={scheduledPreventiveMaintinance}
                setErrors={setErrors}
                setLoading={setLoading}
                readOnly={readOnly}
              />
            </Grid>
          </TabPanel>
        </Grid>
      </RelativeBackdrop>
      {/* ////Dialogo Manteninimiento//// */}
      <Dialog open={maintDialogOpen} onClose={() => setMaintDialogOpen(false)}>
        <DialogTitle>{i18n.t("maintenance.contractDialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {i18n.t("maintenance.contractDialog.body")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setMaintDialogOpen(false)}
            style={{ marginRight: "auto" }}
          >
            {i18n.t("common.cancel")}
          </Button>
          <Button
            className="btn-special"
            onClick={() => saveMaintenance(true)}
            autoFocus
            startIcon={
              mantenimiento.activo ? (
                <RemoveShoppingCart />
              ) : (
                <AddShoppingCart />
              )
            }
          >
            {mantenimiento.activo
              ? i18n.t("maintenance.deactivate")
              : i18n.t("maintenance.contract")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TabMantenimiento;
