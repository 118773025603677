// @ts-nocheck
import React, { useEffect, useState, useCallback } from "react";
import MaterialTable from "material-table";
import AuthService from "../../../services/AuthService";
import i18n from "../../../locales/i18n";
import {
  EquipoStockEmergenciaAPI,
  PlantaStockEmergenciaAPI,
} from "../../../services/API";
import { Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";

export default function EquipoStockEmergenciaTable(props) {
  const {
    equipoId,
    plantaId,
    //keyuser,
    vacon,
    loading,
    setLoading,
    getStock,
    stock,
    setStock,
    sustituciones,
  } = props;
  const isPartner = AuthService.isGestorFromSomePartner();
  const [plantaStock, setPlantaStock] = useState([]);
  const [plantaStockDic, setPlantaStockDic] = useState({});

  const getPlantaStock = useCallback(async () => {
    setLoading(true);
    await PlantaStockEmergenciaAPI.getByPlanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        let dictionary = Object.assign(
          {},
          ...data.map((x) => ({ [x.pntc.pn]: x.pntc.pn + " / " + x.pntc.tc }))
        );
        setPlantaStockDic(dictionary);
        setPlantaStock(data);
      })
      .catch((error) => {});
    setLoading(false);
  }, [plantaId, setLoading]);

  const addStock = async (stock) => {
    if (
      !stock.plantaStockEmergencia ||
      !stock.plantaStockEmergencia.pntc ||
      !stock.plantaStockEmergencia.pntc.pn ||
      !plantaStock.some(
        (p) => p.pntc.pn === stock.plantaStockEmergencia.pntc.pn
      )
    )
      return;
    setLoading(true);

    stock.plantaStockEmergenciaId = plantaStock.find(
      (p) => p.pntc.pn === stock.plantaStockEmergencia.pntc.pn
    ).id;
    stock.plantaStockEmergencia = null;

    await EquipoStockEmergenciaAPI.add({ ...stock, equipoId: equipoId })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        getStock();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteStock = async (stock) => {
    setLoading(true);

    await EquipoStockEmergenciaAPI.remove(stock.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        getStock();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (equipoId) {
      //getStock();
      getPlantaStock();
    }
  }, [equipoId, getPlantaStock]);

  return (
    <MaterialTable
      style={{ width: "100%" }}
      title={i18n.t("equipo.emergencyStock")}
      // @ts-ignore
      localization={i18n.t("dataTableProps")}
      isLoading={loading}
      data={stock}
      options={{
        addRowPosition: "first",
        actionsColumnIndex: -1,
        sorting: true,
        showTitle: true,
        paging: false,
        search: false,
        searchFieldAlignment: "left",
      }}
      columns={[
        {
          field: "plantaStockEmergencia.pntc.pn",
          title: i18n.t("equipo.emergencyStockReference"),
          render: (rowData) => (
            <a
              href={`https://store.danfoss.com/${i18n.locale}/p/${rowData.plantaStockEmergencia.pntc.pn}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {rowData.plantaStockEmergencia.pntc.pn}
            </a>
          ),
          validate: (rowData) => {
            var isEmpty = !rowData.plantaStockEmergencia;
            var isValid =
              !isEmpty &&
              plantaStock &&
              plantaStock.some(
                (p) => p.pntc.pn === rowData.plantaStockEmergencia.pntc.pn
              );
            return {
              isValid: isValid,
              helperText: isEmpty
                ? "Requerido"
                : isValid
                ? ""
                : "Referencia no encontrada en el stock de la planta",
            };
          },
          lookup: plantaStockDic,
        },
        {
          field: "plantaStockEmergencia.pntc.tc",
          title: vacon ? "TYPE" : "TC",
          editable: "never",
        },
      ]}
      detailPanel={[
        {
          icon: () => <ArrowForwardIos />,
          tooltip: i18n.t("equipo.emergencyStock"),
          render: (rowData) => {
            console.log(rowData);
            return (
              <MaterialTable
                // @ts-ignore
                localization={i18n.t("dataTableProps")}
                data={sustituciones.filter(
                  (data) => data.pn === rowData.plantaStockEmergencia.pntc.pn
                )}
                style={{ backgroundColor: "whitesmoke" }}
                columns={[
                  {
                    title: i18n.t("equipo.emergencyStockReference"),
                    field: "pn",
                  },
                  {
                    title: "TC / TYPE",
                    field: "tc",
                  },
                  {
                    title: "SN",
                    field: "sn",
                    render: (rowData) => (
                      <a
                        href={`/plantas/${rowData.zona.plantaId}/zonas/${rowData.zonaId}/equipos/${rowData.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={rowData.sn}
                      >
                        <Typography>{rowData.sn}</Typography>
                      </a>
                    ),
                  },
                  {
                    title: i18n.t("equipo.sustitutos.found_in"),
                    field: "zona.descripcion",
                  },
                ]}
                options={{
                  showTitle: false,
                  search: false,
                  paging: false,
                  headerStyle: { backgroundColor: "whitesmoke" },
                }}
                components={{
                  Toolbar: (props) => <></>,
                }}
              />
            );
          },
        },
      ]}
      editable={
        isPartner
          ? {
              onRowAdd: (newData) => addStock(newData),
              onRowDelete: (oldData) => deleteStock(oldData),
            }
          : {}
      }
      actions={[
        {
          icon: "refresh",
          tooltip: i18n.t("alarma.actualizar"),
          isFreeAction: true,
          onClick: (event) => {
            getStock();
          },
        },
      ]}
    />
  );
}
