import React from "react";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import { Assignment, Build } from "@material-ui/icons";
import { DialogActions, Button } from "@material-ui/core";
import i18n from "../locales/i18n";
import moment from "moment";

export default function EventDialog(props) {
  const { open, events, onClose, onItemClick } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {/* <DialogTitle>
        {events && events.length > 0
          ? i18n.t("maintenance.choose")
          : i18n.t("maintenance.noItems")}
      </DialogTitle> */}
      <List>
        {events.map((event) => (
          <ListItem
            button
            onClick={() => onItemClick(event)}
            key={event.name + event.startDate.toString()}
          >
            <ListItemAvatar>
              <Avatar
                style={{
                  background:
                    event.isTask === true
                      ? event.estado === 0
                        ? "gray"
                        : event.estado === 1
                        ? "linear-gradient(45deg, rgb(232, 55, 105) 30%, rgb(110, 68, 142) 90%)"
                        : "#43a047"
                      : event.type === 0
                      ? "YellowGreen"
                      : event.type === 1
                      ? "SteelBlue"
                      : event.type === 2
                      ? "Coral"
                      : "Black",
                }}
              >
                {event.isTask ? <Assignment /> : <Build />}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={event.name}
              secondary={
                event.isTask
                  ? event.description
                  : moment(event.startDate).format("DD/MM/YYYY") +
                    " " +
                    (event.state === 3
                      ? i18n.t("maintenance.altualScheduled.proposed")
                      : event.state === 2
                      ? i18n.t("maintenance.altualScheduled.cancelled")
                      : event.state === 1
                      ? i18n.t("maintenance.altualScheduled.done")
                      : i18n.t("maintenance.altualScheduled.scheduled"))
              }
            />
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button onClick={handleClose}>{i18n.t("common.cancel")}</Button>
      </DialogActions>
    </Dialog>
  );
}

EventDialog.propTypes = {
  events: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
