export default class EquipoExchange {
  /** @type {number} */   id = undefined
  /** @type {string} */   sn = null
  /** @type {string} */   pn = null
  /** @type {string} */   tc = null
  /** @type {string} */   marca = null
  /** @type {number} */   anyoFabricacion = undefined
  /** @type {string} */   fotoUrl = null
  /** @type {number} */   estadoExchange = undefined
  /** @type {object} */   planta = undefined
  /** @type {number} */   plantaId = undefined
}