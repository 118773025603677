import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function getAccess() {
    const userInfo = AuthService.getUserInfo();
    const access_token = userInfo.access_token;

    return fetch(`${baseUrl}/api/dashboard/access`, {
        headers: { Authorization: `Bearer ${access_token}` }
    });
}