import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
} from "@material-ui/core";
import { Add, AttachFile, CloudDownload, Delete } from "@material-ui/icons";
import RelativeBackdrop from "../../RelativeBackdrop";
import { PlantasAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import i18n from "../../../locales/i18n";
import AlertDialog from "../../AlertDialog";
import { filter } from "../../../helpers/Filter";

export default function TabDocumentosPlanta({ planta, goBack }) {
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [description, setDescription] = useState("");
  const [search, setSearch] = useState("");

  const fileInputRef = useRef(null);
  const uploadDialog = useRef(null);
  const deleteDialog = useRef(null);

  const loadDocs = useCallback(() => {
    setLoading(true);

      PlantasAPI.getDocuments(planta.id)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        setDocuments(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, [planta]);

  const downloadDoc = async (doc) => {
    setLoading(true);
    try {
      const response = await PlantasAPI.downloadDocument(planta.id, doc.filePath);

      if (response.ok) {
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = doc.fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      } else {
        var error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const uploadDoc = async () => {
    setLoading(true);

    const file = fileInputRef.current.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("description", description);

    PlantasAPI.uploadFile(planta.id, formData)
      .then(async (response) => {
        if (response.ok) {
          loadDocs();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });

    uploadDialog.current.close();
  };

  const deleteDoc = async (document) => {
    setLoading(true);
    deleteDialog.current.close();

    PlantasAPI.removeDocument(planta.id, document.filePath)
      .then(async (response) => {
        if (response.ok) {
          loadDocs();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  };

  useEffect(() => loadDocs(), [loadDocs]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Grid container spacing={2}>
        <Grid item container xs={12} sm={8} md={6} xl={4}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: search,
              onChange: (event) => {
                event.persist();
                setSearch(event.target.value);
              },
            }}
            goBack={goBack}
            endButton={
              <React.Fragment>
                <Button
                  style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
                  onClick={() => uploadDialog.current.open()}
                >
                  <Add />
                  {i18n.t("common.new.masculine")}
                </Button>
              </React.Fragment>
            }
          />
        </Grid>
        <Grid item container spacing={2}>
          {filter(documents, search).map((document, index) => (
            <Grid key={index.toString()} item xs={12} md={4} xl={3}>
              <List>
                <Paper>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AttachFile />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={document.fileName}
                      secondary={
                        document.description || i18n.t("common.noDescription")
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => downloadDoc(document)}
                      >
                        <CloudDownload />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={() => deleteDialog.current.open(document)}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Paper>
              </List>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <AlertDialog
        ref={uploadDialog}
        title="Subir documento"
        loading={loading}
        text={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input inputRef={fileInputRef} type="file" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                className="mt-3"
                value={description}
                label={i18n.t("elementoFisico.descripcion")}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        }
        cancelText={i18n.t("documents.alertDialog.cancelText")}
        confirmText="Subir"
        confirmAction={() =>
          fileInputRef.current.files.length === 0 ? null : uploadDoc()
        }
      />
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("documents.alertDialog.title")}
        text={i18n.t("documents.alertDialog.text")}
        cancelText={i18n.t("documents.alertDialog.cancelText")}
        confirmText={i18n.t("documents.alertDialog.confirmText")}
        confirmAction={() => deleteDoc(deleteDialog.current.getItem())}
      />
    </RelativeBackdrop>
  );
}
