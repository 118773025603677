import React, { useEffect } from "react";
import i18n from "../../../locales/i18n";

export default function Barcodes({ setNavBarTitle }) {
  useEffect(() => {
    setNavBarTitle(i18n.t("sidebar.barcode"));
  }, [setNavBarTitle]);

  return (
    <>
      <iframe
        src="barcode/grid.html"
        title="Barcode"
        frameBorder="0"
        style={{ height: "2000px", width: "100%" }}
      />
    </>
  );
}
