export default class ClienteBase {
    /** @type {number} */ id = undefined;
    /** @type {string} */ cif = null;
    /** @type {string} */ razonSocial = null;
    /** @type {string} */ direccion = null;
    /** @type {string} */ cp = null;
    /** @type {string} */ poblacion = null;
    /** @type {string} */ provincia = null;
    /** @type {string} */ pais = null;
    /** @type {string} */ avatar = null;
    /** @type {number} */ partnerId = undefined;
  
    /**@param {Object} IntiObj  */
    constructor(IntiObj) {
      if(IntiObj.id) { this.id = IntiObj.id; }
      if(IntiObj.cif) { this.cif = IntiObj.cif; }
      if(IntiObj.razonSocial) { this.razonSocial = IntiObj.razonSocial; }
      if(IntiObj.direccion) { this.direccion = IntiObj.direccion; }
      if(IntiObj.cp) { this.cp = IntiObj.cp; }
      if(IntiObj.poblacion) { this.poblacion = IntiObj.poblacion; }
      if(IntiObj.provincia) { this.provincia = IntiObj.provincia; }
      if(IntiObj.pais) { this.pais = IntiObj.pais; }
      if(IntiObj.avatar) { this.avatar = IntiObj.avatar; }
      if(IntiObj.partnerId) { this.partnerId = IntiObj.partnerId; }
    }
  }