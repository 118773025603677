import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { ArrowBack, Business, Delete, Save } from "@material-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import i18n from "../../../locales/i18n";
import { PartnersAPI, PlantasAPI } from "../../../services/API";
import AuthService from "../../../services/AuthService";

function CardPlanta(props) {
  const {
    planta,
    colWith,
    errors,
    handleInputChange,
    id,
    deleteDialog,
    updatePlanta,
    addPlanta,
    IsPartnerPlanta,
  } = props;


  const readOnly = props.readOnly && !props.keyuser;
  const [partnersOptions, setPartnersOptions] = useState([]);
  const [respExchangeOptions, setRespExchangeOptions] = useState([]);
  const isGestorFromPlant = AuthService.isGestorFromPlant(planta.id);
  const isGestorFromClient = AuthService.isGestorFromClient(planta.clienteId);
  const isGestorFromPartner = AuthService.isGestorFromPartner(planta.partnerId);
  const getPartnerOptions = async () => {
    if(props.keyuser && !id){
      return await PartnersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong")
        }
      })
      .then((data) => {
        var partners = []
        data.forEach(element => {
          partners.push({ id: element.id, name: element.razonSocial });
        });
        setPartnersOptions(partners);
      })
      .catch(() => { });
    }
  }

  const getResponsableExchangeOptions = useCallback(async () => {
    return await PlantasAPI.getUsuariosPlanta(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong")
        }
      })
      .then((data) => {
        var users = []
        data.forEach(element => {
          users.push({ id: element.id, name: element.nombre + " " + element.apellidos });
        });
        setRespExchangeOptions(users);
      })
      .catch(() => { });
  }, [id]);

  useEffect(() => {
    getPartnerOptions();
    getResponsableExchangeOptions();
  }, [getResponsableExchangeOptions]);

  return (
    <Card>
      <CardHeader
        title={planta.denominacion}
        subheader={i18n.t("planta.tituloCard")}
        avatar={
          <Avatar aria-label="avatar" style={{ backgroundColor: "#f50057" }}>
            <Business />
          </Avatar>
        }
      />
      <CardContent>
        <form autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={colWith}>
              <TextField
                required
                fullWidth
                value={planta.denominacion || ""}
                label={i18n.t("planta.denominacion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                error={
                  errors["Denominacion"] !== undefined ||
                  errors["Denominacion_unica"] !== undefined
                }
                helperText={
                  errors["Denominacion"]
                    ? i18n.t("textField.required")
                    : errors["Denominacion_unica"]
                      ? i18n.t("planta.nombreUsado")
                      : null
                }
                name="denominacion"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={colWith}>
              <TextField
                required
                fullWidth
                value={planta.direccion || ""}
                label={i18n.t("planta.direccion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                error={errors["Direccion"] !== undefined}
                helperText={
                  errors["Direccion"] ? i18n.t("textField.required") : null
                }
                name="direccion"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={colWith}>
              <TextField
                required
                fullWidth
                value={planta.cp || ""}
                label={i18n.t("planta.cp")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                error={errors["CP"] !== undefined}
                helperText={errors["CP"] ? i18n.t("textField.required") : null}
                name="cp"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={colWith}>
              <TextField
                required
                fullWidth
                value={planta.poblacion || ""}
                label={i18n.t("planta.poblacion")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                error={errors["Poblacion"] !== undefined}
                helperText={
                  errors["Poblacion"] ? i18n.t("textField.required") : null
                }
                name="poblacion"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={colWith}>
              <TextField
                required
                fullWidth
                value={planta.provincia || ""}
                label={i18n.t("planta.provincia")}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  readOnly: readOnly,
                }}
                error={errors["Provincia"] !== undefined}
                helperText={
                  errors["Provincia"] ? i18n.t("textField.required") : null
                }
                name="provincia"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={colWith}>
              <FormControl fullWidth required>
                <InputLabel shrink id="pais">
                  {i18n.t("planta.pais")}
                </InputLabel>
                <Select
                  labelId="pais"
                  value={planta.pais || ""}
                  inputProps={{
                    readOnly: readOnly,
                  }}
                  error={errors["Pais"] !== undefined}
                  name="pais"
                  onChange={handleInputChange}
                >
                  <MenuItem value={"España"}>
                    {i18n.t("paises.españa")}
                  </MenuItem>
                  <MenuItem value={"Francia"}>
                    {i18n.t("paises.francia")}
                  </MenuItem>
                  <MenuItem value={"Italia"}>
                    {i18n.t("paises.italia")}
                  </MenuItem>
                  <MenuItem value={"Portugal"}>
                    {i18n.t("paises.portugal")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {props.keyuser && !planta.id && (
              <Grid item xs={colWith}>
                <FormControl fullWidth required>
                  <InputLabel shrink id="partnerId">
                    {i18n.t("planta.partner")}
                  </InputLabel>
                  <Select
                    labelId="partnerId"
                    value={planta.partnerId || ""}
                    inputProps={{
                      readOnly: readOnly,
                    }}
                    error={errors["partnerId"] !== undefined}
                    name="partnerId"
                    onChange={handleInputChange}
                  >
                    {partnersOptions.map((p) =>
                      <MenuItem value={p.id} key={p.id} >
                        {p.name}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {((props.keyuser || IsPartnerPlanta) && planta.id) && (
              <>
                <Grid item xs={colWith}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={planta.isExchange}
                        onChange={handleInputChange}
                        name="isExchange"
                        color="secondary"
                      />
                    }
                    label={i18n.t("planta.isExchange")}
                  />
                </Grid>
                <Grid item xs={colWith}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="appUserId">
                      {i18n.t("planta.appUser")}
                    </InputLabel>
                    <Select
                      labelId="appUserId"
                      value={planta.appUserId || ""}
                      inputProps={{
                        readOnly: !planta.isExchange,
                      }}
                      error={errors["appUserId"] !== undefined}
                      name="appUserId"
                      onChange={handleInputChange}
                    >
                      {respExchangeOptions.map((p) =>
                        <MenuItem value={p.id} key={p.id} >
                          {p.name}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="back" onClick={() => props.history.goBack()}>
          <ArrowBack />
        </IconButton>
        {!readOnly && (props.keyuser||isGestorFromClient||isGestorFromPartner) &&(
          <>
            <IconButton
              aria-label="save"
              onClick={() => (id ? updatePlanta() : addPlanta())}
            >
              <Save />
            </IconButton>
            {/* {id && !isGestorFromPlant &&( */}
            {id && ( 
              <Button
                aria-label="delete"
                className="text-danger"
                style={{ marginLeft: "auto" }}
                onClick={() => deleteDialog.current.open()}
              >
                {i18n.t("planta.borrar")}
                <Delete className="ml-2" />
              </Button>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
}

export default CardPlanta;
