import {
  // Button,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogContentText,
  // DialogTitle,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import {
  // AddShoppingCart,
  Assignment,
  Attachment,
  CalendarToday,
  Memory,
  // RemoveShoppingCart,
} from "@material-ui/icons";
import React, { useEffect, useState, useCallback } from "react";
import TabPanel from "../../../components/TabPanel";
// import VaconMaintenance from "../../../helpers/vacon-maintenance-calculation.json";
// import VLTMaintenance from "../../../helpers/vlt-maintenance-calculation.json";
import i18n from "../../../locales/i18n";
import {
  EquiposAPI,
  PlantasAPI,
  // EquipoMantenimientosAPI,
  // EquipoScheduledPreventiveMaintenancesAPI,
} from "../../../services/API";
import Actividades from "../../../components/Actividades";
import DocumentList from "../../../components/DocumentList";
import Equipo from "../../../models/equipo";
import TabVariador from "../../../components/organism/tabVariador/tabVariador";
import TabMantenimiento from "../../../components/organism/tabMantenimiento/tabMantenimiento";
import Swal from "sweetalert2";
import { isVacon } from "../../../helpers/Common";

export default function EquiposDetails(props) {
  const { keyuser, setNavBarTitle } = props;
  const { id, plantaId, zonaId } = props.match.params;
  const readOnly = props.readOnly && !keyuser;
  const locale = i18n.locale ? i18n.locale : i18n.defaultLocale;
  const colWith = props.fullWidth ? 2 : 6;

  const [equipo, setEquipo] = useState(new Equipo());
  const [zonas, setZonas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [mantFlag, setMantFlag] = useState(false);

  const [brandStored, setBrandStored] = useState(false);
  
  const [dirty, setDirty] = useState(false);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_EquiposDetails: newValue,
    };
  };

  const handleInputChange = (event) => {
    const vacon = isVacon(equipo.marca);
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (vacon && name === "sn") {
      setEquipo((prevEquipo) => ({ ...prevEquipo, [name]: value, pn: value }));
    } else {
      setEquipo((prevEquipo) => ({ ...prevEquipo, [name]: value }));
    }
      if (name === "marca") {
        setMantFlag(true);
      }

    setDirty(true);
  };

  const goBack = () => {
    if (dirty) {
      Swal.fire({
        title: i18n.t("saveAlert.title"),
        text: i18n.t("saveAlert.message"),
        icon: "warning",
        confirmButtonText: i18n.t("saveAlert.ok"),
        cancelButtonText: i18n.t("saveAlert.cancel"),
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          goBackHandler();
        }
      });
    } else {
      goBackHandler();
    }
  };

  const goBackHandler = () => {
    if (props.history.length > 1) {
      props.history.goBack();
    } else {
      if (zonaId) {
        props.history.replace({
          pathname: `/plantas/${plantaId}/zonas/${zonaId}`,
          state: { tabZonaValue: 2 },
        });
      } else {
        props.history.replace({
          pathname: `/plantas/${plantaId}`,
          state: { tabPlantaValue: 2 },
        });
      }
    }
  };

  const getEquipo = useCallback(async () => {
    EquiposAPI.get(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipo({ ...data, int: data.in });
        setTabValue(data.ioTDeviceId ? 1 : 0);
        setBrandStored(data.marca ? true : false);
      })
      .catch((error) => {});
  }, [id]);

  const getZonas = useCallback(async () => {
    return PlantasAPI.getZonas(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZonas(data);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId]);

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
    if (
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_EquiposDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_EquiposDetails);
    }
  }, []);

  useEffect(() => {
    setNavBarTitle(i18n.t("equipo.titulo"));
  }, [setNavBarTitle]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getZonas()]).then(() => {
      if (id) {
        Promise.all([
          getEquipo(),
        ]).then(() => setLoading(false));
      } else {
        setLoading(false);
      }
    });
  }, [id, getZonas, getEquipo]);

  useEffect(() => {
    if (zonas.length && zonaId) {
      setEquipo((prevEquipo) => ({ ...prevEquipo, zonaId: zonaId }));
    }
  }, [zonaId, zonas]);


  return (
    <>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          variant="standard"
          indicatorColor="secondary"
          textColor="secondary"
          TabIndicatorProps={{
            style: {
              height: 3,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            },
          }}
        >
          <Tab
            label={i18n.t("equipo.itemTitle")}
            icon={<Memory />}
            id="simple-tab-0"
          />
          {id && (
            <Tab
              label={i18n.t("maintenance.title")}
              icon={<CalendarToday />}
              id="simple-tab-2"
            />
          )}
          {id && (
            <Tab
              label={i18n.t("common.documentos")}
              icon={<Attachment />}
              id="simple-tab-4"
            />
          )}
          {id && (
            <Tab
              label={i18n.t("actividades.titulo")}
              icon={<Assignment />}
              id="simple-tab-5"
            />
          )}
        </Tabs>
      </Paper>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={0}>
        <TabVariador
          id={id}
          locale={locale}
          readOnly={readOnly}
          keyuser={keyuser}
          zonas={zonas}
          plantaId={props.match.params.plantaId}
          equipo={equipo}
          setEquipo={setEquipo}
          errors={errors}
          setErrors={setErrors}
          loading={loading}
          setLoading={setLoading}
          colWith={colWith}
          goBack={goBack}
          handleInputChange={handleInputChange}
          setBrandStored={setBrandStored}
          setDirty={setDirty}
          history={props.history}
        />
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={1}>
        {/* ////Inicio Tab Mantenimiento//// */}
        {id && (
          <TabMantenimiento
            id={id}
            equipo={equipo}
            readOnly={readOnly}
            brandStored={brandStored}
            colWith={colWith}
            goBack={goBack}
            loading={loading}
            setLoading={setLoading}
            errors={errors}
            setErrors={setErrors} 
            mantFlag={mantFlag}
            setMantFlag={setMantFlag}
          />
        )}
        {/* ////Final Tab Mantenimiento//// */}
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={2}>
        {/* ////Tab Documentos//// */}
        <DocumentList equipo={equipo} goBack={goBack} readOnly={readOnly} />
      </TabPanel>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={3}>
        {/* ////Tab Actividades//// */}
        <Actividades
          id={equipo.id}
          equipoActividades={equipo.id ? equipo.equipoActividades : []}
          readOnly={readOnly}
          goBack={goBack}
        />
      </TabPanel>
    </>
  );
}
