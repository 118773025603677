import React, {useRef} from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import RelativeBackdrop from "../../RelativeBackdrop";
import i18n from "../../../locales/i18n";
import {
  EquiposAPI,
  EquipoStockEmergenciaAPI,
  PNTCsAPI,
} from "../../../services/API";
import CardVariador from "../cardVariador/cardVariador";
import CardUtilidadVariador from "../../molecule/cardUtilVariador/cardUtilVariador";
import CardImagenesVariador from "../../molecule/cardImgVariador/cardImgVariador";
import CardRiesgoVariador from "../cardRiesgoVariador/cardRiesgoVariador";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import CardRetrofit from "../cardRetrofit/cardRetrofit";
import CardStockEmergencia from "../cardStockEmergencia/cardStockEmergencia";
import { isVacon } from "../../../helpers/Common";
import CardStockIdentico from "../cardStockIdentico/cardStockIdentico";
import AlertDialog from "../../../components/AlertDialog";
import swal from "sweetalert";


function TabVariador({
  id,
  locale,
  readOnly,
  keyuser,
  zonas,
  plantaId,
  equipo,
  setEquipo,
  setBrandStored,
  errors,
  setErrors,
  loading,
  setLoading,
  colWith,
  goBack,
  handleInputChange,
  setDirty, 
  history
}) {
  const vacon = isVacon(equipo.marca);

  const [loadingRetrofit, setLoadingRetrofit] = useState(false);
  const [stock_identico, setStock_identico] = useState([]);
  const [stock_retrofit, setStock_retrofit] = useState([]);
  const [stock_emergencia, setStock_emergencia] = useState([]);
  const [stockE, setStockE] = useState([]);
  const [loadingSE, setLoadingSE] = useState(false);
  const [images, setImages] = useState([]);
  const [sendingOferta, setSendingOferta] = useState(false);


  const restoreDialog = useRef(null);
  const provisionalDialog = useRef(null);
  const ofertaDialog = useRef(null);
  const deleteDialog = useRef(null);


  const updateEquipo = async (inactivo, provisional) => {
    setLoading(true);
    setDirty(false);
    return EquiposAPI.update({
      ...equipo,
      inactivo: inactivo === undefined ? equipo.inactivo : inactivo,
      provisional: provisional === undefined ? equipo.provisional : provisional,
    })
      .then(async (response) => {
        if (response.ok) {
          setErrors([]);
          setBrandStored(equipo.marca ? true : false);
          setEquipo({
            ...equipo,
            inactivo: inactivo === undefined ? equipo.inactivo : inactivo,
            provisional:
              provisional === undefined ? equipo.provisional : provisional,
          });
          provisionalDialog.current.close();
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const addEquipo = async () => {
    setLoading(true);
    setDirty(false);

    return EquiposAPI.add(equipo)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((equipo) => {
        if (equipo.zonaId) {
          history.replace(
            `/plantas/${plantaId}/zonas/${equipo.zonaId}/equipos/${equipo.id}`
          );
        } else {
          history.replace(`/plantas/${plantaId}/equipos/${equipo.id}`);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
const getImages = useCallback(async () => {
    EquiposAPI.getEquipoImages(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setImages(data);
      })
      .catch((error) => {});
  }, [id]);
   const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result.toString();
        resolve(baseURL.split("base64,")[1]);
      };
    });
  };
  const sendOferta = async () => {
    setSendingOferta(true);
    try {
      let response = await EquiposAPI.sendOferta(equipo.id);
      if (response.ok) {
        ofertaDialog.current.close();
        swal(i18n.t("equipo.ofertaEnviada"), "", "success");
      } else {
        const error = await response.text();
        throw new Error(error);
      }
    } catch (error) {
      swal("Error", i18n.t("equipo.ofertaError"), "error");
    }

    setSendingOferta(false);
  };
    const deleteEquipo = async () => {
    setLoading(true);

    EquiposAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const addImage = async (file) => {
    setLoading(true);
    let base64 = "";
    await getBase64(file)
      .then((result) => {
        base64 = result;
      })
      .catch((err) => {
        console.log(err);
      });
    EquiposAPI.addImages(id, [base64])
      .then((response) => {
        if (response.ok) {
          getImages();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };
  const removeImage = async (imageIndex) => {
    setLoading(true);
    await EquiposAPI.removeImage(id, imageIndex)
      .then((response) => {
        if (response.ok) {
          setImages(images.filter((value, index) => index !== imageIndex));
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };
  const saveEquipo = () => {
    if (id) {
      return updateEquipo();
    } else {
      return addEquipo();
    }
  };

  const getPNFromStore = () => {
    setLoading(true);

    var standardTc = equipo.tc.substr(0, 16) + "2XGXXXXSXXXXAXBXCXXXXDX";
    PNTCsAPI.getPNFromStore(standardTc)
      .then((response) => response.json())
      .then((data) => {
        var json = JSON.parse(data);
        var pn = json.products[0].code;
        window.open(`https://store.danfoss.com/${locale}/p/${pn}`, "_blank");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        alert(i18n.t("equipo.noDocAvailable"));
        setLoading(false);
      });
  };

  const getReplacementEquipment = useCallback(async () => {
    //setLoading(false);
    await EquiposAPI.getReplacementEquipment(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        let identicos = [];
        let retrofits = [];
        let equiposEmerg = [];
        let equiposRetrofit = [];
        if (equipo.retrofit) {
          equiposRetrofit = equipo.retrofit.split(";");
        }
        data.map((repl) => {
          if (repl.pn === equipo.pn) {
            identicos.push(repl);
          } else if (equiposRetrofit.includes(repl.pn)) {
            retrofits.push(repl);
          } else {
            equiposEmerg.push(repl);
          }
          return repl;
        });
        setStock_identico(identicos);
        setStock_retrofit(retrofits);
        setStock_emergencia(equiposEmerg);
        //setLoading(false);
      })
      .catch((error) => {
        //setLoading(false);
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipo]);

  const getStockEmerg = useCallback(async () => {
    setLoadingSE(true);
    await EquipoStockEmergenciaAPI.getByEquipo(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setStockE(data);
      })
      .catch((error) => {
        setLoadingSE(false);
      });
    setLoadingSE(false);
  }, [id]);

  useEffect(() => {
    if (id) {
      getStockEmerg();
      getImages();
    }
  }, [id, getStockEmerg, getImages]);

  useEffect(() => {
    getReplacementEquipment();
  }, [getReplacementEquipment]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          xs={12}
          sm={id ? 7 : 12}
          md={id ? 8 : 12}
          xl={id ? 9 : 12}
        >
          <RelativeBackdrop hidden={!loading}>
            <CardVariador
              id={id}
              keyuser={keyuser}
              plantaId={plantaId}
              zonas={zonas}
              readOnly={readOnly}
              equipo={equipo}
              setEquipo={setEquipo}
              saveEquipo={saveEquipo}
              errors={errors}
              colWith={colWith}
              goBack={goBack}
              handleInputChange={handleInputChange}
              provisionalDialog={provisionalDialog}
              deleteDialog={deleteDialog}
            />
          </RelativeBackdrop>
        </Grid>
        {id && (
          <Grid container item xs={12} sm={5} md={4} xl={3}>
            <CardUtilidadVariador
              locale={locale}
              equipo={equipo}
              updateEquipo={updateEquipo}
              getPNFromStore={getPNFromStore}
              goBack={goBack}
              ofertaDialog={ofertaDialog}
              restoreDialog={restoreDialog}
              readOnly={readOnly}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <CardRiesgoVariador
            equipo={equipo}
            colWith={colWith}
            handleInputChange={handleInputChange}
            readOnly={readOnly}
          />
        </Grid>
        {equipo.zona && !equipo.zona.isWarehouse && (
          <>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <CardRetrofit
                equipo={equipo}
                keyuser={keyuser}
                loadingRetrofit={loadingRetrofit}
                setLoadingRetrofit={setLoadingRetrofit}
                setEquipo={setEquipo}
                stock_retrofit={stock_retrofit}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} xl={12}>
              <CardStockEmergencia
                id={id}
                plantaId={plantaId}
                vacon={vacon}
                loadingSE={loadingSE}
                setLoadingSE={setLoadingSE}
                getStockEmerg={getStockEmerg}
                stockE={stockE}
                setStockE={setStockE}
                stock_emergencia={stock_emergencia}
              />
            </Grid>
            {stock_identico.length > 0 && (
              <Grid item xs={12} sm={12} md={12} xl={12}>
                <CardStockIdentico
                  equipo={equipo}
                  stock_identico={stock_identico}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>

      {id && (
        <RelativeBackdrop hidden={!loading}>
          <CardImagenesVariador
            images={images}
            removeImage={removeImage}
            addImage={addImage}
            loading={loading}
            readOnly={readOnly}
          />
        </RelativeBackdrop>
      )}
      {/* ////Alerts//// */}
      <AlertDialog
        ref={restoreDialog}
        title={i18n.t("equipo.recuperarTitle")}
        text={
          <TextField
            select
            required
            fullWidth
            value={equipo.zonaId || ""}
            label={i18n.t("equipo.recuperarText")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: readOnly,
            }}
            name="zonaId"
            error={errors["ZonaId"] !== undefined}
            helperText={errors["ZonaId"] ? i18n.t("textField.required") : null}
            onChange={handleInputChange}
            style={{ minWidth: 200 }}
          >
            {zonas.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.descripcion}
              </MenuItem>
            ))}
          </TextField>
        }
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("common.guardar")}
        confirmAction={() => updateEquipo(false).then(() => goBack())}
      />
      <AlertDialog
        ref={provisionalDialog}
        loading={loading}
        title={i18n.t("equipo.provisionalTitle")}
        text={i18n.t("equipo.provisionalText")}
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.confirmar")}
        confirmAction={() => updateEquipo(undefined, false)}
      />
      <AlertDialog
        ref={ofertaDialog}
        loading={sendingOferta}
        title={i18n.t("equipo.ofertaTitulo")}
        text={i18n.t("equipo.ofertaTexto")}
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("equipo.ofertaSolicitar")}
        confirmAction={() => sendOferta()}
      />
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("equipo.alertDialog.title")}
        text={i18n.t("equipo.alertDialog.text")}
        cancelText={i18n.t("equipo.alertDialog.cancelText")}
        confirmText={i18n.t("equipo.alertDialog.confirmText")}
        confirmAction={() => deleteEquipo()}
      />
    </>
  );
}

export default TabVariador;
