import React, { Component } from "react";
// @ts-ignore
// @ts-ignore
import cx from "classnames";

import { withRouter } from "react-router";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Sidebar from "./Sidebar.js";
import NavBar from "./NavBar.js";

// Styles
import styles from "../../assets/jss/material-dashboard-react/adminStyle.js";
// @ts-ignore
const useStyles = makeStyles(styles);

export class MainLayout extends Component {
  render() {
    return <NavLayout {...this.props} ref={this.props.navbarRef}></NavLayout>;
  }
}

const NavLayout = React.forwardRef((props, ref) => {
  // get saved mini-sidebar config
  const savedMiniConfig = localStorage.getItem("miniActive") === "true";

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(savedMiniConfig);
  const [toolbarVariant, setToolbarVariant] = React.useState("dense");

  // @ts-ignore
  const image = require("../../assets/images/sidebar.jpg");
  const color = "ingesis";
  const bgColor = "black";
  // @ts-ignore
  const logo = require("../../assets/images/ingesis-logo-white.png");

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  // ref for main panel div
  const mainPanel = React.createRef();

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    window.addEventListener("resize", resizeFunction);
    resizeFunction();

    // Specify how to clean up after this effect:
    return function cleanup() {
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
      setToolbarVariant("dense");
    } else {
      setMiniActive(false);
      setToolbarVariant("regular");
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        logoText={"Altid"}
        // @ts-ignore
        logo={logo}
        // @ts-ignore
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        // @ts-ignore
        setMobileOpen={setMobileOpen}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <NavBar
          ref={ref}
          miniActive={miniActive}
          setMiniActive={setMiniActive}
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          toolbarVariant={toolbarVariant}
        />
        <div id="main-content">{props.children}</div>
      </div>
    </div>
  );
});

export default withRouter(MainLayout);
