import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getWithUserAccessPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/withUserAccessPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getWithUserAccessPermisionsLight() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/withUserAccessPermisionsLight`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getZonas(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/zonas`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function getUsuariosPlanta(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/usuarios`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function add(planta) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(planta),
  });
}

export function update(planta) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${planta.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(planta),
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function getDocuments(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/documents`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function downloadDocument(id, filePath) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/documents/${filePath}`, {
    headers: { Authorization: `Bearer ${access_token}` },
  });
}

export function uploadFile(id, formData) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/documents`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
    body: formData,
  });
}

export function removeDocument(id, filePath) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/documents/${filePath}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
}

export function downloadMantenimientosPrevistos(id, locale){
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/plantas/${id}/mantenimientosPrevistos`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ locale: locale }),
  });
}
