import React, { useEffect, useState } from "react";
import i18n from "../../../locales/i18n";
import CardVariadorExchange from "../../../components/organism/cardVariadorExchange/cardVariadorExchange";
import CardImgVariadorExchange from "../../../components/molecule/cardImgVariadorExchange/cardImgVariadorExchange";
import RelativeBackdrop from "../../RelativeBackdrop";
import AuthService from "../../../services/AuthService";

export default function EquiposExchangeDetails(props) {
  const { keyuser, setNavBarTitle, history, location } = props;
  const { id, plantaId } = props.match.params;
  const readOnly = props.readOnly && !keyuser;
  const locale = i18n.locale;
  const isExchange = AuthService.isExchange();

  const IsPartnerPlanta = (
    location.state &&
    location.state.hasOwnProperty("IsPartnerPlanta")
  )
    ? location.state.IsPartnerPlanta
    : false;
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace({
        pathname: `/plantas/${plantaId}`,
        state: { tabPlantaValue: 7 },
      });
    }
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);

  useEffect(() => {
    setNavBarTitle(i18n.t("equipo.titulo"));
  }, [setNavBarTitle]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <CardVariadorExchange
        id={id}
        locale={locale}
        readOnly={readOnly}
        keyuser={keyuser}
        plantaId={plantaId}
        goBack={goBack}
        history={history}
        setLoading={setLoading}
        IsPartnerPlanta={IsPartnerPlanta}
        isExchange={isExchange}
      />
      <CardImgVariadorExchange
        id={id}
        loading={loading}
        setLoading={setLoading}
        isExchange={isExchange}
      />
    </RelativeBackdrop>
  );
}