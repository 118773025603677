import React, { useEffect, useState, useCallback, useRef } from "react";
import { EquiposAPI, ZonasAPI } from "../services/API";
import RiskCalculation from "../helpers/riskCalculation.json";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@material-ui/core";
import {
  AssignmentTurnedIn,
  Cached,
  FilterList,
  Memory,
  Report,
  Search,
  Warning,
} from "@material-ui/icons";
import { ResponsiveBar } from "@nivo/bar";
import RelativeBackdrop from "./RelativeBackdrop";
import { ResponsivePie } from "@nivo/pie";
import i18n from "../locales/i18n";
import AlertDialog from "./AlertDialog";
import SearchField from "./molecule/SearchField/SearchField";
import { filterZonas } from "../helpers/Filter";

function AssessmentChart(props) {
  const [loading, setLoading] = useState(false);
  const [datosBar, setDatosBar] = useState([]);
  const [datosPieLCS, setdatosPieLCS] = useState([]);
  const [datosPieAC, setDatosPieAC] = useState([]);
  const [datosPieACo, setDatosPieACo] = useState([]);
  const [datosPieAR, setDatosPieAR] = useState([]);
  const [zonasOptions, setZonasOptions] = useState([]);
  const [zonasSelect, setZonasSelect] = useState([]);
  const [antFilter, setAntFilter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [all, setAll] = useState(true);

  const filterDialog = useRef(null);
  const activeFilter = useRef(null);

  const getFamilias = useCallback(async (zonas) => {
    setLoading(true);

    const request = EquiposAPI.postAssessment(zonas);

    await request
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        Object.keys(data.fcGroups).forEach((family) => {
          data.fcGroups[family] = data.fcGroups[family].map((equipo) =>
            getRisk(equipo)
          );
        });
        Object.keys(data.vltGroups).forEach((family) => {
          data.vltGroups[family] = data.vltGroups[family].map((equipo) =>
            getRisk(equipo)
          );
        });

        data.fcd302 = data.fcd302.map((equipo) => getRisk(equipo));
        data.fcd300 = data.fcd300.map((equipo) => getRisk(equipo));
        data.mcd200 = data.mcd200.map((equipo) => getRisk(equipo));
        data.vacon = data.vacon.map((equipo) => getRisk(equipo));
        data.fcm = data.fcm.map((equipo) => getRisk(equipo));
        data.fcp = data.fcp.map((equipo) => getRisk(equipo));
        dataBarChart(data);
        dataPieLCS(data);
        dataPieAC(data);
        dataPieACo(data);
        dataPieAR(data);
      })
      .catch((error) => {
        setLoading(false);
      });
    setLoading(false);
  }, []);

  const getRisk = (equipo) => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );
    if (!risk) return equipo;
    equipo.riesgo = risk.result;
    return equipo;
  };

  const getZonesOptions = useCallback(async (todas) => {
    return await ZonasAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        var zonas = data.map((z) => z.id);
        if (todas) setZonasSelect([...zonas, -1]);
        setZonasOptions(data);
      })
      .catch(() => {});
  }, []);

  const getFilter = () => {
    var filter = JSON.parse(localStorage.getItem("filtros"));
    if (filter) {
      if (filter.some((zs) => zs === -1)) {
        setAll(true);
      } else {
        setAll(false);
      }
      setZonasSelect(filter);
      return filter;
    } else {
      return [-1];
    }
  };

  const dataBarChart = (familia) => {
    var data = [];
    var conth = 0;
    var contm = 0;
    var contl = 0;
    var contu = 0;
    Object.keys(familia.vltGroups).forEach((family) => {
      //data.push({ name: family, number: familia.vltGroups[family].length });
      conth = 0;
      contm = 0;
      contl = 0;
      contu = 0;
      familia.vltGroups[family].map((e) => {
        if (e.riesgo === 0) conth++;
        if (e.riesgo === 1) contm++;
        if (e.riesgo === 2) contl++;
        if (e.riesgo === 3) contu++;
        return 0;
      });
      data.push({
        name: family,
        [i18n.t("common.alto")]: conth,
        [i18n.t("common.alto") + "Color"]: "#f48fb1",
        [i18n.t("common.medio")]: contm,
        [i18n.t("common.medio") + "Color"]: "#ffab91",
        [i18n.t("common.bajo")]: contl,
        [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
        [i18n.t("common.indefinido")]: contu,
        [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
      });
    });
    Object.keys(familia.fcGroups).forEach((family) => {
      //data.push({ name: family, number: familia.fcGroups[family].length });
      conth = 0;
      contm = 0;
      contl = 0;
      contu = 0;
      familia.fcGroups[family].map((e) => {
        if (e.riesgo === 0) conth++;
        if (e.riesgo === 1) contm++;
        if (e.riesgo === 2) contl++;
        if (e.riesgo === 3) contu++;
        return 0;
      });
      data.push({
        name: family,
        [i18n.t("common.alto")]: conth,
        [i18n.t("common.alto") + "Color"]: "#f48fb1",
        [i18n.t("common.medio")]: contm,
        [i18n.t("common.medio") + "Color"]: "#ffab91",
        [i18n.t("common.bajo")]: contl,
        [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
        [i18n.t("common.indefinido")]: contu,
        [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
      });
    });
    // data.push({ name: "fcd302", number: familia.fcd302.length });
    conth = 0;
    contm = 0;
    contl = 0;
    contu = 0;
    familia.fcd302.map((e) => {
      if (e.riesgo === 0) conth++;
      if (e.riesgo === 1) contm++;
      if (e.riesgo === 2) contl++;
      if (e.riesgo === 3) contu++;
      return 0;
    });
    data.push({
      name: "fcd302",
      [i18n.t("common.alto")]: conth,
      [i18n.t("common.alto") + "Color"]: "#f48fb1",
      [i18n.t("common.medio")]: contm,
      [i18n.t("common.medio") + "Color"]: "#ffab91",
      [i18n.t("common.bajo")]: contl,
      [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
      [i18n.t("common.indefinido")]: contu,
      [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
    });
    // data.push({ name: "fcd300", number: familia.fcd300.length });
    conth = 0;
    contm = 0;
    contl = 0;
    contu = 0;
    familia.fcd300.map((e) => {
      if (e.riesgo === 0) conth++;
      if (e.riesgo === 1) contm++;
      if (e.riesgo === 2) contl++;
      if (e.riesgo === 3) contu++;
      return 0;
    });
    data.push({
      name: "fcd300",
      [i18n.t("common.alto")]: conth,
      [i18n.t("common.alto") + "Color"]: "#f48fb1",
      [i18n.t("common.medio")]: contm,
      [i18n.t("common.medio") + "Color"]: "#ffab91",
      [i18n.t("common.bajo")]: contl,
      [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
      [i18n.t("common.indefinido")]: contu,
      [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
    });
    // data.push({ name: "mcd200", number: familia.mcd200.length });
    conth = 0;
    contm = 0;
    contl = 0;
    contu = 0;
    familia.mcd200.map((e) => {
      if (e.riesgo === 0) conth++;
      if (e.riesgo === 1) contm++;
      if (e.riesgo === 2) contl++;
      if (e.riesgo === 3) contu++;
      return 0;
    });
    data.push({
      name: "mcd200",
      [i18n.t("common.alto")]: conth,
      [i18n.t("common.alto") + "Color"]: "#f48fb1",
      [i18n.t("common.medio")]: contm,
      [i18n.t("common.medio") + "Color"]: "#ffab91",
      [i18n.t("common.bajo")]: contl,
      [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
      [i18n.t("common.indefinido")]: contu,
      [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
    });
    conth = 0;
    contm = 0;
    contl = 0;
    contu = 0;
    familia.fcm.map((e) => {
      if (e.riesgo === 0) conth++;
      if (e.riesgo === 1) contm++;
      if (e.riesgo === 2) contl++;
      if (e.riesgo === 3) contu++;
      return 0;
    });
    data.push({
      name: "FCM",
      [i18n.t("common.alto")]: conth,
      [i18n.t("common.alto") + "Color"]: "#f48fb1",
      [i18n.t("common.medio")]: contm,
      [i18n.t("common.medio") + "Color"]: "#ffab91",
      [i18n.t("common.bajo")]: contl,
      [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
      [i18n.t("common.indefinido")]: contu,
      [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
    });
    conth = 0;
    contm = 0;
    contl = 0;
    contu = 0;
    familia.fcp.map((e) => {
      if (e.riesgo === 0) conth++;
      if (e.riesgo === 1) contm++;
      if (e.riesgo === 2) contl++;
      if (e.riesgo === 3) contu++;
      return 0;
    });
    data.push({
      name: "FCP",
      [i18n.t("common.alto")]: conth,
      [i18n.t("common.alto") + "Color"]: "#f48fb1",
      [i18n.t("common.medio")]: contm,
      [i18n.t("common.medio") + "Color"]: "#ffab91",
      [i18n.t("common.bajo")]: contl,
      [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
      [i18n.t("common.indefinido")]: contu,
      [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
    });
    // data.push({ name: "vacon", number: familia.vacon.length });
    conth = 0;
    contm = 0;
    contl = 0;
    contu = 0;
    familia.vacon.map((e) => {
      if (e.riesgo === 0) conth++;
      if (e.riesgo === 1) contm++;
      if (e.riesgo === 2) contl++;
      if (e.riesgo === 3) contu++;
      return 0;
    });
    data.push({
      name: "vacon",
      [i18n.t("common.alto")]: conth,
      [i18n.t("common.alto") + "Color"]: "#f48fb1",
      [i18n.t("common.medio")]: contm,
      [i18n.t("common.medio") + "Color"]: "#ffab91",
      [i18n.t("common.bajo")]: contl,
      [i18n.t("common.bajo") + "Color"]: "#a5d6a7",
      [i18n.t("common.indefinido")]: contu,
      [i18n.t("common.indefinido") + "Color"]: "#c3bcb9",
    });
    setDatosBar(data);
  };

  const dataPieLCS = (familia) => {
    var contActive = 0;
    var contLimited = 0;
    var contInactive = 0;
    var contUndefined = 0;

    Object.keys(familia.vltGroups).forEach((family) => {
      familia.vltGroups[family].map((e) => {
        if (e.cicloVida === 0) contActive++;
        if (e.cicloVida === 1) contLimited++;
        if (e.cicloVida === 2) contInactive++;
        if (e.cicloVida === 3) contUndefined++;
        return 0;
      });
    });
    Object.keys(familia.fcGroups).forEach((family) => {
      familia.fcGroups[family].map((e) => {
        if (e.cicloVida === 0) contActive++;
        if (e.cicloVida === 1) contLimited++;
        if (e.cicloVida === 2) contInactive++;
        if (e.cicloVida === 3) contUndefined++;
        return 0;
      });
    });
    familia.fcd302.map((e) => {
      if (e.cicloVida === 0) contActive++;
      if (e.cicloVida === 1) contLimited++;
      if (e.cicloVida === 2) contInactive++;
      if (e.cicloVida === 3) contUndefined++;
      return 0;
    });
    familia.fcd300.map((e) => {
      if (e.cicloVida === 0) contActive++;
      if (e.cicloVida === 1) contLimited++;
      if (e.cicloVida === 2) contInactive++;
      if (e.cicloVida === 3) contUndefined++;
      return 0;
    });
    familia.mcd200.map((e) => {
      if (e.cicloVida === 0) contActive++;
      if (e.cicloVida === 1) contLimited++;
      if (e.cicloVida === 2) contInactive++;
      if (e.cicloVida === 3) contUndefined++;
      return 0;
    });
    familia.fcm.map((e) => {
      if (e.cicloVida === 0) contActive++;
      if (e.cicloVida === 1) contLimited++;
      if (e.cicloVida === 2) contInactive++;
      if (e.cicloVida === 3) contUndefined++;
      return 0;
    });
    familia.fcp.map((e) => {
      if (e.cicloVida === 0) contActive++;
      if (e.cicloVida === 1) contLimited++;
      if (e.cicloVida === 2) contInactive++;
      if (e.cicloVida === 3) contUndefined++;
      return 0;
    });
    familia.vacon.map((e) => {
      if (e.cicloVida === 0) contActive++;
      if (e.cicloVida === 1) contLimited++;
      if (e.cicloVida === 2) contInactive++;
      if (e.cicloVida === 3) contUndefined++;
      return 0;
    });

    var data = [
      {
        id: i18n.t("common.activo.singularM"),
        label: i18n.t("common.activo.singularM"),
        value: contActive,
        color: "#a5d6a7",
      },
      {
        id: i18n.t("common.limitado"),
        label: i18n.t("common.limitado"),
        value: contLimited,
        color: "#ffab91",
      },
      {
        id: i18n.t("common.inactivo"),
        label: i18n.t("common.inactivo"),
        value: contInactive,
        color: "#f48fb1",
      },
      {
        id: i18n.t("common.indefinido"),
        label: i18n.t("common.indefinido"),
        value: contUndefined,
        color: "#c3bcb9",
      },
    ];
    setdatosPieLCS(data);
  };

  const dataPieAC = (familia) => {
    var contHigh = 0;
    var contMedium = 0;
    var contLow = 0;
    var contUndefined = 0;

    Object.keys(familia.vltGroups).forEach((family) => {
      familia.vltGroups[family].map((e) => {
        if (e.criticidad === 0) contHigh++;
        if (e.criticidad === 1) contMedium++;
        if (e.criticidad === 2) contLow++;
        if (e.criticidad === 3) contUndefined++;
        return 0;
      });
    });
    Object.keys(familia.fcGroups).forEach((family) => {
      familia.fcGroups[family].map((e) => {
        if (e.criticidad === 0) contHigh++;
        if (e.criticidad === 1) contMedium++;
        if (e.criticidad === 2) contLow++;
        if (e.criticidad === 3) contUndefined++;
        return 0;
      });
    });
    familia.fcd302.map((e) => {
      if (e.criticidad === 0) contHigh++;
      if (e.criticidad === 1) contMedium++;
      if (e.criticidad === 2) contLow++;
      if (e.criticidad === 3) contUndefined++;
      return 0;
    });
    familia.fcd300.map((e) => {
      if (e.criticidad === 0) contHigh++;
      if (e.criticidad === 1) contMedium++;
      if (e.criticidad === 2) contLow++;
      if (e.criticidad === 3) contUndefined++;
      return 0;
    });
    familia.mcd200.map((e) => {
      if (e.criticidad === 0) contHigh++;
      if (e.criticidad === 1) contMedium++;
      if (e.criticidad === 2) contLow++;
      if (e.criticidad === 3) contUndefined++;
      return 0;
    });
    familia.fcm.map((e) => {
      if (e.criticidad === 0) contHigh++;
      if (e.criticidad === 1) contMedium++;
      if (e.criticidad === 2) contLow++;
      if (e.criticidad === 3) contUndefined++;
      return 0;
    });
    familia.fcp.map((e) => {
      if (e.criticidad === 0) contHigh++;
      if (e.criticidad === 1) contMedium++;
      if (e.criticidad === 2) contLow++;
      if (e.criticidad === 3) contUndefined++;
      return 0;
    });
    familia.vacon.map((e) => {
      if (e.criticidad === 0) contHigh++;
      if (e.criticidad === 1) contMedium++;
      if (e.criticidad === 2) contLow++;
      if (e.criticidad === 3) contUndefined++;
      return 0;
    });

    var data = [
      {
        id: i18n.t("common.alto"),
        label: i18n.t("common.alto"),
        value: contHigh,
        color: "#f48fb1",
      },
      {
        id: i18n.t("common.medio"),
        label: i18n.t("common.medio"),
        value: contMedium,
        color: "#ffab91",
      },
      {
        id: i18n.t("common.bajo"),
        label: i18n.t("common.bajo"),
        value: contLow,
        color: "#a5d6a7",
      },
      {
        id: i18n.t("common.indefinido"),
        label: i18n.t("common.indefinido"),
        value: contUndefined,
        color: "#c3bcb9",
      },
    ];
    setDatosPieAC(data);
  };

  const dataPieACo = (familia) => {
    var contGood = 0;
    var contMedium = 0;
    var contLow = 0;
    var contUndefined = 0;

    Object.keys(familia.vltGroups).forEach((family) => {
      familia.vltGroups[family].map((e) => {
        if (e.estado === 0) contGood++;
        if (e.estado === 1) contMedium++;
        if (e.estado === 2) contLow++;
        if (e.estado === 3) contUndefined++;
        return 0;
      });
    });
    Object.keys(familia.fcGroups).forEach((family) => {
      familia.fcGroups[family].map((e) => {
        if (e.estado === 0) contGood++;
        if (e.estado === 1) contMedium++;
        if (e.estado === 2) contLow++;
        if (e.estado === 3) contUndefined++;
        return 0;
      });
    });
    familia.fcd302.map((e) => {
      if (e.estado === 0) contGood++;
      if (e.estado === 1) contMedium++;
      if (e.estado === 2) contLow++;
      if (e.estado === 3) contUndefined++;
      return 0;
    });
    familia.fcd300.map((e) => {
      if (e.estado === 0) contGood++;
      if (e.estado === 1) contMedium++;
      if (e.estado === 2) contLow++;
      if (e.estado === 3) contUndefined++;
      return 0;
    });
    familia.mcd200.map((e) => {
      if (e.estado === 0) contGood++;
      if (e.estado === 1) contMedium++;
      if (e.estado === 2) contLow++;
      if (e.estado === 3) contUndefined++;
      return 0;
    });
    familia.fcm.map((e) => {
      if (e.estado === 0) contGood++;
      if (e.estado === 1) contMedium++;
      if (e.estado === 2) contLow++;
      if (e.estado === 3) contUndefined++;
      return 0;
    });
    familia.fcp.map((e) => {
      if (e.estado === 0) contGood++;
      if (e.estado === 1) contMedium++;
      if (e.estado === 2) contLow++;
      if (e.estado === 3) contUndefined++;
      return 0;
    });
    familia.vacon.map((e) => {
      if (e.estado === 0) contGood++;
      if (e.estado === 1) contMedium++;
      if (e.estado === 2) contLow++;
      if (e.estado === 3) contUndefined++;
      return 0;
    });

    var data = [
      {
        id: i18n.t("common.bueno"),
        label: i18n.t("common.bueno"),
        value: contGood,
        color: "#a5d6a7",
      },
      {
        id: i18n.t("common.medio"),
        label: i18n.t("common.medio"),
        value: contMedium,
        color: "#ffab91",
      },
      {
        id: i18n.t("common.pobre"),
        label: i18n.t("common.pobre"),
        value: contLow,
        color: "#f48fb1",
      },
      {
        id: i18n.t("common.indefinido"),
        label: i18n.t("common.indefinido"),
        value: contUndefined,
        color: "#c3bcb9",
      },
    ];
    setDatosPieACo(data);
  };

  const dataPieAR = (familia) => {
    var contLow = 0;
    var contMedium = 0;
    var contHigh = 0;
    var contUndefined = 0;
    //3 undefined

    Object.keys(familia.vltGroups).forEach((family) => {
      familia.vltGroups[family].map((e) => {
        if (e.riesgo === 0) contHigh++;
        if (e.riesgo === 1) contMedium++;
        if (e.riesgo === 2) contLow++;
        if (e.riesgo === 3) contUndefined++;
        return 0;
      });
    });
    Object.keys(familia.fcGroups).forEach((family) => {
      familia.fcGroups[family].map((e) => {
        if (e.riesgo === 0) contHigh++;
        if (e.riesgo === 1) contMedium++;
        if (e.riesgo === 2) contLow++;
        if (e.riesgo === 3) contUndefined++;
        return 0;
      });
    });
    familia.fcd302.map((e) => {
      if (e.riesgo === 0) contHigh++;
      if (e.riesgo === 1) contMedium++;
      if (e.riesgo === 2) contLow++;
      if (e.riesgo === 3) contUndefined++;
      return 0;
    });
    familia.fcd300.map((e) => {
      if (e.riesgo === 0) contHigh++;
      if (e.riesgo === 1) contMedium++;
      if (e.riesgo === 2) contLow++;
      if (e.riesgo === 3) contUndefined++;
      return 0;
    });
    familia.mcd200.map((e) => {
      if (e.riesgo === 0) contHigh++;
      if (e.riesgo === 1) contMedium++;
      if (e.riesgo === 2) contLow++;
      if (e.riesgo === 3) contUndefined++;
      return 0;
    });
    familia.fcm.map((e) => {
      if (e.riesgo === 0) contHigh++;
      if (e.riesgo === 1) contMedium++;
      if (e.riesgo === 2) contLow++;
      if (e.riesgo === 3) contUndefined++;
      return 0;
    });
    familia.fcp.map((e) => {
      if (e.riesgo === 0) contHigh++;
      if (e.riesgo === 1) contMedium++;
      if (e.riesgo === 2) contLow++;
      if (e.riesgo === 3) contUndefined++;
      return 0;
    });
    familia.vacon.map((e) => {
      if (e.riesgo === 0) contHigh++;
      if (e.riesgo === 1) contMedium++;
      if (e.riesgo === 2) contLow++;
      if (e.riesgo === 3) contUndefined++;
      return 0;
    });

    var data = [
      {
        id: i18n.t("common.alto"),
        label: i18n.t("common.alto"),
        value: contHigh,
        color: "#f48fb1",
      },
      {
        id: i18n.t("common.medio"),
        label: i18n.t("common.medio"),
        value: contMedium,
        color: "#ffab91",
      },
      {
        id: i18n.t("common.bajo"),
        label: i18n.t("common.bajo"),
        value: contLow,
        color: "#a5d6a7",
      },
      {
        id: i18n.t("common.indefinido"),
        label: i18n.t("common.indefinido"),
        value: contUndefined,
        color: "#c3bcb9",
      },
    ];
    setDatosPieAR(data);
  };

  const handleChange = (event) => {
    var zonas = [];
    if (event.target.name === "-1") {
      if (zonasSelect.some((zs) => zs === parseInt(event.target.name, 10))) {
        setZonasSelect([]);
        setAll(false);
      } else {
        zonas = zonasOptions.map((z) => z.id);
        setZonasSelect([...zonas, -1]);
        setAll(true);
      }
    } else {
      if (zonasSelect.some((zs) => zs === parseInt(event.target.name, 10))) {
        zonas = zonasSelect.filter(
          (z) => z !== parseInt(event.target.name, 10)
        );
        zonas = zonas.filter((z) => z !== -1);
        setAll(false);
        setZonasSelect(zonas);
      } else {
        zonas = [...zonasSelect, parseInt(event.target.name, 10)];
        zonas = zonas.filter((z) => z !== -1);
        setAll(false);
        setZonasSelect(zonas);
      }
    }
  };

  const openFilters = () => {
    setAntFilter(zonasSelect);
    setSearchValue("");
    filterDialog.current.open();
  };

  const applyFilter = () => {
    getFamilias(zonasSelect);
    localStorage.setItem("filtros", JSON.stringify(zonasSelect));
    filterDialog.current.close();
  };

  const cancelFilters = () => {
    setZonasSelect(antFilter);
    if (antFilter.some((zs) => zs === -1)) {
      setAll(true);
    } else {
      setAll(false);
    }
    filterDialog.current.close();
  };

  useEffect(() => {
    var zonas = getFilter();
    var todas = zonas.some((zs) => zs === -1);
    getFamilias(zonas);
    getZonesOptions(todas);
  }, [getFamilias, getZonesOptions]);

  return (
    <Grid container spacing={3}>
      <Grid item>
        <Button
          onClick={() => openFilters()}
          startIcon={<FilterList />}
          variant="contained"
          color="default"
          style={{ backgroundColor: "white", margin: "10px" }}
        >
          {i18n.t("dashboard.filters")}
        </Button>
        {!all && (
          <Button
            onClick={() => activeFilter.current.open()}
            startIcon={<Search />}
            variant="contained"
            color="default"
            style={{ backgroundColor: "white", margin: "10px" }}
          >
            {i18n.t("dashboard.alertDialog.activeFilter")}
          </Button>
        )}
      </Grid>
      <Grid item xs={12}>
        <RelativeBackdrop hidden={!loading} absolute>
          <Card>
            <CardHeader
              title={i18n.t("assessment.productFamily")}
              className="pb-0"
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#CCAAFF" }}
                >
                  <Memory />
                </Avatar>
              }
            />
            <CardContent style={{ height: 300, padding: 0 }}>
              <ResponsiveBar
                data={datosBar}
                keys={[
                  i18n.t("common.alto"),
                  i18n.t("common.medio"),
                  i18n.t("common.bajo"),
                  i18n.t("common.indefinido"),
                ]}
                colors={({ id, data }) => String(data[`${id}Color`])}
                indexBy="name"
                margin={{ top: 50, right: 10, bottom: 50, left: 60 }}
                padding={0.3}
                minValue={0}
                // @ts-ignore
                height={250}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: i18n.t("assessment.numAssets"),
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
              />
            </CardContent>
          </Card>
        </RelativeBackdrop>
      </Grid>
      <Grid item xs={6}>
        <RelativeBackdrop hidden={!loading} absolute>
          <Card>
            <CardHeader
              title={i18n.t("assessment.LCS")}
              className="pb-0"
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#767A5D" }}
                >
                  <Cached />
                </Avatar>
              }
            />
            <CardContent style={{ height: 300, padding: 0 }}>
              <ResponsivePie
                data={datosPieLCS}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                colors={{ datum: "color" }}
                radialLabelsLinkColor={{ from: "color" }}
              />
            </CardContent>
          </Card>
        </RelativeBackdrop>
      </Grid>
      <Grid item xs={6}>
        <RelativeBackdrop hidden={!loading} absolute>
          <Card>
            <CardHeader
              title={i18n.t("assessment.AC")}
              className="pb-0"
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#E7E460" }}
                >
                  <Report />
                </Avatar>
              }
            />
            <CardContent style={{ height: 300, padding: 0 }}>
              <ResponsivePie
                data={datosPieAC}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                colors={{ datum: "color" }}
                radialLabelsLinkColor={{ from: "color" }}
              />
            </CardContent>
          </Card>
        </RelativeBackdrop>
      </Grid>
      <Grid item xs={6}>
        <RelativeBackdrop hidden={!loading} absolute>
          <Card>
            <CardHeader
              title={i18n.t("assessment.ACo")}
              className="pb-0"
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#389969" }}
                >
                  <AssignmentTurnedIn />
                </Avatar>
              }
            />
            <CardContent style={{ height: 300, padding: 0 }}>
              <ResponsivePie
                data={datosPieACo}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                colors={{ datum: "color" }}
                radialLabelsLinkColor={{ from: "color" }}
              />
            </CardContent>
          </Card>
        </RelativeBackdrop>
      </Grid>
      <Grid item xs={6}>
        <RelativeBackdrop hidden={!loading} absolute>
          <Card>
            <CardHeader
              title={i18n.t("assessment.AR")}
              className="pb-0"
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#7C9FF1" }}
                >
                  <Warning />
                </Avatar>
              }
            />
            <CardContent style={{ height: 300, padding: 0 }}>
              <ResponsivePie
                data={datosPieAR}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                colors={{ datum: "color" }}
                radialLabelsLinkColor={{ from: "color" }}
              />
            </CardContent>
          </Card>
        </RelativeBackdrop>
      </Grid>
      <AlertDialog
        ref={filterDialog}
        title={i18n.t("dashboard.alertDialog.filters")}
        loading={loading}
        text={
          <>
            <SearchField
              inputBaseProps={{
                placeholder: i18n.t("common.search"),
                value: searchValue,
                onChange: (event) => {
                  setSearchValue(event.target.value);
                },
              }}
              disableBackButton={true}
            />
            <div
              style={{ overflowY: "scroll", height: "300px", width: "550px" }}
            >
              {(!searchValue ||
                "Todas".toLowerCase().includes(searchValue.toLowerCase())) && (
                <div className="registros" key={0}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={"-1"}
                        onChange={handleChange}
                        checked={all}
                      />
                    }
                    label={i18n.t("dashboard.alertDialog.all")}
                    style={{ margin: "10px" }}
                  />
                </div>
              )}
              {filterZonas(zonasOptions, searchValue).map((r) => (
                <div className="registros" key={r.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={r.id + ""}
                        onChange={handleChange}
                        checked={zonasSelect.some((zs) => zs === r.id)}
                      />
                    }
                    label={r.descripcion + " (" + r.planta.denominacion + ")"}
                    style={{ margin: "10px" }}
                  />
                </div>
              ))}
            </div>
          </>
        }
        cancelText={i18n.t("dashboard.alertDialog.cancelText")}
        confirmText={i18n.t("dashboard.alertDialog.confirmText")}
        confirmAction={() => applyFilter()}
        cancelAction={() => cancelFilters()}
      />
      <AlertDialog
        ref={activeFilter}
        title={i18n.t("dashboard.alertDialog.activeFilter")}
        loading={loading}
        text={
          <div style={{ overflowY: "scroll", height: "300px", width: "550px" }}>
            {zonasSelect.map((r) => (
              <div className="registros" key={r}>
                {zonasOptions
                  .filter((z) => z.id === r)
                  .map((f) => (
                    <div key={r}>
                      {f.descripcion + " (" + f.planta.denominacion + ")"}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        }
        confirmText={i18n.t("dashboard.alertDialog.close")}
        confirmAction={() => activeFilter.current.close()}
      />
    </Grid>
  );
}

export default AssessmentChart;
