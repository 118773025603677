import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { Menu, MenuItem, Button } from "@material-ui/core";
import { Translate, ExpandMore } from "@material-ui/icons";
import i18n from "../../locales/i18n";

const styles = {
  menuButton: {
    marginRight: 10,
    "&:focus": {
      outline: "none",
    },
  },
  menuButtonMobile: {
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
};

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      languageMenuAnchor: null,
      locale: i18n.locale || i18n.defaultLocale,
    };
  }

  handleClick = (event) => {
    this.setState({ languageMenuAnchor: event.currentTarget });
  };

  handleClose = (locale) => {
    this.setState({ languageMenuAnchor: null });
    let currentLocale = i18n.locale || i18n.defaultLocale;
    if (locale !== currentLocale) {
      switch (locale) {
        case "es":
        case "en":
          localStorage.setItem("locale", locale);
          i18n.locale = locale;
          window.location.reload();
          break;
        default:
          break;
      }
    }
  };

  render() {
    const {
      miniActive,
      setMiniActive,
      mobileOpen,
      setMobileOpen,
      toolbarVariant,
      classes,
    } = this.props;

    const toggleSidebar = () => {
      if (toolbarVariant === "dense") {
        setMiniActive(!miniActive);
        localStorage.setItem("miniActive", !miniActive ? "true" : "false");
      } else {
        setMobileOpen(!mobileOpen);
      }
    };

    const getMenuIcon = () => {
      return toolbarVariant === "regular" ? (
        <MenuIcon />
      ) : miniActive ? (
        <MenuIcon />
      ) : (
        <MenuOpenIcon />
      );
    };

    return (
      <AppBar position="static" style={{ backgroundColor: "rgb(26, 26, 26)" }}>
        <Toolbar variant={toolbarVariant}>
          <IconButton
            onClick={toggleSidebar}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            {getMenuIcon()}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {this.state.title}
          </Typography>
          <Button
            aria-haspopup="true"
            className="text-white"
            onClick={this.handleClick}
            startIcon={<Translate />}
            endIcon={<ExpandMore />}
          >
            {i18n.t("settings.languageName")}
          </Button>
          <Menu
            keepMounted
            anchorEl={this.state.languageMenuAnchor}
            open={Boolean(this.state.languageMenuAnchor)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={() => this.handleClose("es")}>
              {i18n.t("settings.languageName", { locale: "es" })}
            </MenuItem>
            <MenuItem onClick={() => this.handleClose("en")}>
              {i18n.t("settings.languageName", { locale: "en" })}
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(NavBar);
