import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";

import queryString from "query-string";
import Auth from "../components/layout/Auth";
import { Button, Paper } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { UsersAPI, PartnersAPI } from "../services/API";
import i18n from "../locales/i18n";

export default function RecoveryValidation(props) {
  i18n.locale = localStorage.getItem("locale");
  const [partner, setPartner] = useState(props.match.params.partner);
  const [partnerEmail, setPartnerEmail] = useState(null);
  const search = props.location.search;
  const [validating, setValidating] = useState(true);
  const [validated, setValidated] = useState(false);
  const [locale] = useState(i18n.locale || i18n.defaultLocale);

  useEffect(() => {
    setValidating(true);
    const query = queryString.parse(search);
    UsersAPI.validateAccountRecovery(query.token, query.email)
      .then((response) => {
        if (response.ok) {
          setValidated(true);
        }
        setValidating(false);
      })
      .catch((error) => setValidating(false));
  }, [search]);

  useEffect(() => {
    PartnersAPI.getPartnerList()
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong");
      }
    })
    .then((data) => {
      let bEncontrado = false;
      for (let index = 0; index < data.length; index++) {
        if (data[index].nombreAbreviado === partner) {
          bEncontrado = true;
          setPartnerEmail(data[index].emailContacto);
          break;
        }
      }
      if (!bEncontrado) {
        setPartner('');
        console.log('Not includes');
      } else {
        console.log('Includes');
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }, [partner]);

    return (
      <Auth locale={locale} partner={partner} email={partnerEmail}>
        <Row
          className="justify-content-center align-items-center h-100 ml-0 mr-0"
          style={{ paddingBottom: "15%" }} 
        >
          <Col sm="12" md="10" lg="8" xl="7">
            <Paper elevation={3} style={{ padding: 40 }}>
              <Row className="d-flex justify-content-start mb-4">
                <Col>
                  <h4>
                    {validating ? null : validated ? (
                      <Check
                        className="align-text-top"
                        htmlColor="green"
                        fontSize="large"
                      />
                    ) : (
                      <Close
                        className="align-text-top"
                        htmlColor="red"
                        fontSize="large"
                      />
                    )}{" "}
                    {validating
                      ? i18n.t("recovery.validating")
                      : validated
                      ? i18n.t("recovery.validated")
                      : i18n.t("recovery.nonValidated")}
                  </h4>
                </Col>
              </Row>

              <div className="mb-3">
                {validating ? (
                  i18n.t("recovery.validatingText")
                ) : validated ? (
                  i18n.t("recovery.validatedText")
                ) : (
                  <div>
                    {i18n.t("recovery.nonValidatedText")}
                    <br />
                    <br />
                    {i18n.t("recovery.nonValidatedText2")}
                  </div>
                )}
              </div>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => props.history.replace("/login")}
              >
                {i18n.t("login.login")}
              </Button>
            </Paper>
          </Col>
        </Row>
      </Auth>
    );
  }
