import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import i18n from "../../../locales/i18n";
import RiskCalculation from "../../../helpers/riskCalculation.json";

import MaterialTable, { MTableGroupRow } from "material-table";
import ScrollDialog from "../../ScrollDialog";
import { Button, Grid } from "@material-ui/core";
import { GetApp, PostAdd } from "@material-ui/icons";
import { EquiposAPI } from "../../../services/API";

export default function InventarioTable(props) {
  const { plantaId, keyuser, isGestorThisPartner, isGestorThisPlant, nombre } =
    props;

  const [equipos, setEquipos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogText, setDialogText] = useState("");

  const fileInputRef = useRef(null);

  const getEquiposByplanta = useCallback(async () => {
    setLoading(true);
    await EquiposAPI.getEquiposByplanta(plantaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        data = data.map((equipo) => getRisk(equipo));
        setEquipos(data);
      })
      .catch((error) => {
        setLoading(false);
      });

    setLoading(false);
  }, [plantaId]);

  const getRisk = (equipo) => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );
    if (!risk) return equipo;
    equipo.riesgo = risk.result;
    return equipo;
  };

  const uploadExcel = async () => {
    setLoading(true);

    const file = fileInputRef.current.files[0];
    let formData = new FormData();
    formData.append("file", file);
    formData.append("locale", i18n.locale);

    await EquiposAPI.uploadInventario(plantaId, formData)
      .then(async (response) => {
        if (response.ok) {
          getEquiposByplanta();
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        setDialogText(data);
        setDialogOpen(true);
      })
      .catch((error) => {
        console.error(error.message);
      });

    setLoading(false);
  };

  useEffect(() => {
    getEquiposByplanta();
  }, [getEquiposByplanta]);

  return (
    <Grid container spacing={2}>
      <ScrollDialog
        open={dialogOpen}
        title="Importación"
        text={dialogText}
        handleClose={() => setDialogOpen(false)}
      />
      {/* {(keyuser || isGestorThisPartner || isGestorThisPlant) && ( */}
      {(keyuser || isGestorThisPartner) && (
        <Grid item xs={12}>
          <input
            type="file"
            ref={fileInputRef}
            accept=".xlsx"
            onChange={uploadExcel}
            style={{ display: "none" }}
          />
          <Button
            onClick={() => {
              fileInputRef.current.value = "";
              fileInputRef.current.click();
            }}
            variant="contained"
            endIcon={<PostAdd />}
            style={{ backgroundColor: "#01723a", color: "white" }}
          >
            {i18n.t("dataTableProps.toolbar.importCSV")}
          </Button>
        </Grid>
      )}
      <Grid item xs={12}>
        <MaterialTable
          style={{ width: "100%" }}
          title={null}
          // @ts-ignore
          localization={i18n.t("dataTableProps")}
          isLoading={loading}
          data={equipos}
          options={{
            sorting: true,
            showTitle: false,
            searchFieldAlignment: "left",
            pageSize: 10,
            grouping: true,
            exportButton: { csv: true },
            exportCsv: (columns, renderData) => {
              var exportEquipos = renderData
                .map((row) => {
                  if (row.data && row.data.length > 0) {
                    return row.data.flat();
                  } else if (row.groups && row.groups.length > 0) {
                    var groups = row.groups;
                    var data = row.data;

                    do {
                      if (groups.length > 0) {
                        data = groups.map((g) => g.data).flat();
                        groups = groups.map((g) => g.groups).flat();
                      }
                    } while (groups.length > 0);

                    return data.flat();
                  } else {
                    return row;
                  }
                })
                .flat();

              exportEquipos = exportEquipos.map((e) => {
                if (e.retrofit === "") e.retrofit = "N/A";
                return e;
              });

              EquiposAPI.getExportInventario(exportEquipos, i18n.locale)
                .then((response) => response.blob())
                .then((blob) => {
                  var url = window.URL.createObjectURL(blob);
                  var a = document.createElement("a");
                  a.href = url;
                  a.download =
                    i18n.locale === "en"
                      ? "Inventory " + nombre + ".xlsx"
                      : "Inventario " + nombre + ".xlsx";
                  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                  a.click();
                  a.remove(); //afterwards we remove the element again
                });
            },
          }}
          components={{
            GroupRow: (props) => {
              const { groupData } = props;
              var value = (
                <span>
                  {groupData.value === 0
                    ? "0"
                    : !groupData.value
                    ? "N/A"
                    : groupData.value}{" "}
                  -{" "}
                  <b>
                    {groupData.data.length === 0
                      ? groupData.groups.length
                      : groupData.data.length}{" "}
                    {i18n.t("common.unidades")}
                  </b>
                </span>
              );
              var newGroupData = { ...groupData, value: value };
              return <MTableGroupRow {...props} groupData={newGroupData} />;
            },
          }}
          columns={[
            {
              field: "idCliente",
              title: i18n.t("equipo.idCliente"),
              grouping: false,
              render: (equipo) => (
                <Link target="_blank" to={`${plantaId}/equipos/${equipo.id}`}>
                  {equipo.idCliente || i18n.t("common.noId")}
                </Link>
              ),
            },
            {
              field: "nombre",
              title: i18n.t("equipo.nombre"),
              grouping: false,
              render: (equipo) => (
                <Link target="_blank" to={`${plantaId}/equipos/${equipo.id}`}>
                  {equipo.nombre || i18n.t("common.noName")}
                </Link>
              ),
            },
            {
              field: "descripcion",
              title: i18n.t("equipo.descripcion"),
              render: (equipo) =>
                equipo.descripcion || i18n.t("common.noDescription"),
            },
            { field: "tc", title: "TC / TYPE" },
            { field: "pn", title: "PN", defaultGroupOrder: 1 },
            { field: "zona.descripcion", title: i18n.t("equipo.zona") },
            {
              field: "añoFabricacion",
              title: i18n.t("equipo.antiguedad"),
              searchable: false,
            },
            {
              field: "numErrores",
              title: i18n.t("equipo.errores"),
              searchable: false,
              width: 135,
            },
            {
              field: "uso",
              title: i18n.t("equipo.uso"),
              searchable: false,
              width: 135,
            },
            {
              field: "riesgo",
              title: i18n.t("equipo.riesgo"),
              searchable: false,
              width: 135,
              render: (equipo) => {
                switch (equipo.riesgo) {
                  case 0:
                    return i18n.t("common.alto");
                  case 1:
                    return i18n.t("common.medio");
                  case 2:
                    return i18n.t("common.bajo");
                  default:
                    return "N/A";
                }
              },
            },
          ]}
          actions={[
            {
              icon: () => <GetApp />,
              tooltip: i18n.t("dataTableProps.toolbar.exportDanfoss"),
              isFreeAction: true,
              onClick: () => {
                var exportEquipos = equipos
                  .map((row) => {
                    if (row.data && row.data.length > 0) {
                      return row.data.flat();
                    } else if (row.groups && row.groups.length > 0) {
                      var groups = row.groups;
                      var data = row.data;

                      do {
                        if (groups.length > 0) {
                          data = groups.map((g) => g.data).flat();
                          groups = groups.map((g) => g.groups).flat();
                        }
                      } while (groups.length > 0);

                      return data.flat();
                    } else {
                      return row;
                    }
                  })
                  .flat();

                exportEquipos = exportEquipos.map((e) => {
                  if (e.retrofit === "") e.retrofit = "N/A";
                  return e;
                });

                EquiposAPI.getExportInventarioDanfoss(
                  exportEquipos,
                  i18n.locale
                )
                  .then((response) => response.blob())
                  .then((blob) => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download =
                      i18n.locale === "en"
                        ? "Site  Assessment " + nombre + ".xlsx"
                        : "Site  Assessment " + nombre + ".xlsx";
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                  });
              },
            },
            {
              icon: "refresh",
              tooltip: i18n.t("alarma.actualizar"),
              isFreeAction: true,
              onClick: (event) => {
                getEquiposByplanta();
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
