import React from "react";
import i18n from "../locales/i18n";

export default (props) => {
  const { type } = props;

  return (
    <div
      style={{
        width: "100px",
        marginLeft: "auto",
        marginRight: "auto",
        color: type === 0 ? "#43a047" : type === 1 ? "#0288d1" : type === 2 ? "#ffb300" : "000000",
        borderRadius: "10px",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor:
          type === 0 ? "#43a047" : type === 1 ? "#0288d1" : type === 2 ? "#ffb300" : "000000",
      }}
    >
      {type === 0
        ? i18n.t("maintenance.altualScheduled.module1")
        : type === 1
        ? i18n.t("maintenance.altualScheduled.module2")
        : type === 2 
        ? i18n.t("maintenance.altualScheduled.module3")
        : i18n.t("maintenance.altualScheduled.moduleBase")}
    </div>
  );
};
