import React, { useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Input,
  Paper,
  Typography,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { Camera, DeleteForever } from "@material-ui/icons";
import AlertDialog from "../../AlertDialog";
import Swal from "sweetalert2";

function CardImagenesVariador(props) {
  const {
    images,
    removeImage,
    isExchange,
    addImage,
    loading,
    readOnly,
    hideHeader,
  } = props;
  const [imageIndex, setImageIndex] = useState(0);

  const imageDialog = useRef(null);
  const fileInputRef = useRef(null);

  const imageAdd = (file) => {
    imageDialog.current.close();
    if (file.type === "image/jpeg" || file.type === "image/png") {
      addImage(file);
    } else {
      Swal.fire(
        i18n.t("equipo.imagen.errorAlert"),
        i18n.t("equipo.imagen.errorMessage"),
        "error"
      );
    }
  };

  return (
    <>
      <Card style={{ marginTop: 20 }}>
        {!hideHeader && (
          <CardHeader
            title={i18n.t("common.imagen")}
            avatar={
              <Avatar style={{ backgroundColor: "#f50057" }}>
                <Camera />
              </Avatar>
            }
          />
        )}
        <CardContent>
          {!isExchange && !readOnly && (
            <Button
              variant="contained"
              onClick={() => imageDialog.current.open()}
            >
              {i18n.t("equipo.imagen.addImagen")}
            </Button>
          )}
          <Grid container>
            <Grid
              item
              container
              xs={2}
              style={{
                zIndex: 1,
                alignContent: "start",
                backgroundColor: "white",
              }}
            >
              {images.map((image, index) => (
                <a
                  download={`image${index}.jpg`}
                  style={{ width: "100%" }}
                  href={image}
                  onClick={(e) => {
                    e.preventDefault();
                    setImageIndex(index);
                  }}
                >
                  <Paper style={{ marginTop: 10 }} variant="outlined">
                    <Typography
                      style={{
                        marginLeft: 10,
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!hideHeader ? `image${index}.jpg` : ""}
                      {!isExchange && !readOnly && (
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            removeImage(index);
                            return false;
                          }}
                          color="secondary"
                          style={{ marginLeft: "auto" }}
                        >
                          <DeleteForever fontSize="inherit" />
                        </IconButton>
                      )}
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        height: "20vh",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>
                  </Paper>
                </a>
              ))}
            </Grid>
            {images.length > 0 && (
              <Grid item xs={10}>
                <ReactPanZoom image={`${images[imageIndex]}`} alt="Drive" />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      <AlertDialog
        ref={imageDialog}
        title={i18n.t("equipo.imagen.uploadImage")}
        loading={loading}
        text={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Input inputRef={fileInputRef} type="file" />
            </Grid>
          </Grid>
        }
        cancelText={i18n.t("documents.alertDialog.cancelText")}
        confirmText="Subir"
        confirmAction={() =>
          fileInputRef.current.files.length === 0
            ? null
            : imageAdd(fileInputRef.current.files[0])
        }
      />
    </>
  );
}

export default CardImagenesVariador;
