import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import i18n from "../../../locales/i18n";
import { ClientesSolicitudesAPI } from "../../../services/API";
import SearchField from "../../molecule/SearchField/SearchField";
import { filter } from "../../../helpers/Filter";
import { DeleteForever } from "@material-ui/icons";
// @ts-ignore
import Doc from "../../../assets/images/document.jpeg";
import moment from "moment";

export default function RegistrosList(props) {
  const { setNavBarTitle } = props;
  const [solicitudes, setSolicitudes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setsearchQuery] = useState("");

  const getSolicitudes = async () => {
    setLoading(true);

    ClientesSolicitudesAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setSolicitudes(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteClienteSolicitud = async (id) => {
    setLoading(true);

    await ClientesSolicitudesAPI.remove(id)
      .then(async (response) => {
        if (response.ok) {
          setSolicitudes((prev) => prev.filter((s) => s.id !== id));
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .catch((error) => {
        alert(error.message);
      });

    setLoading(false);
  };

  useEffect(() => {
    setNavBarTitle("Solicitudes de registro");
    document.getElementById("main-content").scrollTop = 0;
    getSolicitudes();
  }, [setNavBarTitle]);

  return (
    <RelativeBackdrop hidden={!loading}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <SearchField
            inputBaseProps={{
              placeholder: i18n.t("common.search"),
              value: searchQuery,
              onChange: (event) =>
              setsearchQuery(event.target.value),
            }}
          />
        </Grid>
        <Grid container item spacing={2}>
          {filter(solicitudes, searchQuery)
            .sort((a, b) => b.emailConfirmed - a.emailConfirmed)
            .map((solicitud) => (
              <SolicitudCard
                key={solicitud.id}
                onDelete={() => deleteClienteSolicitud(solicitud.id)}
                {...solicitud}
                {...props}
              />
            ))}
        </Grid>
      </Grid>
    </RelativeBackdrop>
  );
}

function SolicitudCard(props) {
  const {
    nombre,
    apellidos,
    empresa,
    cargo,
    telefono,
    email,
    emailConfirmed,
    partner,
    onDelete,
    keyuser,
    fecha,
  } = props;

  const abbreviatonLetters = empresa.split(" ");
  const abbreviaton =
    abbreviatonLetters.length > 1
      ? (abbreviatonLetters[0][0] + abbreviatonLetters[1][0]).toUpperCase()
      : empresa.substr(0, 2).toUpperCase();

  return (
    <Grid item xs={6} md={4} xl={3}>
      <Card
        style={{
          background: `linear-gradient(rgba(255,255,255,1),rgba(255,255,255,.8), rgba(255,255,255, 1)), url('${Doc}')`,
          backgroundSize: "115%",
        }}
      >
        <CardHeader
          classes={{ content: "overflow-hidden" }}
          titleTypographyProps={{ noWrap: true, variant: "body2" }}
          subheaderTypographyProps={{ noWrap: true, variant: "body2" }}
          title={empresa}
          subheader={`${nombre} ${apellidos}`}
          avatar={
            <Avatar style={{ backgroundColor: "#552877", fontWeight: 300 }}>
              {abbreviaton}
            </Avatar>
          }
        />
        <CardContent>
          <Typography variant="body2" display="block" noWrap>
            {i18n.t("user.cargo")}: {cargo}
          </Typography>
          <Typography variant="body2" display="block" noWrap>
            {i18n.t("user.telefono")}: {telefono}
          </Typography>
          <Typography variant="body2" display="block" noWrap>
            {i18n.t("user.email")}: {email}
          </Typography>
          {(partner && keyuser) &&
            <Typography variant="body2" display="block" noWrap>
              {i18n.t("user.partner")}: {partner.razonSocial}
            </Typography>
          }
          <Typography variant="body2" display="block" noWrap>
            {i18n.t("user.fecha")}: {moment(fecha).format('DD-MM-YYYY')}
          </Typography>
        </CardContent>
        <CardActions>
          {emailConfirmed && (
            <Button>Email {i18n.t("common.verificado")}</Button>
          )}
          <Button
            className="ml-auto"
            color="secondary"
            startIcon={<DeleteForever />}
            onClick={(event) => {
              event.stopPropagation();
              onDelete();
            }}
          >
            {i18n.t("common.borrar")}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
