import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";

/**
 * @param {{ activeFilter: number; setActiveFilter: function(number):void; }} props
 */
export default function Select_ExchangeSearchEngineFilter(props) {
  const { activeFilter, setActiveFilter } = props;

  return (
    <FormControl fullWidth variant="filled">
      <InputLabel>{i18n.t("planta.filtrarEquiposExchange.label")}</InputLabel>
      <Select
        value={activeFilter}
        onChange={(e) => {
          const valueNumber = Number.parseInt(e.target.value.toString());
          if (Number(valueNumber) === valueNumber) {
            setActiveFilter(valueNumber);
          }
        }}
        classes={{ root: useStyles().selectRoot }}
      >
        <MenuItem value={0}>
          {i18n.t("planta.filtrarEquiposExchange.todos")}
        </MenuItem>
        <MenuItem value={1}>
          {i18n.t("planta.filtrarEquiposExchange.disponibles")}
        </MenuItem>
        <MenuItem value={3}>
          {i18n.t("planta.filtrarEquiposExchange.solicitados")}
        </MenuItem>
        <MenuItem value={2}>
          {i18n.t("planta.filtrarEquiposExchange.prestados")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    backgroundColor: 'white',
    //...other styles
    '&:focus': {
      backgroundColor: 'white'
    }
  }
}));
