import React, { useEffect, useState, useCallback } from "react";
import i18n from "../../../locales/i18n";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  Add,
  Room,
  ViewComfy,
  ViewHeadline,
  ViewList,
} from "@material-ui/icons";
import { ZonasAPI } from "../../../services/API";
import { filter } from "../../../helpers/Filter";
import SearchField from "../../molecule/SearchField/SearchField";
import ZonaCard from "../../molecule/ZonaCard/ZonaCard";
import ZonaRow from "../../molecule/ZonaRow/ZonaRow";
import RelativeBackdrop from "../../RelativeBackdrop";
import AuthService from "../../../services/AuthService";
import Swal from "sweetalert2";
import ZonaGrid from "../../molecule/ZonaRow/ZonaGrid";

export default function ZonaSearchAndAdd(props) {
  const { zonaId, plantaId, readOnly, history, goBack } = props;

  const [loading, setLoading] = useState(false);
  const [newZona, setNewZona] = useState({ descripcion: "" });
  const [zonaDialogOpen, setZonaDialogOpen] = useState(false);
  const [zonas, setZonas] = useState([]);
  const [zonasSearch, setZonasSearch] = useState("");
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("viewMode") || "Card"
  );

  const getZonas = useCallback(async () => {
    setLoading(true);

    ZonasAPI.getNextLevelOfZones(plantaId, zonaId)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setZonas(data.zonas);
        setLoading(false);
        AuthService.updateUserAccessPermisions(data.userAccessPermissions);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [plantaId, zonaId]);

  const addZona = async () => {
    let zona = {
      plantaId: plantaId,
      plantaZonaId: zonaId,
      descripcion: newZona.descripcion,
    };

    setLoading(true);

    ZonasAPI.add(zona)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.json();
          throw error;
        }
      })
      .then((zona) => {
        setLoading(false);
        setZonaDialogOpen(false);
        setNewZona({ descripcion: "" });
        AuthService.addTempAccessToZone(zona.id);
        getZonas();
      })
      .catch((e) => {
        setLoading(false);
        if (e.message === "unique") {
          setZonaDialogOpen(false);
          setNewZona({ descripcion: "" });
          Swal.fire({
            title: "Error",
            text: "La zona ya existe",
            icon: "error",
          });
        }
      });
  };

  const onChangeViewMode = (mode) => {
    if (mode) {
      setViewMode(mode);
      localStorage.setItem("viewMode", mode);
    } else {
      if (viewMode === "Card") {
        setViewMode("List");
        localStorage.setItem("viewMode", "List");
      } else if (viewMode === "List") {
        setViewMode("Grid");
        localStorage.setItem("viewMode", "Grid");
      } else {
        //if (viewMode === "Grid")
        setViewMode("Card");
        localStorage.setItem("viewMode", "Card");
      }
    }
  };

  const onZoneClick = (zona) => {
    zonaId
      ? history.push(`./${zona.id}`)
      : history.push(`./${plantaId}/zonas/${zona.id}`);
  };

  useEffect(() => {
    getZonas();
  }, [getZonas]);

  useEffect(() => {}, []);

  return (
    <Grid item xs={12}>
      <RelativeBackdrop hidden={!loading}>
        <Grid container spacing={2}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <SearchField
                inputBaseProps={{
                  placeholder: i18n.t("common.searchZone"),
                  value: zonasSearch,
                  onChange: (event) => setZonasSearch(event.target.value),
                }}
                goBack={goBack}
                endButton={
                  !readOnly && (
                    <Button
                      style={{
                        padding: 10,
                        color: "rgba(0, 0, 0, 0.70)",
                      }}
                      onClick={() => {
                        setZonaDialogOpen(true);
                        setNewZona({ descripcion: "" });
                      }}
                    >
                      <Add />
                      {i18n.t("common.new.femenine")} {i18n.t("common.zona")}
                    </Button>
                  )
                }
              />
            </Grid>
            <Grid item>
              <Paper>
                <Button
                  aria-label="view mode"
                  disabled={viewMode === "Card"}
                  onClick={() => onChangeViewMode("Card")}
                >
                  <ViewComfy className="ml-2" />
                </Button>
                {/* <Button
                  aria-label="view mode"
                  disabled={viewMode === "List"}
                  onClick={() => onChangeViewMode("List")}
                >
                  <ViewList className="ml-2" />
                </Button> */}
                <Button
                  aria-label="view mode"
                  disabled={viewMode === "Grid"}
                  onClick={() => onChangeViewMode("Grid")}
                >
                  <ViewHeadline className="ml-2" />
                </Button>
              </Paper>
            </Grid>
          </Grid>
          {viewMode === "Grid" ? (
            <Grid
              item
              container
              xs={12}
              spacing={0}
              className="MuiPaper-elevation1 MuiPaper-root MuiPaper-rounded"
              style={{
                margin: 10,
                padding: 0,
              }}
            >
              {zonas &&
                filter(zonas, zonasSearch)
                  .sort((a, b) => (a.descripcion < b.descripcion ? -1 : 1))
                  .map((zona) => {
                    return (
                      <Grid item xs={12} key={zona.id}>
                        <ZonaGrid
                          zona={zona}
                          onClick={() => onZoneClick(zona)}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          ) : (
            <Grid item container spacing={2}>
              {zonas &&
                filter(zonas, zonasSearch)
                  .sort((a, b) => (a.descripcion < b.descripcion ? -1 : 1))
                  .map((zona) => {
                    return viewMode === "Card" ? (
                      <Grid item xs={6} md={3} xl={2} key={zona.id}>
                        <ZonaCard
                          zona={zona}
                          onClick={() => onZoneClick(zona)}
                        />
                      </Grid>
                    ) : (
                      //viewMode === "List"
                      <Grid item xs={12} lg={6} key={zona.id} spacing={0}>
                        <ZonaRow
                          zona={zona}
                          onClick={() => onZoneClick(zona)}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          )}
        </Grid>
        <Dialog
          open={zonaDialogOpen}
          onClose={() => setZonaDialogOpen(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            <Room color="secondary" style={{ verticalAlign: "sub" }} />{" "}
            {i18n.t("common.new.femenine")} {i18n.t("common.zona")}
          </DialogTitle>
          <DialogContent style={{ minWidth: 350 }}>
            <TextField
              defaultValue={newZona.descripcion}
              autoFocus
              margin="dense"
              label={i18n.t("zona.descripcion")}
              fullWidth
              onChange={(event) =>
                setNewZona({ descripcion: event.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              style={{ marginRight: "auto" }}
              onClick={() => setZonaDialogOpen(false)}
            >
              {i18n.t("common.cancel")}
            </Button>
            <Button
              onClick={() => addZona()}
              color="secondary"
              startIcon={<Add />}
            >
              {i18n.t("common.create")}
            </Button>
          </DialogActions>
        </Dialog>
      </RelativeBackdrop>
    </Grid>
  );
}
