import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import moment from "moment";
import i18n from "../locales/i18n";
import { EquipoScheduledPreventiveMaintenancesAPI } from "../services/API";
import PreventiveMaintenanceType from "./PreventiveMaintenance_Type";
import PreventiveMaintenanceState from "./PreventiveMaintenance_State";
import RelativeBackdrop from "./RelativeBackdrop";

export default function ScheduledPreventiveMaintenance(props) {
  //const { history } = props;
  const [loading, setLoading] = useState(false);
  const [schePrevMaint, setSchePrevMaint] = useState([]);

  useEffect(() => {
    getScheduledPreventiveMaintinance();
  }, []);

  const getScheduledPreventiveMaintinance = () => {
    setLoading(true);

    const currentYear = new Date().getFullYear().toString();
    EquipoScheduledPreventiveMaintenancesAPI.getScheduledInYear(currentYear)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setSchePrevMaint(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <RelativeBackdrop hidden={!loading}>
      <Card>
        <CardHeader
          title={i18n.t("maintenance.title")}
          className="pb-0"
          avatar={
            <Avatar aria-label="avatar" style={{ backgroundColor: "#552877" }}>
              <CalendarToday />
            </Avatar>
          }
        />
        <CardContent style={{ cursor: "pointer" }}>
          <Table size="small" style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">{i18n.t("equipo.nombre")}</TableCell>
                <TableCell align="center">{i18n.t("equipo.zona")}</TableCell>
                <TableCell align="center">{i18n.t("planta.tituloCard")}</TableCell>
                <TableCell align="center">{i18n.t("planta.cliente")}</TableCell>
                <TableCell align="center">
                  {i18n.t("maintenance.altualScheduled.date")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("maintenance.altualScheduled.type")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("maintenance.altualScheduled.state")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schePrevMaint.length > 0 &&
                schePrevMaint.map((row) => (
                  <TableRow
                    key={row.id}
                    style={{ height: 45 }}
                    onClick={() =>
                      //history.push('./plantas/'+row.equipo.zona.plantaId+'/zonas/'+row.equipo.zona.id+'/equipos/'+row.equipo.id)
                      window
                        .open(
                          "./plantas/" +
                            row.equipo.zona.plantaId +
                            "/zonas/" +
                            row.equipo.zona.id +
                            "/equipos/" +
                            row.equipo.id,
                          "_blank"
                        )
                        .focus()
                    }
                  >
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.equipo.nombre}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.equipo.zona.descripcion}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.equipo.zona.planta.denominacion}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {row.equipo.zona.planta.cliente.razonSocial}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="subtitle1">
                        {moment(row.scheduledDate).local().format("DD/MM/yyyy")}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <PreventiveMaintenanceType type={row.type} />
                    </TableCell>
                    <TableCell align="center">
                      <PreventiveMaintenanceState
                        state={row.state}
                        isProvisionalDate={row.isProvisionalDate}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              {schePrevMaint.length === 0 && (
                <TableRow style={{ height: 45 }}>
                  <TableCell colSpan={5} align="center">
                    {i18n.t(
                      "maintenance.altualScheduled.emptyDataSourceMessage"
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </RelativeBackdrop>
  );
}
