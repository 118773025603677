import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Hidden, Button } from "@material-ui/core";
// @ts-ignore
import Header from "../../assets/images/header.jpg";
// @ts-ignore
import Logo from "../../assets/images/ingesis-logo.png";
// @ts-ignore
import Solut1 from "../../assets/images/solut-1.png";
// @ts-ignore
import Solut2 from "../../assets/images/solut-2.png";
// @ts-ignore
import Solut3 from "../../assets/images/solut-3.png";
// @ts-ignore
import Gbar from "../../assets/images/g-bar.png";
import i18n from "../../locales/i18n";

// @ts-ignore
const useStyles = makeStyles(() => ({
  containerRow: {
    height: "100vh",
  },
  rightLogo: {
    height: "150px",
    backgroundImage: `url("${Logo}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  leftCol: {
    alignItems: "center",
    justifyContent: "center",
    padding: 50,
    paddingBottom: 10,
  },
  rightCol: {
    backgroundImage: `url("${Header}")`,
    backgroundSize: "cover",
    backgroundPosition: "right",
  },
  logoContainer: {
    justifyContent: "center",
    height: "350px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    //marginBottom: 75,
  },
  boxItem: {
    padding: "20px 40px 30px 40px",
  },
  boxItemImg: {
    width: 50,
    marginTop: 30,
  },
  boxItemImgRight: {
    width: 80,
    marginTop: 0,
  },
  infoBox1: {
    borderRadius: "5px 0 0 5px",
    backgroundColor: "#e1003e",
    color: "white",
  },
  infoBox2: {
    backgroundColor: "#666666",
    color: "white",
  },
  infoBox3: {
    borderRadius: "0 5px 5px 0",
    backgroundColor: "#f0f0f0",
  },
  gBar: {
    margin: "25px 0 20px",
    backgroundImage: `url("${Gbar}")`,
    backgroundSize: "cover",
    height: 5,
    width: 60,
  },
  infoText: {
    height: 100,
  },
  foot: {
    marginTop: "10px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
}));

export default function Auth(props) {
  const classes = useStyles();
  const partner = props.partner;
  const [ingesis, setIngesis] = useState(false);
  const [emailPartner, setEmailPartner] = useState("");

  useEffect(() => {
    if (!partner || partner === "ingesis" || partner === "") {
      setIngesis(true);
      setEmailPartner("ingesis@ingesis.es");
    }
  }, [partner]);

  useEffect(() => {
    setEmailPartner(props.email);
  }, [props.email]);

  return (
    <Grid container className={`ml-0 mr-0 ${classes.containerRow}`}>
      <Hidden smDown>
        <Grid item sm={6} container className={classes.leftCol}>
          <Grid container>
            <Grid
              item
              container
              xs={6}
              className={classes.logoContainer}
              style={{
                backgroundImage: "url(/images/logosPartner/danfossLogo.png)",
              }}
            >
              {/* <img src="/images/logosPartner/danfossLogo.png" alt="logo" /> */}
            </Grid>
            <Grid item xs={1}></Grid>
            {!ingesis && (
              <Grid
                item
                container
                xs={5}
                className={classes.logoContainer}
                style={{
                  backgroundImage: `url(/images/logosPartner/${partner}.png)`,
                }}
              >
                {/* <img src={`/images/logosPartner/${partner}.png`} alt="logo" /> */}
              </Grid>
            )}
            {ingesis && (
              <>
                <Grid
                  item
                  container
                  xs={5}
                  className={classes.logoContainer}
                  style={{
                    backgroundImage: `url(${Logo})`,
                  }}
                >
                  <a
                    href="http://ingesis.es"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ alignSelf: "center" }}
                  >
                    {/* <img src={Logo} alt="logo" /> */}
                  </a>
                </Grid>

                <Grid item xs={12} md={6} lg={4} className={classes.infoBox1}>
                  <div className={classes.boxItem}>
                    <img
                      className={classes.boxItemImg}
                      src={Solut1}
                      alt="Soluciones integrales de automatización industrial"
                    />
                    <div className={classes.gBar} />
                    <p className={classes.infoText}>
                      {i18n.t("login.box1Text1")}
                      <br /> {i18n.t("login.box1Text2")}
                      <br />
                      <strong>
                        {i18n.t("login.box1Text3")}
                        <br /> {i18n.t("login.box1Text4")}
                      </strong>
                    </p>
                    <Button
                      href="http://ingesis.es/que-hacemos"
                      target="_blank"
                      color="inherit"
                      variant="outlined"
                    >
                      {i18n.t("login.saberMas")}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.infoBox2}>
                  <div className={classes.boxItem}>
                    <img
                      className={classes.boxItemImg}
                      src={Solut2}
                      alt="Digitalización industrial"
                    />
                    <div className={classes.gBar} />
                    <p className={classes.infoText}>
                      <span
                        style={{
                          fontWeight: i18n.locale === "es" ? "bold" : "normal",
                        }}
                      >
                        {i18n.t("login.box2Text1")}
                      </span>
                      <br />{" "}
                      <span
                        style={{
                          fontWeight: i18n.locale === "en" ? "bold" : "normal",
                        }}
                      >
                        {i18n.t("login.box2Text2")}
                      </span>
                    </p>
                    <Button
                      href="http://ingesis.es/que-hacemos#digitalizacion"
                      target="_blank"
                      color="inherit"
                      variant="outlined"
                    >
                      {i18n.t("login.saberMas")}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className={classes.infoBox3}>
                  <div className={classes.boxItem}>
                    <img
                      className={classes.boxItemImgRight}
                      src={Solut3}
                      alt="Danfoss Drives"
                    />
                    <div className={classes.gBar} />
                    <p className={classes.infoText}>
                      <span
                        style={{
                          fontWeight: i18n.locale === "en" ? "bold" : "normal",
                        }}
                      >
                        {i18n.t("login.box3Text1")}
                      </span>
                      <br />{" "}
                      <span
                        style={{
                          fontWeight: i18n.locale === "en" ? "bold" : "normal",
                        }}
                      >
                        {i18n.t("login.box3Text2")}
                      </span>
                      <br />{" "}
                      <span
                        style={{
                          fontWeight: i18n.locale === "es" ? "bold" : "normal",
                        }}
                      >
                        {i18n.t("login.box3Text3")}
                      </span>
                    </p>
                    <Button
                      href="http://ingesis.es/sat-danfoss"
                      target="_blank"
                      color="inherit"
                      variant="outlined"
                    >
                      {i18n.t("login.saberMas")}
                    </Button>
                  </div>
                </Grid>
              </>
            )}
            <Grid
              item
              container
              xs={12}
              className="mt-3"
              style={{ alignContent: "center" }}
            >
              <p>
                {i18n.t("login.contact1")}
                <a href={`mailto:${emailPartner}`}>{i18n.t("common.aqui")}</a>
                .
                <br />
                {i18n.t("login.contact2")}
                <a href={`mailto:${emailPartner}`}>{emailPartner}</a>.
              </p>
              <div className={classes.foot}>
                <a href="/privacity" target="_blank">
                  Politica de privacidad
                </a>
                <a href="/disclaimer" target="_blank">
                  Aviso legal
                </a>
                <a href="/cookies" target="_blank">
                  Politica de cookies
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Grid
        item
        xs={12}
        md={6}
        className={`pt-4 pt-lg-0 pb-4 pb-lg-0 pl-2 pr-2 ${classes.rightCol}`}
      >
        {props.children}
      </Grid>
    </Grid>
  );
}
