import PartnerBase from "./partnerBase";

export default class Partner extends PartnerBase {
  /**@type {Array} */   accesos = null;
  /**@type {Array} */   partnerClientes = null;

  /**@param {Object} IntiObj  */
  constructor(IntiObj) {
    super(IntiObj);
    if(IntiObj.accesos) { this.accesos = IntiObj.accesos; }
    if(IntiObj.partnerClientes) { this.partnerClientes = IntiObj.partnerClientes; }
  }
}