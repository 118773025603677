import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";

export default function TabPanel({ children, value, index, className,  ...other }) {
  //const  = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
