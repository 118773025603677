import axios from "axios";
import { baseUrl } from "../helpers/Url";

const TOKEN_URL = `${baseUrl}/connect/token`;
const REGISTER_URL = `${baseUrl}/api/userManager/registro`;
const VERIFY_URL = `${baseUrl}/api/userManager/verificar`;

class AuthService {
  login(credentials) {
    /*iNGESIS: We add the parameter version temporarily to solve 
    the accesses with uppercase instead of lowerCamelCase.*/
    return axios.post(
      TOKEN_URL,
      `username=${credentials.username}&password=` +
        `${credentials.password}&grant_type=password&version=1`
    );
  }

  register(nombre, apellidos, empresa, cargo, telefono, email, partner) {
    return axios.post(REGISTER_URL, null, {
      params: {
        nombre,
        apellidos,
        empresa,
        cargo,
        telefono,
        email,
        partner,
      },
    });
  }

  verify(token) {
    return axios.post(VERIFY_URL, null, {
      params: { token },
    });
  }

  logOut() {
    localStorage.removeItem("tokenInfo");
    localStorage.removeItem("filtros");
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem("tokenInfo"));
  }

  isAuthenticated() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    const tokenInfo = JSON.parse(token);
    if (tokenInfo.expireDate === undefined) return true;
    else return new Date(tokenInfo.expireDate) >= new Date();
  }

  isInRole(role) {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    return JSON.parse(token).roles.includes(role);
  }

  isGestorFromPartner(partnerId) {
    if (!partnerId) {
      return false;
    }
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    return JSON.parse(token).partnerAccesos.some(
      (a) =>
        a.appRoleName === "gestor-partner" &&
        a.partnerId.toString() === partnerId.toString()
    );
  }

  isGestorFromPartnerForClient(clienteId) {
    if (!clienteId) {
      return false;
    }
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }
    if (JSON.parse(token).roles.includes("keyuser")) {
      return true;
    }
    const accesosPartnerGestor = JSON.parse(token).partnerAccesos.filter(
      (a) => a.appRoleName === "gestor-partner"
    );
    let result = false;
    if (accesosPartnerGestor.length) {
      accesosPartnerGestor.forEach((access) => {
        access.partner.partnerClientes.forEach((element) => {
          if (element.clienteId.toString() === clienteId.toString()) {
            result = true;
          }
        });
      });
    }
    return result;
  }

  isGestorFromClient(clienteId) {
    if (!clienteId) {
      return false;
    }
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    return JSON.parse(token).clienteAccesos.some(
      (a) =>
        a.appRoleName === "gestor-cliente" &&
        a.clienteId.toString() === clienteId.toString()
    );
  }

  isGestorFromPlant(plantaId) {
    if (!plantaId) {
      return false;
    }
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    return JSON.parse(token).plantaAccesos.some(
      (a) =>
        a.appRoleName === "gestor-planta" &&
        a.plantaId.toString() === plantaId.toString()
    );
  }

  isGestorFromZone(zoneId) {
    if (!zoneId) {
      return false;
    }
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    return JSON.parse(token).zonaAccesos.some(
      (a) =>
        a.appRoleName === "gestor-zona" &&
        a.plantaZonaId.toString() === zoneId.toString()
    );
  }

  addTempAccessToPartner(partnerId) {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    var json = JSON.parse(token);
    json.partnerAccesos.push({
      appRoleName: "gestor-partner",
      appUser: null,
      appUserId: json.userid,
      partner: null,
      partnerId: partnerId,
    });

    localStorage.setItem("tokenInfo", JSON.stringify(json));
  }

  addTempAccessToClient(clientId) {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    var json = JSON.parse(token);
    json.clienteAccesos.push({
      appRoleName: "gestor-cliente",
      appUser: null,
      appUserId: json.userid,
      cliente: null,
      clienteId: clientId,
    });

    localStorage.setItem("tokenInfo", JSON.stringify(json));
  }

  addTempAccessToPlant(plantaId) {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    var json = JSON.parse(token);
    json.plantaAccesos.push({
      appRoleName: "gestor-planta",
      appUser: null,
      appUserId: json.userid,
      planta: null,
      plantaId: plantaId,
    });

    localStorage.setItem("tokenInfo", JSON.stringify(json));
  }

  addTempAccessToZone(zonaId) {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) return false;

    var json = JSON.parse(token);
    json.zonaAccesos.push({
      appRoleName: "gestor-zona",
      appUser: null,
      appUserId: json.userid,
      plantaZona: null,
      plantaZonaId: zonaId,
    });

    localStorage.setItem("tokenInfo", JSON.stringify(json));
  }

  isGestorFromSomePartner() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }

    return (
      JSON.parse(token).partnerAccesos.filter(
        (a) => a.appRoleName === "gestor-partner"
      ).length > 0
    );
  }

  isGestorFromSomeClient() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }

    return (
      JSON.parse(token).clienteAccesos.filter(
        (a) => a.appRoleName === "gestor-cliente"
      ).length > 0
    );
  }

  isGestorFromSomePlant() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }

    return (
      JSON.parse(token).plantaAccesos.filter(
        (a) => a.appRoleName === "gestor-planta"
      ).length > 0
    );
  }

  isGestorFromSomeZone() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }

    return (
      JSON.parse(token).zonaAccesos.filter(
        (a) => a.appRoleName === "gestor-zona"
      ).length > 0
    );
  }

  updateUserAccessPermisions(userAccessPermissions) {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }
    const json = JSON.parse(token);
    let updateTokenInfo = false;

    if (
      !userAccessPermissions.partnerAccesos &&
      !userAccessPermissions.clienteAccesos &&
      !userAccessPermissions.plantaAccesos &&
      !userAccessPermissions.zonaAccesos
    ) {
      this.logOut();
      window.location.replace("/unauthorized");
    }

    const isEqualsJson = (obj1, obj2) => {
      if (!obj1) {
        obj1 = [];
      }
      if (!obj2) {
        obj2 = [];
      }
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      /*return true when the two json has same length and 
      all the properties has same value key by key*/
      return (
        keys1.length === keys2.length &&
        Object.keys(obj1).every((key) => obj1[key] === obj2[key])
      );
    };

    if (
      !isEqualsJson(json.partnerAccesos, userAccessPermissions.partnerAccesos)
    ) {
      json.partnerAccesos = userAccessPermissions.partnerAccesos;
      updateTokenInfo = true;
    }
    if (
      !isEqualsJson(json.clienteAccesos, userAccessPermissions.clienteAccesos)
    ) {
      json.clienteAccesos = userAccessPermissions.clienteAccesos;
      updateTokenInfo = true;
    }
    if (
      !isEqualsJson(json.plantaAccesos, userAccessPermissions.plantaAccesos)
    ) {
      json.plantaAccesos = userAccessPermissions.plantaAccesos;
      updateTokenInfo = true;
    }
    if (!isEqualsJson(json.zonaAccesos, userAccessPermissions.zonaAccesos)) {
      json.zonaAccesos = userAccessPermissions.zonaAccesos;
      updateTokenInfo = true;
    }

    if (
      json.isResponsableExchange !== userAccessPermissions.isResponsableExchange
    ) {
      json.isResponsableExchange = userAccessPermissions.isResponsableExchange;
      updateTokenInfo = true;
    }

    if (updateTokenInfo) {
      localStorage.setItem("tokenInfo", JSON.stringify(json));
    }
  }

  /**
   * @returns {number}
   */
  getPartnerId() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return 0;
    }

    const json = JSON.parse(token);
    const partners = json.partnerAccesos;
    const partnersGestor = partners.filter(
      (p) => p.appRoleName === "gestor-partner"
    );
    let partnerId = 0;
    if (partnersGestor.length > 0) {
      partnerId = parseInt(partnersGestor[0].partnerId);
    }
    return isNaN(partnerId) ? 0 : partnerId;
  }

  /**
   * @returns {boolean}
   */
  isExchange() {
    const token = localStorage.getItem("tokenInfo");
    if (token === null) {
      return false;
    }

    const json = JSON.parse(token);
    const exchange = json.isResponsableExchange;
    return exchange;
  }
}

export default new AuthService();
