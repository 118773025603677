import React, { useEffect } from "react";
import i18n from "../locales/i18n";

export default function Privacidad({ setNavBarTitle }) {
  useEffect(() => {
    setNavBarTitle(i18n.t("privacity.title"));
  }, [setNavBarTitle]);

  return (
    <div
      style={{
        maxWidth: "1000px",
        textAlign: "justify",
      }}
    >
      <h3>POLÍTICA DE PRIVACIDAD</h3>
      <br />
      <h5>1. Información adicional sobre protección de datos.</h5>
      <p>
        1.1. La presente política de privacidad está redactada conforme lo
        dispuesto en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL
        CONSEJO relativo a la protección de las personas físicas en lo que
        respecta al tratamiento de datos personales y a la libre circulación de
        estos y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de
        Datos Personales y garantía de los derechos digitales. INGESIS
        AUTOMATIZACIÓN, S.L da información al Usuario del sitio web y/o
        aplicación WWW.ALTID.ES y derivados (en adelante sitios web) sobre
        protección de datos en un formato fácilmente entendible pregunta-
        repuesta:
      </p>
      <p>
        <b>¿Quién es el Responsable del tratamiento de sus datos?</b>
      </p>
      <p>
        Cuando un cliente se registra en nuestra web www. altid.es y/o en
        nuestra app ALTID INGESIS, INGESIS AUTOMATIZACIÓN, S.L es el Responsable
        de sus datos personales. No obstante, el acceso a ALTID por parte del
        cliente, es consecuencia de un contrato de prestación de servicios
        firmado previamente entre nuestra empresa y la empresa usuaria de
        nuestros servicios. En este sentido, todos los datos de representantes
        legales y/o empleados de la empresa usuaria que se introduzcan en ALTID
        INGESIS para el uso de la aplicación serán tratados por INGESIS
        AUTOMATIZACIÓN S.L en calidad de encargada del tratamiento de las
        empresas usuarias de nuestra aplicación o portal web, siguiendo las
        instrucciones de éstas para su tratamiento y tratándose, únicamente,
        mientras se mantenga el uso de la aplicación por parte de la empresa
        usuaria. Por lo tanto, si usted es un empleado registrado por su empresa
        en nuestra aplicación y quiere ejercer sus derechos en protección de
        datos debe dirigir su petición a su empresa.
      </p>
      <p>Los datos de contacto de nuestra empresa son:</p>
      <p>
        <b>Identidad:</b> INGESIS AUTOMATIZACIÓN, S.L
        <br />
        <b>Dirección postal:</b> C/ JORNALERS, 33, POL. IND. SEPES, ALBERIC
        (VALENCIA)
        <br />
        <b>Teléfono: </b>962440181
        <br />
        <b>Correo electrónico:</b> INGESIS@INGESIS.ES
      </p>
      <p>
        <b>¿Con qué finalidad tratamos sus datos personales?</b>
      </p>
      <p>
        INGESIS AUTOMATIZACIÓN, S.L trata la información que nos facilita con
        las siguientes finalidades:
      </p>
      <p>
        1) Cursar su solicitud de usuario registrado en WWW.ALTID.ES y mantener
        su cuenta de cliente de los productos de soluciones informáticas en
        línea de automatización y digitalización industrial que ofrece INGESIS
        AUTOMATIZACIÓN, S.L y a los que también puede acceder a través de
        nuestra app móvil “Altid Ingesis”. Una vez confirmada y aceptada su
        solicitud, el usuario recibirá un correo electrónico de confirmación en
        la dirección indicada al momento de completar el formulario de registro
        y después de ser verificado por los servicios informáticos de INGESIS
        AUTOMATIZACIÓN, S.L.
      </p>
      <p>
        2) Responder a las peticiones o solicitudes que sean realizadas por el
        usuario a través de cualquiera de las formas de contacto que se ponen a
        disposición del usuario en el sitio web WWW.ALTID.ES y/o en la
        aplicación ALTID INGESIS.
      </p>
      <p>
        3) Atención de quejas, reclamaciones y ejercicios de derechos de
        clientes.
      </p>
      <p>
        4) Ofrecerle todas las funcionalidades de nuestra aplicación
        solicitándole permisos para acceder a contenido de su dispositivo.
      </p>
      <p>
        No se llevarán a cabo decisiones automatizadas, como por ejemplo la
        elaboración de perfiles para ofertar servicios o productos, salvo lo
        dispuesto en la Política de Cookies. En el caso de que esto tuviera
        lugar, le informaremos de la lógica aplicada, así como de la importancia
        y las consecuencias previstas de dicho tratamiento para usted.
      </p>
      <p>
        <b>
          ¿Qué datos personales e información se trata al utilizar ALTID
          INGESIS?
        </b>
      </p>
      <p>
        Nuestra aplicación tan solo registra datos identificativos del usuario
        (nombre, apellidos, teléfono, email), así como el cargo que ostenta en
        la empresa cliente de INGESIS AUTOMATIZACIÓN S.L. El usuario podría
        introducir otros datos personales para gestionar en su cuenta de usuario
        los productos o servicios contratados (al crear alarmas, notas, etc…),
        aunque en ningún caso estos datos son solicitados por INGESIS
        AUTOMATIZACIÓN S.L para utilizar la aplicación.
      </p>
      <p>
        Por otra parte, cuando usted descargue nuestra aplicación ALTID INGESIS,
        le solicitaremos permiso para acceder a la siguiente información de su
        dispositivo:
      </p>
      <p>
        - <ins>Fotos/multimedia/archivos</ins>
      </p>
      <ul>
        <li>leer el contenido de tu almacenamiento USB</li>
        <li>modificar o eliminar contenido del almacenamiento USB</li>
      </ul>
      <p>
        - <ins>Cámara</ins>
      </p>
      <ul>
        <li>realizar fotografías y vídeos</li>
      </ul>
      <p>
        - <ins>Micrófono</ins>
      </p>
      <ul>
        <li>grabar sonido</li>
      </ul>
      <p>
        - <ins>Información sobre la conexión Wi‑Fi</ins>
      </p>
      <ul>
        <li>ver conexiones Wi-Fi</li>
      </ul>
      <p>
        - <ins>Otro motivo</ins>
      </p>
      <ul>
        <li>descargar archivos sin notificación</li>
        <li>recibir datos de Internet</li>
        <li>ver conexiones de red</li>
        <li>acceso completo a red</li>
        <li>cambiar la configuración de audio</li>
        <li>ejecutarse al inicio</li>
        <li>controlar la vibración</li>
        <li>impedir que el dispositivo entre en modo de suspensión</li>
        <li>Comprobación de licencia de Google Play</li>
      </ul>
      <p>
        Si no nos facilita los permisos solicitados, no podrá utilizar parte o
        todas las funcionalidades de nuestra aplicación.
      </p>
      <p>
        <b>¿Por cuánto tiempo conservaremos sus datos?</b>
      </p>
      <p>
        Si usted es cliente de alguno de nuestros productos digitales en línea
        para su empresa y accede a través de las aplicaciones que hemos puesto a
        su disposición, conservaremos sus datos mientras usted no solicite la
        supresión de su cuenta y, en todo caso, mientras se halle en vigor el
        contrato de servicios.
      </p>
      <p>
        Si usted ha realizado alguna consulta o petición de información a través
        de los medios de contacto habilitados en la web y/o en la app ALTID
        INGESIS conservaremos sus datos durante el tiempo estrictamente
        necesario para la resolución de dicha consulta y en cualquier caso
        durante el plazo máximo de un año desde la recogida de los datos.
      </p>
      <p>
        En el caso de haber realizado alguna reclamación, conservaremos sus
        datos durante el tiempo estricta y legalmente necesario para la
        resolución del litigio o controversia ocasionada.
      </p>
      <p>
        Los permisos concedidos a la aplicación para acceder a su dispositivo se
        cancelarán cuando elimine la aplicación de su dispositivo o cuando
        cancele los permisos otorgados.
      </p>
      <p>
        <b>¿Cuál es la legitimación para el tratamiento de sus datos?</b>
      </p>
      <p>
        Cuando usted nos realiza una consulta a través de las formas de contacto
        habilitadas en nuestra web o en nuestra app, así como cuando solicita la
        creación de una cuenta de usuario o accede a su cuenta de las soluciones
        digitales de automatización industrial que ofrecemos, estamos
        legitimados para el tratamiento de sus datos por la adopción de medidas
        precontractuales o por la ejecución del contrato de prestación de
        servicios que mantenemos con usted, ya que para utilizar nuestra app y
        nuestro portal web ALTID INGESIS es necesario que previamente haya
        contratado nuestros servicios.
      </p>
      <p>
        Para acceder a determinadas áreas del dispositivo donde instale nuestra
        aplicación estamos legitimados siempre y cuando usted nos de su
        consentimiento.
      </p>
      <p>
        <b>¿A qué destinatarios se comunicarán sus datos?</b>
      </p>
      <p>
        No está previsto ceder sus datos personales a terceros, salvo por
        obligación legal. Cualquier cesión de datos personales que realicemos
        como consecuencia de la prestación de nuestros servicios será informada
        en el momento de la contratación.
      </p>
      <p>
        Existen también empresas a las que INGESIS AUTOMATIZACIÓN, S.L no les
        cede datos, pero que nos prestan diferentes servicios, implicando estos
        servicios el alojamiento de datos de carácter personal por parte de
        dichas empresas o el acceso puntual por su parte a los mismos. Estas
        empresas que actúan como encargados del tratamiento de los datos y con
        quienes hemos firmado un contrato para garantizar la seguridad de los
        datos de carácter personal son:
      </p>
      <p>
        *Servicios informáticos: Diferentes empresas nos prestan el servicio de
        alojamiento de nuestros correos electrónicos corporativos, el servicio
        de mantenimiento de nuestros diferentes softwares y el servicio de
        alojamiento en línea de la información corporativa, entre otros
        servicios.
      </p>
      <p>No está previsto realizar transferencias internacionales de datos</p>
      <p>
        <b>¿Cuáles son sus derechos cuando nos facilita sus datos?</b>
      </p>
      <ul>
        <li>
          El interesado tiene derecho a obtener confirmación sobre si en INGESIS
          AUTOMATIZACIÓN, S.L estamos tratando datos personales que le
          conciernan, o no.
        </li>
        <li>
          El interesado tiene <b>derecho a acceder a sus datos personales</b>,
          así como a solicitar la <b>rectificación</b> de los datos inexactos o,
          en su caso, solicitar su <b>supresión</b> cuando, entre otros motivos,
          los datos ya no sean necesarios para los fines que fueron recogidos.
        </li>
        <li>
          El interesado podrá solicitar la{" "}
          <b>limitación del tratamiento de sus datos</b>, en cuyo caso
          únicamente los conservaremos para el ejercicio o la defensa de
          reclamaciones.
        </li>
        <li>
          Igualmente, en determinadas circunstancias y por motivos relacionados
          con su situación particular, <b>los interesados podrán oponerse</b>
          al tratamiento de sus datos, así como a{" "}
          <b>retirarnos el consentimiento para el tratamiento de los mismos.</b>
          INGESIS AUTOMATIZACIÓN, S.L dejará de tratar los datos, salvo por
          motivos legítimos imperiosos, o el ejercicio o la defensa de posibles
          reclamaciones.
        </li>
        <li>
          El interesado tendrá{" "}
          <b>derecho a recibir los datos personales que le incumban</b>, que nos
          haya facilitado, en un formato estructurado, de uso común y lectura
          mecánica, y a transmitirlos a otro responsable del tratamiento sin que
          lo impida el responsable al que se los hubiera facilitado.
        </li>
        <li>
          El interesado tiene{" "}
          <b>derecho a no ser objeto de una decisión automatizada</b> basada
          únicamente en el tratamiento de sus datos, incluida la elaboración de
          perfiles, que produzca efectos jurídicos sobre usted o le afecte
          significativamente de forma similar. Sobre esta elaboración de
          perfiles, se trata de cualquier forma de tratamiento de sus datos
          personales que evalúe aspectos personales, en particular analizar o
          predecir aspectos relacionados con su rendimiento en el trabajo,
          situación económica, salud, las preferencias o intereses personales,
          fiabilidad o el comportamiento.
        </li>
      </ul>
      <p>
        Para ejercitar estos derechos puede dirigir un escrito con su petición a
        la siguiente dirección: C/ JORNALERS, 33, POL. IND. SEPES, ALBERIC
        (VALENCIA), o la siguiente dirección de correo electrónico:
        INGESIS@INGESIS.ES y nos pondremos en contacto con usted para indicarle
        los pasos a seguir para ejercer sus derechos en protección de datos. De
        igual modo le informamos que puede presentar una reclamación ante la
        Agencia Española de Protección de Datos, especialmente si no viera
        satisfechos el ejercicio de sus derechos.
      </p>
      <p>Datos de contacto de la Agencia Española de Protección de Datos:</p>
      <p>
        Dirección: <b>C/ JORGE JUAN, 6, 28001 (MADRID)</b>
        <br />
        Teléfonos de contacto: <b>901 100 099 - 912 663 517</b>
        <br />
        Página web: <b>www.aepd.es</b>
        <br />
      </p>
      <p>
        1.2. El mero acceso a el sitio web y/o a la aplicación, así como de todo
        o parte de sus contenidos y servicios, implica la aceptación sin
        reservas de la presente política de privacidad, también aplicable
        previamente a la posible contratación de los servicios que INGESIS
        AUTOMATIZACIÓN, S.L ponga a disposición de los clientes. Esta política
        de privacidad es de aceptación y cumplimiento obligatorio, previamente
        al acceso y a la posible contratación en el sitio web y/o en la
        aplicación.
      </p>
      <p>
        1.3. Usted acepta que la navegación por el sitio web y/o la aplicación
        es un acto de libre manifestación de voluntad para la recogida y
        tratamiento de datos en INGESIS AUTOMATIZACIÓN, S.L. INGESIS
        AUTOMATIZACIÓN, S.L podrá libremente realizar cambios en la política de
        privacidad del sitio web y/o de la aplicación, se indicarán dichos
        cambios en la presente declaración sobre privacidad, así como en
        cualquier otro lugar que consideremos apropiado para que usted sea
        consciente de la información recopilada, cómo la tratamos y en qué
        circunstancias, en su caso, la comunicamos.
      </p>
      <br />
      <h5>
        2. Recogida de datos en la posible contratación de servicios de INGESIS
        AUTOMATIZACIÓN, S.L
      </h5>
      <p>
        2.1. En el momento de la aceptación de las condiciones de contratación
        de los servicios de INGESIS AUTOMATIZACIÓN, S.L, ésta precisará la
        recogida de unos datos básicos e imprescindibles para la prestación de
        los servicios. Los mencionados datos son nombre y apellidos reales,
        tratamiento, domicilio, domicilio opcional, código postal, localidad,
        país, DNI, un correo electrónico y teléfono de contacto válidos, así
        como otra información que sea relevante a efecto contractuales sobre su
        persona y representación. En cada momento, el usuario será informado del
        carácter necesario de la recogida de tales datos para la correcta
        gestión de la prestación de servicios.
      </p>
      <p>
        2.2. Asimismo, para la prestación de los servicios, INGESIS
        AUTOMATIZACIÓN, S.L podrá solicitar al usuario una serie de datos
        complementarios a través de asistencia en persona o telefónicamente,
        para lo que será informado, en su momento, de la recogida y tratamiento
        de los mismos.
      </p>
      <p>
        2.3. Siempre que INGESIS AUTOMATIZACIÓN, S.L solicite el número de una
        cuenta corriente bancaria del cliente que desee contratar nuestros
        servicios, lo hará de forma cifrada y cumpliendo el más estricto deber
        de confidencialidad.
      </p>
      <p>
        2.4. Para una mejor prestación de servicios, usted autoriza a que
        INGESIS AUTOMATIZACIÓN, S.L tenga el derecho de verificar, investigar
        y/o comprobar los datos proporcionados. Usted acepta que INGESIS
        AUTOMATIZACIÓN, S.L podrá revelar sus datos personales, cumpliendo con
        lo exigido en un procedimiento u orden judicial o en un proceso legal.
        Además, usted acepta que, si INGESIS AUTOMATIZACIÓN, S.L vende parte o
        toda la empresa o se fusiona con otra, pueda revelar su información a
        los nuevos socios comerciales.
      </p>
      <br />
      <h5>3.Limitación de responsabilidad</h5>
      <p>
        INGESIS AUTOMATIZACIÓN, S.L es responsable únicamente de la información
        que solicita y recaba de los usuarios que deseen contratar, no teniendo
        responsabilidad en ningún momento de toda otra información personal que,
        de manera eventual, pudiera cederse, transmitirse o facilitarse por los
        propios usuarios del sitio web y/o de la aplicación mediante cualquier
        otro mecanismo.
      </p>
      <br />
      <h5>4. Garantía de calidad de datos</h5>
      <p>
        Usted garantiza que los datos proporcionados son verdaderos, exactos,
        completos y actualizados, siendo responsable de cualquier daño o
        perjuicio, directo o indirecto, que pudiera ocasionarse como
        consecuencia del incumplimiento de tal obligación tanto a INGESIS
        AUTOMATIZACIÓN, S.L como a terceros.
      </p>
      <br />
      <h5>5. Consentimiento para el cumplimiento de la LSSI-CE</h5>
      <p>
        5.1 En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de
        la Sociedad de la Información y Comercio Electrónico, INGESIS
        AUTOMATIZACIÓN, S.L solicitará y el usuario podrá prestar su
        consentimiento para que, si así lo desea, INGESIS AUTOMATIZACIÓN, S.L
        pueda hacer uso de sus datos con el fin de enviarle información y
        publicidad tanto sobre el sitio web y/o de la aplicación como las
        diferentes ofertas, promociones especiales y terceros prestadores del
        servicio.
      </p>
      <p>
        5.2 INGESIS AUTOMATIZACIÓN, S.L, con el objetivo de garantizar la
        seguridad y confidencialidad de sus datos personales, ha adoptado las
        medidas de seguridad pertinentes y ha instalado los medios técnicos, a
        su alcance, para evitar la pérdida, mal uso, alteración, acceso no
        autorizado y robo de los datos personales facilitados a INGESIS
        AUTOMATIZACIÓN, S.L.
      </p>
      <br />
      <h6>ALBERIC, a 03 de NOVIEMBRE de 2021</h6>
    </div>
  );
}
