import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import {
  Add,
  ArrowBack,
  Business,
  Delete,
  Group,
  Save,
  Send,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AlertDialog from "../../../components/AlertDialog";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import TabPanel from "../../../components/TabPanel";
import i18n from "../../../locales/i18n";
import { ClientesAPI, PartnersAPI } from "../../../services/API";
import { filter } from "../../../helpers/Filter";
import AuthService from "../../../services/AuthService";
import CardPlantClient from "../../../components/molecule/cardPlantClient/cardPlantClient";
// @ts-ignore
import PlantaCard from "../../../assets/images/planta-card.jpg";
import { capitalizeFirstLetter } from "../../../helpers/Common";
import Cliente from "../../../models/cliente";
import ClienteBase from "../../../models/clienteBase";
import Swal from "sweetalert2";
import Partner from "../../../models/partaner";


/**
* Detalle del cliente
* @Component
* @param {Object} props - parametros del componente
* @param {Object} props.match
* @param {Object} props.history
* @param {boolean} props.keyuser
* @param {boolean} props.readOnly
* @param  {function} props.setNavBarTitle
*/
export default function ClientesDetails({match, history, keyuser, readOnly, setNavBarTitle}) {
  /** @type {number} */ const id = match.params.id;
  /** @type {number} */ const partnerId = match.params.partnerId;
  /** @type {boolean} */ const isGestor = AuthService.isGestorFromPartnerForClient(id);

  const [cliente, setCliente] = useState(new Cliente({}));
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [plantasSearch, setPlantasSearch] = useState("");
  const [partnersList, setPartnersList] = useState([]);
  const [errors, setErrors] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [showAllForm, setShowAllForm] = useState(true);
  const [partner, setPartner] = useState(new Partner({}));
  const [initialClient, setInitialClient] = useState(new Cliente({}));

  const deleteDialog = useRef(null);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setCliente((prevCliente) => ({...prevCliente, [name]:value}));
    if(name === 'cif') {
      setShowAllForm(false);
      setCanEdit(true);
    }
    const errorName = name ? capitalizeFirstLetter(name) : null;
    if(errors[errorName] || errors["CIF_Unique"]) {
      if(errors["CIF_Unique"]){
        setErrors((e) => ({...errors, "CIF_Unique":undefined}));
      }else{
        setErrors((e) => ({...errors, [errorName]:undefined}));
      }
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window['AltidVars'] = { ...window['AltidVars'], Tabs_ClientesDetails: newValue };
  };

  const getCliente = useCallback(async () => {
    setLoading(true);

    ClientesAPI.get(id.toString())
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setCliente(data);
        setInitialClient(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const addCliente = async () => {
    setLoading(true);
    const clientB = new ClienteBase(cliente);
    if (!keyuser) {
      clientB.partnerId = AuthService.getPartnerId();
    }
    ClientesAPI.add(clientB)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((cliente) => {
        AuthService.addTempAccessToClient(cliente.id);
        history.replace(`/clientes`);
        document.getElementById("main-content").scrollTop = 0;
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateCliente = async () => {
    setLoading(true);
    const clientB = new ClienteBase(cliente);
    ClientesAPI.update(clientB)
      .then(async (response) => {
        if (response.ok) {
          //All right
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteCliente = async () => {
    if (keyuser && !partnerId) {
      deleteDialog.current.close();
      Swal.fire({
        title: i18n.t("cliente.borrar.titulo"),
        text: i18n.t("cliente.borrar.texto"),
        icon: "warning",
        confirmButtonText: i18n.t("common.aceptar"),
      });
    } else if (keyuser && partnerId) {
      setLoading(true);
      ClientesAPI.removeFromPartner(id, partnerId)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => showDeleteStatus(data))
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      ClientesAPI.remove(id)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => showDeleteStatus(data))
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const showDeleteStatus = (data) => {
    if(data.status === 'Ok') {
      console.info(data.message);
      history.goBack();
    } else {
      // data.status === 'Error'
      deleteDialog.current.close();
      Swal.fire({
        title: i18n.t("cliente.borrarError.titulo"),
        text: i18n.t("cliente.borrarError.texto"),
        icon: "warning",
        confirmButtonText: i18n.t("common.aceptar"),
      });
      setLoading(false);
    }
  };

  const verificarCIF = async () => {
    let newFormErrors = {};
    if (!cliente.cif) {
      newFormErrors.CIF = i18n.t("textField.required");
    }

    if (Object.entries(newFormErrors).length === 0) {
      setErrors({});
      setLoading(true);
      ClientesAPI.getIfCIFExist(cliente.cif)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data.exist) {
            if (!id) {
              Swal.fire({
                icon: "info",
                title: i18n.t("cliente.existe.titulo"),
                html: i18n.t("cliente.existe.html"),
              });
              setCliente(data.cliente);
              setCanEdit(false);
              setShowAllForm(true);
            } else {
              if (data.cliente.cif === initialClient.cif) {
                setCliente(initialClient);
                setShowAllForm(true);
                setCanEdit(true);
              } else {
                Swal.fire({
                  icon: "info",
                  title: i18n.t("cliente.existe.titulo"),
                  html: i18n.t("cliente.existe.html_Edit"),
                });
              }
            }
          } else {
            setCliente(new Cliente({cif:cliente.cif, id:id}));
            setShowAllForm(true);
            setCanEdit(true);
          }
        });
      setLoading(false);
    } else {
      setErrors(newFormErrors);
    }    
  };

  const goBack = () => {
    if(history.length > 1) {
      history.goBack();
    } else {
      history.push('/clientes');
    }
    window['AltidVars'] = { ...window['AltidVars'], Tabs_ClientesDetails: undefined };
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);

  useEffect(() => {
    if (cliente.razonSocial) {
      setNavBarTitle(
        i18n.t("cliente.titulo") + " " + cliente.razonSocial
      );
    } else {
      setNavBarTitle(i18n.t("cliente.titulo"));
    }
  }, [setNavBarTitle, cliente.razonSocial]);

  useEffect(() => {
    if (
      id &&
      window['AltidVars'] !== undefined &&
      window['AltidVars'].Tabs_ClientesDetails !== undefined
    ) {
      setTabValue(window['AltidVars'].Tabs_ClientesDetails);
    }

    if (id) {
      getCliente();
    } else {
      setShowAllForm(false);
    }
  }, [id, getCliente]);

  useEffect(() => {
    if (keyuser && !id) {
      setLoading(true);

      PartnersAPI.get()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          setPartnersList(data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [keyuser, id]);

  useEffect(() => {
    setCanEdit(keyuser || !id || (!readOnly && cliente.partnerId === AuthService.getPartnerId()))
  }, [keyuser, id, readOnly, cliente.partnerId]);

  useEffect(() => {
    if (!id && keyuser && partnerId) {
      cliente.partnerId = partnerId;
    }
  }, [id, keyuser, partnerId, cliente.partnerId]);

  useEffect(() => {
    if(cliente.partnerId){
      setLoading(true);
      PartnersAPI.get(cliente.partnerId.toString())
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPartner(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    }
  },[cliente.partnerId]);

  const colWith = 4;

  return (
    <>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            label={i18n.t("cliente.tituloCard")}
            icon={<Group />}
            id="simple-tab-0"
          />
          {id && (
            <Tab
              label={i18n.t("planta.tituloCardLista")}
              icon={<Business />}
              id="simple-tab-1"
            />
          )}
        </Tabs>
      </Paper>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={0}>
        <RelativeBackdrop hidden={!loading}>
          <Card>
            <CardHeader
              title={cliente.razonSocial}
              subheader={partner.razonSocial}
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#f50057" }}
                >
                  {cliente.avatar}
                </Avatar>
              }
            />
            <CardContent>
              <form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={cliente.cif || ""}
                      label={i18n.t("cliente.cif")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: (!canEdit && id),
                      }}
                      error={errors["CIF"] !== undefined || errors["CIF_Unique"] !== undefined}
                      helperText={
                        errors["CIF"] ? i18n.t("textField.required") : errors["CIF_Unique"] ? i18n.t("cliente.cifInUse") : null
                      }
                      name="cif"
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
                {showAllForm &&
                <Grid container spacing={3}>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={cliente.razonSocial || ""}
                      label={i18n.t("cliente.razonSocial")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !canEdit,
                      }}
                      error={errors["RazonSocial"] !== undefined}
                      helperText={
                        errors["RazonSocial"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="razonSocial"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={cliente.direccion || ""}
                      label={i18n.t("cliente.direccion")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !canEdit,
                      }}
                      error={errors["Direccion"] !== undefined}
                      helperText={
                        errors["Direccion"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="direccion"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={cliente.cp || ""}
                      label={i18n.t("cliente.cp")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !canEdit,
                      }}
                      error={errors["CP"] !== undefined}
                      helperText={
                        errors["CP"] ? i18n.t("textField.required") : null
                      }
                      name="cp"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={cliente.poblacion || ""}
                      label={i18n.t("cliente.poblacion")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !canEdit,
                      }}
                      error={errors["Poblacion"] !== undefined}
                      helperText={
                        errors["Poblacion"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="poblacion"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={cliente.provincia || ""}
                      label={i18n.t("cliente.provincia")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: !canEdit,
                      }}
                      error={errors["Provincia"] !== undefined}
                      helperText={
                        errors["Provincia"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="provincia"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <FormControl fullWidth required>
                      <InputLabel shrink id="pais">
                        {i18n.t("cliente.pais")}
                      </InputLabel>
                      <Select
                        labelId="pais"
                        value={cliente.pais || ""}
                        inputProps={{
                          readOnly: !canEdit,
                        }}
                        error={errors["Pais"] !== undefined}
                        required
                        name="pais"
                        onChange={handleInputChange}
                      >
                        <MenuItem value={"España"}>
                          {i18n.t("paises.españa")}
                        </MenuItem>
                        <MenuItem value={"Francia"}>
                          {i18n.t("paises.francia")}
                        </MenuItem>
                        <MenuItem value={"Italia"}>
                          {i18n.t("paises.italia")}
                        </MenuItem>
                        <MenuItem value={"Portugal"}>
                          {i18n.t("paises.portugal")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  { (keyuser && !id) && 
                  <Grid item xs={colWith}>
                    <FormControl fullWidth required>
                      <InputLabel shrink id="partnerId">
                        {i18n.t("cliente.partner")}
                      </InputLabel>
                      <Select
                        labelId="partnerId"
                        value={cliente.partnerId || ""}
                        inputProps={{
                          readOnly: (!canEdit && id),
                        }}
                        error={errors["PartnerId"] !== undefined}
                        required
                        name="partnerId"
                        onChange={handleInputChange}
                      >
                      {partnersList.map((element) => (
                        <MenuItem key={element.id.toString()} value={element.id}>
                          {element.razonSocial}
                        </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  }
                </Grid>
                }
              </form>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton
                aria-label="back"
                onClick={goBack}
              >
                <ArrowBack />
              </IconButton>
              <>
              {((canEdit && id) || !id) && (
                !showAllForm ? (
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Send />}
                  onClick={() => verificarCIF()}
                >
                  {i18n.t("cliente.verificarCIF")}
                </Button>
                )
                : (
                  <IconButton
                  aria-label="save"
                  onClick={() => (id ? updateCliente() : addCliente())}
                  >
                    <Save />
                  </IconButton>
                )
              )}
              {(isGestor && id) && (
                <Button
                  aria-label="delete"
                  className="text-danger"
                  style={{ marginLeft: "auto" }}
                  onClick={() => deleteDialog.current.open()}
                >
                  {i18n.t("cliente.btnBorrar")}
                  <Delete className="ml-2" />
                </Button>
              )}
              </>
            </CardActions>
          </Card>
        </RelativeBackdrop>
      </TabPanel>
      {id && (
        <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={1}>
          <RelativeBackdrop hidden={!loading}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} xl={3}>
                <SearchField
                  inputBaseProps={{
                    placeholder: i18n.t("common.search"),
                    value: plantasSearch,
                    onChange: (event) => setPlantasSearch(event.target.value),
                  }}
                  goBack={goBack}
                  endButton={ isGestor && (
                      <Button
                        style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
                        onClick={() =>
                          history.push(`/clientes/${id}/nueva-planta`)
                        }
                      >
                        <Add />
                        {i18n.t("common.new.femenine")}
                      </Button>
                    )
                  }
                />
              </Grid>
              <Grid container item spacing={2}>
                {filter(cliente.plantas, plantasSearch).map((planta) => {
                  planta.cliente = cliente;
                  return (
                    <Grid item xs={6} md={4} xl={3} key={planta.id}>
                      <CardPlantClient
                        title={planta.denominacion}
                        subtitle={planta.cliente.razonSocial}
                        contents={[
                          { key: "direccion", value: planta.direccion },
                          { key: "cp", value: planta.cp },
                          { key: "poblacion", value: planta.poblacion },
                          { key: "provincia", value: planta.provincia },
                          { key: "pais", value: planta.pais },
                        ]}
                        type="planta"
                        style={{
                          background: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('${PlantaCard}')`,
                        }}
                        onClick={() => {
                          history.push(`/plantas/${planta.id}`);
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </RelativeBackdrop>
        </TabPanel>
      )}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("cliente.alertDialog.title")}
        text={i18n.t("cliente.alertDialog.text")}
        cancelText={i18n.t("cliente.alertDialog.cancelText")}
        confirmText={i18n.t("cliente.alertDialog.confirmText")}
        confirmAction={() => deleteCliente()}
      />
    </>
  );
}
