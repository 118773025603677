import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";

/**
 * @param {{ activeFilter: number; setActiveFilter: function(number):void;  equipmetSearch: function(String, Number):void; setValue: function(string):void}} props
 */
export default function Select_FrequencyConvertersSearchEngineFilter(props) {
  const {activeFilter, setActiveFilter, equipmetSearch, setValue} = props;

  return(
    <FormControl fullWidth variant="filled">
      <InputLabel>{i18n.t("planta.filtrarEquipos.label")}</InputLabel>
      <Select
        value={activeFilter}
        onChange={(e) => {
          const valueNumber = Number.parseInt(e.target.value.toString());
          if(Number(valueNumber) === valueNumber) {
            setActiveFilter(valueNumber);
            equipmetSearch("", valueNumber);
            setValue("");
          }
        }}
        classes={{ root: useStyles().selectRoot }}
      >
        <MenuItem value={0}>
          {i18n.t("planta.filtrarEquipos.todos")}
        </MenuItem>
        <MenuItem value={1}>
          {i18n.t("planta.filtrarEquipos.activos")}
        </MenuItem>
        <MenuItem value={2}>
          {i18n.t("planta.filtrarEquipos.inactivos")}
        </MenuItem>
        <MenuItem value={3}>
          {i18n.t("planta.filtrarEquipos.provisionales")}
        </MenuItem>
        <MenuItem value={4}>
          {i18n.t("planta.filtrarEquipos.obsoletos")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    backgroundColor:'white',
    //...other styles
    '&:focus':{
      backgroundColor:'white'
    }
  }
}));
