import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import {
  Add,
  ArrowBack,
  Delete,
  Group,
  PeopleOutline,
  Save,
} from "@material-ui/icons";
import React, { useEffect, useRef, useState, useCallback } from "react";
import AlertDialog from "../../AlertDialog";
import RelativeBackdrop from "../../RelativeBackdrop";
import SearchField from "../../molecule/SearchField/SearchField";
import TabPanel from "../../TabPanel";
import i18n from "../../../locales/i18n";
import { PartnersAPI } from "../../../services/API";
import { filter } from "../../../helpers/Filter";
import AuthService from "../../../services/AuthService";
import CardPlantClient from "../../molecule/cardPlantClient/cardPlantClient";
// @ts-ignore
import ClienteCard from "../../../assets/images/cliente-card.jpg";
import { capitalizeFirstLetter } from "../../../helpers/Common";
import PartnerBase from "../../../models/partnerBase";
import Partner from "../../../models/partaner";

/**
 * Detalle del partner
 * @Component
 * @param {Object} props - parametros del componente
 * @param {Object} props.match
 * @param {Object} props.history
 * @param {boolean} props.keyuser
 * @param {boolean} props.readOnly
 * @param  {function} props.setNavBarTitle
 */
export default function PartnerDetails({
  match,
  history,
  keyuser,
  readOnly,
  setNavBarTitle,
}) {
  /** @type {number} */ const id = match.params.id;
  /** @type {boolean} */ const isGestor = AuthService.isGestorFromPartner(id);

  const [partner, setPartner] = useState(new Partner({}));
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [clientesSearch, setClientesSearch] = useState("");
  const [errors, setErrors] = useState({});
  const [listClientsFilter, setListClientsFilter] = useState([]);

  const deleteDialog = useRef(null);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setPartner((prevPartner) => ({ ...prevPartner, [name]: value }));
    const errorName = name ? capitalizeFirstLetter(name) : null;
    if (errors[errorName] || errors["CIF_Unique"]) {
      if (errors["CIF_Unique"]) {
        setErrors((e) => ({ ...errors, CIF_Unique: undefined }));
      } else {
        setErrors((e) => ({ ...errors, [errorName]: undefined }));
      }
    }
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    window["AltidVars"] = {
      ...window["AltidVars"],
      Tabs_PartnersDetails: newValue,
    };
  };

  const getPartner = useCallback(async () => {
    setLoading(true);

    PartnersAPI.get(id.toString())
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setPartner(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [id]);

  const addPartner = async () => {
    setLoading(true);

    PartnersAPI.add(partner)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((data) => {
        AuthService.addTempAccessToClient(data.id);
        history.replace(`/partners/${data.id}`);
        document.getElementById("main-content").scrollTop = 0;
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updatePartner = async () => {
    setLoading(true);

    const thePartnerToUpdate = new PartnerBase(partner);
    PartnersAPI.update(thePartnerToUpdate)
      .then(async (response) => {
        if (response.ok) {
          if (!partner.razonSocial) {
            setNavBarTitle(
              i18n.t("partner.titulo") + " " + partner.razonSocial
            );
          }
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deletePartner = async () => {
    setLoading(true);

    PartnersAPI.remove(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const goBack = () => {
    history.replace("/partners");
  };

  useEffect(() => {
    document.getElementById("main-content").scrollTop = 0;
  }, []);

  useEffect(() => {
    if (partner.razonSocial) {
      setNavBarTitle(i18n.t("partner.titulo") + " " + partner.razonSocial);
    } else {
      setNavBarTitle(i18n.t("partner.titulo"));
    }
  }, [setNavBarTitle, partner.razonSocial]);

  useEffect(() => {
    if (
      id &&
      window["AltidVars"] !== undefined &&
      window["AltidVars"].Tabs_PartnerDetails !== undefined
    ) {
      setTabValue(window["AltidVars"].Tabs_PartnerDetails);
    }

    if (id) {
      getPartner();
    }
  }, [id, getPartner]);

  useEffect(() => {
    if (partner.partnerClientes) {
      setListClientsFilter(
        filter(
          partner.partnerClientes.map((p) => p.cliente),
          clientesSearch
        )
      );
    }
  }, [partner.partnerClientes, clientesSearch]);

  const colWith = 4;

  return (
    <>
      <Paper square>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          centered
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab
            label={i18n.t("partner.tituloCard")}
            icon={<PeopleOutline />}
            id="simple-tab-0"
          />
          {id && (
            <Tab
              label={i18n.t("cliente.tituloCardLista")}
              icon={<Group />}
              id="simple-tab-1"
            />
          )}
        </Tabs>
      </Paper>
      <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={0}>
        <RelativeBackdrop hidden={!loading}>
          <Card>
            <CardHeader
              title={i18n.t("partner.tituloCard")}
              subheader={partner.razonSocial}
              avatar={
                <Avatar
                  aria-label="avatar"
                  style={{ backgroundColor: "#f50057" }}
                >
                  {partner.avatar}
                </Avatar>
              }
            />
            <CardContent>
              <form autoComplete="off">
                <Grid container spacing={3}>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={partner.razonSocial || ""}
                      label={i18n.t("partner.razonSocial")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      error={errors["RazonSocial"] !== undefined}
                      helperText={
                        errors["RazonSocial"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="razonSocial"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  {keyuser && (
                    <Grid item xs={colWith}>
                      <TextField
                        required
                        fullWidth
                        value={partner.cif || ""}
                        label={i18n.t("partner.cif")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          readOnly: readOnly,
                        }}
                        error={
                          errors["CIF"] !== undefined ||
                          errors["CIF_Unique"] !== undefined
                        }
                        helperText={
                          errors["CIF"]
                            ? i18n.t("textField.required")
                            : errors["CIF_Unique"]
                            ? i18n.t("partner.cifInUse")
                            : null
                        }
                        name="cif"
                        onChange={handleInputChange}
                      />
                    </Grid>
                  )}

                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={partner.direccion || ""}
                      label={i18n.t("partner.direccion")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      error={errors["Direccion"] !== undefined}
                      helperText={
                        errors["Direccion"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="direccion"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={partner.cp || ""}
                      label={i18n.t("partner.cp")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      error={errors["CP"] !== undefined}
                      helperText={
                        errors["CP"] ? i18n.t("textField.required") : null
                      }
                      name="cp"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={partner.poblacion || ""}
                      label={i18n.t("partner.poblacion")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      error={errors["Poblacion"] !== undefined}
                      helperText={
                        errors["Poblacion"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="poblacion"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      required
                      fullWidth
                      value={partner.provincia || ""}
                      label={i18n.t("partner.provincia")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      error={errors["Provincia"] !== undefined}
                      helperText={
                        errors["Provincia"]
                          ? i18n.t("textField.required")
                          : null
                      }
                      name="provincia"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={colWith}>
                    <FormControl fullWidth required>
                      <InputLabel shrink id="pais">
                        {i18n.t("partner.pais")}
                      </InputLabel>
                      <Select
                        labelId="pais"
                        value={partner.pais || ""}
                        inputProps={{
                          readOnly: readOnly,
                        }}
                        error={errors["Pais"] !== undefined}
                        required
                        name="pais"
                        onChange={handleInputChange}
                      >
                        <MenuItem value={"España"}>
                          {i18n.t("paises.españa")}
                        </MenuItem>
                        <MenuItem value={"Francia"}>
                          {i18n.t("paises.francia")}
                        </MenuItem>
                        <MenuItem value={"Italia"}>
                          {i18n.t("paises.italia")}
                        </MenuItem>
                        <MenuItem value={"Portugal"}>
                          {i18n.t("paises.portugal")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={colWith}>
                    <TextField
                      fullWidth
                      value={partner.email || ""}
                      label={i18n.t("partner.email")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        readOnly: readOnly,
                      }}
                      error={errors["Email"] !== undefined}
                      helperText={
                        errors["Email"] ? i18n.t("textField.required") : null
                      }
                      name="email"
                      onChange={handleInputChange}
                    />
                  </Grid>
                  {keyuser && (
                    <Grid item xs={colWith}>
                      <TextField
                        fullWidth
                        value={partner.nombreAbreviado || ""}
                        label={i18n.t("partner.nombreAbreviado")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          readOnly: readOnly,
                        }}
                        error={errors["NombreAbreviado"] !== undefined}
                        helperText={
                          errors["NombreAbreviado"]
                            ? i18n.t("textField.required")
                            : null
                        }
                        name="nombreAbreviado"
                        onChange={handleInputChange}
                      />
                    </Grid>
                  )}
                </Grid>
              </form>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="back" onClick={() => goBack()}>
                <ArrowBack />
              </IconButton>
              {!readOnly && (
                <>
                  <IconButton
                    aria-label="save"
                    onClick={() => (id ? updatePartner() : addPartner())}
                  >
                    <Save />
                  </IconButton>
                  {keyuser && id && (
                    <Button
                      aria-label="delete"
                      className="text-danger"
                      style={{ marginLeft: "auto" }}
                      onClick={() => deleteDialog.current.open()}
                    >
                      {i18n.t("partner.btnBorrar")}
                      <Delete className="ml-2" />
                    </Button>
                  )}
                </>
              )}
            </CardActions>
          </Card>
        </RelativeBackdrop>
      </TabPanel>
      {id && (
        <TabPanel className="TabPanelOnlyTopPadding" value={tabValue} index={1}>
          <RelativeBackdrop hidden={!loading}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} xl={3}>
                <SearchField
                  inputBaseProps={{
                    placeholder: i18n.t("common.search"),
                    value: clientesSearch,
                    onChange: (event) => setClientesSearch(event.target.value),
                  }}
                  endButton={
                    (isGestor || keyuser) && (
                      <Button
                        style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
                        onClick={() =>
                          history.push(`/partners/${id}/nuevo-cliente`)
                        }
                      >
                        <Add />
                        {i18n.t("common.new.masculine")}
                      </Button>
                    )
                  }
                  goBack={goBack}
                />
              </Grid>
              <Grid container item spacing={2}>
                {listClientsFilter.map((cliente) => {
                  return (
                    <Grid item xs={6} md={4} xl={3} key={cliente.id}>
                      <CardPlantClient
                        title={cliente.razonSocial}
                        subtitle={partner.razonSocial}
                        contents={[
                          { key: "direccion", value: cliente.direccion },
                          { key: "cp", value: cliente.cp },
                          { key: "poblacion", value: cliente.poblacion },
                          { key: "provincia", value: cliente.provincia },
                          { key: "pais", value: cliente.pais },
                        ]}
                        type="cliente"
                        style={{
                          background: `linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('${ClienteCard}')`,
                        }}
                        onClick={() => {
                          history.push(
                            `/partners/${id}/clientes/${cliente.id}`
                          );
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </RelativeBackdrop>
        </TabPanel>
      )}
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("partner.alertDialog.title")}
        text={i18n.t("partner.alertDialog.text")}
        cancelText={i18n.t("partner.alertDialog.cancelText")}
        confirmText={i18n.t("partner.alertDialog.confirmText")}
        confirmAction={() => deletePartner()}
      />
    </>
  );
}
