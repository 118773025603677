// @ts-nocheck
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import i18n from "../../../locales/i18n";
import { Typography } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";

export default function EquipoRetrofitTable(props) {
  const { keyuser, loading, setLoading, equipo, setEquipo, sustituciones } =
    props;
  const [retrofit, setRetrofit] = useState([]);

  const addStock = async (item) => {
    setLoading(true);
    if (!retrofit.some((x) => x.pn === item.pn)) {
      //setRetrofit([...retrofit, item]);
      let eqRetro = [...retrofit, item];
      setEquipo({ ...equipo, retrofit: retrofitLikeString(eqRetro) });
    }
    setLoading(false);
  };

  const updateStock = async (itemNew, itemOld) => {
    setLoading(true);
    if (!retrofit.some((x) => x.pn === itemNew.pn)) {
      let eqRetro = [...retrofit];
      eqRetro = eqRetro.map((e) => (e.pn === itemOld.pn ? itemNew : e));
      //setRetrofit(eqRetro);
      setEquipo({ ...equipo, retrofit: retrofitLikeString(eqRetro) });
    }
    setLoading(false);
  };

  const deleteStock = async (item) => {
    setLoading(true);
    //setRetrofit(retrofit.filter((x) => x.pn !== item.pn));
    let eqRetro = [...retrofit];
    eqRetro = eqRetro.filter((x) => x.pn !== item.pn);
    setEquipo({ ...equipo, retrofit: retrofitLikeString(eqRetro) });
    setLoading(false);
  };

  const retrofitLikeString = (arrayObjects) => {
    return arrayObjects.map((x) => (x.pn ? x.pn : "")).join(";");
  };

  useEffect(() => {
    if (equipo && equipo.retrofit) {
      setRetrofit(equipo.retrofit.split(";").map((x) => ({ pn: x })));
    }
  }, [equipo, equipo.retrofit]);

  return (
    <MaterialTable
      style={{ width: "100%" }}
      title={i18n.t("equipo.retrofitTable.title")}
      // @ts-ignore
      localization={i18n.t("dataTableProps")}
      isLoading={loading}
      data={retrofit}
      options={{
        addRowPosition: "first",
        actionsColumnIndex: -1,
        sorting: true,
        showTitle: true,
        paging: false,
        search: false,
        searchFieldAlignment: "left",
      }}
      columns={[
        {
          field: "pn",
          title: i18n.t("equipo.retrofitTable.reference"),
          validate: (rowData) => {
            var isEmpty = !rowData.pn;
            var isValid = !isEmpty;
            return {
              isValid: isValid,
              helperText: isEmpty
                ? "Requerido"
                : !isValid
                ? "Ya existe esta referencia"
                : "",
            };
          },
        },
      ]}
      detailPanel={[
        {
          icon: () => <ArrowForwardIos />,
          tooltip: i18n.t("equipo.sustitutos.retrofit"),
          render: (rowData) => {
            return (
              <MaterialTable
                // @ts-ignore
                localization={i18n.t("dataTableProps")}
                data={sustituciones.filter((data) => data.pn === rowData.pn)}
                style={{ backgroundColor: "whitesmoke" }}
                columns={[
                  {
                    title: i18n.t("equipo.retrofitTable.reference"),
                    field: "pn",
                  },
                  {
                    title: "TC / TYPE",
                    field: "tc",
                  },
                  {
                    title: "SN",
                    field: "sn",
                    render: (rowData) => (
                      <a
                        href={`/plantas/${rowData.zona.plantaId}/zonas/${rowData.zonaId}/equipos/${rowData.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={rowData.sn}
                      >
                        <Typography>{rowData.sn}</Typography>
                      </a>
                    ),
                  },
                  {
                    title: i18n.t("equipo.sustitutos.found_in"),
                    field: "zona.descripcion",
                  },
                ]}
                options={{
                  showTitle: false,
                  search: false,
                  paging: false,
                  headerStyle: { backgroundColor: "whitesmoke" },
                }}
                components={{
                  Toolbar: (props) => <></>,
                }}
              />
            );
          },
        },
      ]}
      editable={
        keyuser
          ? {
              onRowAdd: (newData) => addStock(newData),
              onRowUpdate: (newData, oldData) => updateStock(newData, oldData),
              onRowDelete: (oldData) => deleteStock(oldData),
            }
          : {}
      }
    />
  );
}
