import { PNTCsAPI } from "../services/API";

export async function getEquipoFromVisionString(visionString, equipo) {
  visionString = visionString || "";
  visionString = visionString.toUpperCase();

  let OCRParams = {
    typeIndex: 0,
    tcIndex: 0,
    pnIndex: 0,
    snIndex: 0,
    inIndex: 0,
    outIndex: 0,
    tempIndex: 0,
    tempLength: 0,
    typeNextReturnIndex: 0,
    tcNextReturnIndex: 0,
    snNextReturnIndex: 0,
    inNextReturnIndex: 0,
    outNextReturnIndex: 0,
  };

  let ocrEquipo = {
    ...equipo,
    sn: "",
    tc: "",
    pn: "",
    in: "",
    out: "",
    tempAmbiental: "",
    chassis: "",
  };

  visionString = replaceVision(visionString);

  const driveType = getDriveType(visionString);
  console.log(driveType);

  switch (driveType) {
    case "FCM":
      console.log("FCM");
      OCRParams = inittializeParam(OCRParams, visionString);
      OCRParams = getIndex(OCRParams, visionString);
      OCRParams = getTempIndex(OCRParams, visionString);
      OCRParams = getInIndex(OCRParams, visionString);
      ocrEquipo = getTc(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getPnFCM(OCRParams, ocrEquipo, visionString);
      //ocrEquipo = getMarca(ocrEquipo, visionString);
      ocrEquipo.marca = "VLT";
      ocrEquipo = await getPnAPI(ocrEquipo);
      //ocrEquipo = getSnAnyo(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getSnFCM(ocrEquipo, visionString);
      ocrEquipo = getIn(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getOut(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getTemp(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getChasis(ocrEquipo, visionString);
      break;

    case "VLT2000":
      console.log("VLT2000");
      OCRParams = inittializeParam(OCRParams, visionString);
      OCRParams = getIndex(OCRParams, visionString);
      OCRParams = getTempIndex(OCRParams, visionString);
      OCRParams = getInIndex(OCRParams, visionString);
      ocrEquipo = getPnVLT2000(ocrEquipo, visionString);
      ocrEquipo = getTcVLT2000(ocrEquipo);
      ocrEquipo.marca = "VLT";
      ocrEquipo = await getPnAPI(ocrEquipo);
      ocrEquipo = getSnVLT2000(ocrEquipo, visionString);
      ocrEquipo = getIn(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getOut(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getTemp(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getChasis(ocrEquipo, visionString);
      break;

    default:
      console.log("default");
      OCRParams = inittializeParam(OCRParams, visionString);
      OCRParams = getIndex(OCRParams, visionString);
      OCRParams = getTempIndex(OCRParams, visionString);
      OCRParams = getInIndex(OCRParams, visionString);
      ocrEquipo = getTc(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getPn(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getMarca(ocrEquipo, visionString);
      ocrEquipo = await getPnAPI(ocrEquipo);
      ocrEquipo = getSnAnyo(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getIn(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getOut(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getTemp(OCRParams, ocrEquipo, visionString);
      ocrEquipo = getChasis(ocrEquipo, visionString);
      if (ocrEquipo.marca === "Vacon" && !ocrEquipo.pn) {
        ocrEquipo.pn = ocrEquipo.tc;
      }
      break;
  }

  console.log("\nCHECK:");
  console.log(visionString);
  console.log("Marca " + ocrEquipo.marca);
  console.log("TC " + OCRParams.tcIndex + " -> " + ocrEquipo.tc);
  console.log("PN " + OCRParams.pnIndex + " -> " + ocrEquipo.pn);
  console.log("SN " + OCRParams.snIndex + " -> " + ocrEquipo.sn);
  console.log("IN " + OCRParams.inIndex + " -> " + ocrEquipo.in);
  console.log("OUT " + OCRParams.outIndex + " -> " + ocrEquipo.out);
  console.log(
    "Temp: " + OCRParams.tempIndex + " -> " + ocrEquipo.tempAmbiental
  );
  console.log("Chassis: -> " + ocrEquipo.chassis);

  // Type checking
  if (typeof ocrEquipo.sn !== "string") {
    ocrEquipo.sn = null;
  }
  if (typeof ocrEquipo.tc !== "string") {
    ocrEquipo.tc = null;
  }
  if (typeof ocrEquipo.pn !== "string") {
    ocrEquipo.pn = null;
  }
  if (typeof ocrEquipo.in !== "string") {
    ocrEquipo.in = null;
  }
  if (typeof ocrEquipo.out !== "string") {
    ocrEquipo.out = null;
  }
  if (typeof ocrEquipo.tempAmbiental !== "number") {
    ocrEquipo.tempAmbiental = null;
  }
  if (typeof ocrEquipo.chassis !== "string") {
    ocrEquipo.chassis = null;
  }
  if (typeof ocrEquipo.semanaFabricacion !== "number") {
    ocrEquipo.semanaFabricacion = null;
  }
  if (typeof ocrEquipo.añoFabricacion !== "number") {
    ocrEquipo.añoFabricacion = null;
  }
  if (typeof ocrEquipo.marca !== "string") {
    ocrEquipo.marca = null;
  }

  if (ocrEquipo.pn === "") {
    ocrEquipo.pn = "PROVISIONAL";
  }

  if (ocrEquipo.tc === "") {
    ocrEquipo.tc = "PROVISIONAL";
  }

  return ocrEquipo;
}

function inittializeParam(OCRParams, visionString) {
  OCRParams.typeIndex = visionString.search("TYPE:");
  OCRParams.tcIndex = visionString.search("T/C:");
  OCRParams.pnIndex = visionString.search("P/N:");
  OCRParams.snIndex = visionString.search("S/N:");
  OCRParams.inIndex = visionString.search("IN:");
  OCRParams.outIndex = visionString.search("OUT:");
  OCRParams.tempIndex = visionString.search("TAMB MAX.");
  OCRParams.tempLength = "TAMB MAX.".length;
  OCRParams.typeNextReturnIndex = 0;
  OCRParams.tcNextReturnIndex = 0;
  OCRParams.snNextReturnIndex = 0;
  OCRParams.inNextReturnIndex = 0;
  OCRParams.outNextReturnIndex = 0;

  return OCRParams;
}

function replaceVision(visionString) {
  visionString = visionString
    .replace("UCT", "VLT")
    .replace("VCT", "VLT")
    .replace("ULT", "VLT")
    .replace("UIT", "VLT")
    .replace("OLT", "VLT")
    .replace("OIT", "VLT")
    .replace("0LT", "VLT")
    .replace("0IT", "VLT")
    .replace("VIT", "VLT")
    .replace("F0-", "FC-")
    .replace("FO-", "FC-")
    .replace("FC0", "FCD")
    .replace("FCO", "FCD")
    .replace("FCN", "FCM")
    .replace("HCM", "FCM")
    .replace("MCO", "MCD")
    .replace("MC0", "MCD")
    .replace("MOO", "MCD")
    .replace("M00", "MCD")
    .replace("M0O", "MCD")
    .replace("MO0", "MCD")
    .replace("VAC0N", "VACON")
    .replace("VA0CN", "VACON")
    .replace("VA00N", "VACON")
    .replace("VACCN", "VACON");
  return visionString;
}

function getDriveType(visionString) {
  visionString = visionString.replace(/ /g, "");
  if (visionString.match(/(FCM)([0-9])/g)) {
    return "FCM";
  }
  if (visionString.match(/VLT.*TYPE.*20/gm)) {
    return "VLT2000";
  }
  if (visionString.match(/VLT.*20.*PACK/gm)) {
    return 'VLT2000';
  }
}

function getIndex(OCRParams, visionString) {
  OCRParams.typeIndex =
    OCRParams.typeIndex > -1
      ? OCRParams.typeIndex
      : visionString.search("TYPE"); // Type - TC
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("T/C:"); // TC
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("T/C");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("TIC:");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("TIC");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CNO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CHO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CAO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CMO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CN.");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CN");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("NO");
  OCRParams.tcIndex =
    OCRParams.tcIndex > -1 ? OCRParams.tcIndex : visionString.search("CO");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("P/N:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("P/N"); // PN
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("PIN:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("PIN");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("C/N:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("C/N");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("CIN:");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("CIN");
  OCRParams.pnIndex =
    OCRParams.pnIndex > -1 ? OCRParams.pnIndex : visionString.search("CN:");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("S/N:"); // SN
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("S/NO");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("S/N");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("8/N:");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("8/N");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("SIN:");
  OCRParams.snIndex =
    OCRParams.snIndex > -1 ? OCRParams.snIndex : visionString.search("SIN");
  OCRParams.inIndex =
    OCRParams.inIndex > -1 ? OCRParams.inIndex : visionString.search("IN"); // IN
  OCRParams.outIndex =
    OCRParams.outIndex > -1 ? OCRParams.outIndex : visionString.search("OUT"); // OUT
  OCRParams.outIndex =
    OCRParams.outIndex > -1 ? OCRParams.outIndex : visionString.search("QUT");

  OCRParams.typeNextReturnIndex = visionString.indexOf(
    "\n",
    OCRParams.typeIndex
  );
  OCRParams.tcNextReturnIndex = visionString.indexOf("\n", OCRParams.tcIndex);
  OCRParams.snNextReturnIndex = visionString.indexOf("\n", OCRParams.snIndex);
  OCRParams.inNextReturnIndex = visionString.indexOf("\n", OCRParams.inIndex);
  OCRParams.outNextReturnIndex = visionString.indexOf("\n", OCRParams.outIndex);

  return OCRParams;
}

function getTempIndex(OCRParams, visionString) {
  if (OCRParams.tempIndex === -1) {
    OCRParams.tempIndex = visionString.search("TAMB MAX");
    OCRParams.tempLength = "TAMB MAX".length;
  }

  if (OCRParams.tempIndex === -1) {
    OCRParams.tempIndex = visionString.search("TAMB");
    OCRParams.tempLength = "TAMB".length;
  }

  if (OCRParams.tempIndex === -1) {
    OCRParams.tempIndex = visionString.search("MAX.");
    OCRParams.tempLength = "MAX.".length;
  }

  return OCRParams;
}

function getInIndex(OCRParams, visionString) {
  const bINConditions = () =>
    OCRParams.inIndex > -1 &&
    (visionString.substr(OCRParams.inIndex - 1, 3) === "UIN" ||
      visionString.substr(OCRParams.inIndex - 1, 3) === "VIN" ||
      OCRParams.inIndex <= OCRParams.tcIndex ||
      OCRParams.inIndex <= OCRParams.snIndex ||
      OCRParams.inIndex <= OCRParams.pnIndex);
  if (!bINConditions) {
    const indexes = [...visionString.matchAll(new RegExp("IN", "gi"))].map(
      (a) => a.index
    );
    if (indexes.length >= 2 && indexes[0] === OCRParams.inIndex) {
      OCRParams.inIndex = indexes[1];
    } else {
      OCRParams.inIndex = -1;
    }
  }
  if (bINConditions) {
    if (
      OCRParams.inIndex > OCRParams.pnIndex &&
      OCRParams.inIndex <= OCRParams.pnIndex + 5
    ) {
      OCRParams.inIndex = visionString.indexOf(
        "IN:",
        OCRParams.inNextReturnIndex
      );
      OCRParams.inIndex =
        OCRParams.inIndex > -1
          ? OCRParams.inIndex
          : visionString.indexOf("IN", OCRParams.pnIndex + 14);
      OCRParams.inNextReturnIndex = visionString.indexOf(
        "\n",
        OCRParams.inIndex
      );
    }

    if (
      OCRParams.inIndex >= OCRParams.snIndex &&
      OCRParams.inIndex <= OCRParams.snIndex + 5
    ) {
      OCRParams.inIndex = visionString.indexOf(
        "IN:",
        OCRParams.inNextReturnIndex
      );
      OCRParams.inIndex =
        OCRParams.inIndex > -1
          ? OCRParams.inIndex
          : visionString.indexOf("IN", OCRParams.snIndex + 16);
      OCRParams.inNextReturnIndex = visionString.indexOf(
        "\n",
        OCRParams.inIndex
      );
    }
  }

  if (
    OCRParams.inIndex > -1 &&
    OCRParams.snNextReturnIndex >= OCRParams.inNextReturnIndex
  ) {
    OCRParams.snNextReturnIndex = visionString.indexOf(
      " ",
      OCRParams.snIndex + 6
    );
  }

  return OCRParams;
}

function getTc(OCRParams, ocrEquipo, visionString) {
  var tcMatch = visionString.match(
    // eslint-disable-next-line no-useless-escape
    /(VLT|VACON|FCM|FC|FC-|MCD|NXP|NXL|FCD|FCP|MCD|MCD5-|MCD6-)([0-9]){3,}([a-zA-Z0-9-\+]+\s)*[a-zA-Z0-9-\+]+/g
  );

  if (tcMatch !== null) {
    ocrEquipo.tc = tcMatch[0].replace(/O/gi, "0").replace(/ /g, "");
  } else if (OCRParams.tcIndex > -1) {
    ocrEquipo.tc = visionString
      .substring(OCRParams.tcIndex + 4, OCRParams.tcNextReturnIndex)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("VL1", "VLT")
      .trim();
  } else if (OCRParams.typeIndex > -1) {
    ocrEquipo.tc = visionString
      .substring(OCRParams.typeIndex + 4, OCRParams.typeNextReturnIndex)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("VL1", "VLT")
      .trim();
  }

  return ocrEquipo;
}

function getPn(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.pnIndex > -1) {
    ocrEquipo.pn = visionString
      .substr(OCRParams.pnIndex + 4, 10)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("S/N", "")
      .replace("SIN", "")
      .replace("S/", "")
      .replace("SI", "")
      .substr(0, 8)
      .trim();

    if (ocrEquipo.pn.length >= 4) {
      if (ocrEquipo.pn[3] === "2" || ocrEquipo.pn[3] === "7") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "Z" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "8") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "B" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "0") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "U" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "6") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "G" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "1") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "H" + ocrEquipo.pn.substr(3 + 1);
      }
    }
  }

  return ocrEquipo;
}

function getMarca(ocrEquipo, visionString) {
  if (
    ocrEquipo.tc &&
    (ocrEquipo.tc.includes("FC") || ocrEquipo.tc.includes("VLT"))
  ) {
    ocrEquipo.marca = "VLT";
  }

  if (
    visionString.includes("VACON") ||
    (ocrEquipo.tc && ocrEquipo.tc.includes("NXP"))
  ) {
    ocrEquipo.marca = "Vacon";
  }

  return ocrEquipo;
}

function getSnAnyo(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.snIndex > -1) {
    let sn = visionString
      .substring(OCRParams.snIndex + 4, OCRParams.snNextReturnIndex)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .trim();

    if (
      ocrEquipo.tc &&
      (ocrEquipo.tc.startsWith("FC-051") ||
        ocrEquipo.tc.startsWith("FC-102") ||
        ocrEquipo.tc.startsWith("FC-202") ||
        ocrEquipo.tc.startsWith("FC-301") ||
        ocrEquipo.tc.startsWith("FC-302") ||
        ocrEquipo.tc.startsWith("FCD302") ||
        ocrEquipo.tc.startsWith("VLT")) &&
      (sn[sn.length - 4] === "0" ||
        sn[sn.length - 4] === "6" ||
        sn[sn.length - 4] === "9" ||
        sn[sn.length - 4] === "4")
    ) {
      sn = sn.substr(0, sn.length - 4) + "G" + sn.substr(sn.length - 4 + 1);
    }

    ocrEquipo.sn = sn;
    var snMatch = sn.match("[a-zA-Z]");
    if (snMatch !== null) {
      var letterIndex = snMatch.index;

      var semana = sn.substr(letterIndex + 1, 2);
      var año = sn.substr(letterIndex + 3, 1);
      var añoActual = new Date().getFullYear();
      var añoActualModificado = añoActual.toString().replace(/.$/, año);

      var añosPosibles = [
        parseInt(añoActualModificado, 10) - 10,
        parseInt(añoActualModificado, 10),
      ];

      var sortedAños = añosPosibles.sort((a, b) => {
        return Math.abs(añoActual - a) - Math.abs(añoActual - b);
      });

      var añoMasProximo = sortedAños[0];

      ocrEquipo.semanaFabricacion = semana;
      if (añoMasProximo.toString().length === 4) {
        ocrEquipo.añoFabricacion =
          añoMasProximo < añoActual ? añoMasProximo : sortedAños[1];
      }
    }
  }

  return ocrEquipo;
}

function getIn(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.inIndex > -1) {
    ocrEquipo.in = visionString
      .substring(OCRParams.inIndex + 3, OCRParams.inNextReturnIndex)
      .replace(/\n/g, "")
      .replace("UT", "")
      .replace("UT:", "")
      .replace("PUT", "")
      .replace("PUT:", "")
      .replace("RATINGS", "")
      .replace("RATINGS:", "")
      .replace(":", "")
      .replace(": ", "")
      .trim();
  }

  return ocrEquipo;
}

function getOut(OCRParams, ocrEquipo, visionString) {
  console.log("out");
  if (OCRParams.outIndex > -1) {
    ocrEquipo.out = visionString
      .substring(OCRParams.outIndex + 4, OCRParams.outNextReturnIndex)
      .replace(/\n/g, "")
      .replace("UT", "")
      .replace("UT:", "")
      .replace("PUT", "")
      .replace("PUT:", "")
      .replace("RATINGS", "")
      .replace("RATINGS:", "")
      .replace(":", "")
      .replace(": ", "")
      .trim();
  }

  return ocrEquipo;
}

function getTemp(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.tempIndex > -1) {
    ocrEquipo.tempAmbiental = visionString
      .substr(OCRParams.tempIndex + OCRParams.tempLength, 4)
      .replace(/O/gi, "0")
      .replace(/\D/g, "")
      .trim();
  }

  return ocrEquipo;
}

function getChasis(ocrEquipo, visionString) {
  var chassisMatch = visionString
    .replace(/O/gi, "0")
    .match(new RegExp("IP ?[0-9]{2}"));
  ocrEquipo.chassis = chassisMatch ? chassisMatch[0].replace(" ", "") : "";

  return ocrEquipo;
}

async function getPnAPI(ocrEquipo) {
  console.log(ocrEquipo.pn);
  if (ocrEquipo.pn && ocrEquipo.marca === "VLT") {
    await PNTCsAPI.get(ocrEquipo.pn)
      .then(async (response) => {
        if (response.ok) {
          console.warn(response);
          return response.json();
        } else {
          const error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        ocrEquipo.tc = data.tc;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (ocrEquipo.tc && ocrEquipo.marca === "Vacon") {
    await PNTCsAPI.getVacon(ocrEquipo.tc)
      .then(async (response) => {
        if (response.ok) {
          console.warn(response);
          return response.json();
        } else {
          const error = await response.text();
          throw new Error(error);
        }
      })
      .then((data) => {
        ocrEquipo.pn = data.pn;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return ocrEquipo;
}

function getPnFCM(OCRParams, ocrEquipo, visionString) {
  if (OCRParams.snIndex > -1) {
    ocrEquipo.pn = visionString
      .substr(OCRParams.snIndex + 4, 10)
      .replace(/ /g, "")
      .replace(/\n/g, "")
      .replace(/O/gi, "0")
      .replace("S/N", "")
      .replace("SIN", "")
      .replace("S/", "")
      .replace("SI", "")
      .substr(0, 8)
      .trim();

    if (ocrEquipo.pn.length >= 4) {
      if (ocrEquipo.pn[3] === "2" || ocrEquipo.pn[3] === "7") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "Z" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "8") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "B" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "0") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "U" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "6") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "G" + ocrEquipo.pn.substr(3 + 1);
      } else if (ocrEquipo.pn[3] === "1") {
        ocrEquipo.pn =
          ocrEquipo.pn.substr(0, 3) + "H" + ocrEquipo.pn.substr(3 + 1);
      }
    }
  }

  return ocrEquipo;
}

function getSnFCM(ocrEquipo, visionString) {
  let visionBack = visionString
    .replace(/,/g, "")
    .replace(/\./g, "")
    //Esto no es un Guion normal, es un ascii diferente
    .replace(/–/g, "-")
    .replace(/O/gi, "0")
    .trim();

  visionString = visionBack.replace(/ /g, "");

  let sn = visionString.match(new RegExp(/[0-9]{6}-[0-9]{3,}/gm));
  if (sn == null) {
    sn = visionBack.match(new RegExp(/[0-9]{6} [0-9]{3,}/gm));
  }

  ocrEquipo.sn = sn ? sn[0] : null;

  return ocrEquipo;
}

function getSnVLT2000(ocrEquipo, visionString) {
  let sn = visionString.match(/[0-9]{6}[A-Z,a-z]{1}[0-9]{3}/gm);

  if (sn == null) {
    sn = visionString.match(/[0-9]{6}.[0-9]{3}/gm);
    if (sn) {
      if (sn[0][6] === "6" || sn[0][6] === "9" || sn[0][6] === "0") {
        sn[0] = sn[0].substr(0, 6) + "G" + sn[0].substr(6 + 1);
      }
    }
  }

  ocrEquipo.sn = sn ? sn[0] : "";
  return ocrEquipo;
}

function getPnVLT2000(ocrEquipo, visionString) {
  let pn = visionString.match(/[0-9]{3}[A-Z,a-z]{1}[0-9]{4}/gm);
  ocrEquipo.pn = pn ? pn[0] : "";
  return ocrEquipo;
}

function getTcVLT2000(ocrEquipo) {
  ocrEquipo.tc = ocrEquipo.pn;
  return ocrEquipo;
}
