import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import {
  TextField,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  InputAdornment,
  Paper,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Swal from "sweetalert2";

import AuthService from "../services/AuthService";
import Auth from "../components/layout/Auth";

import {
  EmailOutlined,
  ExpandMore,
  Translate,
  VpnKey,
} from "@material-ui/icons";
import i18n from "../locales/i18n";
import { Link } from "react-router-dom";
import { UsersAPI, PartnersAPI } from "../services/API";

export default function Login(props) {
  const [partner, setPartner] = useState(props.match.params.partner);
  const [partnerEmail, setPartnerEmail] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState(null);
  const [message, setMessage] = useState(undefined);
  const [locale, setLocale] = useState(i18n.locale || i18n.defaultLocale);

  const handleLangClick = (event) => {
    setLanguageMenuAnchor(event.currentTarget);
  };

  const handleLangClose = (locale) => {
    setLanguageMenuAnchor(null);
    let currentLocale = i18n.locale || i18n.defaultLocale;
    if (locale !== currentLocale) {
      switch (locale) {
        case "es":
        case "en":
          localStorage.setItem("locale", locale);
          i18n.locale = locale;
          setLocale(locale);
          break;
        default:
          break;
      }
    }
  };

  const login = (event) => {
    event.preventDefault();
    const credentials = {
      grant_type: "password",
      username: username,
      password: password,
    };

    AuthService.login(credentials)
      .then((res) => {
        if (!rememberMe) {
          var expireDate = new Date();
          expireDate.setHours(expireDate.getHours() + 1);
          res.data.expireDate = expireDate;
        }

        localStorage.setItem("tokenInfo", JSON.stringify(res.data));

        // Navigate to the requested source OR home
        if (props.location.state && props.location.state.referrer) {
          props.history.push(props.location.state.referrer);
        } else {
          props.history.push("/");
        }

      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          setMessage(error.response.data.error_description);
        } else if (error.request) {
          setMessage("There was an error");
        } else {
          // Something happened in setting up the request and triggered an Error
          setMessage(error.message);
        }
        console.log(error.config);
      });
  };

  const onChange = (e) => {
    switch (e.target.name) {
      case "username":
        setUsername(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      case "rememberMe":
        setRememberMe(e.target.value);
        break;      
      case "languageMenuAnchor":
        setLanguageMenuAnchor(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  const recoverPassword = () => {
    Swal.fire({
      title: i18n.t("recovery.forgotPasswordTitle"),
      text: i18n.t("recovery.forgotPasswordText"),
      input: "email",
      confirmButtonText: i18n.t("recovery.forgotPasswordSend"),
      cancelButtonText: i18n.t("recovery.forgotPasswordCancel"),
      showCancelButton: true,
      progressSteps: [
        '<i class="material-icons" style="font-size: 18px; vertical-align: sub;">email</i>',
      ],
      validationMessage: i18n.t("recovery.forgotPasswordError"),
    }).then((email) => {
      if (email.value) {
        UsersAPI.recoverAccount(email.value)
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                icon: "info",
                title: i18n.t("recovery.forgotPasswordOk"),
                text: i18n.t("recovery.forgotPasswordOkText"),
              });
            } else {
              throw new Error();
            }
          })
          .catch(() =>
            Swal.fire({
              icon: "error",
              title: "Error",
              text: i18n.t("recovery.forgotPasswordErrorText"),
            })
          );
      }
    });
  };

  useEffect(() => {
    AuthService.logOut();
  }, []);

  useEffect(() => {
    PartnersAPI.getPartnerList()
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Something went wrong");
      }
    })
    .then((data) => {
      let bEncontrado = false;
      for (let index = 0; index < data.length; index++) {
        if (data[index].nombreAbreviado === partner) {
          bEncontrado = true;
          setPartnerEmail(data[index].emailContacto);
          break;
        }
      }
      if (!bEncontrado) {
        setPartner('');
        console.log('Not includes');
      } else {
        console.log('Includes');
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }, [partner]);

  return (
    <Auth locale={locale} partner={partner} email={partnerEmail}>
      <Row
        className="justify-content-center align-items-center h-100 ml-0 mr-0"
        style={{ paddingBottom: "15%" }}
      >
        <Col sm="12" md="10" lg="8">
          <Paper elevation={3} style={{ padding: 40 }}>
            <Row className="d-flex justify-content-start mb-4">
              <Col>
                <h3 className="mt-2 mb-3">{i18n.t("login.login")}</h3>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0.3,
                }}
              >
                <Button
                  aria-haspopup="true"
                  onClick={handleLangClick}
                  startIcon={<Translate />}
                  endIcon={<ExpandMore />}
                >
                  {i18n.t("settings.languageName")}
                </Button>
                <Menu
                  keepMounted
                  anchorEl={languageMenuAnchor}
                  open={Boolean(languageMenuAnchor)}
                  onClose={handleLangClose}
                >
                  <MenuItem onClick={() => handleLangClose("es")}>
                    {i18n.t("settings.languageName", { locale: "es" })}
                  </MenuItem>
                  <MenuItem onClick={() => handleLangClose("en")}>
                    {i18n.t("settings.languageName", { locale: "en" })}
                  </MenuItem>
                </Menu>
              </Col>
            </Row>

            <form onSubmit={(event) => login(event)}>
              <FormGroup row className="mb-4">
                <TextField
                  id="inputUsername"
                  type="text"
                  name="username"
                  label={i18n.t("login.email")}
                  size="medium"
                  fullWidth
                  value={username}
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>

              <FormGroup row>
                <React.Fragment>
                  <TextField
                    id="inputPassword"
                    type="password"
                    name="password"
                    label={i18n.t("login.password")}
                    size="medium"
                    fullWidth
                    error={message !== undefined}
                    helperText={message}
                    value={password}
                    onChange={onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Link
                    to="#"
                    className="mt-1"
                    onClick={recoverPassword}
                  >
                    <Typography>
                      {i18n.t("recovery.forgotPassword")}
                    </Typography>
                  </Link>
                </React.Fragment>
              </FormGroup>

              <FormGroup row className="mt-2 mb-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="rememberMe"
                      name="rememberMe"
                      indeterminate={false}
                      defaultChecked={true}
                      onChange={(event, checked) =>
                        setRememberMe(checked)
                      }
                    />
                  }
                  label={i18n.t("login.remember")}
                />
              </FormGroup>

              <Grid container>
                <Button type="submit" variant="contained" color="secondary">
                  {i18n.t("login.continuar")}
                </Button>

                <Button
                  onClick={() => (partner 
                                  ? props.history.push("/registro/" + partner) 
                                  : props.history.push("/registro"))}
                  type="submit"
                  variant="text"
                  color="secondary"
                  className="ml-auto"
                >
                  {i18n.t("login.registro")}
                </Button>
              </Grid>
            </form>
          </Paper>
        </Col>
      </Row>
    </Auth>
  );
}
