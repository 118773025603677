import React from "react";
import { Row, Col } from "reactstrap";

import Auth from "../components/layout/Auth";
import { Button, Paper } from "@material-ui/core";
import { PanToolRounded } from "@material-ui/icons";

export default function Unauthorized(props) {
  return (
    <Auth>
      <Row
        className="justify-content-center align-items-center h-100 ml-0 mr-0"
        style={{ paddingBottom: "15%" }}
      >
        <Col sm="12" md="10" lg="8" xl="7">
          <Paper elevation={3} style={{ padding: 40 }}>
            <Row className="d-flex justify-content-start mb-4">
              <Col>
                <h4>
                  <PanToolRounded /> Acceso no autorizado
                </h4>
              </Col>
            </Row>

            <p className="mb-3">
              No tienes autorización para acceder al contenido solicitado
            </p>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => props.history.goBack()}
            >
              Volver
            </Button>
          </Paper>
        </Col>
      </Row>
    </Auth>
  );
}
