import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` }
  });
}

export function getWhitPlantas() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/withplantas`, {
    headers: { Authorization: `Bearer ${access_token}` }
  });
}

export function getWithUserAccessPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/withUserAccessPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` }
  });
}

export function add(clienteB) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(clienteB)
  });
}

export function update(cliente) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${cliente.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({...cliente, plantas: null})
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function removeFromPartner(id, partnerId) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/${id}/partner/${partnerId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}

export function getIfCIFExist(cif){
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/clientes/verifycif`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cif),
  })
}