import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import i18n from "../../../locales/i18n";
import { isVacon } from "../../../helpers/Common";

/**
 * @Conponent
 * @param {Object} props
 * @param {Object} props.equipoExchange
 * @param {Boolean} props.readOnly
 * @param {Object} props.errors
 * @param {*} props.handleInputChange
 * @param {boolean} props.IsPartnerPlanta
 * @param {boolean} props.isExchange
 */

export default function formVariadorExchange(props) {
  const {
    equipoExchange,
    readOnly,
    errors,
    handleInputChange,
    IsPartnerPlanta,
    isExchange
  } = props;
  const ro = isExchange ? readOnly : true;

  return (
    <form autoComplete="off" onSubmit={(event) => event.preventDefault()}>
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <TextField
            select
            required
            fullWidth
            inputProps={{
              readOnly: ro,
            }}
            value={equipoExchange.marca || ""}
            label={i18n.t("maintenance.fields.brand")}
            InputLabelProps={{
              shrink: true,
            }}
            name="marca"
            error={errors["Marca"] !== undefined}
            helperText={errors["Marca"] ? i18n.t("textField.required") : null}
            onChange={handleInputChange}
          >
            <MenuItem value="VLT">Danfoss VLT</MenuItem>
            <MenuItem value="Vacon">Danfoss Vacon</MenuItem>
          </TextField>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <TextField
            required
            fullWidth
            value={equipoExchange.sn || ""}
            label={i18n.t("equipo.sn")}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              readOnly: ro,
            }}
            name="sn"
            error={
              errors["SN"] !== undefined || errors["SN_Unique"] !== undefined
            }
            helperText={
              errors["SN"]
                ? i18n.t("textField.required")
                : errors["SN_Unique"]
                  ? i18n.t("textField.unique", { name: "SN" })
                  : null
            }
            onChange={handleInputChange}
          />
        </Grid>
        {isVacon(equipoExchange.marca) &&
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              required
              fullWidth
              value={equipoExchange.tc || ""}
              label={
                isVacon(equipoExchange.marca)
                  ? i18n.t("equipo.vType")
                  : i18n.t("equipo.tc")
              }
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                readOnly: ro,
              }}
              name="tc"
              id="tc"
              error={errors["TC"] !== undefined}
              helperText={errors["TC"] ? i18n.t("textField.required") : null}
              onChange={handleInputChange}
            />
          </Grid>
        }
        {!isVacon(equipoExchange.marca) &&
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              required
              fullWidth
              value={equipoExchange.pn || ""}
              label={i18n.t("equipo.pn")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                readOnly: ro || isVacon(equipoExchange.marca),
              }}
              name="pn"
              id="pn"
              error={errors["PN"] !== undefined}
              helperText={errors["PN"] ? i18n.t("textField.required") : null}
              onChange={handleInputChange}
            />
          </Grid>
        }
        {IsPartnerPlanta &&
          <Grid item lg={4} md={6} xs={12}>
            <TextField
              fullWidth
              type="number"
              value={equipoExchange.anyoFabricacion || ""}
              label={i18n.t("equipo.añoFabricacion")}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                readOnly: readOnly,
              }}
              name="anyoFabricacion"
              error={errors["anyoFabricacion"] !== undefined}
              helperText={
                errors["anyoFabricacion"] ? i18n.t("textField.required") : null
              }
              onChange={handleInputChange}
            />
          </Grid>
        }
        {equipoExchange.id &&
          <Grid item lg={4} md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel>{i18n.t("common.estado")}</InputLabel>
              <Select
                readOnly={!IsPartnerPlanta}
                value={equipoExchange.estadoExchange || "0"}
                name="estadoExchange"
                displayEmpty
                onChange={handleInputChange}
              >
                <MenuItem value="0">{i18n.t("equipo.estadoExchange.disponible")}</MenuItem>
                <MenuItem value="2">{i18n.t("equipo.estadoExchange.solicitado")}</MenuItem>
                <MenuItem value="1">{i18n.t("equipo.estadoExchange.prestado")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        }
      </Grid>
    </form>
  );
}