import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import {
  ArrowBack,
  Delete,
  Memory,
  Save,
} from "@material-ui/icons";
import i18n from "../../../locales/i18n";
// @ts-ignore
//import Gbar from "../../../assets/images/g-bar.png";
import FormularioVariadorExchange from "../../molecule/formVariadorExchange/formVariadorExchange";
import EquipoExchange from "../../../models/equipoExchange";
import { EquiposExchangeAPI } from "../../../services/API";
import AlertDialog from "../../../components/AlertDialog";

export default function CardVariadorExchange(props) {
  const {
    id,
    plantaId,
    readOnly,
    goBack,
    history,
    setLoading,
    IsPartnerPlanta,
    isExchange
  } = props;
  const [equipoExchange, setEquipoExchange] = useState(new EquipoExchange());
  const [errors, setErrors] = useState([]);
  const deleteDialog = useRef(null);

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setEquipoExchange((prevEquipoExchange) => ({ ...prevEquipoExchange, [name]: value }));

    if (name === "marca" && value === "Vacon") {
      setEquipoExchange((prevEquipoExchange) => ({ ...prevEquipoExchange, "pn": prevEquipoExchange.tc }));
    }
    if (equipoExchange.marca === "Vacon" && name === "tc") {
      setEquipoExchange((prevEquipoExchange) => ({ ...prevEquipoExchange, "pn": value }));
    }
  };

  const updateEquipoExchange = () => {
    return EquiposExchangeAPI.updateEquipoExchange(equipoExchange)
      .then(async (response) => {
        if (response.ok) {
          setErrors([]);
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .catch((error) => {
        console.log('updateEquipoExchange Error: ' + error);
      });
  };

  const addEquipoExchange = () => {
    return EquiposExchangeAPI.addEquipoExchange({ ...equipoExchange, plantaId: plantaId })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var json = await response.json();
          setErrors(json.errors);
        }
      })
      .then((data) => {
        history.replace({
          pathname: `/plantas/${plantaId}/equipoexchange/${data.id}`,
          state: { IsPartnerPlanta: IsPartnerPlanta },
        });
      })
      .catch((error) => {
        console.log('addEquipoExchange Error: ' + error);
      });
  };

  const saveEquipoExchange = () => {
    if (id) {
      return updateEquipoExchange();
    } else {
      return addEquipoExchange();
    }
  };

  const deleteEquipoExchange = async () => {
    setLoading(true);

    EquiposExchangeAPI.deleteEquipoExchange(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        goBack();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getEquipoExchange = useCallback(async () => {
    EquiposExchangeAPI.getEquipo(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setEquipoExchange(data);
      })
      .catch((error) => { });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    if (id) {
      Promise.all([
        getEquipoExchange(),
        //getImages(),
      ]).then(() => setLoading(false));
    } else {
      setLoading(false);
    };
  }, [
    id,
    getEquipoExchange,
    setLoading
    //getImages,
  ]);

return (
  <>
    <Card>
      <CardHeader
        title={equipoExchange.sn || i18n.t("common.noName")}
        subheader={
          id
            ? equipoExchange.tc || i18n.t("common.noDescription")
            : i18n.t("equipo.nuevo")
        }
        avatar={
          <Avatar
            aria-label="avatar"
            style={{ backgroundColor: "#f50057" }}
          >
            <Memory />
          </Avatar>
        }
      />
      <CardContent>
        <FormularioVariadorExchange
          equipoExchange={equipoExchange}
          readOnly={readOnly}
          errors={errors}
          handleInputChange={handleInputChange}
          IsPartnerPlanta={IsPartnerPlanta}
          isExchange={isExchange}
        />
        {/*<div*/}
        {/*  style={{*/}
        {/*    marginLeft: "auto",*/}
        {/*    marginRight: "auto",*/}
        {/*    marginBottom: 25,*/}
        {/*    marginTop: 25,*/}
        {/*    backgroundImage: `url("${Gbar}")`,*/}
        {/*    backgroundSize: "cover",*/}
        {/*    height: 3,*/}
        {/*    width: "100%",*/}
        {/*  }}*/}
        {/*/>*/}
        {/*ToDo Imagenes.*/}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="back" onClick={() => goBack()}>
          <ArrowBack />
        </IconButton>
        {!readOnly && (
          <>
            <IconButton
              aria-label="save"
              onClick={() => saveEquipoExchange()
              }
            >
              <Save />
            </IconButton>
            {(id && isExchange) && (
              <Button
                aria-label="delete"
                className="text-danger"
                style={{ marginLeft: "auto" }}
                onClick={() => deleteDialog.current.open()}
              >
                {i18n.t("equipo.borrar")}
                <Delete className="ml-2" />
              </Button>
            )}
          </>
        )}
      </CardActions>
    </Card>
    <AlertDialog
      ref={deleteDialog}
      title={i18n.t("equipo.alertDialog.title")}
      text={i18n.t("equipo.alertDialog.text")}
      cancelText={i18n.t("equipo.alertDialog.cancelText")}
      confirmText={i18n.t("equipo.alertDialog.confirmText")}
      confirmAction={() => deleteEquipoExchange()}
    />
  </>
);
}