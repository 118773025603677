import AuthService from "./AuthService";
import { baseUrl } from "../helpers/Url";

export function get(id = "") {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}`, {
    headers: { Authorization: `Bearer ${access_token}` }
  });
}

export function getPartnerList() {
  return fetch(`${baseUrl}/api/partners/list`);
}

export function getWithUserAccessPermisions() {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/withUserAccessPermisions`, {
    headers: { Authorization: `Bearer ${access_token}` }
  });
}

export function add(partner) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(partner)
  });
}

export function update(partner) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${partner.id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(partner)
  });
}

export function remove(id) {
  const userInfo = AuthService.getUserInfo();
  const access_token = userInfo.access_token;

  return fetch(`${baseUrl}/api/partners/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  });
}
