import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  Add,
  Assignment,
  AssignmentInd,
  AssignmentTurnedIn,
  Person,
} from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchField from "./molecule/SearchField/SearchField";
import withHover from "./withHover";
// @ts-ignore
import Gbar from "../assets/images/g-bar.png";
import i18n from "../locales/i18n";
import moment from "moment";
import AlertDialog from "./AlertDialog";

import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { UsersAPI, EquipoActividadesAPI } from "../services/API";

const CardWithHover = withHover(Card, { init: 2, hovered: 8 });

export default function Actividades({
  id,
  equipoActividades,
  readOnly,
  goBack,
}) {
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const [users, setUsers] = useState([]);
  const [actividades, setActividades] = useState(
    equipoActividades
  );

  const [search, setSearch] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const dialogRef = useRef(null);

  const getUsers = () => {
    setLoading(true);
    setLoadingUsers(true);

    return UsersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        setUsers(data.users);
        setLoading(false);
        setLoadingUsers(false);
      })
      .catch((error) => {
        setLoading(false);
        setLoadingUsers(false);
      });
  };

  const addActividad = () => {
    let actividadesAPI = EquipoActividadesAPI;

    setLoading(true);
    return actividadesAPI
      .add(currentItem)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setLoading(false);
        }
      })
      .then((data) => {
        setLoading(false);
        setActividades((prevState) => [
          ...prevState,
          { ...currentItem, id: data.id },
        ]);

        dialogRef.current.close();
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const updateActividad = () => {
    let actividadesAPI = EquipoActividadesAPI;

    setLoading(true);
    return actividadesAPI
      .update(currentItem)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setActividades((prevState) =>
            prevState.map((a) => (a.id === currentItem.id ? currentItem : a))
          );
          dialogRef.current.close();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteActividad = () => {
    let actividadesAPI = EquipoActividadesAPI;

    setLoading(true);
    return actividadesAPI
      .remove(currentItem.id)
      .then((response) => {
        if (response.ok) {
          setLoading(false);
          setActividades((prevState) =>
            prevState.filter((a) => a.id !== currentItem.id)
          );
          dialogRef.current.close();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const openActividad = (actividad) => {
    if (!actividad) {
      var appUser = users.length > 0 ? users[0] : null;
      var appUserId = appUser ? appUser.id : null;
      actividad = {
        appUser: appUser,
        appUserId: appUserId,
        estado: 0,
        fecha: new Date(),
        descripcion: "",
        observaciones: "",
      };

      if (equipoActividades) {
        actividad.equipoId = id;
      } else {
        actividad.fisicalElementId = id;
      }
    }
    setCurrentItem(actividad);
    dialogRef.current.open(actividad);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item container xs={12} sm={8} md={6} xl={4}>
        <SearchField
          inputBaseProps={{
            placeholder: i18n.t("common.search"),
            value: search,
            onChange: (event) => setSearch(event.target.value),
          }}
          goBack={goBack}
          endButton={!readOnly&&(
            <Button
              style={{ padding: 10, color: "rgba(0, 0, 0, 0.70)" }}
              onClick={() => openActividad()}
            >
              <Add />
              {i18n.t("common.new.femenine")}
            </Button>
          )
            
          }
        />
      </Grid>
      <Grid item container spacing={3}>
        <Grid item xs={6} md={4}>
          <Card variant="outlined">
            <CardHeader
              title={i18n.t("actividades.tituloPendientes")}
              subheader={i18n.t("actividades.subtituloPendientes")}
              avatar={
                <Avatar>
                  <Assignment />
                </Avatar>
              }
            />
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "#bdbdbd",
                backgroundSize: "cover",
                height: 3,
                width: "100%",
              }}
            />
            <CardContent style={{ backgroundColor: "#ececec" }}>
              <Grid container spacing={2}>
                {actividades
                  .filter((a) => a.estado === 0)
                  .map((actividad) => (
                    <Grid item xs={12} key={actividad.id.toString()}>
                      <CardWithHover onClick={() => openActividad(actividad)}>
                        <CardHeader
                          title={`${actividad.appUser.nombre} ${actividad.appUser.apellidos}`}
                          subheader={moment(actividad.fecha)
                            .local()
                            .format("DD/MM/yyyy")}
                          avatar={
                            <Avatar style={{ backgroundColor: "gray" }}>
                              <Person />
                            </Avatar>
                          }
                        />
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: "#bdbdbd",
                            backgroundSize: "cover",
                            height: 1,
                            width: "100%",
                          }}
                        />
                        <CardContent>{actividad.descripcion}</CardContent>
                      </CardWithHover>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={4}>
          <Card variant="outlined">
            <CardHeader
              title={i18n.t("actividades.tituloRealizandose")}
              subheader={i18n.t("actividades.subtituloRealizandose")}
              avatar={
                <Avatar
                  style={{
                    background:
                      "linear-gradient(45deg, rgb(232, 55, 105) 30%, rgb(110, 68, 142) 90%)",
                  }}
                >
                  <AssignmentInd />
                </Avatar>
              }
            />
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                backgroundImage: `url("${Gbar}")`,
                backgroundSize: "cover",
                height: 3,
                width: "100%",
              }}
            />
            <CardContent style={{ backgroundColor: "#ececec" }}>
              <Grid container spacing={2}>
                {actividades
                  .filter((a) => a.estado === 1)
                  .map((actividad) => (
                    <Grid item xs={12} key={actividad.id.toString()}>
                      <CardWithHover onClick={() => openActividad(actividad)}>
                        <CardHeader
                          title={`${actividad.appUser.nombre} ${actividad.appUser.apellidos}`}
                          subheader={moment(actividad.fecha)
                            .local()
                            .format("DD/MM/yyyy")}
                          avatar={
                            <Avatar
                              style={{
                                background:
                                  "linear-gradient(45deg, rgb(232, 55, 105) 30%, rgb(110, 68, 142) 90%)",
                              }}
                            >
                              <Person />
                            </Avatar>
                          }
                        />
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: "#bdbdbd",
                            backgroundSize: "cover",
                            height: 1,
                            width: "100%",
                          }}
                        />
                        <CardContent>{actividad.descripcion}</CardContent>
                      </CardWithHover>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} md={4}>
          <Card variant="outlined">
            <CardHeader
              title={i18n.t("actividades.tituloFinalizadas")}
              subheader={i18n.t("actividades.subtituloFinalizadas")}
              avatar={
                <Avatar style={{ backgroundColor: "#43a047" }}>
                  <AssignmentTurnedIn />
                </Avatar>
              }
            />
            <div
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "#43a047",
                backgroundSize: "cover",
                height: 3,
                width: "100%",
              }}
            />
            <CardContent style={{ backgroundColor: "#ececec" }}>
              <Grid container spacing={2}>
                {actividades
                  .filter((a) => a.estado === 2)
                  .map((actividad) => (
                    <Grid item xs={12} key={actividad.id.toString()}>
                      <CardWithHover onClick={() => openActividad(actividad)}>
                        <CardHeader
                          title={`${actividad.appUser.nombre} ${actividad.appUser.apellidos}`}
                          subheader={moment(actividad.fecha)
                            .local()
                            .format("DD/MM/yyyy")}
                          avatar={
                            <Avatar style={{ backgroundColor: "#43a047" }}>
                              <Person />
                            </Avatar>
                          }
                        />
                        <div
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: "#bdbdbd",
                            backgroundSize: "cover",
                            height: 1,
                            width: "100%",
                          }}
                        />
                        <CardContent>{actividad.descripcion}</CardContent>
                      </CardWithHover>
                    </Grid>
                  ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <AlertDialog
        ref={dialogRef}
        loading={loading || loadingUsers}
        title={
          currentItem
            ? `${currentItem.appUser ? currentItem.appUser.nombre : ""} ${
                currentItem.appUser ? currentItem.appUser.apellidos + " - " : ""
              }${moment(currentItem.fecha).local().format("DD/MM/yyyy")}`
            : i18n.t("actividades.nueva")
        }
        text={
          <Grid container spacing={3} style={{ margin: 0, width: "100%" }}>
            <Grid item xs={12}>
              <Autocomplete
                disabled={readOnly}
                value={
                  currentItem
                    ? currentItem.appUser
                    : users.length > 0
                    ? users[0]
                    : null
                }
                options={users}
                getOptionLabel={(option) =>
                  `${option.nombre} ${option.apellidos}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={loadingUsers ? i18n.t("common.cargando") : ""}
                    label={i18n.t("actividades.responsable")}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                onChange={(event, value) => {
                  setCurrentItem((prevItem) => {
                    return {
                      ...prevItem,
                      appUserId: value ? value.id : currentItem.appUserId,
                      appUser: value ? value : currentItem.appUser,
                    };
                  });
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth required>
                <InputLabel shrink>{i18n.t("actividades.estado")}</InputLabel>
                <Select
                  inputProps={{
                    readOnly: readOnly,
                  }}
                  required
                  value={currentItem ? currentItem.estado : "0"}
                  onChange={(e) =>
                    setCurrentItem((prevItem) => {
                      return {
                        ...prevItem,
                        estado: parseInt(e.target.value.toString()),
                      };
                    })
                  }
                >
                  <MenuItem value="0">
                    {i18n.t("actividades.estados.pendiente")}
                  </MenuItem>
                  <MenuItem value="1">
                    {i18n.t("actividades.estados.realizandose")}
                  </MenuItem>
                  <MenuItem value="2">
                    {i18n.t("actividades.estados.finalizada")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <FormControl fullWidth required>
                  <InputLabel shrink>{i18n.t("actividades.fecha")}</InputLabel>
                  <KeyboardDatePicker
                    disabled={readOnly}
                    required
                    disableToolbar
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    value={
                      currentItem
                        ? moment(currentItem.fecha)
                        : moment(new Date())
                    }
                    helperText={
                      currentItem &&
                      !moment(currentItem.fecha).isValid() &&
                      i18n.t("textField.required")
                    }
                    onChange={(date) =>
                      setCurrentItem((prevItem) => {
                        return {
                          ...prevItem,
                          fecha: date ? date.toDate() : null,
                        };
                      })
                    }
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                disabled={readOnly}
                fullWidth
                multiline
                value={currentItem ? currentItem.descripcion : ""}
                label={i18n.t("actividades.descripcion")}
                onChange={(e) => {
                  e.persist();
                  setCurrentItem((prevItem) => {
                    return { ...prevItem, descripcion: e.target.value };
                  });
                }}
                error={currentItem && !currentItem.descripcion}
                helperText={
                  currentItem &&
                  !currentItem.descripcion &&
                  i18n.t("textField.required")
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={readOnly}
                multiline
                value={currentItem ? currentItem.observaciones : ""}
                label={i18n.t("actividades.observaciones")}
                onChange={(e) => {
                  e.persist();
                  setCurrentItem((prevItem) => {
                    return { ...prevItem, observaciones: e.target.value };
                  });
                }}
              />
            </Grid>
          </Grid>
        }
        leftButton={
          <Button
            color="secondary"
            className="mr-auto"
            disabled={readOnly ? true : currentItem ? (currentItem.id ? false : true) : true}
            onClick={() => deleteActividad()}
          >
            {i18n.t("common.borrar")}
          </Button>
        }
        cancelText={i18n.t("common.cancelar")}
        confirmText={i18n.t("common.guardar")}
        confirmDisabled={readOnly || (
          currentItem &&
          (!currentItem.descripcion || !moment(currentItem.fecha).isValid()))
        }
        confirmAction={() =>
          currentItem.id ? updateActividad() : addActividad()
        }
      />
    </Grid>
  );
}
