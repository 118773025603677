import React, { createRef, useEffect } from "react";
import { Route, withRouter } from "react-router";
import MainLayout from "./components/layout/MainLayout";
import PrivateRoute from "./components/PrivateRoute";

import Login from "./pages/Login";
import Unauthorized from "./pages/Unauthorized";
import Home from "./pages/Home";
import Exchange from "./components/page/EquiposExchange/EquiposExchangeSearch";

import Clientes from "./components/page/Clientes/ClientesList";
import ClientesDetails from "./components/page/Clientes/ClientesDetails";
import EquiposDetails from "./components/page/Equipos/EquiposDetails";

import PlantasList from "./components/page/Plantas/PlantasList";
import PlantasDetails from "./components/page/Plantas/PlantasDetails";
import ZonasDetails from "./components/page/Zonas/ZonasDetails";

import UsersList from "./components/page/UserManager/UsersList";
import UserDetails from "./components/page/UserManager/UserDetails";
import UserAdd from "./components/page/UserManager/UserAdd";

import i18n from "../src/locales/i18n";
import AuthService from "./services/AuthService";
import "./assets/css/custom.css";
import Registro from "./pages/Registro";
import RegistrosList from "./components/page/Registros/RegistrosList";
import PartNumbers from "./components/page/partNumber/PartNumbers";
import Privacidad from "./pages/Privacidad";
import Cookies from "./pages/Cookies";
import CookieSnackbar from "./components/CookieSnackbar";
import RecoveryValidation from "./pages/RecoveryValidation";
import News from "./components/page/news/News";
import TestOffline from "./components/page/Tests/TestOffline";
import ClearCache from "./helpers/ClearCache";
import PartnersList from "./components/page/partners/PartnersList";
import PartnersDetails from "./components/page/partners/PartnersDetails";
import EquiposExchangeDetails from "./components/page/EquiposExchange/EquiposExchangeDetails";
import Barcodes from "./components/page/Barcodes/Barcodes";
import AvisoLegal from "./pages/AvisoLegal";
import Stats from "./components/page/Stats/stats";
import RecoveryLand from "./pages/RecoveryLand";
import UnsubLand from "./pages/UnsubLand";

const nonLayoutPages = [
  "/login",
  "/unauthorized",
  "/registro",
  "/recovery-validation",
  "/unsub",
];

const App = (props) => {
  const navbar = createRef();
  i18n.locale = localStorage.getItem("locale");

  const setNavBarTitle = (title) => {
    if (navbar.current) navbar.current.setState({ title });
  };

  props.history.listen((location, action) => {
    if (navbar.current && props.location.pathname !== location.pathname)
      navbar.current.setState({ title: location.pathname });
  });

  const isLayoutPage = !nonLayoutPages.some((p) =>
    props.location.pathname.startsWith(p)
  );
  if (isLayoutPage) {
    return (
      <ClearCache>
        <MainLayout {...props.router} navbarRef={navbar}>
          <CookieSnackbar />
          <Route
            path="/disclaimer"
            exact
            component={(props) => (
              <AvisoLegal {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <Route
            path="/privacity"
            exact
            component={(props) => (
              <Privacidad {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <Route
            path="/cookies"
            exact
            component={(props) => (
              <Cookies {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <Home {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/news"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <News {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          {/* Mis plantas */}
          <PrivateRoute
            exact
            path="/plantas"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <PlantasList {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/plantas/:id"
            acceso={{ nivel: "planta", varId: "id" }}
            component={(props) => (
              <PlantasDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/plantas/:plantaId/zonas/:id?"
            acceso={{ nivel: "zona", varId: "id" }}
            component={(props) => (
              <ZonasDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/plantas/:plantaId/zonas/:zonaId/equipos/:id?"
            acceso={{ nivel: "zona", varId: "zonaId" }}
            component={(props) => (
              <EquiposDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/plantas/:plantaId/equipos/:id?"
            acceso={{ nivel: "planta", varId: "plantaId" }}
            component={(props) => (
              <EquiposDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/plantas/:plantaId/equipoexchange/:id?"
            acceso={{ nivel: "planta", varId: "plantaId" }}
            component={(props) => (
              <EquiposExchangeDetails
                {...props}
                setNavBarTitle={setNavBarTitle}
              />
            )}
          />
          {/* // Mis plantas */}

          <PrivateRoute
            exact
            path="/listaregistros"
            acceso={{ nivel: "partner" }}
            component={(props) => (
              <RegistrosList {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/nuevo-partner"
            acceso={{ nivel: "keyuser" }}
            component={(props) => (
              <PartnersDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/partners"
            acceso={{ nivel: "partner" }}
            component={(props) => (
              <PartnersList {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/partners/:id"
            acceso={{ nivel: "partner", varId: "id" }}
            component={(props) => (
              <PartnersDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/partners/:partnerId/nuevo-cliente"
            acceso={{ nivel: "partner", varId: "partnerId" }}
            component={(props) => (
              <ClientesDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/partners/:partnerId/clientes/:id"
            acceso={{ nivel: "cliente", varId: "id" }}
            component={(props) => (
              <ClientesDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/nuevo-cliente"
            acceso={{ nivel: "partner" }}
            component={(props) => (
              <ClientesDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/clientes"
            acceso={{ nivel: "partner" }}
            component={(props) => (
              <Clientes {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/clientes/:id"
            acceso={{ nivel: "cliente", varId: "id" }}
            component={(props) => (
              <ClientesDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/clientes/:clienteId/nueva-planta"
            acceso={{ nivel: "cliente", varId: "clienteId" }}
            component={(props) => (
              <PlantasDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/users"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <UsersList {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/user/:id"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <UserDetails {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />
          <PrivateRoute
            exact
            path="/newuser"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <UserAdd {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/pns"
            acceso={{ nivel: "keyuser" }}
            component={(props) => (
              <PartNumbers {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/Exchange"
            acceso={{ nivel: "free" }}
            component={(props) => (
              <Exchange {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/Barcode"
            acceso={{ nivel: "keyuser" }}
            component={(props) => (
              <Barcodes {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/kpi"
            acceso={{ nivel: "keyuser" }}
            component={(props) => (
              <Stats {...props} setNavBarTitle={setNavBarTitle} />
            )}
          />

          <PrivateRoute
            exact
            path="/TestOffline"
            acceso={{ nivel: "free" }}
            component={(props) => <TestOffline />}
          />
        </MainLayout>
      </ClearCache>
    );
  } else {
    return (
      <>
        <ClearCache>
          <CookieSnackbar />
          <Route path="/login" exact component={Login} />
          <Route path="/login/:partner" exact component={Login} />
          <Route path="/login/:partner/recovery" exact component={RecoveryLand} />
          <Route path="/unsub/:partner/:id" exact component={UnsubLand} />
          <Route path="/registro" exact component={Registro} />
          <Route path="/registro/:partner" exact component={Registro} />
          <Route path="/unauthorized" component={Unauthorized} />
          <Route
            path="/recovery-validation"
            exact
            component={RecoveryValidation}
          />
          <Route
            path="/recovery-validation/:partner"
            exact
            component={RecoveryValidation}
          />
        </ClearCache>
      </>
    );
  }
};

export default withRouter(App);
