import React, { useEffect } from "react";
import i18n from "../locales/i18n";

export default function AvisoLegal({ setNavBarTitle }) {
  useEffect(() => {
    setNavBarTitle(i18n.t("legalDisclaimer.title"));
  }, [setNavBarTitle]);

  return (
    <div
      style={{
        maxWidth: "1000px",
        textAlign: "justify",
      }}
    >
      <h3>AVISO LEGAL</h3>
      <h4>TÉRMINOS Y CONDICIONES DE USO</h4>
      <br />
      <h5>1. Introducción</h5>
      <p>
        <b>1.1.</b> Mediante el uso y el acceso a este sitio web y/o a la
        aplicación ALTID INGESIS (en adelante, la aplicación), así como a las
        direcciones URL derivadas de WWW.ALTID.ES (en adelante el sitio web),
        usted otorga un contrato vinculante con INGESIS AUTOMATIZACIÓN, S.L, su
        contrato con nosotros incluye su aceptación a estos “Términos y
        Condiciones de Uso”, a nuestra “Política de Privacidad” y a nuestra
        “Política de Cookies”. Si no estuviera de acuerdo con cualquiera de los
        términos, por favor, le invitamos a que abandone el sitio web y/o la
        aplicación. Le rogamos que lea detenidamente las presentes condiciones
        antes de utilizar nuestra página web.
      </p>
      <p>
        <b>1.2. Titularidad del sitio web y/o de la aplicación.</b>
        <br />
        El sitio web WWW.ALTID.ES y la aplicación ALTID INGESIS son titularidad
        de INGESIS AUTOMATIZACIÓN, S.L, con sede social en C/ JORNALERS, 33,
        POL. IND. SEPES, ALBERIC (VALENCIA), con NIF B-97746267, teléfono de
        contacto: 962440181 y correo electrónico: INGESIS@INGESIS.ES.
        <br />
        INGESIS AUTOMATIZACIÓN, S.L es una empresa inscrita en el Registro
        Mercantil de Valencia con nº de inscripción 1ª, Libro 5741, Tomo 8451,
        Folio 134, Sección 8ª, Hoja V- 113.867.
      </p>
      <p>
        <b>1.3.</b> INGESIS AUTOMATIZACIÓN, S.L le indica que es obligación del
        usuario leer los presentes “Términos y Condiciones de Uso”,
        considerándose vigentes y por lo tanto aplicables, recomendando a los
        usuarios imprimir o guardar una copia de los presentes “Términos y
        Condiciones de Uso” para posteriormente tener conocimiento de los
        mismos, si bien los mismos estarán en todo momento disponibles en la
        página web (WWW.ALTID.ES) y en la aplicación ALTID INGESIS. Todas las
        condiciones pueden ser almacenadas y reproducidas por los
        usuarios/clientes a través de cualquier herramienta de los procesadores
        de texto convencionales en el mercado.
      </p>
      <p>
        <b>
          1.4. Edad mínima para poder usar y navegar el sitio web y/o aplicación
          de INGESIS AUTOMATIZACIÓN, S.L.
        </b>
        <br />
        Para poder usar y navegar el sitio web y/o la aplicación descargable
        “Altid Ingesis”, de INGESIS AUTOMATIZACIÓN, S.L, el Usuario debe (a)
        tener 18 o más años, o tener 14 y contar con el consentimiento paterno o
        de su tutor a estos términos, y (b) tener capacidad para otorgar un
        contrato vinculante con nosotros y que ninguna ley aplicable lo prohíba.
        El Usuario también garantiza que toda información que proporcione a
        INGESIS AUTOMATIZACIÓN, S.L por cualquiera de los medios que pone a su
        disposición sea veraz, precisa y completa, y acepta que así sea en todo
        momento.
      </p>
      <br />
      <h5>2. Objeto</h5>
      <p>
        <b>2.1.</b> Es objeto de los presentes términos de uso la navegación,
        acceso y uso del portal y de su información (Browsing, es decir el ojear
        un espacio con el propósito de reconocer objetos en él), está conducta
        fáctica supone la aceptación jurídica automática y sin modificación
        alguna, de los términos, condiciones y advertencias recogidas en el
        presente texto. Las conductas descritas confieren la condición de
        usuario sin necesidad de firma convencional por parte del usuario, en
        cuanto a los términos aquí establecidos sin perjuicio de la aplicación
        de las correspondientes normativas de obligado cumplimiento.
      </p>
      <p>
        Es obligación de INGESIS AUTOMATIZACIÓN, S.L advertir que, si el Usuario
        estuviera en desacuerdo con los términos presentes en este documento, el
        usuario deberá abstenerse de acceder al sitio web y/o a la aplicación
        “Altid Ingesis”, por lo que INGESIS AUTOMATIZACIÓN, S.L le invita
        inmediatamente a abandonar este sitio web y/o la aplicación si está
        conforme a citada advertencia.
      </p>
      <p>
        <b>2.2.</b> El Usuario se declara una persona física o jurídica, INGESIS
        AUTOMATIZACIÓN, S.L declara, y el Usuario reconoce, que INGESIS
        AUTOMATIZACIÓN, S.L proporciona gran diversidad de información,
        servicios y datos, asumiendo el usuario su responsabilidad en el uso
        correcto del sitio web y/o de la aplicación. Esta responsabilidad se
        extenderá a todos aquellos usos y prácticas que realice el usuario
        dentro del sitio web y/o de la aplicación, así como el uso de la
        información, servicios y datos ofrecidos por INGESIS AUTOMATIZACIÓN,
        S.L.
      </p>
      <br />
      <h5>3. Directrices del Usuario</h5>
      <p>
        Hemos establecido una serie de normas básicas para que el usuario pueda
        seguirlas cuando acceda a nuestros sitios web y/o a nuestra aplicación
        ALTID INGESIS, así como cuando contrate nuestros servicios. Por favor,
        siga estas normas:
      </p>
      <p>
        <b>3.1.</b> Directrices de acceso a el sitio web y/o a la aplicación
        “Altid Ingesis”
      </p>
      <p>
        <b>3.1.1.</b> Para acceder a el sitio web y/o a la aplicación, el
        Usuario debe contar con un acceso y conexión a la Red Internet, abonar
        las tarifas de acceso, tener el equipo/dispositivo y los sistemas
        electrónicos necesarios para realizar la conexión a la red, incluyendo
        un terminal que sea adecuado al efecto (ordenador, teléfono, tablet,
        etc…) y un modem u otro dispositivo de acceso análogo o similar.
      </p>
      <p>
        <b>3.1.2.</b> El Usuario conoce que el correcto acceso y uso de
        determinados contenidos y servicios del sitio web y/o a la aplicación,
        puede implicar la descarga en sus dispositivos informáticos de
        determinados programas de ordenador u otros elementos lógicos. El
        Usuario es el único responsable por la instalación de citados elementos,
        declinando INGESIS AUTOMATIZACIÓN, S.L cualquier tipo de responsabilidad
        que de ello pudiera derivar.
      </p>
      <p>
        <b>3.2.</b> Directrices de utilización del sitio web y/o de la
        aplicación por el usuario
      </p>
      <p>
        <b>3.2.1.</b> El usuario se obliga a llevar a cabo una utilización del
        sitio web y/o de la aplicación, sus condiciones, servicios que se
        pudieran ofrecer, contenidos de conformidad con la Ley, la Moral, las
        buenas Costumbres y el orden público, no utilizándolo contrariamente a
        los contenidos presentes en este texto, de forma lesiva para INGESIS
        AUTOMATIZACIÓN, S.L o terceros, o que de cualquier forma pueda dañar,
        inutilizar o deteriorar el sitio web y/o de la aplicación o sus
        servicios, o impedir un normal disfrute del sitio web y/o de la
        aplicación por otros Usuarios.
      </p>
      <p>
        <b>3.2.2.</b> El usuario se abstendrá de realizar acciones que dañen,
        interrumpan o generen errores en los sistemas de INGESIS AUTOMATIZACIÓN,
        S.L, así como introducir programas, virus, macros, applets, controles
        ActiveX o cualquier otro dispositivo lógico o secuencia de caracteres
        que causen o sean susceptibles de causar cualquier tipo de alteración en
        los sistemas informáticos.
      </p>
      <p>
        <b>3.2.3</b> El Usuario podrá crear libremente una cuenta de cliente
        registrándose en nuestra web (www.altid.es/registro) o directamente
        desde la aplicación en cualquier momento, siendo verificado por nuestro
        servicio informático la contratación previa del servicio.
      </p>
      <p>
        El Usuario se obliga a proporcionar la información actual, completa y
        precisa de los datos que le solicitemos siendo responsable de la misma.
        Disponemos de un sistema de avisos en pantalla que identifican los
        errores cometidos en la introducción de datos y permiten al cliente
        corregirlos. Acepta actualizar de inmediato su cuenta y otra
        información, incluida su dirección de correo electrónico.
      </p>
      <p>
        En el momento en que usted nos facilita sus datos, los almacenamos en
        nuestro servidor seguro por lo que sus datos personales son incorporados
        a nuestra base de datos. Sus datos se utilizarán para gestionar su
        cuenta de usuario, así como para enviar información sobre ofertas y
        servicios que puedan resultar de su interés, si usted nos da su
        consentimiento. En cualquier momento podrá modificar sus datos
        personales a través de su cuenta web de cliente, así como cuando realice
        un pedido.
      </p>
      <p>
        <b>3.2.4.</b> INGESIS AUTOMATIZACIÓN, S.L se reserva el derecho a
        denegar o retirar el acceso a el sitio web y/o a la aplicación o a los
        Servicios, en cualquier momento y sin necesidad de previo aviso a
        aquellos USUARIOS que incumplan los términos y condiciones de uso. 3.3.
        Limitación de responsabilidad de INGESIS AUTOMATIZACIÓN, S.L por el uso
        del sitio web y/o de la aplicación.
      </p>
      <p>
        <b>3.3.1.</b> El sitio web y/o la aplicación pueden albergar contenidos
        publicitarios o estar patrocinados. Los anunciantes y patrocinadores son
        los únicos responsables de asegurarse que el material remitido, para su
        inclusión en el sitio web y/o en la aplicación, cumple con las leyes que
        en cada caso puedan ser de aplicación. INGESIS AUTOMATIZACIÓN, S.L no
        será responsable de cualquier error, inexactitud o irregularidad que
        puedan contener los contenidos publicitarios o de los patrocinadores.
      </p>
      <p>
        <b>3.3.2.</b> INGESIS AUTOMATIZACIÓN, S.L hace todos los esfuerzos
        necesarios para ofrecer la información contenida en el sitio web y/o en
        la aplicación de forma veraz y sin errores tipográficos. En el caso que
        en algún momento se produjera algún error de este tipo, ajeno en todo
        momento a la voluntad de INGESIS AUTOMATIZACIÓN, S.L, se procedería a la
        subsanación de la errata a la mayor brevedad y se facilitará al Usuario
        la información y opciones establecidas en la legislación vigente.
      </p>
      <p>
        <b>3.3.3.</b> INGESIS AUTOMATIZACIÓN, S.L no será responsable frente al
        Usuario, en cuanto:
      </p>
      <p>
        La eficacia en la conexión, así como la velocidad de acceso a el sitio
        web y/o de la aplicación por el Usuario. Las interferencias, omisiones,
        interrupciones, virus informáticos, averías telefónicas o desconexiones
        en el funcionamiento operativo del sistema electrónico por causas ajenas
        a INGESIS AUTOMATIZACIÓN, S.L.
      </p>
      <p>
        La información introducida por usuarios y terceras personas, ajenas a
        INGESIS AUTOMATIZACIÓN, S.L y con los que no se mantenga ningún tipo de
        acuerdo ni colaboración contractual.
      </p>
      <br />
      <h5>4.Política de enlaces y exenciones de responsabilidad</h5>
      <p>
        <b>4.1</b> INGESIS AUTOMATIZACIÓN, S.L no se hace responsable del
        contenido de las páginas web a las que el Usuario pueda acceder a través
        de los enlaces establecidos en la plataforma y declara que en ningún
        caso procederá a examinar o ejercitar ningún tipo de control sobre el
        contenido de otras páginas de la red. Asimismo, tampoco garantizará la
        disponibilidad técnica, exactitud, veracidad, validez o legalidad de
        páginas ajenas a su propiedad a las que se pueda acceder por medio de
        los enlaces.
      </p>
      <p>
        <b>4.2</b> INGESIS AUTOMATIZACIÓN, S.L declara haber adoptado todas las
        medidas necesarias para evitar cualquier daño que, a los Usuarios de sus
        sitios webs y/o aplicación, pudieran derivarse de la navegación por sus
        sitios webs o aplicación. En consecuencia, INGESIS AUTOMATIZACIÓN, S.L
        no se hace responsable, en ningún caso, de los eventuales daños que por
        la navegación por Internet pudiera sufrir el Usuario.
      </p>
      <p>
        <b>4.3.</b> Si como consecuencia del registro opcional en nuestra web
        y/o aplicación, se dotara al usuario de un identificador y/o contraseña
        o éste creara su propia contraseña de acceso, el usuario se compromete a
        hacer un uso diligente y a mantener en secreto la contraseña y/o
        identificador para acceder a nuestros servicios, asumiendo que la
        contraseña y/o identificador es personal e intransferible. En
        consecuencia, los usuarios son responsables de la adecuada custodia y
        confidencialidad de los mencionados identificadores y/o contraseñas, y
        se comprometen a no ceder su uso a terceros, ya sea temporal o
        permanente, ni a permitir su acceso a personas ajenas. Será
        responsabilidad del usuario la utilización ilícita de los servicios por
        cualquier tercero ilegítimo que emplee a tal efecto una contraseña a
        causa de una utilización no diligente o de la pérdida de la misma por el
        usuario. En virtud de lo anterior, es obligación del usuario notificar
        de forma inmediata a la dirección de contacto de INGESIS AUTOMATIZACIÓN,
        S.L acerca de cualquier hecho que permita el uso indebido de los
        identificadores y/o contraseñas, tales como el robo, extravío, o el
        acceso no autorizado a los mismos, con el fin de proceder a su inmediata
        cancelación.
      </p>
      <p>
        Mientras no se comuniquen tales hechos, INGESIS AUTOMATIZACIÓN, S.L
        quedará eximido de cualquier responsabilidad que pudiera derivarse del
        uso indebido de los identificadores o contraseñas por terceros no
        autorizados.
      </p>
      <p>
        Recordamos al usuario que las contraseñas que INGESIS AUTOMATIZACIÓN,
        S.L le facilite o las que el mismo usuario genere como consecuencia de
        su registro en alguno de los servicios ofrecidos en esta web o en la app
        ALTID INGESIS deben cumplir con las siguientes características:
      </p>
      <ol>
        <li>
          Se evitarán nombres comunes o cualquier otra combinación que pueda
          identificar al usuario fácilmente, tales como fecha de nacimiento,
          matrículas de vehículos, etc.
        </li>
        <li>
          La contraseña contendrá un mínimo de ocho caracteres alfanuméricos.
        </li>
        <li>
          Las contraseñas deberán cambiarse en un plazo que, en ningún caso,
          será superior a un año.
        </li>
        <li>
          Se debe evitar la conservación de la contraseña. En documentos o
          archivos sin cifrar para evitar la sustracción de la misma.
        </li>
      </ol>
      <p>
        En caso de que olvide su contraseña de acceso, deberá seleccionar la
        opción “He olvidado mi contraseña” situada en la pantalla de acceso a su
        cuenta. Le enviaremos un enlace de verificación al correo electrónico
        que nos facilitó al crear su cuenta, desde donde podrá establecer una
        nueva contraseña.
      </p>
      <br />
      <h5>5. Vigencia del presente texto </h5>
      <p>
        <b>5.1.</b> INGESIS AUTOMATIZACIÓN, S.L se reserva el derecho a realizar
        las modificaciones que considere oportunas, sin aviso previo, en el
        contenido del sitio web y/o de la aplicación. Tanto en lo referente a
        los contenidos de los sitios, como en las condiciones de uso de la
        mismos. Dichas modificaciones podrán realizarse, a través del sitio web
        y/o de la aplicación, por cualquier forma admisible en derecho y serán
        de obligado cumplimiento durante el tiempo en que exclusivamente se
        encuentren publicadas y a disposición en el sitio web y/o aplicación y
        hasta que no sean modificadas por otras posteriores.
      </p>
      <p>
        <b>5.2.</b> INGESIS AUTOMATIZACIÓN, S.L se reserva el derecho a
        actualizar el uso, términos y condiciones aplicables a el sitio web y/o
        a la aplicación. Siendo siempre de aplicación, en beneficio del Usuario,
        los vigentes en el momento en que acceda al sitio web y/o aplicación,
        así como los vigentes a fecha de la posible solicitud de servicios, si
        fuera este el caso.
      </p>
      <br />
      <h5>6. Propiedad intelectual e industrial. </h5>
      <p>
        <b>6.1.</b> Todos los textos, imágenes, contenidos, logos, marcas
        registradas o no, así como los medios y formas de presentación y montaje
        del sitio web y/o de la aplicación, son titularidad originaria o
        derivativamente de INGESIS AUTOMATIZACIÓN, S.L. Consecuentemente son
        obras o contenidos protegidos como propiedad intelectual y/o propiedad
        industrial por el ordenamiento jurídico español conforme al Real Decreto
        legislativo 1/1996, de 12 de abril por el que se aprueba el Texto
        Refundido de la Ley de Propiedad intelectual y por la Ley 17/2001, de 7
        de diciembre, de Marcas, siéndoles además aplicables tanto la normativa
        española afín y comunitaria en los citados campos, como los tratados
        internacionales relativos a la materia y suscritos por España.
      </p>
      <p>
        <b>6.2.</b> Todos los derechos sobre los contenidos están reservados
        quedando prohibida expresamente la reproducción, distribución,
        comunicación pública, transformación y/o cualquier modalidad de uso, de
        la totalidad o parte de los contenidos del sitio web y/o de la
        aplicación sin el consentimiento expreso de INGESIS AUTOMATIZACIÓN, S.L.
      </p>
      <p>
        <b>6.3.</b> Cualquier material promocional (catálogos, carteles, etc.)
        que se entregue a los clientes para el uso y consulta a efectos
        comerciales, a título particular o empresarial, únicamente se utilizará
        conforme a las instrucciones y fines autorizados por INGESIS
        AUTOMATIZACIÓN, S.L para la venta de sus productos. Los clientes no
        deben considerarse como agentes de INGESIS AUTOMATIZACIÓN, S.L, ya que
        esto implicaría que están actuando en nombre de, o con la autorización
        del equipo de gestión de INGESIS AUTOMATIZACIÓN, S.L. La marca "INGESIS
        AUTOMATIZACIÓN, S.L", u otras de las que sea titular, así como sus
        dominios web o aplicaciones, no pueden utilizarse sin previa
        autorización escrita de INGESIS AUTOMATIZACIÓN, S.L. Los clientes que
        deseen publicitar deben consultar con el departamento comercial de
        INGESIS AUTOMATIZACIÓN, S.L de antemano para recibir su aprobación.
      </p>
      <br />
      <h5>7. Legislación aplicable y jurisdicción. </h5>
      <p>
        <b>7.1.</b> INGESIS AUTOMATIZACIÓN, S.L se reserva asimismo la facultad
        de presentar las acciones civiles o penales que considere oportunas por
        la utilización indebida de sus páginas web, aplicaciones y contenidos, o
        por el incumplimiento de las presentes condiciones.
      </p>
      <p>
        <b>7.2</b> Las partes acuerdan, de sus libres voluntades que la relación
        entre el usuario y INGESIS AUTOMATIZACIÓN, S.L se regirá por la
        normativa española vigente y serán competentes para decidir sobre
        cualquier controversia que se pudiera suscitar entre el Usuario y
        INGESIS AUTOMATIZACIÓN, S.L, los juzgados o tribunales de la ciudad de
        ALZIRA.
      </p>
      <br />
      <h6>ALBERIC, a 03 de NOVIEMBRE de 2021</h6>
    </div>
  );
}
