import { Card, CardContent } from "@material-ui/core";
import React from "react";
import Gbar from "../../../assets/images/g-bar.png";
import EquipoStockEmergenciaTable from "../../molecule/equipoStockEmergenciaTable/EquipoStockEmergenciaTable";

export default function CardStockEmergencia(props) {
  const {
    id,
    plantaId,
    vacon,
    loadingSE,
    setLoadingSE,
    getStockEmerg,
    stockE,
    setStockE,
    stock_emergencia
  } = props;
  return (
    <>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: 25,
          marginTop: 25,
          backgroundImage: `url("${Gbar}")`,
          backgroundSize: "cover",
          height: 3,
          width: "100%",
        }}
      />
      <Card>
        {/* <CardHeader title={i18n.t("equipo.emergencyStockReference")} /> */}
        <CardContent>
          <EquipoStockEmergenciaTable
            equipoId={id}
            plantaId={plantaId}
            //keyuser={keyuser}
            vacon={vacon}
            loading={loadingSE}
            setLoading={setLoadingSE}
            getStock={getStockEmerg}
            stock={stockE}
            setStock={setStockE}
            sustituciones={stock_emergencia}
          />
        </CardContent>
      </Card>
    </>
  );
}
