import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import RiskCalculation from "../../../helpers/riskCalculation.json";
import i18n from "../../../locales/i18n";
import MouseOverPopover from "../../MouseOverPopover";
import { HelpOutline } from "@material-ui/icons";

function FormularioRiesgoVariador({
  equipo,
  colWith,
  handleInputChange,
  readOnly,
}) {
  const getRisk = () => {
    var risk = RiskCalculation.find(
      (c) =>
        c.lifeCycle === equipo.cicloVida &&
        c.criticality === equipo.criticidad &&
        c.condition === equipo.estado
    );

    if (!risk)
      return {
        value: -1,
        text: i18n.t("common.indefinido"),
        recommendation: i18n.t("common.indefinido"),
      };
    return risk.result === 0
      ? {
          value: 0,
          text: i18n.t(`common.alto`),
          recommendation: "Retrofit-replacement",
        }
      : risk.result === 1
      ? {
          value: 1,
          text: i18n.t(`common.medio`),
          recommendation: "Spare Drive",
        }
      : risk.result === 2
      ? { value: 2, text: i18n.t(`common.bajo`), recommendation: "Exchange" }
      : {
          value: -1,
          text: i18n.t(`common.indefinido`),
          recommendation: i18n.t("common.indefinido"),
        };
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={colWith} lg={4}>
        <FormControl fullWidth required>
          <InputLabel style={{ width: "134%" }}>
            <>
              {i18n.t("equipo.cicloVida")}
              <MouseOverPopover
                content={
                  <React.Fragment>
                    <Typography component="span" style={{ display: "block" }}>
                      <b>{i18n.t("common.activo.singularM")}</b>:{" "}
                      {i18n.t("equipo.cicloVidaHelp.active")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.limitado")}</b>:{" "}
                      {i18n.t("equipo.cicloVidaHelp.limited")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.inactivo")}</b>:{" "}
                      {i18n.t("equipo.cicloVidaHelp.inactive")}
                    </Typography>
                  </React.Fragment>
                }
                parent={
                  <HelpOutline
                    style={{
                      position: "absolute",
                      right: 0,
                      top: -5,
                    }}
                  />
                }
              />
            </>
          </InputLabel>
          <Select
            required
            value={
              equipo.cicloVida !== undefined ? equipo.cicloVida.toString() : 3
            }
            style={{
              backgroundColor:
                equipo.cicloVida === 0
                  ? "#66bb6a"
                  : equipo.cicloVida === 1
                  ? "#ff7043"
                  : equipo.cicloVida === 2
                  ? "#ef5350"
                  : "initial",
            }}
            name="cicloVida"
            displayEmpty
            onChange={handleInputChange}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            <MenuItem value={0}>{i18n.t("common.activo.singularM")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.limitado")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.inactivo")}</MenuItem>
            <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={colWith} lg={4}>
        <FormControl fullWidth required>
          <InputLabel style={{ width: "134%" }}>
            <>
              {i18n.t("equipo.estadoEquipo")}
              <MouseOverPopover
                content={
                  <React.Fragment>
                    <Typography component="span" style={{ display: "block" }}>
                      <b>{i18n.t("common.bueno")}</b>:{" "}
                      {i18n.t("equipo.estadoHelp.good")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.medio")}</b>:{" "}
                      {i18n.t("equipo.estadoHelp.medium")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.pobre")}</b>:{" "}
                      {i18n.t("equipo.estadoHelp.poor")}
                    </Typography>
                  </React.Fragment>
                }
                parent={
                  <HelpOutline
                    style={{
                      position: "absolute",
                      right: 0,
                      top: -5,
                    }}
                  />
                }
              />
            </>
          </InputLabel>
          <Select
            required
            value={equipo.estado !== undefined ? equipo.estado.toString() : 3}
            style={{
              backgroundColor:
                equipo.estado === 0
                  ? "#66bb6a"
                  : equipo.estado === 1
                  ? "#ff7043"
                  : equipo.estado === 2
                  ? "#ef5350"
                  : "initial",
            }}
            name="estado"
            displayEmpty
            onChange={handleInputChange}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            <MenuItem value={0}>{i18n.t("common.bueno")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.pobre")}</MenuItem>
            <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={colWith} lg={4}>
        <FormControl fullWidth required>
          <InputLabel style={{ width: "134%" }}>
            <>
              {i18n.t("equipo.criticidad")}
              <MouseOverPopover
                content={
                  <React.Fragment>
                    <Typography component="span" style={{ display: "block" }}>
                      <b>{i18n.t("common.alto")}</b>:{" "}
                      {i18n.t("equipo.criticidadHelp.high")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.medio")}</b>:{" "}
                      {i18n.t("equipo.criticidadHelp.medium")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.bajo")}</b>:{" "}
                      {i18n.t("equipo.criticidadHelp.low")}
                    </Typography>
                  </React.Fragment>
                }
                parent={
                  <HelpOutline
                    style={{
                      position: "absolute",
                      right: 0,
                      top: -5,
                    }}
                  />
                }
              />
            </>
          </InputLabel>
          <Select
            required
            value={
              equipo.criticidad !== undefined
                ? equipo.criticidad.toString()
                : "3"
            }
            style={{
              backgroundColor:
                equipo.criticidad === 2
                  ? "#66bb6a"
                  : equipo.criticidad === 1
                  ? "#ff7043"
                  : equipo.criticidad === 0
                  ? "#ef5350"
                  : "initial",
            }}
            name="criticidad"
            displayEmpty
            onChange={handleInputChange}
            inputProps={{
              readOnly: readOnly,
            }}
          >
            <MenuItem value={0}>{i18n.t("common.alto")}</MenuItem>
            <MenuItem value={1}>{i18n.t("common.medio")}</MenuItem>
            <MenuItem value={2}>{i18n.t("common.bajo")}</MenuItem>
            <MenuItem value={3}>{i18n.t("common.indefinido")}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={colWith} lg={4}>
        <TextField
          fullWidth
          value={getRisk().text}
          label={
            <>
              {i18n.t("equipo.riesgo")}
              <MouseOverPopover
                content={
                  <React.Fragment>
                    <Typography component="span" style={{ display: "block" }}>
                      <b>{i18n.t("common.alto")}</b>:{" "}
                      {i18n.t("equipo.riesgoHelp.high")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.medio")}</b>:{" "}
                      {i18n.t("equipo.riesgoHelp.medium")}
                    </Typography>
                    <Typography
                      component="span"
                      style={{ marginTop: 5, display: "block" }}
                    >
                      <b>{i18n.t("common.bajo")}</b>:{" "}
                      {i18n.t("equipo.riesgoHelp.low")}
                    </Typography>
                  </React.Fragment>
                }
                parent={
                  <HelpOutline
                    style={{
                      position: "absolute",
                      right: 0,
                      top: -5,
                    }}
                  />
                }
              />
            </>
          }
          InputLabelProps={{
            shrink: true,
            style: { width: "134%" },
          }}
          inputProps={{
            readOnly: true,
            style: {
              backgroundColor:
                getRisk().value === 2
                  ? "#66bb6a"
                  : getRisk().value === 1
                  ? "#ff7043"
                  : getRisk().value === 0
                  ? "#ef5350"
                  : "initial",
            },
          }}
        />
      </Grid>
      <Grid item xs={colWith} lg={4}>
        <TextField
          fullWidth
          value={getRisk().recommendation}
          label={i18n.t("equipo.recomendacion")}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default FormularioRiesgoVariador;
