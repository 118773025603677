import React, { useState, useEffect, useRef, useCallback } from "react";

import i18n from "../../../locales/i18n";
import {
  UsersAPI,
  ClientesAPI,
  PlantasAPI,
  ZonasAPI,
  PartnersAPI,
} from "../../../services/API";
import MaterialTable from "material-table";
import RelativeBackdrop from "../../../components/RelativeBackdrop";
import {
  TextField,
  Card,
  Grid,
  CardHeader,
  CardContent,
  IconButton,
  CardActions,
  Avatar,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
  InputAdornment,
  Paper,
  Tabs,
  Tab,
  Button,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  Save,
  ArrowBack,
  AssignmentInd,
  Visibility,
  VisibilityOff,
  Delete,
  Room,
  Business,
  Group,
  Lock,
  PeopleOutline,
  NotInterested,
  Check,
  ErrorOutlineOutlined,
} from "@material-ui/icons";
import AlertDialog from "../../../components/AlertDialog";
import AuthService from "../../../services/AuthService";
import TabPanel from "../../../components/TabPanel";
import swal from "sweetalert2";

export default function UserDetails(props) {
  const currentUserId = AuthService.getUserInfo().userid;
  const isGestorCliente = AuthService.isGestorFromSomeClient();
  const isGestorPlanta = AuthService.isGestorFromSomePlant();
  const isGestorZona = AuthService.isGestorFromSomeZone();
  const isGestorPartner = AuthService.isGestorFromSomePartner();

  const { keyuser } = props;
  const { setNavBarTitle } = props;
  const { id } = props.match.params;
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [cargo, setCargo] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [repeatPwd, setRepeatPwd] = useState("");
  const [showRepeatPwd, setShowRepeatPwd] = useState(false);
  const [inactivo, setInactivo] = useState(false);
  const [sinPartner, setSinPartner] = useState(false);

  const [formErrors, setFormErrors] = useState({});

  const [partnersOptions, setPartnersOptions] = useState({});
  const [clientesOptions, setClientesOptions] = useState({});
  const [plantasOptions, setPlantasOptions] = useState({});
  const [zonasOptions, setZonasOptions] = useState({});
  const [partners, setPartners] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [zonas, setZonas] = useState([]);

  const [tabValue, setTabValue] = useState(
    keyuser
      ? 0
      : isGestorCliente
      ? 1
      : isGestorPlanta
      ? 2
      : isGestorZona
      ? 3
      : false
  );
  const [partnerRoles, setPartnerRoles] = useState([]);
  const [clientRoles, setClientRoles] = useState([]);
  const [plantRoles, setPlantRoles] = useState([]);
  const [zoneRoles, setZoneRoles] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [emailReminder, setEmailReminder] = useState(false);
  const deleteDialog = useRef(null);

  const getUser = useCallback(() => {
    return UsersAPI.get(id)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          window.location.replace("/unauthorized");
          throw new Error(error);
        }
      })
      .then(({ user, readOnly, partner, inactivo, sinPartner }) => {
        setEmail(user.email);
        setNombre(user.nombre);
        setApellidos(user.apellidos);
        setCargo(user.cargo);
        setTelefono(user.telefono);
        setEmailReminder(user.emailReminder);
        setInactivo(inactivo);
        setSinPartner(sinPartner);
        var notEditable =
          (readOnly && partner !== AuthService.getPartnerId()) || inactivo;
        setReadOnly(notEditable);
      })
      .catch(() => {});
  }, [id]);

  const getAccess = useCallback(() => {
    return UsersAPI.getAccess(id)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          var error = await response.text();
          throw new Error(error);
        }
      })
      .then(({ accesses, permissions }) => {
        setPartnerRoles(
          accesses
            .filter((e) => e.level === "partner")
            .map((e) => ({ ...e, elementAccess: e.elementAccess + " " }))
        );
        setClientRoles(
          accesses
            .filter((e) => e.level === "cliente")
            .map((e) => ({ ...e, elementAccess: e.elementAccess + " " }))
        );
        setPlantRoles(
          accesses
            .filter((e) => e.level === "planta")
            .map((e) => ({ ...e, elementAccess: e.elementAccess + " " }))
        );
        setZoneRoles(
          accesses
            .filter((e) => e.level === "zona")
            .map((e) => ({ ...e, elementAccess: e.elementAccess + " " }))
        );
        setPermissions(permissions);
      })
      .catch(() => {});
  }, [id]);

  const getPartnerOptions = async () => {
    return await PartnersAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        var partners = {};
        data.forEach((element) => {
          partners[element.id + " "] = element.razonSocial;
        });
        setPartners(data);
        setPartnersOptions(partners);
      })
      .catch(() => {});
  };

  const getClientsOptions = async () => {
    return await ClientesAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        var clients = {};
        data.forEach((element) => {
          clients[element.id + " "] = element.razonSocial;
        });
        setClientes(data);
        setClientesOptions(clients);
      })
      .catch(() => {});
  };

  const getPlantsOptions = async () => {
    return await PlantasAPI.getWithUserAccessPermisionsLight()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        var plants = {};
        data.plantas.forEach((element) => {
          plants[element.id + " "] =
            element.denominacion + " (" + element.cliente.razonSocial + ")";
        });
        setPlantas(data.plantas);
        setPlantasOptions(plants);
      })
      .catch(() => {});
  };

  const getZonesOptions = async () => {
    return await ZonasAPI.get()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((data) => {
        var zones = {};
        data.forEach((element) => {
          zones[element.id + " "] =
            element.descripcion + " (" + element.planta["denominacion"] + ")";
        });
        setZonas(data);
        setZonasOptions(zones);
      })
      .catch(() => {});
  };

  const updateUser = async () => {
    let updateFormErrors = {};
    if (nombre === "") {
      updateFormErrors.nombre = i18n.t("textField.required");
    }
    if (apellidos === "") {
      updateFormErrors.apellidos = i18n.t("textField.required");
    }
    if (id === currentUserId && email === "") {
      updateFormErrors.email = i18n.t("textField.required");
    }
    if (id === currentUserId && password === "") {
      updateFormErrors.password = i18n.t("textField.required");
    }
    if (id === currentUserId && password !== repeatPwd) {
      updateFormErrors.repeatPwd = i18n.t(
        "gestorUsuarios.detallesUsuario.passwordNoCoincide"
      );
    }

    if (Object.entries(updateFormErrors).length === 0) {
      setLoading(true);
      let userRequest = {
        nombre,
        apellidos,
        email,
        cargo,
        telefono,
        password,
        repeatPwd,
        emailReminder,
      };

      UsersAPI.update(id, userRequest)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((data) => {
          if (data.status === "error") {
            alert(data.message);
          } else {
            setPassword("");
            setRepeatPwd("");
          }

          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      setFormErrors(updateFormErrors);
    }
  };

  const cambioEstadoUsuario = async () => {
    setLoading(true);
    swal
      .fire({
        title: inactivo
          ? i18n.t("gestorUsuarios.detallesUsuario.alertState.titleEnable")
          : i18n.t("gestorUsuarios.detallesUsuario.alertState.titleDisable"),
        text: inactivo
          ? i18n.t("gestorUsuarios.detallesUsuario.alertState.textEnable")
          : i18n.t("gestorUsuarios.detallesUsuario.alertState.textDisable"),
        icon: "info",
        showCancelButton: true,
        cancelButtonText: i18n.t(
          "gestorUsuarios.detallesUsuario.alertDialog.cancelText"
        ),
      })
      .then((value) => {
        if (value.isConfirmed) {
          if (inactivo) {
            UsersAPI.habilitarUsuario(id)
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error("Something went wrong");
                }
              })
              .then((data) => {
                setInactivo(data.inactivo);
                setReadOnly(false);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
              });
          } else {
            UsersAPI.deshabilitarUsuario(id)
              .then((response) => {
                if (response.ok) {
                  return response.json();
                } else {
                  throw new Error("Something went wrong");
                }
              })
              .then((data) => {
                setInactivo(data.inactivo);
                setReadOnly(true);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
              });
          }
        }
        setLoading(false);
      });
  };

  const addRole = async (newData, level) => {
    if (level === "partner" && partnerRoles.length > 0) {
      swal.fire({
        title: "No permitido",
        text: "Un usuario no pude tener permisos de diversos Partners",
        icon: "warning",
        confirmButtonText: i18n.t("common.aceptar"),
      });
    } else {
      setLoading(true);
      newData.elementAccess = newData.elementAccess.replace(" ", "");
      newData.level = level;
      UsersAPI.addAccess(id, newData)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then(() => {
          getAccess();
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const updateRole = async (newData, level) => {
    setLoading(true);
    newData.elementAccess = newData.elementAccess.replace(" ", "");
    newData.level = level;
    UsersAPI.updateAccess(id, newData)
      .then((response) => {
        if (response.ok) {
          getAccess();
          setLoading(false);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteRole = async (oldData, level) => {
    if (
      clientRoles.length +
        plantRoles.length +
        zoneRoles.length +
        partnerRoles.length <=
      1
    ) {
      swal.fire(
        i18n.t("common.noPermitido"),
        i18n.t("gestorUsuarios.detallesUsuario.lastRoleMessage"),
        "warning"
      );
      return;
    }

    setLoading(true);
    oldData.level = level;
    UsersAPI.removeAccess(id, oldData)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then(() => {
        getAccess();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const deleteUser = async () => {
    setLoading(true);
    deleteDialog.current.close();
    if (keyuser) {
      UsersAPI.remove(id)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then(() => {
          alert(i18n.t("gestorUsuarios.detallesUsuario.borrarOk"));
          props.history.goBack();
        })
        .catch(() => {
          setLoading(false);
          alert(i18n.t("gestorUsuarios.detallesUsuario.borrarError"));
        });
    } else {
      const partnerId = AuthService.getPartnerId();
      UsersAPI.removePartnerAcces(id, partnerId)
        .then((response) => {
          if (response.ok) {
            return;
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then(() => {
          alert(i18n.t("gestorUsuarios.detallesUsuario.borrarOk"));
          props.history.goBack();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          alert(i18n.t("gestorUsuarios.detallesUsuario.borrarError"));
        });
    }
  };

  const resetPassword = async () => {
    setLoading(true);
    UsersAPI.resetPassword(id)
      .then((response) => {
        if (response.ok) {
          alert("Contraseña restablecida correctamente");
          setLoading(false);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .catch(() => {
        alert("No se ha podido restablecer la contraseña");
        setLoading(false);
      });
  };

  const isPartnerEditable = (rowData) => {
    const { elementAccess } = rowData;
    /** @type {number} */
    const elementAccesNum = parseInt(elementAccess);
    if (!elementAccess || !permissions) return false;

    const imGestorFromPartner =
      AuthService.isGestorFromPartner(elementAccesNum);
    const isGestorFromPartner = permissions.partnerAccesos.some(
      (a) =>
        a.clienteId === elementAccesNum && a.appRoleName === "gestor-partner"
    );

    return (
      (keyuser ||
        (rowData.roleAccess === "usuario-Gestor" &&
          imGestorFromPartner &&
          !isGestorFromPartner)) &&
      !inactivo
    );
  };

  const isClienteEditable = (rowData) => {
    const { elementAccess } = rowData;
    /** @type {number} */
    const elementAccesNum = parseInt(elementAccess);
    if (!elementAccess || !permissions) return false;

    const imGestorFromCliente = AuthService.isGestorFromClient(elementAccesNum);
    const imGestorFromPartner = AuthService.isGestorFromPartner(
      AuthService.getPartnerId()
    );
    const isGestorFromCliente = permissions.clienteAccesos.some(
      (a) =>
        a.clienteId === elementAccesNum && a.appRoleName === "gestor-cliente"
    );

    return (
      (keyuser ||
        (rowData.roleAccess === "usuario-cliente" &&
          imGestorFromCliente &&
          !isGestorFromCliente) ||
        imGestorFromPartner) &&
      !inactivo
    );
  };

  const isPlantaEditable = (rowData) => {
    const { elementAccess } = rowData;
    if (!elementAccess || !permissions) return false;

    const planta = plantas.find((p) => p.id === parseInt(elementAccess));
    const cliente = clientes.find((c) => c.id === planta.clienteId);

    const imGestorFromPlanta = AuthService.isGestorFromPlant(planta.id);
    const isGestorFromPlanta = permissions.plantaAccesos.some(
      (a) => a.plantaId === planta.id && a.appRoleName === "gestor-planta"
    );

    const imGestorFromCliente = AuthService.isGestorFromClient(cliente.id);
    const isGestorFromCliente = permissions.clienteAccesos.some(
      (a) => a.clienteId === cliente.id && a.appRoleName === "gestor-cliente"
    );

    return (
      (keyuser ||
        (imGestorFromCliente && !isGestorFromCliente) ||
        (imGestorFromPlanta && !isGestorFromPlanta)) &&
      !inactivo
    );
  };

  const isZonaEditable = (rowData) => {
    const { elementAccess } = rowData;
    if (!elementAccess || !permissions) return false;

    const zona = zonas.find((z) => z.id === parseInt(elementAccess));
    const planta = plantas.find((p) => p.id === zona.plantaId);
    const cliente = clientes.find((c) => c.id === planta.clienteId);

    const imGestorFromZona = AuthService.isGestorFromZone(zona.id);
    const isGestorFromZona = permissions.zonaAccesos.some(
      (a) => a.plantaZonaId === zona.id && a.appRoleName === "gestor-zona"
    );

    const imGestorFromPlanta = AuthService.isGestorFromPlant(planta.id);
    const isGestorFromPlanta = permissions.plantaAccesos.some(
      (a) => a.plantaId === planta.id && a.appRoleName === "gestor-planta"
    );

    const imGestorFromCliente = AuthService.isGestorFromClient(cliente.id);
    const isGestorFromCliente = permissions.clienteAccesos.some(
      (a) => a.clienteId === cliente.id && a.appRoleName === "gestor-cliente"
    );

    const imGestorFromPartner = AuthService.isGestorFromPartner(
      AuthService.getPartnerId()
    );

    return (
      (keyuser ||
        (imGestorFromCliente && !isGestorFromCliente) ||
        (imGestorFromPlanta && !isGestorFromPlanta) ||
        (imGestorFromZona && !isGestorFromZona) ||
        imGestorFromPartner) &&
      !inactivo
    );
  };

  useEffect(() => {
    setNavBarTitle(i18n.t("gestorUsuarios.titulo"));
    document.getElementById("main-content").scrollTop = 0;
  }, [setNavBarTitle]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getUser(),
      getAccess(),
      getPartnerOptions(),
      getClientsOptions(),
      getPlantsOptions(),
      getZonesOptions(),
    ]).then(() => setLoading(false));
  }, [getUser, getAccess]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <RelativeBackdrop hidden={!loading}>
            <Card>
              <CardHeader
                title={i18n.t("gestorUsuarios.detallesUsuario.tituloCard")}
                subheader={email}
                avatar={
                  <Avatar style={{ backgroundColor: "#f50057" }}>
                    <AssignmentInd />
                  </Avatar>
                }
              />
              <CardContent>
                <form autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item container>
                      <Grid item xs={6} md={4} lg={3} className="pr-3">
                        <TextField
                          required
                          fullWidth
                          value={nombre ? nombre : ""}
                          label={i18n.t("user.nombre")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="nombre"
                          onChange={(event) => {
                            setNombre(event.target.value);
                          }}
                          error={formErrors["nombre"] !== undefined}
                          helperText={
                            formErrors["nombre"] !== undefined
                              ? formErrors["nombre"]
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="pl-3">
                        <TextField
                          required
                          fullWidth
                          value={apellidos ? apellidos : ""}
                          label={i18n.t("user.apellidos")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="apellidos"
                          onChange={(event) => setApellidos(event.target.value)}
                          error={formErrors["apellidos"] !== undefined}
                          helperText={
                            formErrors["apellidos"] !== undefined
                              ? formErrors["apellidos"]
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item container>
                      <Grid item xs={6} md={4} lg={3} className="pr-3">
                        <TextField
                          fullWidth
                          value={cargo ? cargo : ""}
                          label={i18n.t("user.cargo")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="cargo"
                          onChange={(event) => setCargo(event.target.value)}
                          error={formErrors["cargo"] !== undefined}
                          helperText={
                            formErrors["cargo"] !== undefined
                              ? formErrors["cargo"]
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={6} md={4} lg={3} className="pl-3">
                        <TextField
                          fullWidth
                          value={telefono ? telefono : ""}
                          label={i18n.t("user.telefono")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          name="telefono"
                          onChange={(event) => setTelefono(event.target.value)}
                          error={formErrors["telefono"] !== undefined}
                          helperText={
                            formErrors["telefono"] !== undefined
                              ? formErrors["telefono"]
                              : null
                          }
                        />
                      </Grid>
                    </Grid>

                    <Grid item container>
                      <Grid item xs={12} md={8} lg={6}>
                        <TextField
                          required
                          fullWidth
                          value={email ? email : ""}
                          label={i18n.t("user.email")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            readOnly: id !== currentUserId,
                          }}
                          name="email"
                          autoComplete="off"
                          onChange={(event) => setEmail(event.target.value)}
                          error={formErrors["email"] !== undefined}
                          helperText={
                            formErrors["email"] !== undefined
                              ? formErrors["email"]
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    {id === currentUserId && (
                      <>
                        <Grid item container>
                          <Grid item xs={12} md={8} lg={6}>
                            <FormControl
                              error={formErrors["password"] !== undefined}
                              required
                              fullWidth
                            >
                              <InputLabel htmlFor="password" shrink>
                                {i18n.t("user.contraseña")}
                              </InputLabel>
                              <Input
                                id="password"
                                type={showPwd ? "text" : "password"}
                                name="password"
                                value={password}
                                autoComplete="new-password"
                                onChange={(event) =>
                                  setPassword(event.target.value)
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setShowPwd(!showPwd)}
                                      onMouseDown={(event) => {
                                        event.preventDefault();
                                      }}
                                    >
                                      {showPwd ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {formErrors["password"] !== undefined && (
                                <FormHelperText id="password-error-text">
                                  {formErrors["password"] !== undefined
                                    ? formErrors["password"]
                                    : null}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item container>
                          <Grid item xs={12} md={8} lg={6}>
                            <FormControl
                              error={formErrors["repeatPwd"] !== undefined}
                              required
                              fullWidth
                            >
                              <InputLabel htmlFor="password" shrink>
                                {i18n.t("user.repetirContraseña")}
                              </InputLabel>
                              <Input
                                id="repeatPassword"
                                type={showRepeatPwd ? "text" : "password"}
                                name="repeatPassword"
                                value={repeatPwd}
                                onChange={(event) =>
                                  setRepeatPwd(event.target.value)
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowRepeatPwd(!showRepeatPwd)
                                      }
                                      onMouseDown={(event) => {
                                        event.preventDefault();
                                      }}
                                    >
                                      {showPwd ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              {formErrors["repeatPwd"] !== undefined && (
                                <FormHelperText id="repeatPassword-error-text">
                                  {formErrors["repeatPwd"] !== undefined
                                    ? formErrors["repeatPwd"]
                                    : null}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {/* COMENTADO durante desarrollo, descomentar cuando estén todas las partes terminadas */}
                    {currentUserId !== id &&
                      !readOnly &&
                      isGestorPartner &&
                      plantRoles.length > 0 && (
                        <Grid item container>
                          <Grid item xs={12} md={8} lg={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={emailReminder}
                                  onChange={() =>
                                    setEmailReminder(!emailReminder)
                                  }
                                  color="secondary"
                                />
                              }
                              label={i18n.t("user.reminder")}
                            />
                          </Grid>
                        </Grid>
                      )}
                    {currentUserId === id &&
                      !readOnly &&
                      isGestorPlanta &&
                      !isGestorPartner &&
                      !isGestorCliente && (
                        <Grid item container>
                          <Grid item xs={12} md={8} lg={6}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={emailReminder}
                                  onChange={() =>
                                    setEmailReminder(!emailReminder)
                                  }
                                  color="secondary"
                                />
                              }
                              label={i18n.t("user.reminder")}
                            />
                          </Grid>
                        </Grid>
                      )}
                    {keyuser && (
                      <Grid item container>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<Lock />}
                          onClick={resetPassword}
                        >
                          Reset password
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </form>
              </CardContent>
              <CardActions disableSpacing>
                <IconButton onClick={() => props.history.goBack()}>
                  <ArrowBack />
                </IconButton>
                {!readOnly && (
                  <IconButton onClick={() => updateUser()}>
                    <Save />
                  </IconButton>
                )}
                {currentUserId !== id && isGestorPartner && !sinPartner && (
                  <Button
                    className="text-danger"
                    onClick={() => {
                      cambioEstadoUsuario();
                    }}
                  >
                    {inactivo ? (
                      <>
                        {i18n.t("gestorUsuarios.detallesUsuario.habilitar")}
                        <Check className="ml-2" />
                      </>
                    ) : (
                      <>
                        {i18n.t("gestorUsuarios.detallesUsuario.deshabilitar")}
                        <NotInterested className="ml-2" />
                      </>
                    )}
                  </Button>
                )}
                {sinPartner && (
                  <div style={{ color: "red" }}>
                    <ErrorOutlineOutlined className="ml-2" />
                    Sin Partner
                  </div>
                )}
                {currentUserId !== id && !readOnly && isGestorPartner && (
                  <Button
                    className="text-danger"
                    style={{ marginLeft: "auto" }}
                    onClick={() => deleteDialog.current.open()}
                  >
                    {i18n.t("gestorUsuarios.detallesUsuario.borrar")}
                    <Delete className="ml-2" />
                  </Button>
                )}
              </CardActions>
            </Card>
          </RelativeBackdrop>
        </Grid>
      </Grid>
      <AlertDialog
        ref={deleteDialog}
        title={i18n.t("gestorUsuarios.detallesUsuario.alertDialog.title")}
        text={i18n.t("gestorUsuarios.detallesUsuario.alertDialog.text")}
        cancelText={i18n.t(
          "gestorUsuarios.detallesUsuario.alertDialog.cancelText"
        )}
        confirmText={i18n.t(
          "gestorUsuarios.detallesUsuario.alertDialog.confirmText"
        )}
        confirmAction={() => deleteUser()}
      />
      {currentUserId !== id && (
        <>
          <Paper square className="mt-4">
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
              centered
              indicatorColor="secondary"
              textColor="secondary"
            >
              {keyuser && (
                <Tab
                  value={0}
                  label={i18n.t("gestorUsuarios.nivelAcceso.partner")}
                  icon={<PeopleOutline />}
                  id="simple-tab-0"
                />
              )}
              {isGestorCliente && (
                <Tab
                  value={1}
                  label={i18n.t("gestorUsuarios.nivelAcceso.cliente")}
                  icon={<Group />}
                  id="simple-tab-0"
                />
              )}
              {isGestorPlanta && (
                <Tab
                  value={2}
                  label={i18n.t("gestorUsuarios.nivelAcceso.planta")}
                  icon={<Business />}
                  id="simple-tab-1"
                />
              )}
              {isGestorZona && (
                <Tab
                  value={3}
                  label={i18n.t("gestorUsuarios.nivelAcceso.zona")}
                  icon={<Room />}
                  id="simple-tab-2"
                />
              )}
            </Tabs>
          </Paper>
          {keyuser && (
            <TabPanel
              className="TabPanelOnlyTopPadding"
              value={tabValue}
              index={0}
            >
              <RelativeBackdrop hidden={!loading}>
                <Card>
                  <CardHeader
                    title={i18n.t("gestorUsuarios.nivelAcceso.partner")}
                    subheader={email}
                    avatar={
                      <Avatar style={{ backgroundColor: "#f50057" }}>
                        <PeopleOutline />
                      </Avatar>
                    }
                  ></CardHeader>
                  <CardContent className="p-0">
                    <MaterialTable
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                      }}
                      // @ts-ignore
                      localization={i18n.t("dataTableProps")}
                      data={partnerRoles.filter((pr) =>
                        partners.some((p) => p.id + " " === pr.elementAccess)
                      )}
                      columns={[
                        {
                          title: i18n.t("gestorUsuarios.nivelAcceso.partner"),
                          field: "elementAccess",
                          lookup: partnersOptions,
                          validate: (rowData) =>
                            rowData.elementAccess !== undefined,
                        },
                        {
                          title: i18n.t("common.rol"),
                          field: "roleAccess",
                          lookup: {
                            "gestor-partner": i18n.t("roles.gestor-partner"),
                            "usuario-partner": i18n.t("roles.usuario-partner"),
                          },
                          validate: (rowData) =>
                            isPartnerEditable(rowData) &&
                            rowData.roleAccess !== undefined,
                        },
                      ]}
                      editable={{
                        isEditable: (rowData) => isPartnerEditable(rowData),
                        isDeletable: (rowData) => isPartnerEditable(rowData),
                        onRowAdd: (newData) => addRole(newData, "partner"),
                        onRowUpdate: (newData) =>
                          updateRole(newData, "partner"),
                        onRowDelete: (oldData) =>
                          deleteRole(oldData, "partner"),
                      }}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        addRowPosition: "first",
                      }}
                    />
                  </CardContent>
                </Card>
              </RelativeBackdrop>
            </TabPanel>
          )}
          {isGestorCliente && (
            <TabPanel
              className="TabPanelOnlyTopPadding"
              value={tabValue}
              index={1}
            >
              <RelativeBackdrop hidden={!loading}>
                <Card>
                  <CardHeader
                    title={i18n.t("gestorUsuarios.nivelAcceso.cliente")}
                    subheader={email}
                    avatar={
                      <Avatar style={{ backgroundColor: "#f50057" }}>
                        <Group />
                      </Avatar>
                    }
                  ></CardHeader>
                  <CardContent className="p-0">
                    <MaterialTable
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                      }}
                      // @ts-ignore
                      localization={i18n.t("dataTableProps")}
                      data={clientRoles.filter((cr) =>
                        clientes.some((c) => c.id + " " === cr.elementAccess)
                      )}
                      columns={[
                        {
                          title: i18n.t("gestorUsuarios.nivelAcceso.cliente"),
                          field: "elementAccess",
                          lookup: clientesOptions,
                          validate: (rowData) =>
                            rowData.elementAccess !== undefined,
                        },
                        {
                          title: i18n.t("common.rol"),
                          field: "roleAccess",
                          lookup: {
                            "gestor-cliente": i18n.t("roles.gestor-cliente"),
                            "usuario-cliente": i18n.t("roles.usuario-cliente"),
                          },
                          validate: (rowData) =>
                            isClienteEditable(rowData) &&
                            rowData.roleAccess !== undefined,
                        },
                      ]}
                      editable={{
                        isEditable: (rowData) => isClienteEditable(rowData),
                        isDeletable: (rowData) => isClienteEditable(rowData),
                        onRowAdd: (newData) => addRole(newData, "cliente"),
                        onRowUpdate: (newData) =>
                          updateRole(newData, "cliente"),
                        onRowDelete: (oldData) =>
                          deleteRole(oldData, "cliente"),
                      }}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        addRowPosition: "first",
                      }}
                    />
                  </CardContent>
                </Card>
              </RelativeBackdrop>
            </TabPanel>
          )}
          {isGestorPlanta && (
            <TabPanel
              className="TabPanelOnlyTopPadding"
              value={tabValue}
              index={2}
            >
              <RelativeBackdrop hidden={!loading}>
                <Card>
                  <CardHeader
                    title={i18n.t("gestorUsuarios.nivelAcceso.planta")}
                    subheader={email}
                    avatar={
                      <Avatar style={{ backgroundColor: "#f50057" }}>
                        <Business />
                      </Avatar>
                    }
                  />
                  <CardContent className="p-0">
                    <MaterialTable
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                      }}
                      // @ts-ignore
                      localization={i18n.t("dataTableProps")}
                      data={plantRoles.filter((pr) =>
                        plantas.some((p) => p.id + " " === pr.elementAccess)
                      )}
                      columns={[
                        {
                          title: i18n.t("gestorUsuarios.nivelAcceso.planta"),
                          field: "elementAccess",
                          lookup: plantasOptions,
                          validate: (rowData) =>
                            rowData.elementAccess !== undefined,
                        },
                        {
                          title: i18n.t("common.rol"),
                          field: "roleAccess",
                          lookup: {
                            "gestor-planta": i18n.t("roles.gestor-planta"),
                            "usuario-planta": i18n.t("roles.usuario-planta"),
                          },
                          validate: (rowData) =>
                            isPlantaEditable(rowData) &&
                            rowData.roleAccess !== undefined,
                        },
                      ]}
                      editable={{
                        isEditable: (rowData) => isPlantaEditable(rowData),
                        isDeletable: (rowData) => isPlantaEditable(rowData),
                        onRowAdd: (newData) => addRole(newData, "planta"),
                        onRowUpdate: (newData) => updateRole(newData, "planta"),
                        onRowDelete: (oldData) => deleteRole(oldData, "planta"),
                      }}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        addRowPosition: "first",
                      }}
                    />
                  </CardContent>
                </Card>
              </RelativeBackdrop>
            </TabPanel>
          )}
          {isGestorZona && (
            <TabPanel
              className="TabPanelOnlyTopPadding"
              value={tabValue}
              index={3}
            >
              <RelativeBackdrop hidden={!loading}>
                <Card>
                  <CardHeader
                    title={i18n.t("gestorUsuarios.nivelAcceso.zona")}
                    subheader={email}
                    avatar={
                      <Avatar style={{ backgroundColor: "#f50057" }}>
                        <Room />
                      </Avatar>
                    }
                  ></CardHeader>
                  <CardContent className="p-0">
                    <MaterialTable
                      components={{
                        Container: (props) => (
                          <Paper {...props} elevation={0} />
                        ),
                      }}
                      // @ts-ignore
                      localization={i18n.t("dataTableProps")}
                      data={zoneRoles.filter((zr) =>
                        zonas.some((z) => z.id + " " === zr.elementAccess)
                      )}
                      columns={[
                        {
                          title: i18n.t("gestorUsuarios.nivelAcceso.zona"),
                          field: "elementAccess",
                          lookup: zonasOptions,
                          validate: (rowData) =>
                            rowData.elementAccess !== undefined,
                        },
                        {
                          title: i18n.t("common.rol"),
                          field: "roleAccess",
                          lookup: {
                            "gestor-zona": i18n.t("roles.gestor-zona"),
                            "usuario-zona": i18n.t("roles.usuario-zona"),
                          },
                          validate: (rowData) =>
                            isZonaEditable(rowData) &&
                            rowData.roleAccess !== undefined,
                        },
                      ]}
                      editable={{
                        isEditable: (rowData) => isZonaEditable(rowData),
                        isDeletable: (rowData) => isZonaEditable(rowData),
                        onRowAdd: (newData) => addRole(newData, "zona"),
                        onRowUpdate: (newData) => updateRole(newData, "zona"),
                        onRowDelete: (oldData) => deleteRole(oldData, "zona"),
                      }}
                      options={{
                        actionsColumnIndex: -1,
                        sorting: true,
                        showTitle: false,
                        searchFieldAlignment: "left",
                        addRowPosition: "first",
                      }}
                    />
                  </CardContent>
                </Card>
              </RelativeBackdrop>
            </TabPanel>
          )}
        </>
      )}
    </>
  );
}
